import { DEVSA2_DOMAINS } from "../constants";
import { handleResponse, handleError } from "./apiUtils";
const baseUrl = DEVSA2_DOMAINS.includes(window.location.host) ? 'https://devsa2.amakkn.com' : process.env.REACT_APP_API_URL;

function POST(data) {
  return {
    method: "POST",
    headers: { Accept: "application/json", "content-type": "application/json" },
    body: JSON.stringify({
      ...data,
    }),
  };
}

export function createUpdateNews(data) {
  return fetch(baseUrl + "/Blogs/createUpdateNews/", POST(data))
    .then(handleResponse)
    .catch(handleError);
}
export function getNews(data) {
  return fetch(baseUrl + "/Blogs/getNews/", POST(data))
    .then(handleResponse)
    .catch(handleError);
}
export function getNewsDetail(data) {
  return fetch(baseUrl + "/Blogs/getNewsDetail/", POST(data))
    .then(handleResponse)
    .catch(handleError);
}
export function changeNewsStatus(data) {
  return fetch(baseUrl + "/Blogs/changeNewsStatus/", POST(data))
    .then(handleResponse)
    .catch(handleError);
}