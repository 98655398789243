import TextField from "@mui/material/TextField";
import React, { useState} from "react";
import { getLocalizedText, resolvePersianAndArabicNumbers } from "../../helpers";
import LoadingButton from "../global-components/loading-btn";
import { useAuth } from "../../helpers/context";
import { CLASSIC2_TEMPLATES, CLASSIC_TEMPLATES, DARK_TEMPLATES, MODERN2_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";
import T04WhyUS from "../pro/template-04/why-us";
import T05WhyUS from "../pro/template-05/why-us";
import T06WhyUS from "../pro/template-06/why-us";
import TranslateText from "../global-components/translate-text";
import { InputAdornment } from "@mui/material";

const WhyUsSection = (props) => {
  const [Data, setData] = useState(props.data);
  const { templateId } = useAuth();
  const { t, i18n } = useTranslation();

  return (
    <div className={`whyus-section ${props.isEdit ? 'admin' : ''}`}>
      <div className='row'>
        <div className='col-12'>
          {props.isEdit ? (
            <>
              <TextField
                label={t("Tagline")}
                variant='outlined'
                fullWidth
                multiline
                rows={3}
                inputProps={{ maxLength: 500 }}
                InputLabelProps={{ shrink: true }}
                className='mt-4 text-start'
                value={getLocalizedText(Data.desc, i18n.language)}
                onChange={(v) => {
                  let arabicPart = getLocalizedText(Data.desc, "ar");
                  let englishPart = getLocalizedText(Data.desc, "en");
                  if (i18n.language === "ar")
                    arabicPart = v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value;
                  else
                    englishPart = v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value;
                  let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                  setData({ ...Data, desc: resolvePersianAndArabicNumbers(text) });
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position={i18n.language === "ar" ? "end" : "start"}
                          sx={{
                            position: 'absolute',
                            bottom: '8px',
                            left: i18n.language === "ar" ? 0 : 'unset',
                            right: i18n.language === "en" ? 0 : 'unset',
                            
                          }}>
                          <TranslateText
                            textArea
                            maxLength={500}
                            label={i18n.language === "ar" ? "سطر الوصف باللغة الإنجليزيّة" : "Tagline in Arabic"}
                            text={Data.desc} setText={(text) => {
                              setData({ ...Data, desc: resolvePersianAndArabicNumbers(text) });
                            }} />
                        </InputAdornment>
                    ),
                  },
                }}
              />
              <h6 className='rightalign mt-2 mb-0'>
                {Data.desc
                  ? parseInt(getLocalizedText(Data.desc, i18n.language)?.length) + "/500"
                  : "0/500"}
              </h6>
              <div className="row mt-5 px-2 px-sm-5 pb-2 justify-content-center">
                <div className="col-12 col-md-6 col-lg-4 text-center px-md-3">
                 
                  <TextField
                    label={`${t("TitleOfTheFeature")} 1`}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    className='mt-4'
                    value={getLocalizedText(Data.title1, i18n.language)}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.title1, "ar");
                      let englishPart = getLocalizedText(Data.title1, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, title1: resolvePersianAndArabicNumbers(text) });
                    }}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            <TranslateText
                              label={i18n.language === "ar" ? "عنوان الميزة 1 باللغة الإنجليزيّة" : "Title of the feature 1 in Arabic"}
                              text={Data.title1} setText={(text) => {
                                setData({ ...Data, title1: resolvePersianAndArabicNumbers(text) });
                              }} />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                  <TextField
                    label={`${t("DescriptionOfTheFeature")} 1`}
                    variant='outlined'
                    fullWidth
                    multiline
                    InputLabelProps={{ shrink: true }}
                    rows={4}
                    inputProps={{ maxLength: 200 }}
                    className='mt-4 multiline'
                    value={getLocalizedText(Data.content1, i18n.language)}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position={i18n.language === "ar" ? "end" : "start"}
                            sx={{
                              position: 'absolute',
                              bottom: '8px',
                              left: i18n.language === "ar" ? 0 : 'unset',
                              right: i18n.language === "en" ? 0 : 'unset',
                              
                            }}>
                            <TranslateText
                              textArea
                              maxLength={200}
                              label={i18n.language === "ar" ? "وصف الميزة 1 باللغة الإنجليزيّة" : "Description of the feature 1 in Arabic"}
                              text={Data.content1} setText={(text) => {
                                setData({ ...Data, content1: resolvePersianAndArabicNumbers(text) });
                              }} />
                          </InputAdornment>
                        ),
                      },
                    }}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.content1, "ar");
                      let englishPart = getLocalizedText(Data.content1, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, content1: resolvePersianAndArabicNumbers(text) });
                    }}
                  />
                  <h6 className='rightalign mt-2 mb-0'>
                    {Data.content1
                      ? parseInt(getLocalizedText(Data.content1, i18n.language)?.length) + "/200"
                      : "0/200"}
                  </h6>
                </div>
                <div className="col-12 col-md-6 col-lg-4 text-center px-md-3">
                  <TextField
                    label={`${t("TitleOfTheFeature")} 2`}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    className='mt-4'
                    value={getLocalizedText(Data.title2, i18n.language)}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.title2, "ar");
                      let englishPart = getLocalizedText(Data.title2, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, title2: resolvePersianAndArabicNumbers(text) });
                    }}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            <TranslateText
                              label={i18n.language === "ar" ? "عنوان الميزة 2 باللغة الإنجليزيّة" : "Title of the feature 2 in Arabic"}
                              text={Data.title2} setText={(text) => {
                                setData({ ...Data, title2: resolvePersianAndArabicNumbers(text) });
                              }} />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                  <TextField
                    label={`${t("DescriptionOfTheFeature")} 2`}
                    variant='outlined'
                    fullWidth
                    multiline
                    InputLabelProps={{ shrink: true }}
                    rows={4}
                    inputProps={{ maxLength: 200 }}
                    className='mt-4 multiline'
                    value={getLocalizedText(Data.content2, i18n.language)}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position={i18n.language === "ar" ? "end" : "start"}
                            sx={{
                              position: 'absolute',
                              bottom: '8px',
                              left: i18n.language === "ar" ? 0 : 'unset',
                              right: i18n.language === "en" ? 0 : 'unset',
                              
                            }}>
                            <TranslateText
                              textArea
                              maxLength={200}
                              label={i18n.language === "ar" ? "وصف الميزة 2 باللغة الإنجليزيّة" : "Description of the feature 2 in Arabic"}
                              text={Data.content2} setText={(text) => {
                                setData({ ...Data, content2: resolvePersianAndArabicNumbers(text) });
                              }} />
                          </InputAdornment>
                        ),
                      },
                    }}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.content2, "ar");
                      let englishPart = getLocalizedText(Data.content2, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, content2: resolvePersianAndArabicNumbers(text) });
                    }}
                  />
                  <h6 className='rightalign mt-2 mb-0'>
                    {Data.content2
                      ? parseInt(getLocalizedText(Data.content2, i18n.language)?.length) + "/200"
                      : "0/200"}
                  </h6>
                </div>
                <div className="col-12 col-md-6 col-lg-4 text-center px-md-3">
                  
                  <TextField
                    label={`${t("TitleOfTheFeature")} 3`}
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    className='mt-4'
                    value={getLocalizedText(Data.title3, i18n.language)}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.title3, "ar");
                      let englishPart = getLocalizedText(Data.title3, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, title3: resolvePersianAndArabicNumbers(text) });
                    }}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            <TranslateText
                              label={i18n.language === "ar" ? "عنوان الميزة 3 باللغة الإنجليزيّة" : "Title of the feature 3 in Arabic"}
                              text={Data.title3} setText={(text) => {
                                setData({ ...Data, title3: resolvePersianAndArabicNumbers(text) });
                              }} />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                  <TextField
                    label={`${t("DescriptionOfTheFeature")} 3`}
                    variant='outlined'
                    fullWidth
                    inputProps={{ maxLength: 200 }}
                    InputLabelProps={{ shrink: true }}
                    multiline
                    rows={4}
                    className='mt-4 multiline'
                    value={getLocalizedText(Data.content3, i18n.language)}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position={i18n.language === "ar" ? "end" : "start"}
                            sx={{
                              position: 'absolute',
                              bottom: '8px',
                              left: i18n.language === "ar" ? 0 : 'unset',
                              right: i18n.language === "en" ? 0 : 'unset',
                              
                            }}>
                            <TranslateText
                              textArea
                              maxLength={200}
                              label={i18n.language === "ar" ? "وصف الميزة 3 باللغة الإنجليزيّة" : "Description of the feature 3 in Arabic"}
                              text={Data.content3} setText={(text) => {
                                setData({ ...Data, content3: resolvePersianAndArabicNumbers(text) });
                              }} />
                          </InputAdornment>
                        ),
                      },
                    }}
                    onChange={(v) => {
                      let arabicPart = getLocalizedText(Data.content3, "ar");
                      let englishPart = getLocalizedText(Data.content3, "en");
                      if (i18n.language === "ar")
                        arabicPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      else
                        englishPart = v.currentTarget.value?.length >= 300 ? v.currentTarget.value?.substring(0, 300) : v.currentTarget.value;
                      let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                      setData({ ...Data, content3: resolvePersianAndArabicNumbers(text) });
                    }}
                  />
                  <h6 className='rightalign mt-2 mb-0'>
                    {Data.content3
                      ? parseInt(getLocalizedText(Data.content3, i18n.language)?.length) + "/200"
                      : "0/200"}
                  </h6>
                </div>
              </div>
              <div className='mt-3 '>
                <LoadingButton
                  label={t("SaveChanges")}
                  classes="primary-btn px-3 m-auto"
                  loading={props.loading}
                  handleClick={() => props.saveDetails({ data: Data })}
                />
              </div>
            </>
          ) : CLASSIC_TEMPLATES.includes(+templateId) ? <T04WhyUS data={Data} /> :
            CLASSIC2_TEMPLATES.includes(+templateId) ? <T05WhyUS data={Data} /> :
              MODERN2_TEMPLATES.includes(+templateId) ? <T06WhyUS data={Data} /> :
                (
                  <> {
                      getLocalizedText(Data.title1, i18n.language, false)?.trim() === "" && getLocalizedText(Data.title2, i18n.language, false)?.trim() === "" &&
                      getLocalizedText(Data.title3, i18n.language,false)?.trim() === "" &&
                        getLocalizedText(Data.content1, i18n.language, false)?.trim() === "" && getLocalizedText(Data.content2, i18n.language, false)?.trim() === ""
                        && getLocalizedText(Data.content3, i18n.language,false)?.trim() === "" ?
                      <></> : (
                        <>
                          <div className="col-12 justify-content-center align-items-center flex-column d-flex" >
                            <span className="section-prefix">{t("OurAdvantages")}</span>
                            <h2 className='sec-title'>{t("WhatMakesUsDifferentFromOthers")}</h2>
                              <p className="sec-desc">{getLocalizedText(Data.desc, i18n.language, false)}</p>
                          </div>
                          <div className={`row features-row mt-5`}>
                            {(getLocalizedText(Data.title1, i18n.language, false)?.trim() !== "" || getLocalizedText(Data.content1, i18n.language, false)?.trim() !== "") && (
                              <div className="feature col-12 col-lg-4 col-xl-3">
                                <div className="feature-icon">{[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '.01' : '1'}</div>
                                <h5 className="feature-title">{getLocalizedText(Data.title1, i18n.language, false)}</h5>
                                <p className="feature-desc">{getLocalizedText(Data.content1, i18n.language, false)}</p>
                              </div>
                            )}
                            {(getLocalizedText(Data.title2, i18n.language, false)?.trim() !== "" || getLocalizedText(Data.content2, i18n.language, false)?.trim() !== "") && (
                              <div className="feature col-12 col-lg-4 col-xl-3">
                                <div className="feature-icon">{[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '.02' : '2'}</div>
                                <h5 className="feature-title">{getLocalizedText(Data.title2, i18n.language, false)}</h5>
                                <p className="feature-desc">{getLocalizedText(Data.content2, i18n.language, false)}</p>
                              </div>
                            )}
                            {(getLocalizedText(Data.title3, i18n.language, false)?.trim() !== "" || getLocalizedText(Data.content3, i18n.language, false)?.trim() !== "") && (
                              <div className="feature col-12 col-lg-4 col-xl-3">
                                <div className="feature-icon">{[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '.03' : '3'}</div>
                                <h5 className="feature-title">{getLocalizedText(Data.title3, i18n.language, false)}</h5>
                                <p className="feature-desc">{getLocalizedText(Data.content3, i18n.language, false)}</p>
                              </div>
                            )}
                          </div>
                        </>
                      )
                  }
                  </>
                )}
        </div>
      </div>
    </div>
  );
}

export default WhyUsSection;
