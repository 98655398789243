export const appVersion = '3.5.7';

export const DEFAULT_TEMPLATES = [1, 2];
export const MODERN_TEMPLATES = [3, 4];
export const DARK_TEMPLATES = [5, 6];
export const CLASSIC_TEMPLATES = [7, 8];
export const CLASSIC2_TEMPLATES = [9, 10];
export const MODERN2_TEMPLATES = [11, 12];

// export const WHATSAPP_API = "https://devchat.amakkn.com";

export const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const countryCodes = [
  { label: "SA +966", value: "+966" },
  { value: "+971", label: "UAE +971" },
  // { label: "+963", value: "+963" },
  { value: "+973", label: "BH +973" },
  { value: "+965", label: "KW +965" },
  { value: "+212", label: "MO +212" },
  { value: "+213", label: "AL +213" },
  { value: "+90", label: "TU +90" },
  { value: "+1", label: "USA +1" },
  { value: "+91", label: "IN +91" },
];

export const plansFeatures = {
  6: [
    { name: "FreeWebsite", available: "1" },
    { name: "WebsiteWithIndependentDomain", available: "1" },
    { name: "RealEstateADsMap", available: "1" },
    { name: "ProjectsAndInterests", available: "0" },
    { name: "CRM2", available: "1" },
    { name: "Blog", available: "0" },
    { name: "XAccountAppearance", available: "0" },
    { name: "1Agents", available: "1" },
    { name: "3Templates", available: "1" },
    { name:"LinkWithWhatsapp", available: "0" },
    { name:"LinkWithGTM", available: "0" },
    { name:"LinkWithAnalytics", available: "0" }    
  ],
  7: [
    { name: "FreeWebsite", available: "1" },
    { name: "WebsiteWithIndependentDomain", available: "1" },
    { name: "RealEstateADsMap", available: "1" },
    { name: "ProjectsAndInterests", available: "0" },
    { name: "CRM2", available: "1" },
    { name: "Blog", available: "0" },
    { name: "XAccountAppearance", available: "0" },
    { name: "1Agents", available: "1" },
    { name: "3Templates", available: "1" },
    { name:"LinkWithWhatsapp", available: "0" },
    { name:"LinkWithGTM", available: "0" },
    { name:"LinkWithAnalytics", available: "0" }  
  ],
  8: [
    { name: "FreeWebsite", available: "1" },
    { name: "WebsiteWithIndependentDomain", available: "1" },
    { name: "RealEstateADsMap", available: "1" },
    { name: "ProjectsAndInterests", available: "1" },
    { name: "CRM2", available: "1" },
    { name: "Blog", available: "1" },
    { name: "XAccountAppearance", available: "1" },
    { name: "3Agents", available: "1" },
    { name: "10Templates", available: "1" },
    { name:"LinkWithWhatsapp", available: "1" },
    { name:"LinkWithGTM", available: "1" },
    { name:"LinkWithAnalytics", available: "1" }  
  ],
  9: [
    { name: "FreeWebsite", available: "1" },
    { name: "WebsiteWithIndependentDomain", available: "1" },
    { name: "RealEstateADsMap", available: "1" },
    { name: "ProjectsAndInterests", available: "1" },
    { name: "CRM2", available: "1" },
    { name: "Blog", available: "1" },
    { name: "XAccountAppearance", available: "1" },
    { name: "3Agents", available: "1" },
    { name: "10Templates", available: "1" },
    { name:"LinkWithWhatsapp", available: "1" },
    { name:"LinkWithGTM", available: "1" },
    { name:"LinkWithAnalytics", available: "1" }  
  ]
};

export const blackListDomains = ["www", "devsa2", "prodsa1"];

export const FREE_PLANS = [1, 4];
export const TRAIL_PLANS = [9, 10, 11, 12];
export const plansAvailabilty = {
  1: [],
  2: ["6", "7", "8", "9"],
};
export const NORMAL_PLANS_AVAILABILITY = {
  '1': ["2", "3"],
  '2': ["5", "6", '9', '10', '11', '12'],
}

export const wlPlansAvailabilty = {
  1: [],
  2: ['6', '7', "8", "9"],
}

export const premiumPlans = {
  1: ["3", "5"],
  2: ["7", "9"],
};

export const HIDDEN_FEATURES = [
  "1",
  "2",
  "3",
  "6",
  "7",
  "8",
  "9",
  "10",
  // "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "22",
  "23",
  "24",
  "26",
  "27",
  "29",
  "30",
  "32",
  "33",
  "34",
  "35",
  "36",
  "38",
  '42',
  '52',
  '39',
  '51',
  '40',
  '41',
  '50',
  '60'
];
export const HIDDEN_ROOMS = ["8", "12", "15",'27', '25', '43', '40', '60', '61'];
export const HIDDEN_ADITTIONAL_INFO_FOR_ADD = [
  "1",
  "4",
  "5",
  "6",
  "7",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  '22',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '50',
  '51',
  '52',
  '54',
  '55',
  '53',
  '8',
  '39',
  '56',
  '60'
];
export const HIDDEN_ADITTIONAL_INFO_FOR_PAPER = ["5", "6", "7", '50', '51', '52', '38', '56', '10', '23', '18', '21'];
export const HIDDEN_ADDITIONAL_INFO_IF_NO = ["19", "20", "14", "12", "13", "15", "30", "31", "33", "36", "37"];
export const IGNORED_VALIDATION_KEYS = ['1', '2', '3', '6', '7', '9', '10','12', '19', '23', '24', '26', '27', '29', '30', '32', '38', '39', '41', '42', '50', '51', '52', '60'];
export const PLANS_YEARLY_DISCOUNT = 10;

export const INDIVIDUAL_LICENSE_URI =
  "https://eservicesredp.rega.gov.sa/public/IndividualBroker/LicenseDetails/Bml/";
export const CORPORATE_LICENSE_URI =
  "https://eservicesredp.rega.gov.sa/public/OfficesBroker/LicenseDetails/Bml/";

export const AD_THROUGH = [
  { id: 1, value: 'OwnerOffice', label: 'مالك - منشأة' },
  { id: 2, value: 'OwnerIndividual', label: 'مالك - فرد' },
  { id: 3, value: 'OwnerAgent', label: 'وكيل المالك' },
  { id: 4, value: 'BrokerIndividual', label: 'وسيط - فرد' },
  { id: 5, value: 'BrokerOffice', label: 'وسيط - منشأة' }
];

export const AD_CHANNELS = [
  { id:'1',value: 'LicensedPlatform', label: 'منصة مرخصة' },
  { id:'2',value: 'BulletinBoard', label: 'لوحة إعلانية' },
  { id:"3",value: 'SocialMediaPlatforms', label: 'منصات التواصل الإجتماعي' },
  { id:"4",value: 'Radio', label: 'الإذاعة' },
  { id:"5",value: 'Other', label: 'أخرى' },
];

export const AD_TYPES = [
  { id: "1", value: 'Sell', label: 'بيع' },
  { id: "2", value: 'Rent', label: 'ايجار' }
];

export const AD_PROPERTY_USAGE = [
  { id: "1", value: 'Agricultural', label: 'زراعي', visibleFor:['1','7','25'] },
  { id: "2", value: 'Residential', label: 'سكني' , visibleFor:['1','2','3','4','5','6','7','8','16','26'] },
  { id: "3", value: 'Commercial', label: 'تجاري', visibleFor:['1','7','8','9','10','11','12','13','14','15','16','17','18','20','21','24','26'] },
  { id: "4", value: 'Industrial', label: 'صناعي' , visibleFor:['1','12','16','17','19']},
  { id: "5", value: 'Healthy', label: 'صحي' , visibleFor:['1','21'] },
  { id: "6", value: 'Educational', label: 'تعليمي', visibleFor:['1','20'] }
];
export const AD_PROPERTY_UTILITIES = [
  { id: "1", value: 'Electricity', label: 'كهرباء' },
  { id: "2", value: 'Waters', label: 'مياه' },
  { id: "3", value: 'Sanitation', label: 'صرف صحي' },
  { id: "5", value: 'FixedPhone', label: 'هاتف' },
  { id: "6", value: 'FibreOptics', label: 'الياف ضوئية' },
  { id: "7", value: 'FloodDrainage', label: 'تصريف الفيضانات' }
  // “code”: “NoServices”,“name”: “لايوجد خدمات”
];
export const AD_PROPERTY_TYPES = [
  { id: "1", value: 'Land', label: 'أرض' },
  { id: "2", value: 'Floor', label: 'دور' },
  { id: "3", value: 'Apartment', label: 'شقة' },
  { id: "4", value: 'Villa', label: 'فيلا' },
  { id: "5", value: 'Studio', label: 'استوديو' },
  { id: "6", value: 'Room', label: 'غرفة' },
  { id: "7", value: 'RestHouse', label: 'استراحة' },
  // { id: "8", value: 'Compound', label: 'مجمع' },
  // { id: "9", value: 'Tower', label: 'برج' },
  { id: "10", value: 'Exhibition', label: 'معرض' },
  { id: "11", value: 'Office', label: 'مكتب' },
  { id: "12", value: 'Warehouses', label: 'مستودع' },
  // { id: "13", value: 'Booth', label: 'كشك' },
  // { id: "14", value: 'Cinema', label: 'سينما' },
  // { id: "15", value: 'Hotel', label: 'فندق' },
  // { id: "16", value: 'CarParking', label: 'مواقف سيارات' },
  // { id: "17", value: 'RepairShop', label: 'ورشة' },
  // { id: "18", value: 'Teller', label: 'صراف' },
  // { id: "19", value: 'Factory', label: 'مصنع' },
  // { id: "20", value: 'School', label: 'مدرسة' },
  // { id: "21", value: 'HospitalOrHealthCenter', label: 'مستشفى، مركز صحي' },
  // { id: "22", value: 'ElectricityStation', label: 'محطة كهرباء' },
  // { id: "23", value: 'TelecomTower', label: 'برج اتصالات' },
  // { id: "24", value: 'Station', label: 'محطة' },
  { id: "25", value: 'Farm', label: 'مزرعة' },
  { id: "26", value: 'Building', label: 'عمارة' }
];

export const AD_PROPERTY_FACES = [
  { id: "1", value: 'Eastern', label: 'شرقية' },
  { id: "2", value: 'Western', label: 'غربية' },
  { id: "3", value: 'Northern', label: 'شمالية' },
  { id: "4", value: 'Southern', label: 'جنوبية' },
  { id: "5", value: 'Northeast', label: 'شمالية شرقية' },
  { id: "6", value: 'Southeast', label: 'جنوبية شرقية' },
  { id: "7", value: 'Southwest', label: 'جنوبية غربية' },
  { id: "8", value: 'NorthWest', label: 'شمالية غربية' },
  { id: "9", value: 'ThreeStreets', label: 'ثلاثة شوارع' },
  { id: "10", value: 'FourStreets', label: 'اربعة شوارع' }
];

export const AD_PROPERTY_AGES = [
  { id: "1", value: 'New', label: 'جديد' },
  { id: "2", value: 'LessThanYear', label: 'اقل من سنة' },
  { id: "3", value: 'OneYear', label: 'سنة' },
  { id: "4", value: 'TwoYears', label: 'سنتين' },
  { id: "5", value: 'ThreeYears', label: 'ثلاث سنوات' },
  { id: "6", value: 'FourYears', label: 'اربع سنوات' },
  { id: "7", value: 'FiveYears', label: 'خمس سنوات' },
  { id: "8", value: 'SixYears', label: 'ست سنوات' },
  { id: "9", value: 'SevenYears', label: 'سبع سنوات' },
  { id: "10", value: 'EightYears', label: 'ثمان سنوات' },
  { id: "11", value: 'NineYears', label: 'تسع سنوات' },
  { id: "12", value: 'TenYears', label: 'عشر سنوات' },
  { id: "13", value: 'MoreThenTenYears', label: 'اكثر من عشر سنوات' },
];

export const REGIONS = [
  {
    "REGION_ID": "1",
    "REGIONNAME_AR": "الرياض",
    "REGIONNAME_EN": "Riyadh",
    "REGION_LAT": "24.731864",
    "REGION_LONG": "46.774292"
  },
  {
    "REGION_ID": "2",
    "REGIONNAME_AR": "مكة المكرمة",
    "REGIONNAME_EN": "Makkah Al Mukarramah",
    "REGION_LAT": "21.406328",
    "REGION_LONG": "39.809088"
  },
  {
    "REGION_ID": "3",
    "REGIONNAME_AR": "المدينة المنورة",
    "REGIONNAME_EN": "Al Madinah Al Munawwarah",
    "REGION_LAT": "24.427145",
    "REGION_LONG": "39.649658"
  },
  {
    "REGION_ID": "4",
    "REGIONNAME_AR": "القصيم",
    "REGIONNAME_EN": "Al Qassim",
    "REGION_LAT": "26.338499",
    "REGION_LONG": "43.965396"
  },
  {
    "REGION_ID": "5",
    "REGIONNAME_AR": "المنطقة الشرقية",
    "REGIONNAME_EN": "Eastern",
    "REGION_LAT": "26.372185",
    "REGION_LONG": "49.993286"
  },
  {
    "REGION_ID": "6",
    "REGIONNAME_AR": "عسير",
    "REGIONNAME_EN": "Asir",
    "REGION_LAT": "18.20848",
    "REGION_LONG": "42.533569"
  },
  {
    "REGION_ID": "7",
    "REGIONNAME_AR": "تبوك",
    "REGIONNAME_EN": "Tabuk",
    "REGION_LAT": "28.401064",
    "REGION_LONG": "36.573486"
  },
  {
    "REGION_ID": "8",
    "REGIONNAME_AR": "حائل",
    "REGIONNAME_EN": "Hail",
    "REGION_LAT": "27.527758",
    "REGION_LONG": "41.698608"
  },
  {
    "REGION_ID": "9",
    "REGIONNAME_AR": "الحدود الشماليه",
    "REGIONNAME_EN": "Northern Borders",
    "REGION_LAT": "30.977609",
    "REGION_LONG": "41.011962"
  },
  {
    "REGION_ID": "10",
    "REGIONNAME_AR": "جازان",
    "REGIONNAME_EN": "Jazan",
    "REGION_LAT": "16.890959",
    "REGION_LONG": "42.548375"
  },
  {
    "REGION_ID": "11",
    "REGIONNAME_AR": "نجران",
    "REGIONNAME_EN": "Najran",
    "REGION_LAT": "17.489489",
    "REGION_LONG": "44.134333"
  },
  {
    "REGION_ID": "12",
    "REGIONNAME_AR": "الباحة",
    "REGIONNAME_EN": "Al Bahah",
    "REGION_LAT": "20.014645",
    "REGION_LONG": "41.456909"
  },
  {
    "REGION_ID": "13",
    "REGIONNAME_AR": "الجوف",
    "REGIONNAME_EN": "Al Jawf",
    "REGION_LAT": "29.971888",
    "REGION_LONG": "40.200476"
  }
];

export const CITIES = [
   {
    "CITY_ID": "21282",
    "CITYNAME_AR": "الرياض",
    "CITYNAME_EN": "Riyadh",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "1338",
    "CITYNAME_AR": "وادى بن هشبل",
    "CITYNAME_EN": "Wadi Ibn Hashbal",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1339",
    "CITYNAME_AR": "خميس مشيط",
    "CITYNAME_EN": "Khamis Mushait",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "1463",
    "CITYNAME_AR": "قلوه",
    "CITYNAME_EN": "Qilwah",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "1776",
    "CITYNAME_AR": "طلعه التمياط",
    "CITYNAME_EN": "Talaeah Altamyat",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1778",
    "CITYNAME_AR": "عرعر",
    "CITYNAME_EN": "Earear",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1779",
    "CITYNAME_AR": "الشاظي مناحي بن بكر",
    "CITYNAME_EN": "Alshazi Manahy Bin Bikr",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1781",
    "CITYNAME_AR": "جديده عرعر",
    "CITYNAME_EN": "Jadiduh Earear",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1782",
    "CITYNAME_AR": "حزم الجلاميد",
    "CITYNAME_EN": "Huzam Aljalamid",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "1808",
    "CITYNAME_AR": "الشملى",
    "CITYNAME_EN": "Alshumlaa",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1863",
    "CITYNAME_AR": "سميراء",
    "CITYNAME_EN": "Samira'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "1907",
    "CITYNAME_AR": "قريه العليا",
    "CITYNAME_EN": "Qaryat al-Ulya",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "2017",
    "CITYNAME_AR": "العلا",
    "CITYNAME_EN": "Al-Ula",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "2094",
    "CITYNAME_AR": "ضريه",
    "CITYNAME_EN": "Darih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "2401",
    "CITYNAME_AR": "البشائر",
    "CITYNAME_EN": "Al Bashayer",
    "REGION_ID": "6"
   },   
   {
    "CITY_ID": "2499",
    "CITYNAME_AR": "النماص",
    "CITYNAME_EN": "Al-Namas",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2607",
    "CITYNAME_AR": "تثليث",
    "CITYNAME_EN": "Tathlith",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2694",
    "CITYNAME_AR": "بيشه",
    "CITYNAME_EN": "Bisha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "2792",
    "CITYNAME_AR": "الحرجه",
    "CITYNAME_EN": "Al-Harjah",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3086",
    "CITYNAME_AR": "سراة عبيدة",
    "CITYNAME_EN": "Sarat Ubaida",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "3295",
    "CITYNAME_AR": "عقله الصقور",
    "CITYNAME_EN": "Eaqalah Alsuqur",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "3458",
    "CITYNAME_AR": "السليمي",
    "CITYNAME_EN": "Alsalimi",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "3553",
    "CITYNAME_AR": "بني كبير",
    "CITYNAME_EN": "Bani Kabir",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3554",
    "CITYNAME_AR": "بلجرشى",
    "CITYNAME_EN": "Baljurashi",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3581",
    "CITYNAME_AR": "البطين",
    "CITYNAME_EN": "Albatin",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "3696",
    "CITYNAME_AR": "بني حسن",
    "CITYNAME_EN": "Al-Tho'ban",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "3731",
    "CITYNAME_AR": "نجران",
    "CITYNAME_EN": "Najran",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3735",
    "CITYNAME_AR": "المنخلي",
    "CITYNAME_EN": "Al-Mankhali",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3736",
    "CITYNAME_AR": "شروره",
    "CITYNAME_EN": "Sharurah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3739",
    "CITYNAME_AR": "تماني",
    "CITYNAME_EN": "Tamani",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3755",
    "CITYNAME_AR": "يدمه",
    "CITYNAME_EN": "Yadamah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "3795",
    "CITYNAME_AR": "حبونا",
    "CITYNAME_EN": "Hubuna",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4001",
    "CITYNAME_AR": "خباش",
    "CITYNAME_EN": "Khbash",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4014",
    "CITYNAME_AR": "الحصينية",
    "CITYNAME_EN": "Al Husayniyah",
    "REGION_ID": "11"
   },
   {
    "CITY_ID": "4177",
    "CITYNAME_AR": "بلقرن",
    "CITYNAME_EN": "Balqarn",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4205",
    "CITYNAME_AR": "طريب",
    "CITYNAME_EN": "Tarib",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4393",
    "CITYNAME_AR": "ظهران الجنوب",
    "CITYNAME_EN": "Dhahran Al Janub",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "4492",
    "CITYNAME_AR": "أضم",
    "CITYNAME_EN": "Adham",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "6011",
    "CITYNAME_AR": "صبح بلحمر",
    "CITYNAME_EN": "Sabah Billahmar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6013",
    "CITYNAME_AR": "الواديين",
    "CITYNAME_EN": "Al-Wadyin Station ",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6099",
    "CITYNAME_AR": "فرسان",
    "CITYNAME_EN": "Farsan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "6114",
    "CITYNAME_AR": "عنيزه",
    "CITYNAME_EN": "Eanizah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6121",
    "CITYNAME_AR": "القطيف",
    "CITYNAME_EN": "Qatif",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "6145",
    "CITYNAME_AR": "البدائع",
    "CITYNAME_EN": "Albadayie",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "6166",
    "CITYNAME_AR": "ابها",
    "CITYNAME_EN": "Abha",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "6605",
    "CITYNAME_AR": "يبرين",
    "CITYNAME_EN": "Yabrin",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "7390",
    "CITYNAME_AR": "مليجه",
    "CITYNAME_EN": "Mulayjah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "7453",
    "CITYNAME_AR": "المجارده",
    "CITYNAME_EN": "Almjardh",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "7978",
    "CITYNAME_AR": "الاطاوله",
    "CITYNAME_EN": "Al-Atawilah ",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "9010",
    "CITYNAME_AR": "النقيع",
    "CITYNAME_EN": "Al Negea'",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "9192",
    "CITYNAME_AR": "البرك",
    "CITYNAME_EN": "Albarik",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "10061",
    "CITYNAME_AR": "بقعاء",
    "CITYNAME_EN": "Biqaea'",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10099",
    "CITYNAME_AR": "تربة",
    "CITYNAME_EN": "Turba",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "10105",
    "CITYNAME_AR": "تمير",
    "CITYNAME_EN": "Tameer",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "10907",
    "CITYNAME_AR": "رجال المع",
    "CITYNAME_EN": "Rijal Al Ma",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11018",
    "CITYNAME_AR": "السعيره",
    "CITYNAME_EN": "As Saera",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11019",
    "CITYNAME_AR": "معرج السوبان",
    "CITYNAME_EN": "Marj Allsoban",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11020",
    "CITYNAME_AR": "خبيراء",
    "CITYNAME_EN": "Khobaira",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11022",
    "CITYNAME_AR": "المتياهه الجنوبيه",
    "CITYNAME_EN": "Al Metyahat Al Janoubiyah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11023",
    "CITYNAME_AR": "الصداوى",
    "CITYNAME_EN": "As Sadawi",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11024",
    "CITYNAME_AR": "ام كداد",
    "CITYNAME_EN": "Um Kedad",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11025",
    "CITYNAME_AR": "مناخ",
    "CITYNAME_EN": "Manakh",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11026",
    "CITYNAME_AR": "الحيراء",
    "CITYNAME_EN": "Al Hairaa",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11027",
    "CITYNAME_AR": "القيصومه",
    "CITYNAME_EN": "Al Qaisumah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11028",
    "CITYNAME_AR": "ام قليب",
    "CITYNAME_EN": "Umm Qulayb",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11031",
    "CITYNAME_AR": "الذيبيه",
    "CITYNAME_EN": "Thebea",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11032",
    "CITYNAME_AR": "ساموده",
    "CITYNAME_EN": "Samoudah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11034",
    "CITYNAME_AR": "ام عشر الشرقيه",
    "CITYNAME_EN": "UmmOshr (the Eastern) ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11035",
    "CITYNAME_AR": "الفاو الشمالى",
    "CITYNAME_EN": "Al Faw Ash Shamli",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11036",
    "CITYNAME_AR": "الرفيعه ",
    "CITYNAME_EN": "Al Rofayah",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11044",
    "CITYNAME_AR": "البويبيات",
    "CITYNAME_EN": "Al Buwaybat",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11045",
    "CITYNAME_AR": "الخبر",
    "CITYNAME_EN": "Khobar",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11048",
    "CITYNAME_AR": "الدمام",
    "CITYNAME_EN": "Aldammam",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11056",
    "CITYNAME_AR": "بقيق",
    "CITYNAME_EN": "Abqaiq",
    "REGION_ID": "5"
   },  
   {
    "CITY_ID": "11073",
    "CITYNAME_AR": "النعيريه",
    "CITYNAME_EN": "Nariya",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11308",
    "CITYNAME_AR": "محائل",
    "CITYNAME_EN": "Muhayil",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "11858",
    "CITYNAME_AR": "البديع",
    "CITYNAME_EN": "Al-Badey",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "11868",
    "CITYNAME_AR": "الخفجى",
    "CITYNAME_EN": "Al Khafji",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "11875",
    "CITYNAME_AR": "القرين",
    "CITYNAME_EN": "Alqarin",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11877",
    "CITYNAME_AR": "رياض الخبراء",
    "CITYNAME_EN": "Riad Alkhubara'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11880",
    "CITYNAME_AR": "الخبراء والسحابين",
    "CITYNAME_EN": "Alkhubara' Wal Sahabeen",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11882",
    "CITYNAME_AR": "عيون الجواء",
    "CITYNAME_EN": "Euyun Aljawa'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11890",
    "CITYNAME_AR": "دخنه",
    "CITYNAME_EN": "Dakhnh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11951",
    "CITYNAME_AR": "القواره",
    "CITYNAME_EN": "Alqawaruh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11969",
    "CITYNAME_AR": "قصيباء الجديده",
    "CITYNAME_EN": "Qasiba' Aljadidih",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "11983",
    "CITYNAME_AR": "قصرابن عقيل",
    "CITYNAME_EN": "Qasirabin Eaqil",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "12042",
    "CITYNAME_AR": "المندق",
    "CITYNAME_EN": "Al-mandaq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "12190",
    "CITYNAME_AR": "طريف",
    "CITYNAME_EN": "Tarif",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12195",
    "CITYNAME_AR": "دومة الجندل",
    "CITYNAME_EN": "Dumat Al-Jandal",
    "REGION_ID": "13"
   },
   {
    "CITY_ID": "12200",
    "CITYNAME_AR": "شعبه نصاب",
    "CITYNAME_EN": "Shaebih Nasab",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12220",
    "CITYNAME_AR": "ابن شريم",
    "CITYNAME_EN": "Ibn Sharim",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12223",
    "CITYNAME_AR": "العجرميه",
    "CITYNAME_EN": "Aleijramih",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12224",
    "CITYNAME_AR": "قيصومه فيحان",
    "CITYNAME_EN": "Qayasumuh Fayhan",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12225",
    "CITYNAME_AR": "لوقة",
    "CITYNAME_EN": "Lawqa",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12226",
    "CITYNAME_AR": "رفحاء",
    "CITYNAME_EN": "Rafha'",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12293",
    "CITYNAME_AR": "بيش",
    "CITYNAME_EN": "Baysh",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "12515",
    "CITYNAME_AR": "ام خنصر",
    "CITYNAME_EN": "Umm Khansur",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12517",
    "CITYNAME_AR": "المركوز ويشمل",
    "CITYNAME_EN": "Almarkuz Wayashmal",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12518",
    "CITYNAME_AR": "العويقيله",
    "CITYNAME_EN": "Aleuyqilh",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12519",
    "CITYNAME_AR": "الخر",
    "CITYNAME_EN": "Alkharu Hajaruh Bin Eaysh",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12522",
    "CITYNAME_AR": "الكاسب",
    "CITYNAME_EN": "Alkasib",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12523",
    "CITYNAME_AR": "ابورواث",
    "CITYNAME_EN": "Abwrwath",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12526",
    "CITYNAME_AR": "ساده الخر   نعيجان",
    "CITYNAME_EN": "Sadah Alkharu Nueayjan",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "12532",
    "CITYNAME_AR": "سكاكا",
    "CITYNAME_EN": "Sakakah",
    "REGION_ID": "13"
   },
   {
    "CITY_ID": "12536",
    "CITYNAME_AR": "القريات",
    "CITYNAME_EN": "Qurayyat",
    "REGION_ID": "13"
   },
   {
    "CITY_ID": "12548",
    "CITYNAME_AR": "العيساويه",
    "CITYNAME_EN": "Al Issawiyah",
    "REGION_ID": "13"
   },
   {
    "CITY_ID": "12578",
    "CITYNAME_AR": "تيماء",
    "CITYNAME_EN": "Tima'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12587",
    "CITYNAME_AR": "املج",
    "CITYNAME_EN": "Amlij",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12655",
    "CITYNAME_AR": "ضباء",
    "CITYNAME_EN": "Daba'",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12679",
    "CITYNAME_AR": "الوجه",
    "CITYNAME_EN": "Alwajh",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12694",
    "CITYNAME_AR": "حقل",
    "CITYNAME_EN": "Haql",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12703",
    "CITYNAME_AR": "البدع",
    "CITYNAME_EN": "Albadae",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "12713",
    "CITYNAME_AR": "بئر بن هرماس",
    "CITYNAME_EN": "Bayir Bin Hirmas",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "13427",
    "CITYNAME_AR": "حفر الباطن",
    "CITYNAME_EN": "Hafar Al-Batin",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13428",
    "CITYNAME_AR": "درب الابل",
    "CITYNAME_EN": "Darb Al-Ibil ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13429",
    "CITYNAME_AR": "القلت",
    "CITYNAME_EN": "Al Qalt",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13430",
    "CITYNAME_AR": "الرقعى",
    "CITYNAME_EN": "Ar Ruqi",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13431",
    "CITYNAME_AR": "الرقعى الجديده",
    "CITYNAME_EN": "Al-Roq'a Al-Jadidah ",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13432",
    "CITYNAME_AR": "النظيم",
    "CITYNAME_EN": "Al Nadhim",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13433",
    "CITYNAME_AR": "الحماطيات",
    "CITYNAME_EN": "Al Hamatiyat",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13674",
    "CITYNAME_AR": "حائل",
    "CITYNAME_EN": "Hayil",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "13769",
    "CITYNAME_AR": "العيون",
    "CITYNAME_EN": "Aleuyun",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13789",
    "CITYNAME_AR": "الاحساء",
    "CITYNAME_EN": "Al Ahsa",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "13976",
    "CITYNAME_AR": "البصر",
    "CITYNAME_EN": "Albasar",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14001",
    "CITYNAME_AR": "المدينه المنوره",
    "CITYNAME_EN": "Madinah",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "14237",
    "CITYNAME_AR": "الرس",
    "CITYNAME_EN": "Alrasu",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14244",
    "CITYNAME_AR": "الباحة",
    "CITYNAME_EN": "Al Baha",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "14254",
    "CITYNAME_AR": "البكيريه",
    "CITYNAME_EN": "Albikiriuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14261",
    "CITYNAME_AR": "المذنب",
    "CITYNAME_EN": "Almudhanib",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14282",
    "CITYNAME_AR": "الاسياح",
    "CITYNAME_EN": "Al Asyah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14283",
    "CITYNAME_AR": "البرقاء",
    "CITYNAME_EN": "Alburqa'",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14286",
    "CITYNAME_AR": "خصيبه",
    "CITYNAME_EN": "Khasibuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14293",
    "CITYNAME_AR": "طريف",
    "CITYNAME_EN": "Tarif",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14294",
    "CITYNAME_AR": "البرود",
    "CITYNAME_EN": "Alburud",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14295",
    "CITYNAME_AR": "التنومه",
    "CITYNAME_EN": "Altanawumuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14301",
    "CITYNAME_AR": "قبه",
    "CITYNAME_EN": "Qabah",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "14304",
    "CITYNAME_AR": "ابو عريش",
    "CITYNAME_EN": "'Abu Earish",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14463",
    "CITYNAME_AR": "صامطه",
    "CITYNAME_EN": "Samith",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14490",
    "CITYNAME_AR": "احد المسارحه",
    "CITYNAME_EN": "Ahad al Masarihah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14494",
    "CITYNAME_AR": "البيطاريه",
    "CITYNAME_EN": "Al Baytaryah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14495",
    "CITYNAME_AR": "القائم",
    "CITYNAME_EN": "Al Qaem",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14500",
    "CITYNAME_AR": "المنجاره",
    "CITYNAME_EN": "Al Monjarah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14567",
    "CITYNAME_AR": "الحصامه",
    "CITYNAME_EN": "Al Husamah",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "14722",
    "CITYNAME_AR": "ضمد",
    "CITYNAME_EN": "Damad",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15413",
    "CITYNAME_AR": "جبه",
    "CITYNAME_EN": "Jabah",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15423",
    "CITYNAME_AR": "مكه المكرمه",
    "CITYNAME_EN": "Makah Almukaramuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15618",
    "CITYNAME_AR": "بحره",
    "CITYNAME_EN": "Bahra",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "15716",
    "CITYNAME_AR": "جازان",
    "CITYNAME_EN": "Jazan",
    "REGION_ID": "10"
   },
   {
    "CITY_ID": "15718",
    "CITYNAME_AR": "بريده",
    "CITYNAME_EN": "Bariduh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "15817",
    "CITYNAME_AR": "شبيكان",
    "CITYNAME_EN": "Shabikan",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15883",
    "CITYNAME_AR": "الاجفر",
    "CITYNAME_EN": "Alajfir",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "15888",
    "CITYNAME_AR": "لينه",
    "CITYNAME_EN": "Laynah",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15889",
    "CITYNAME_AR": "رغوه الجديده",
    "CITYNAME_EN": "Raghuh Aljadiduh",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15894",
    "CITYNAME_AR": "الحدقة",
    "CITYNAME_EN": "Alhadqa",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15897",
    "CITYNAME_AR": "ابوصور بن جبرين",
    "CITYNAME_EN": "Abwusur Bin Jabrin",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15898",
    "CITYNAME_AR": "القصوريات",
    "CITYNAME_EN": "Alqusuriat",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15899",
    "CITYNAME_AR": "روضة هباس",
    "CITYNAME_EN": "Rawdat Hibas",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15900",
    "CITYNAME_AR": "الخشيبي",
    "CITYNAME_EN": "Alkhashibi",
    "REGION_ID": "9"
   },
   {
    "CITY_ID": "15902",
    "CITYNAME_AR": "بدر",
    "CITYNAME_EN": "Badr",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "15984",
    "CITYNAME_AR": "خيبر",
    "CITYNAME_EN": "Khaybar",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "16099",
    "CITYNAME_AR": "بدائع الدلابحه",
    "CITYNAME_EN": "Bade' Ad-Dalabhah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "16105",
    "CITYNAME_AR": "رفايع الجمش",
    "CITYNAME_EN": "Rafaeya'a Al-Jumsh",
    "REGION_ID": "1"
   }, 
   {
    "CITY_ID": "16161",
    "CITYNAME_AR": "نجخ",
    "CITYNAME_EN": "Najkh",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "16166",
    "CITYNAME_AR": "المويه",
    "CITYNAME_EN": "Al Muwayh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16265",
    "CITYNAME_AR": "خليص",
    "CITYNAME_EN": "Khalis",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16515",
    "CITYNAME_AR": "الجموم",
    "CITYNAME_EN": "Aljumum",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16594",
    "CITYNAME_AR": "رابغ",
    "CITYNAME_EN": "Rabigh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16655",
    "CITYNAME_AR": "القنفذه",
    "CITYNAME_EN": "Alqunafdhuh",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16791",
    "CITYNAME_AR": "الليث",
    "CITYNAME_EN": "Alliyth",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "16877",
    "CITYNAME_AR": "القوز",
    "CITYNAME_EN": "Alqawz",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "17419",
    "CITYNAME_AR": "الحائط",
    "CITYNAME_EN": "Alhayit",
    "REGION_ID": "8"
   },
   {
    "CITY_ID": "17735",
    "CITYNAME_AR": "حوطه سدير",
    "CITYNAME_EN": "Hautat Sudair",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "17743",
    "CITYNAME_AR": "السليل",
    "CITYNAME_EN": "Alsalil",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "17989",
    "CITYNAME_AR": "الشماسيه",
    "CITYNAME_EN": "Alshamasiuh",
    "REGION_ID": "4"
   },
   {
    "CITY_ID": "18048",
    "CITYNAME_AR": "بلسمر",
    "CITYNAME_EN": "Billasmar",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18074",
    "CITYNAME_AR": "رماح",
    "CITYNAME_EN": "Ramah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "18088",
    "CITYNAME_AR": "القصب",
    "CITYNAME_EN": "Al-Qassab",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "18095",
    "CITYNAME_AR": "العقيق",
    "CITYNAME_EN": "Al-Aqiq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18224",
    "CITYNAME_AR": "معشوقه",
    "CITYNAME_EN": "Ma'shouq",
    "REGION_ID": "12"
   },
   {
    "CITY_ID": "18394",
    "CITYNAME_AR": "جدة",
    "CITYNAME_EN": "Jida",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18396",
    "CITYNAME_AR": "ثول",
    "CITYNAME_EN": "Thawl",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18399",
    "CITYNAME_AR": "تنومة",
    "CITYNAME_EN": "Tanawma",
    "REGION_ID": "6"
   },
   {
    "CITY_ID": "18485",
    "CITYNAME_AR": "تربه",
    "CITYNAME_EN": "Turayah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "18543",
    "CITYNAME_AR": "نمره",
    "CITYNAME_EN": "Namerah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19269",
    "CITYNAME_AR": "ينبع",
    "CITYNAME_EN": "Yanbu",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19300",
    "CITYNAME_AR": "الحناكيه",
    "CITYNAME_EN": "Alhanakiuh",
    "REGION_ID": "3"
   },
   {
    "CITY_ID": "19352",
    "CITYNAME_AR": "المجمعه",
    "CITYNAME_EN": "Al-Majma'ah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19366",
    "CITYNAME_AR": "الجبيل   ",
    "CITYNAME_EN": "Jubail",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "19373",
    "CITYNAME_AR": "رأس تنورة",
    "CITYNAME_EN": "Ras Tanura",
    "REGION_ID": "5"
   },
   {
    "CITY_ID": "19375",
    "CITYNAME_AR": "تبوك",
    "CITYNAME_EN": "Tbwk",
    "REGION_ID": "7"
   },
   {
    "CITY_ID": "19403",
    "CITYNAME_AR": "الطائف",
    "CITYNAME_EN": "Alttayif",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "19913",
    "CITYNAME_AR": "الربوه",
    "CITYNAME_EN": "Ar-Rabwah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19919",
    "CITYNAME_AR": "الرزيوه",
    "CITYNAME_EN": "Al Razywah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19931",
    "CITYNAME_AR": "اللكه",
    "CITYNAME_EN": "Al Lakaah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19939",
    "CITYNAME_AR": "مخطط الركا",
    "CITYNAME_EN": "Mukhatat Al Raka",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19960",
    "CITYNAME_AR": "الحصاة",
    "CITYNAME_EN": "Al Hesah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "19963",
    "CITYNAME_AR": "الخرمه",
    "CITYNAME_EN": "Al-Khurma",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20038",
    "CITYNAME_AR": "رنيه",
    "CITYNAME_EN": "Ranyah",
    "REGION_ID": "2"
   },
   {
    "CITY_ID": "20066",
    "CITYNAME_AR": "شقراء",
    "CITYNAME_EN": "Shaqra",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20075",
    "CITYNAME_AR": "السر",
    "CITYNAME_EN": "As Sirr",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20106",
    "CITYNAME_AR": "ساجر",
    "CITYNAME_EN": "Sajer",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20156",
    "CITYNAME_AR": "نفى",
    "CITYNAME_EN": "Nafa",
    "REGION_ID": "1"
   }, 
   {
    "CITY_ID": "20210",
    "CITYNAME_AR": "الدوادمى",
    "CITYNAME_EN": "Dawadmi",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20264",
    "CITYNAME_AR": "مرات",
    "CITYNAME_EN": "Marrat",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20276",
    "CITYNAME_AR": "لبخه",
    "CITYNAME_EN": "Labakhah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20279",
    "CITYNAME_AR": "حويته",
    "CITYNAME_EN": "Hawayatah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20280",
    "CITYNAME_AR": "ثادق",
    "CITYNAME_EN": "Thadiq",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20297",
    "CITYNAME_AR": "حريملاء",
    "CITYNAME_EN": "Harimla'",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20308",
    "CITYNAME_AR": "جلاجل",
    "CITYNAME_EN": "Jalajil",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20309",
    "CITYNAME_AR": "روضه سدير",
    "CITYNAME_EN": "Rawdat Sudair",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20317",
    "CITYNAME_AR": "الرميثى",
    "CITYNAME_EN": "Alramithaa",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20320",
    "CITYNAME_AR": "بدايع العضيان",
    "CITYNAME_EN": "Badayie Aleidyan",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20334",
    "CITYNAME_AR": "الاشعريه",
    "CITYNAME_EN": "Alasheirih",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20339",
    "CITYNAME_AR": "المعلق",
    "CITYNAME_EN": "Almaealaq",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20451",
    "CITYNAME_AR": "عفيف",
    "CITYNAME_EN": "Eafif",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20496",
    "CITYNAME_AR": "عروى",
    "CITYNAME_EN": "Orwa",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20505",
    "CITYNAME_AR": "الرويضه",
    "CITYNAME_EN": "Al Ruwaydah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20620",
    "CITYNAME_AR": "الخاصره",
    "CITYNAME_EN": "Al Khasrah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20633",
    "CITYNAME_AR": "حلبان",
    "CITYNAME_EN": "Halpan",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20659",
    "CITYNAME_AR": "البجاديه",
    "CITYNAME_EN": "Al Bijadyah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20740",
    "CITYNAME_AR": "قرية منيفة",
    "CITYNAME_EN": "Qaryat Muniafa",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20743",
    "CITYNAME_AR": "وادي الدواسر",
    "CITYNAME_EN": "Wadi Ad-Dawasir",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20806",
    "CITYNAME_AR": "القويعيه",
    "CITYNAME_EN": "Al-Quway'iyah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "20883",
    "CITYNAME_AR": "الرين",
    "CITYNAME_EN": "Al Reen",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21072",
    "CITYNAME_AR": "الجله",
    "CITYNAME_EN": "Aljuluh",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21116",
    "CITYNAME_AR": "الحريق",
    "CITYNAME_EN": "Alhariq",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21135",
    "CITYNAME_AR": "الهدار",
    "CITYNAME_EN": "Al-Haddar",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21143",
    "CITYNAME_AR": "الاحمر",
    "CITYNAME_EN": "Al-Ahmar",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21151",
    "CITYNAME_AR": "المزاحميه",
    "CITYNAME_EN": "Almuzahimih",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21182",
    "CITYNAME_AR": "ضرما",
    "CITYNAME_EN": "Darma",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21194",
    "CITYNAME_AR": "الجبيله",
    "CITYNAME_EN": "Al Jubaylah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21200",
    "CITYNAME_AR": "الدرعيه",
    "CITYNAME_EN": "Diriyah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21207",
    "CITYNAME_AR": "الخرج",
    "CITYNAME_EN": "Al Kharj",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21232",
    "CITYNAME_AR": "الدلم",
    "CITYNAME_EN": "Al Delim",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21248",
    "CITYNAME_AR": "الافلاج",
    "CITYNAME_EN": "Al Aflaj",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21274",
    "CITYNAME_AR": "حوطه بنى تميم",
    "CITYNAME_EN": "Hotat Bani Tamim",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21296",
    "CITYNAME_AR": "الهياثم",
    "CITYNAME_EN": "Al Hayathem",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21298",
    "CITYNAME_AR": "الغاط",
    "CITYNAME_EN": "Alghat",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21312",
    "CITYNAME_AR": "اشيقر",
    "CITYNAME_EN": "Ashqeer",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21315",
    "CITYNAME_AR": "الحيانيه",
    "CITYNAME_EN": "Al Hyanyah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21319",
    "CITYNAME_AR": "النهتيه الجديده",
    "CITYNAME_EN": "Alnuhtayuh Aljadiduh",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21320",
    "CITYNAME_AR": "الطوال",
    "CITYNAME_EN": "Al Tewal",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21321",
    "CITYNAME_AR": "مصده",
    "CITYNAME_EN": "Musiddah",
    "REGION_ID": "1"
   },
   {
    "CITY_ID": "21348",
    "CITYNAME_AR": "الزلفى",
    "CITYNAME_EN": "Al-Zulfi",
    "REGION_ID": "1"
   }
  ];

export const DISTRICTS = [
   {
    "DISTRICT_ID": "1",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3",
    "DISTRICTNAME_AR": "رامات",
    "DISTRICTNAME_EN": "Ramat",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5",
    "DISTRICTNAME_AR": "العبيلة",
    "DISTRICTNAME_EN": "Al Ubaylah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "6",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "7",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "8",
    "DISTRICTNAME_AR": "القبعية",
    "DISTRICTNAME_EN": "Al Qabaeia",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "9",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "10",
    "DISTRICTNAME_AR": "أم تلعة",
    "DISTRICTNAME_EN": "Umm Talea",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "11",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "3295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "12",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "3295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "13",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "3295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "14",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "3295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "15",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "3295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "16",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "17",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "18",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "19",
    "DISTRICTNAME_AR": "الدحلة",
    "DISTRICTNAME_EN": "Al Dihla",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "20",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "21",
    "DISTRICTNAME_AR": "الأصفر",
    "DISTRICTNAME_EN": "Al Asfar",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "22",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "23",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "24",
    "DISTRICTNAME_AR": "الرواد",
    "DISTRICTNAME_EN": "Ruwad",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "25",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "26",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "27",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "28",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "29",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "30",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "31",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "32",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "33",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "34",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "35",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "36",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "37",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "38",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "39",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "40",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "41",
    "DISTRICTNAME_AR": "البيضاء",
    "DISTRICTNAME_EN": "Al Baida",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "42",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "43",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "44",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "45",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "46",
    "DISTRICTNAME_AR": "الصحة",
    "DISTRICTNAME_EN": "As Siha",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "47",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "48",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "49",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "50",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "51",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "52",
    "DISTRICTNAME_AR": "الأفق",
    "DISTRICTNAME_EN": "Al Ufuq",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "53",
    "DISTRICTNAME_AR": "أوثال",
    "DISTRICTNAME_EN": "Awthal",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "54",
    "DISTRICTNAME_AR": "شرق أوثال",
    "DISTRICTNAME_EN": "East Uthal",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "55",
    "DISTRICTNAME_AR": "غرب أوثال",
    "DISTRICTNAME_EN": "West Uthal",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "56",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "57",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "58",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "59",
    "DISTRICTNAME_AR": "الرياض",
    "DISTRICTNAME_EN": "Ar Riyadh",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "60",
    "DISTRICTNAME_AR": "الهجرة",
    "DISTRICTNAME_EN": "Al Hijrah",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "61",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "62",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "63",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "64",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "65",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "2094",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "66",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "2094",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "67",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "2094",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "68",
    "DISTRICTNAME_AR": "مركز المطيوي",
    "DISTRICTNAME_EN": "Al Mutiwi Center",
    "CITY_ID": "2094",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "69",
    "DISTRICTNAME_AR": "قرية الرمثية",
    "DISTRICTNAME_EN": "Qaryat Al Rumthiya",
    "CITY_ID": "2094",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "70",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "71",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "72",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "73",
    "DISTRICTNAME_AR": "غطي",
    "DISTRICTNAME_EN": "Ghatiy",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "74",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "75",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "76",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "77",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "78",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "79",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "Ar Rifa",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "80",
    "DISTRICTNAME_AR": "العقيلة الشمالية",
    "DISTRICTNAME_EN": "North Al Uqaila",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "81",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "82",
    "DISTRICTNAME_AR": "الدعبوسية",
    "DISTRICTNAME_EN": "Al Daebusia",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "83",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "84",
    "DISTRICTNAME_AR": "الزمرد",
    "DISTRICTNAME_EN": "Az Zomorod",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "85",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "86",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "87",
    "DISTRICTNAME_AR": "حصيدة",
    "DISTRICTNAME_EN": "Husaydah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "88",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "89",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "90",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "91",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "92",
    "DISTRICTNAME_AR": "الفرسان",
    "DISTRICTNAME_EN": "Al Fursan",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "93",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "94",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "95",
    "DISTRICTNAME_AR": "البادية",
    "DISTRICTNAME_EN": "Al Badiyah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "96",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "97",
    "DISTRICTNAME_AR": "الأمير عبدالاله بن عبدالعزيز",
    "DISTRICTNAME_EN": "Prince AbdulIlah bin Abdulaziz",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "98",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "99",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "100",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "101",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "102",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "103",
    "DISTRICTNAME_AR": "الرسالة",
    "DISTRICTNAME_EN": "Ar Risalah",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "104",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "105",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "106",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "107",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "108",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "109",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "110",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "111",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "112",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "113",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "114",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "115",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "116",
    "DISTRICTNAME_AR": "النظيم",
    "DISTRICTNAME_EN": "An Nadheem",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "117",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "118",
    "DISTRICTNAME_AR": "العبدلية",
    "DISTRICTNAME_EN": "Al Abdaliah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "119",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "120",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "121",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "122",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "123",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "124",
    "DISTRICTNAME_AR": "غاف الجواء",
    "DISTRICTNAME_EN": "Ghaf Al Jiwaa",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "125",
    "DISTRICTNAME_AR": "روض الجواء",
    "DISTRICTNAME_EN": "Rawd Al Jawa'",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "126",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "127",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "128",
    "DISTRICTNAME_AR": "الصناعي",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "129",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "130",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "131",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "132",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "133",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "134",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "135",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "136",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "137",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "138",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "139",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "140",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "141",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "142",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "143",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "144",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "145",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "146",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "147",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "148",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "149",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "150",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "151",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "152",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "153",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "154",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "155",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "156",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "157",
    "DISTRICTNAME_AR": "حدرج",
    "DISTRICTNAME_EN": "Hadraj",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "158",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "159",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "160",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "161",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "162",
    "DISTRICTNAME_AR": "التحكيم",
    "DISTRICTNAME_EN": "At Tahkim",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "163",
    "DISTRICTNAME_AR": "غرب دومة الجندل",
    "DISTRICTNAME_EN": "West Dawmat Al Jandal",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "164",
    "DISTRICTNAME_AR": "الغربي",
    "DISTRICTNAME_EN": "Al Gharbi",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "165",
    "DISTRICTNAME_AR": "الوادي والبحيرات",
    "DISTRICTNAME_EN": "Valley and Lakes",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "166",
    "DISTRICTNAME_AR": "الصفاة",
    "DISTRICTNAME_EN": "Al Safat",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "167",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "168",
    "DISTRICTNAME_AR": "خذماء",
    "DISTRICTNAME_EN": "Khazma",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "169",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "170",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "171",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "172",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "173",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "174",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "175",
    "DISTRICTNAME_AR": "أصفان",
    "DISTRICTNAME_EN": "Asfan",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "176",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "177",
    "DISTRICTNAME_AR": "الرديفة والرافعية",
    "DISTRICTNAME_EN": "Ar Radifah and Ar Rafeiyah",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "178",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "179",
    "DISTRICTNAME_AR": "الغروب",
    "DISTRICTNAME_EN": "Al Ghurub",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "180",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "181",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "182",
    "DISTRICTNAME_AR": "الفرسان",
    "DISTRICTNAME_EN": "Al Fursan",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "183",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "184",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "185",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "186",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "12209",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "187",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "188",
    "DISTRICTNAME_AR": "الطوير",
    "DISTRICTNAME_EN": "At Tuwair",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "189",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "190",
    "DISTRICTNAME_AR": "الضاحية",
    "DISTRICTNAME_EN": "Ad Dahiah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "191",
    "DISTRICTNAME_AR": "الشعيب",
    "DISTRICTNAME_EN": "Ash Shuaib",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "192",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "193",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "194",
    "DISTRICTNAME_AR": "المطر",
    "DISTRICTNAME_EN": "Al Matr",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "195",
    "DISTRICTNAME_AR": "الرواد",
    "DISTRICTNAME_EN": "Ruwad",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "196",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "197",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "198",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "199",
    "DISTRICTNAME_AR": "السوق",
    "DISTRICTNAME_EN": "As Suq",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "200",
    "DISTRICTNAME_AR": "الشلهوب",
    "DISTRICTNAME_EN": "Ash Shalhub",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "201",
    "DISTRICTNAME_AR": "الرحمانية",
    "DISTRICTNAME_EN": "Ar Rahmanyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "202",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "203",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "204",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "205",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "206",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "207",
    "DISTRICTNAME_AR": "السنبلة",
    "DISTRICTNAME_EN": "As Sunbulah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "208",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "209",
    "DISTRICTNAME_AR": "الظلال",
    "DISTRICTNAME_EN": "Adh Dhilal",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "210",
    "DISTRICTNAME_AR": "اليمام",
    "DISTRICTNAME_EN": "Al Yamam",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "211",
    "DISTRICTNAME_AR": "أجياد",
    "DISTRICTNAME_EN": "Ajyad",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "212",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "213",
    "DISTRICTNAME_AR": "الضلع",
    "DISTRICTNAME_EN": "Al Dila",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "214",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "215",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "216",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "217",
    "DISTRICTNAME_AR": "الصديق",
    "DISTRICTNAME_EN": "As Sediq",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "218",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "219",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "220",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "221",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "222",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "223",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "224",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "225",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "226",
    "DISTRICTNAME_AR": "غرناطة الشرقي",
    "DISTRICTNAME_EN": "Eastern Ghirnatah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "227",
    "DISTRICTNAME_AR": "الحرس الوطني",
    "DISTRICTNAME_EN": "National Guard",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "228",
    "DISTRICTNAME_AR": "الشروق الاداري",
    "DISTRICTNAME_EN": "Ash Shrouk Administrative",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "229",
    "DISTRICTNAME_AR": "النخيل الشمالي",
    "DISTRICTNAME_EN": "Al Nakhil Northern",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "230",
    "DISTRICTNAME_AR": "ضاحية الملك فهد",
    "DISTRICTNAME_EN": "Dahiyat Al Malik Fahd",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "231",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "232",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "233",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "234",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "235",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "236",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "237",
    "DISTRICTNAME_AR": "الارجوان الغربي",
    "DISTRICTNAME_EN": "Al Arjiwan Al Gharbi",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "238",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "239",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "240",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "241",
    "DISTRICTNAME_AR": "الوئام",
    "DISTRICTNAME_EN": "Al Wiam",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "242",
    "DISTRICTNAME_AR": "المهندسين",
    "DISTRICTNAME_EN": "Al Muhandisin",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "243",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "244",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "245",
    "DISTRICTNAME_AR": "الاصداف",
    "DISTRICTNAME_EN": "Al Asdaf",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "246",
    "DISTRICTNAME_AR": "الشامل",
    "DISTRICTNAME_EN": "Al Shaamil",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "247",
    "DISTRICTNAME_AR": "الصناعية الأولى",
    "DISTRICTNAME_EN": "1st Industrial",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "248",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "249",
    "DISTRICTNAME_AR": "تلال عرعر",
    "DISTRICTNAME_EN": "Talal Arar",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "250",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "Ar Rifa",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "251",
    "DISTRICTNAME_AR": "القدس الشرقي",
    "DISTRICTNAME_EN": "Eastern Al Quds",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "252",
    "DISTRICTNAME_AR": "النخيل الجنوبي",
    "DISTRICTNAME_EN": "Al Nakhil Southern",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "253",
    "DISTRICTNAME_AR": "البساتين الاداري الشرقي",
    "DISTRICTNAME_EN": "Al Basatin Eastern administrative",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "254",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "255",
    "DISTRICTNAME_AR": "إشبيلية الشمالي",
    "DISTRICTNAME_EN": "Ishbiliyah Northern",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "256",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "257",
    "DISTRICTNAME_AR": "البراعم",
    "DISTRICTNAME_EN": "Al Baraeim",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "258",
    "DISTRICTNAME_AR": "معيلة",
    "DISTRICTNAME_EN": "Maeila",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "259",
    "DISTRICTNAME_AR": "هجرة ابن سعيد",
    "DISTRICTNAME_EN": "Hijrat Ibn Saeid",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "260",
    "DISTRICTNAME_AR": "الصناعية الثانية",
    "DISTRICTNAME_EN": "second industrial",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "261",
    "DISTRICTNAME_AR": "الفروسية",
    "DISTRICTNAME_EN": "Al Frosyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "262",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "263",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "264",
    "DISTRICTNAME_AR": "المروج الغربي",
    "DISTRICTNAME_EN": "Al Muruj Al Gharbi",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "265",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "266",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "267",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "268",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "269",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "270",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "271",
    "DISTRICTNAME_AR": "بدنة",
    "DISTRICTNAME_EN": "Al Badanah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "272",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "273",
    "DISTRICTNAME_AR": "مشرف",
    "DISTRICTNAME_EN": "Mishrif",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "274",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "275",
    "DISTRICTNAME_AR": "المساعدية",
    "DISTRICTNAME_EN": "Al Misadiyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "276",
    "DISTRICTNAME_AR": "الصقار",
    "DISTRICTNAME_EN": "As Sqaar",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "277",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "278",
    "DISTRICTNAME_AR": "تشليح السيارات",
    "DISTRICTNAME_EN": "Auto Repair",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "279",
    "DISTRICTNAME_AR": "البساتين الاداري الغربي",
    "DISTRICTNAME_EN": "Al Basatin Western administrative",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "280",
    "DISTRICTNAME_AR": "المباركية",
    "DISTRICTNAME_EN": "Al Mubarkiah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "281",
    "DISTRICTNAME_AR": "الشذا",
    "DISTRICTNAME_EN": "Al Shadha",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "282",
    "DISTRICTNAME_AR": "المروج الشرقي",
    "DISTRICTNAME_EN": "Al Muruj Al Sharqi",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "283",
    "DISTRICTNAME_AR": "القدس الغربي",
    "DISTRICTNAME_EN": "Western Al Quds",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "284",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "285",
    "DISTRICTNAME_AR": "الأمانة الاداري",
    "DISTRICTNAME_EN": "Administrative Secretariat",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "286",
    "DISTRICTNAME_AR": "محجوزات ارمكو",
    "DISTRICTNAME_EN": "Aramco Reservations",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "287",
    "DISTRICTNAME_AR": "التقنية",
    "DISTRICTNAME_EN": "Al Tiqniya",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "288",
    "DISTRICTNAME_AR": "ام الضيان",
    "DISTRICTNAME_EN": "Umm Al Dayan",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "289",
    "DISTRICTNAME_AR": "مدينة الانعام",
    "DISTRICTNAME_EN": "Madinat Al Aineam",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "290",
    "DISTRICTNAME_AR": "الخليج العربي الشرقي",
    "DISTRICTNAME_EN": "Eastern Arabian Gulf",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "291",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "292",
    "DISTRICTNAME_AR": "المنصورية",
    "DISTRICTNAME_EN": "Mansuriyah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "293",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "294",
    "DISTRICTNAME_AR": "الموطا",
    "DISTRICTNAME_EN": "Al Mawta",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "295",
    "DISTRICTNAME_AR": "إشبيلية الجنوبي",
    "DISTRICTNAME_EN": "Ishbiliyah Southern",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "296",
    "DISTRICTNAME_AR": "صوفان",
    "DISTRICTNAME_EN": "Sufan",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "297",
    "DISTRICTNAME_AR": "الأمير سلطان",
    "DISTRICTNAME_EN": "Prince Sultan",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "298",
    "DISTRICTNAME_AR": "الدحلة",
    "DISTRICTNAME_EN": "Al Dihla",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "299",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "300",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "301",
    "DISTRICTNAME_AR": "المدراء",
    "DISTRICTNAME_EN": "Al Mudaraa",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "302",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "303",
    "DISTRICTNAME_AR": "الحيفة",
    "DISTRICTNAME_EN": "Al Hifaa",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "304",
    "DISTRICTNAME_AR": "الخالدية والعزيزية",
    "DISTRICTNAME_EN": "Al Khalidiyah and Aziziyah",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "305",
    "DISTRICTNAME_AR": "الباردة",
    "DISTRICTNAME_EN": "Al Barida",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "306",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "307",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "308",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "309",
    "DISTRICTNAME_AR": "الجنوب",
    "DISTRICTNAME_EN": "Al Janub",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "310",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "311",
    "DISTRICTNAME_AR": "الضاحية",
    "DISTRICTNAME_EN": "Ad Dahiah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "312",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "313",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "314",
    "DISTRICTNAME_AR": "الخليج العربي الغربي",
    "DISTRICTNAME_EN": "Western Arabian Gulf",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "315",
    "DISTRICTNAME_AR": "غرناطة الغربي",
    "DISTRICTNAME_EN": "Western Ghirnatah",
    "CITY_ID": "1778",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "316",
    "DISTRICTNAME_AR": "الارجوان الشرقي",
    "DISTRICTNAME_EN": "Al Arjiwan Al sharqi",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "317",
    "DISTRICTNAME_AR": "الحميمة و أبو خيال",
    "DISTRICTNAME_EN": "Al Hamimat and Abu Khayal",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "318",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "319",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "Al Khazzan",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "320",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "321",
    "DISTRICTNAME_AR": "جميع",
    "DISTRICTNAME_EN": "Jamie",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "322",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "323",
    "DISTRICTNAME_AR": "الشداخة",
    "DISTRICTNAME_EN": "Al Shadaakha",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "324",
    "DISTRICTNAME_AR": "الحرف",
    "DISTRICTNAME_EN": "Alharaf",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "325",
    "DISTRICTNAME_AR": "قرية المهدي",
    "DISTRICTNAME_EN": "Qaryat Al Mahdi",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "326",
    "DISTRICTNAME_AR": "قنيع و النغيلة",
    "DISTRICTNAME_EN": "Qanie and Al Naghila",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "327",
    "DISTRICTNAME_AR": "الحزامي و النغيلة",
    "DISTRICTNAME_EN": "Al Hizami and  Al Naghila",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "328",
    "DISTRICTNAME_AR": "نمران",
    "DISTRICTNAME_EN": "Namaran",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "329",
    "DISTRICTNAME_AR": "الأمير عبدالله",
    "DISTRICTNAME_EN": "Prince Abdullah",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "330",
    "DISTRICTNAME_AR": "الخضراء",
    "DISTRICTNAME_EN": "Al Khadraa",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "331",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "332",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "333",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "334",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "335",
    "DISTRICTNAME_AR": "الهيشة",
    "DISTRICTNAME_EN": "Al Hisha",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "336",
    "DISTRICTNAME_AR": "الصبيحي",
    "DISTRICTNAME_EN": "Al Subayhi",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "337",
    "DISTRICTNAME_AR": "الحمة",
    "DISTRICTNAME_EN": "Al Hima",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "338",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "339",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "340",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "341",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "342",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "2401",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "343",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "344",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "345",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "346",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "347",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "348",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "349",
    "DISTRICTNAME_AR": "السلطان",
    "DISTRICTNAME_EN": "Al Sultan",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "350",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "351",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "352",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "353",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "354",
    "DISTRICTNAME_AR": "شظاة",
    "DISTRICTNAME_EN": "Shizat",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "355",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "356",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "357",
    "DISTRICTNAME_AR": "الفهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "358",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "359",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "360",
    "DISTRICTNAME_AR": "المصايف",
    "DISTRICTNAME_EN": "Al Masayif",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "361",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "362",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "363",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "364",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "365",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "366",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "367",
    "DISTRICTNAME_AR": "الشلال",
    "DISTRICTNAME_EN": "Ash Shalaal",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "368",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "369",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "370",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "371",
    "DISTRICTNAME_AR": "الرحاب 2",
    "DISTRICTNAME_EN": "Ar Rehab 2",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "372",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "373",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "374",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "375",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "376",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "377",
    "DISTRICTNAME_AR": "الصفح",
    "DISTRICTNAME_EN": "Al Safh",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "378",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "379",
    "DISTRICTNAME_AR": "الشرق",
    "DISTRICTNAME_EN": "Ash Sharq",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "380",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "381",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "382",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "383",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "384",
    "DISTRICTNAME_AR": "الزهر",
    "DISTRICTNAME_EN": "Al Zuhr",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "385",
    "DISTRICTNAME_AR": "السامر",
    "DISTRICTNAME_EN": "As Samir",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "386",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "387",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "388",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "389",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "390",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "391",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "392",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "393",
    "DISTRICTNAME_AR": "السالمية",
    "DISTRICTNAME_EN": "As Salmiya",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "394",
    "DISTRICTNAME_AR": "الأنوار",
    "DISTRICTNAME_EN": "Al Anwar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "395",
    "DISTRICTNAME_AR": "السنابل",
    "DISTRICTNAME_EN": "As Sanabel",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "396",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "397",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "398",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "399",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "400",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "401",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "402",
    "DISTRICTNAME_AR": "بلال",
    "DISTRICTNAME_EN": "Bilal",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "403",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "404",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "405",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "406",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "407",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "408",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "409",
    "DISTRICTNAME_AR": "المطل",
    "DISTRICTNAME_EN": "Al Matl",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "410",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "411",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "412",
    "DISTRICTNAME_AR": "المنتزة الشمالي",
    "DISTRICTNAME_EN": "Al Muntazah Al Shamali",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "413",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "414",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "415",
    "DISTRICTNAME_AR": "مبروكة",
    "DISTRICTNAME_EN": "Mabrukah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "416",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "417",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "418",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "419",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "420",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "421",
    "DISTRICTNAME_AR": "خب الجطيلي",
    "DISTRICTNAME_EN": "Khub Al Jutaily",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "422",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "423",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "424",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "425",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "426",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "427",
    "DISTRICTNAME_AR": "المنتزة الجنوبي",
    "DISTRICTNAME_EN": "Al Muntazah Al Janubi",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "428",
    "DISTRICTNAME_AR": "نجد",
    "DISTRICTNAME_EN": "Najd",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "429",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "430",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "431",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "432",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "433",
    "DISTRICTNAME_AR": "الصديق",
    "DISTRICTNAME_EN": "As Sediq",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "434",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "435",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "436",
    "DISTRICTNAME_AR": "الظهرة",
    "DISTRICTNAME_EN": "Adh Dhahrah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "437",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "438",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "439",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "440",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "441",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "442",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "443",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "444",
    "DISTRICTNAME_AR": "الضاحي الشرقي",
    "DISTRICTNAME_EN": "Ad Dahi Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "445",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "446",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "447",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "448",
    "DISTRICTNAME_AR": "المعارض",
    "DISTRICTNAME_EN": "Al Maarid",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "449",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "450",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "451",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "452",
    "DISTRICTNAME_AR": "الضرس",
    "DISTRICTNAME_EN": "Ad Dirs",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "453",
    "DISTRICTNAME_AR": "المقاعد",
    "DISTRICTNAME_EN": "Al Maqaiid",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "454",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "455",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "456",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "457",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "458",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "459",
    "DISTRICTNAME_AR": "الوعد",
    "DISTRICTNAME_EN": "Al Waad",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "460",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "461",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "2499",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "462",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "463",
    "DISTRICTNAME_AR": "الحيلة الغربي",
    "DISTRICTNAME_EN": "Al Haylah Al Gharbi",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "464",
    "DISTRICTNAME_AR": "الحيلة الشرقي",
    "DISTRICTNAME_EN": "Al Haylah Ash Sharqi",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "465",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "466",
    "DISTRICTNAME_AR": "البصرة",
    "DISTRICTNAME_EN": "Al Basrah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "467",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "468",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "469",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "470",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "471",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "472",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "473",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "474",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "475",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "476",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "477",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "478",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "479",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "480",
    "DISTRICTNAME_AR": "الفرعين 2",
    "DISTRICTNAME_EN": "Al Fareayn 2",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "481",
    "DISTRICTNAME_AR": "الفرعين 1",
    "DISTRICTNAME_EN": "Al Fareayn 1",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "482",
    "DISTRICTNAME_AR": "الفرعين 4",
    "DISTRICTNAME_EN": "Al Fareayn 4",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "483",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "484",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "485",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "486",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "487",
    "DISTRICTNAME_AR": "مرحبين",
    "DISTRICTNAME_EN": "Marhabin",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "488",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "489",
    "DISTRICTNAME_AR": "الفهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "490",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "491",
    "DISTRICTNAME_AR": "المعارف",
    "DISTRICTNAME_EN": "Al Maarif",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "492",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "493",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "494",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "495",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "496",
    "DISTRICTNAME_AR": "الفرسان",
    "DISTRICTNAME_EN": "Al Fursan",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "497",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "498",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "499",
    "DISTRICTNAME_AR": "السامر",
    "DISTRICTNAME_EN": "As Samir",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "500",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "18048",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "501",
    "DISTRICTNAME_AR": "ال صمان",
    "DISTRICTNAME_EN": "Al Samaan",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "502",
    "DISTRICTNAME_AR": "البدع",
    "DISTRICTNAME_EN": "Al Bada",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "503",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "504",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "505",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "506",
    "DISTRICTNAME_AR": "الجنادرية",
    "DISTRICTNAME_EN": "Al Janadriyah",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "507",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "508",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "509",
    "DISTRICTNAME_AR": "الفرعين 3",
    "DISTRICTNAME_EN": "Al Fareayn 3",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "510",
    "DISTRICTNAME_AR": "التسامح",
    "DISTRICTNAME_EN": "Al Tasamuh",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "511",
    "DISTRICTNAME_AR": "السامر",
    "DISTRICTNAME_EN": "As Samir",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "512",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "513",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "514",
    "DISTRICTNAME_AR": "الغزالة",
    "DISTRICTNAME_EN": "Al Ghazalah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "515",
    "DISTRICTNAME_AR": "الزرقاء",
    "DISTRICTNAME_EN": "Al Zarqa",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "516",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "517",
    "DISTRICTNAME_AR": "ال سويدان",
    "DISTRICTNAME_EN": "Al Suwaidan",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "518",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "519",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "520",
    "DISTRICTNAME_AR": "الدائري الجنوبي",
    "DISTRICTNAME_EN": "South Ring",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "521",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "522",
    "DISTRICTNAME_AR": "الدائري الشمالي",
    "DISTRICTNAME_EN": "North Ring",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "523",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "524",
    "DISTRICTNAME_AR": "الدائري الغربي",
    "DISTRICTNAME_EN": "Western Ring",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "525",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "526",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "527",
    "DISTRICTNAME_AR": "النهود",
    "DISTRICTNAME_EN": "An Nahud",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "528",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "529",
    "DISTRICTNAME_AR": "العدالة",
    "DISTRICTNAME_EN": "Al Adalah",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "530",
    "DISTRICTNAME_AR": "خضيراء الجنوبية",
    "DISTRICTNAME_EN": "Khadira Al Janubiyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "531",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "532",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "533",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "10907",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "534",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "535",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "537",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "538",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "539",
    "DISTRICTNAME_AR": "العرين",
    "DISTRICTNAME_EN": "Al Arin",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "540",
    "DISTRICTNAME_AR": "الرحيب",
    "DISTRICTNAME_EN": "Ar Raheeb",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "541",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "542",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "543",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "544",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "545",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "546",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "547",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "548",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "549",
    "DISTRICTNAME_AR": "المنهل",
    "DISTRICTNAME_EN": "Al Manhal",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "550",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "551",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "552",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "553",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "554",
    "DISTRICTNAME_AR": "السمر",
    "DISTRICTNAME_EN": "As Samar",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "555",
    "DISTRICTNAME_AR": "القويع",
    "DISTRICTNAME_EN": "Al Quwaie",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "556",
    "DISTRICTNAME_AR": "الوسيع",
    "DISTRICTNAME_EN": "Al Wasie",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "557",
    "DISTRICTNAME_AR": "المتينيات",
    "DISTRICTNAME_EN": "Al Mateeniyat",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "558",
    "DISTRICTNAME_AR": "الهدية الجنوبية",
    "DISTRICTNAME_EN": "Al Hadyah Al Janubiyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "559",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "560",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "561",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "562",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "563",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "564",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "565",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "566",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "567",
    "DISTRICTNAME_AR": "الجراد",
    "DISTRICTNAME_EN": "Al Jarad",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "568",
    "DISTRICTNAME_AR": "وهطان",
    "DISTRICTNAME_EN": "Wahtan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "569",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "570",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "571",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "572",
    "DISTRICTNAME_AR": "خب البريدي",
    "DISTRICTNAME_EN": "Khub Al Buraydi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "573",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "574",
    "DISTRICTNAME_AR": "الملحة",
    "DISTRICTNAME_EN": "Al Muliha",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "575",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "576",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "577",
    "DISTRICTNAME_AR": "الخزام",
    "DISTRICTNAME_EN": "Al Khuzam",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "578",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "579",
    "DISTRICTNAME_AR": "المسك",
    "DISTRICTNAME_EN": "Al Misk",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "580",
    "DISTRICTNAME_AR": "الفل",
    "DISTRICTNAME_EN": "Al Fal",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "581",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "582",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "583",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "Al Khazzan",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "584",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "585",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "586",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "587",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "588",
    "DISTRICTNAME_AR": "السدر",
    "DISTRICTNAME_EN": "Al Sudr",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "589",
    "DISTRICTNAME_AR": "القطائف",
    "DISTRICTNAME_EN": "Al Qatayif",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "590",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "591",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "592",
    "DISTRICTNAME_AR": "المقضي",
    "DISTRICTNAME_EN": "Al Maqdi",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "593",
    "DISTRICTNAME_AR": "تمنية",
    "DISTRICTNAME_EN": "Tamnia",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "594",
    "DISTRICTNAME_AR": "الموسم",
    "DISTRICTNAME_EN": "Al Mawsim",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "595",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "596",
    "DISTRICTNAME_AR": "دلغان",
    "DISTRICTNAME_EN": "Delgan",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "597",
    "DISTRICTNAME_AR": "الرمان",
    "DISTRICTNAME_EN": "Al Rumaan",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "598",
    "DISTRICTNAME_AR": "الفرعاء",
    "DISTRICTNAME_EN": "Al Furaea",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "599",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "600",
    "DISTRICTNAME_AR": "الرياحين",
    "DISTRICTNAME_EN": "Al Riyahayn",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "601",
    "DISTRICTNAME_AR": "تاتة",
    "DISTRICTNAME_EN": "Tata",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "602",
    "DISTRICTNAME_AR": "المهاريس",
    "DISTRICTNAME_EN": "Al Maharees",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "603",
    "DISTRICTNAME_AR": "القويطير",
    "DISTRICTNAME_EN": "Al Quwayteer",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "604",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "605",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "606",
    "DISTRICTNAME_AR": "الهدية الشمالية",
    "DISTRICTNAME_EN": "Al Hadyah Al Shamaliya",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "607",
    "DISTRICTNAME_AR": "التوفيق",
    "DISTRICTNAME_EN": "Al Tawfiq",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "608",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "609",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "610",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "611",
    "DISTRICTNAME_AR": "النعمان",
    "DISTRICTNAME_EN": "Al Nueman",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "612",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "613",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "614",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "615",
    "DISTRICTNAME_AR": "المناخ",
    "DISTRICTNAME_EN": "Al Manakh",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "616",
    "DISTRICTNAME_AR": "الباحر",
    "DISTRICTNAME_EN": "Al Bahir",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "617",
    "DISTRICTNAME_AR": "الرونة",
    "DISTRICTNAME_EN": "Ar Rawnah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "618",
    "DISTRICTNAME_AR": "النجوم",
    "DISTRICTNAME_EN": "Al Nujum",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "619",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "621",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "622",
    "DISTRICTNAME_AR": "الأراك 1",
    "DISTRICTNAME_EN": "Al Arak 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "623",
    "DISTRICTNAME_AR": "السلام 3",
    "DISTRICTNAME_EN": "As Salam 3",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "624",
    "DISTRICTNAME_AR": "السلام 1",
    "DISTRICTNAME_EN": "As Salam 1",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "625",
    "DISTRICTNAME_AR": "الحزم 2",
    "DISTRICTNAME_EN": "Al Hazm 2",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "626",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "627",
    "DISTRICTNAME_AR": "الخضراء 3",
    "DISTRICTNAME_EN": "Al Khadraa 3",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "628",
    "DISTRICTNAME_AR": "السلام 2",
    "DISTRICTNAME_EN": "As Salam 2",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "629",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "6099",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "630",
    "DISTRICTNAME_AR": "الجامع",
    "DISTRICTNAME_EN": "Al Jama",
    "CITY_ID": "6099",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "631",
    "DISTRICTNAME_AR": "الجنوبي",
    "DISTRICTNAME_EN": "Al Janubi",
    "CITY_ID": "6099",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "632",
    "DISTRICTNAME_AR": "إسكان الملك عبدالله لولديه",
    "DISTRICTNAME_EN": "King Abdullah housing for his sons",
    "CITY_ID": "6099",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "633",
    "DISTRICTNAME_AR": "الخالدية الجنوبية",
    "DISTRICTNAME_EN": "Al Khalidiyah Al Janubiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "634",
    "DISTRICTNAME_AR": "رايس",
    "DISTRICTNAME_EN": "Rise",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "635",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "636",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "637",
    "DISTRICTNAME_AR": "الفرشة",
    "DISTRICTNAME_EN": "Al Firsha",
    "CITY_ID": "13",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "638",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "639",
    "DISTRICTNAME_AR": "العنود",
    "DISTRICTNAME_EN": "Al Anud",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "640",
    "DISTRICTNAME_AR": "الوردتين",
    "DISTRICTNAME_EN": "Al Wardatain",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "641",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "642",
    "DISTRICTNAME_AR": "منطقة الخدمات أ",
    "DISTRICTNAME_EN": "Services Area A",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "643",
    "DISTRICTNAME_AR": "الأنعام",
    "DISTRICTNAME_EN": "Al Aneam",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "644",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "645",
    "DISTRICTNAME_AR": "واحة الشمال",
    "DISTRICTNAME_EN": "North Oasis",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "646",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "647",
    "DISTRICTNAME_AR": "القدس 2",
    "DISTRICTNAME_EN": "Al Quds 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "648",
    "DISTRICTNAME_AR": "الحزم 2",
    "DISTRICTNAME_EN": "Al Hazm 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "649",
    "DISTRICTNAME_AR": "الأندلس 1",
    "DISTRICTNAME_EN": "Al Andalus 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "650",
    "DISTRICTNAME_AR": "الأندلس 4",
    "DISTRICTNAME_EN": "Al Andalus 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "651",
    "DISTRICTNAME_AR": "الأندلس 2",
    "DISTRICTNAME_EN": "Al Andalus 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "652",
    "DISTRICTNAME_AR": "العين",
    "DISTRICTNAME_EN": "Al Ain",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "653",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "654",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "655",
    "DISTRICTNAME_AR": "الأخضر",
    "DISTRICTNAME_EN": "Al Akhdar",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "656",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "657",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "658",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "659",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "660",
    "DISTRICTNAME_AR": "الخبيب",
    "DISTRICTNAME_EN": "Al Khabib",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "661",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "662",
    "DISTRICTNAME_AR": "النزهة 2",
    "DISTRICTNAME_EN": "An Nuzhah 2",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "663",
    "DISTRICTNAME_AR": "النزهة 1",
    "DISTRICTNAME_EN": "An Nuzhah 1",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "664",
    "DISTRICTNAME_AR": "الزهود",
    "DISTRICTNAME_EN": "Al Zuhud",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "665",
    "DISTRICTNAME_AR": "البدور",
    "DISTRICTNAME_EN": "Al Budur",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "666",
    "DISTRICTNAME_AR": "السلام الغربي",
    "DISTRICTNAME_EN": "Al Salam Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "667",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "668",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "669",
    "DISTRICTNAME_AR": "الصفوة",
    "DISTRICTNAME_EN": "As safwa",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "670",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "671",
    "DISTRICTNAME_AR": "الهجن",
    "DISTRICTNAME_EN": "Al Hejin",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "672",
    "DISTRICTNAME_AR": "حفيرة السبعة الأوسط",
    "DISTRICTNAME_EN": "Hafirat Al Sabeat Al Awsat",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "673",
    "DISTRICTNAME_AR": "حفائر العهين",
    "DISTRICTNAME_EN": "Hafayir Al Eahin",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "674",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "675",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "676",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "677",
    "DISTRICTNAME_AR": "شمسان",
    "DISTRICTNAME_EN": "Shamsan",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "678",
    "DISTRICTNAME_AR": "القري",
    "DISTRICTNAME_EN": "Al Qura",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "679",
    "DISTRICTNAME_AR": "الوصايف",
    "DISTRICTNAME_EN": "Al Wasaif",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "680",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "681",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "682",
    "DISTRICTNAME_AR": "الثريا 2",
    "DISTRICTNAME_EN": "Ath Thuraiya 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "683",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "684",
    "DISTRICTNAME_AR": "العقدة",
    "DISTRICTNAME_EN": "Al Uqdah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "685",
    "DISTRICTNAME_AR": "مزهرة",
    "DISTRICTNAME_EN": "Mizhirah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "686",
    "DISTRICTNAME_AR": "العجيبية",
    "DISTRICTNAME_EN": "Al Ujaybiyah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "687",
    "DISTRICTNAME_AR": "الأساملة",
    "DISTRICTNAME_EN": "Al Asamila",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "688",
    "DISTRICTNAME_AR": "حفيرة السبعة الغربي",
    "DISTRICTNAME_EN": "Hafirat Al Sabeat Al Gharbi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "689",
    "DISTRICTNAME_AR": "الحفيرة الشرقي",
    "DISTRICTNAME_EN": "Al Hufayrah Al Sharqi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "692",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "693",
    "DISTRICTNAME_AR": "صفوان",
    "DISTRICTNAME_EN": "Safwan",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "694",
    "DISTRICTNAME_AR": "مسيحل",
    "DISTRICTNAME_EN": "Musihil",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "695",
    "DISTRICTNAME_AR": "البلدة القديمة",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "696",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "697",
    "DISTRICTNAME_AR": "الفيصلية الجنوبية",
    "DISTRICTNAME_EN": "Al Faisaliyah South",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "698",
    "DISTRICTNAME_AR": "الفيصلية الشمالية",
    "DISTRICTNAME_EN": "Al Faisaliyah North",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "699",
    "DISTRICTNAME_AR": "الصفراء",
    "DISTRICTNAME_EN": "Al Safra",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "700",
    "DISTRICTNAME_AR": "شعار",
    "DISTRICTNAME_EN": "Shiear",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "701",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "702",
    "DISTRICTNAME_AR": "الهلال",
    "DISTRICTNAME_EN": "Al Hilal",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "703",
    "DISTRICTNAME_AR": "الليوان",
    "DISTRICTNAME_EN": "Al Liwan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "704",
    "DISTRICTNAME_AR": "السادة",
    "DISTRICTNAME_EN": "Al Sadah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "705",
    "DISTRICTNAME_AR": "التجارة",
    "DISTRICTNAME_EN": "Trading",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "706",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "707",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "708",
    "DISTRICTNAME_AR": "الحسينى",
    "DISTRICTNAME_EN": "Al Husaini",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "709",
    "DISTRICTNAME_AR": "المودة",
    "DISTRICTNAME_EN": "Al Mawada",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "710",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "711",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "712",
    "DISTRICTNAME_AR": "الجمالة",
    "DISTRICTNAME_EN": "Al Jammalah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "713",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "714",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "715",
    "DISTRICTNAME_AR": "الأندلس 5",
    "DISTRICTNAME_EN": "Al Andalus 5",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "716",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "717",
    "DISTRICTNAME_AR": "الحزم 1",
    "DISTRICTNAME_EN": "Al Hazm 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "718",
    "DISTRICTNAME_AR": "القدس 1",
    "DISTRICTNAME_EN": "Al Quds 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "719",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "720",
    "DISTRICTNAME_AR": "منطقة خاصة",
    "DISTRICTNAME_EN": "Private Area",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "721",
    "DISTRICTNAME_AR": "الدفاع",
    "DISTRICTNAME_EN": "Ad Difa",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "722",
    "DISTRICTNAME_AR": "منطقة الخدمات ب",
    "DISTRICTNAME_EN": "Services Area B",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "723",
    "DISTRICTNAME_AR": "المدينة الصناعية",
    "DISTRICTNAME_EN": "Industrial City",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "724",
    "DISTRICTNAME_AR": "اللؤلؤة",
    "DISTRICTNAME_EN": "Al Luluah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "725",
    "DISTRICTNAME_AR": "العلية",
    "DISTRICTNAME_EN": "Al Oalia",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "726",
    "DISTRICTNAME_AR": "الرضوان",
    "DISTRICTNAME_EN": "Al Radwan",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "727",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "728",
    "DISTRICTNAME_AR": "القاع البارد",
    "DISTRICTNAME_EN": "Al Qaa Al Barid",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "729",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "730",
    "DISTRICTNAME_AR": "الاصيل",
    "DISTRICTNAME_EN": "Al Asil",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "731",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "732",
    "DISTRICTNAME_AR": "الاطلال",
    "DISTRICTNAME_EN": "Al Atlal",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "733",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "734",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "735",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "736",
    "DISTRICTNAME_AR": "السويس 1",
    "DISTRICTNAME_EN": "Al Suways 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "737",
    "DISTRICTNAME_AR": "الرحاب 1",
    "DISTRICTNAME_EN": "Ar Rehab 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "738",
    "DISTRICTNAME_AR": "السويس 2",
    "DISTRICTNAME_EN": "Al Suways 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "739",
    "DISTRICTNAME_AR": "الرحاب 2",
    "DISTRICTNAME_EN": "Ar Rehab 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "740",
    "DISTRICTNAME_AR": "البشائر",
    "DISTRICTNAME_EN": "Al Bashaer",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "741",
    "DISTRICTNAME_AR": "المروج 1",
    "DISTRICTNAME_EN": "Al Muruj 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "742",
    "DISTRICTNAME_AR": "القادسية الثانى",
    "DISTRICTNAME_EN": "Al Qadisiyah 2",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "743",
    "DISTRICTNAME_AR": "السحاب",
    "DISTRICTNAME_EN": "Al Sahab",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "744",
    "DISTRICTNAME_AR": "المملكة",
    "DISTRICTNAME_EN": "The Kingdom",
    "CITY_ID": "1863",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "745",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "1863",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "746",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "1863",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "747",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "1863",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "748",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "1863",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "749",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "750",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "751",
    "DISTRICTNAME_AR": "الكواكب",
    "DISTRICTNAME_EN": "Al Kawakib",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "752",
    "DISTRICTNAME_AR": "القلعة",
    "DISTRICTNAME_EN": "Al Qalah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "753",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "754",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "755",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "756",
    "DISTRICTNAME_AR": "النصب",
    "DISTRICTNAME_EN": "An Nasb",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "757",
    "DISTRICTNAME_AR": "أبو سهيلات",
    "DISTRICTNAME_EN": "Abu Suhailat",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "758",
    "DISTRICTNAME_AR": "الغزوة",
    "DISTRICTNAME_EN": "Al Ghazwah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "759",
    "DISTRICTNAME_AR": "الفقهاء",
    "DISTRICTNAME_EN": "Al Fuqahaa",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "760",
    "DISTRICTNAME_AR": "العشوة",
    "DISTRICTNAME_EN": "Al Ushwa",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "761",
    "DISTRICTNAME_AR": "الأندلس 6",
    "DISTRICTNAME_EN": "Al Andalus 6",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "762",
    "DISTRICTNAME_AR": "الأندلس 3",
    "DISTRICTNAME_EN": "Al Andalus 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "763",
    "DISTRICTNAME_AR": "المحمدية 1",
    "DISTRICTNAME_EN": "Al Muhammadiyah 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "764",
    "DISTRICTNAME_AR": "الزرقاء",
    "DISTRICTNAME_EN": "Al Zarqa",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "765",
    "DISTRICTNAME_AR": "الإستراحات الأوسط",
    "DISTRICTNAME_EN": "Al Iistirahat Al Awst",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "766",
    "DISTRICTNAME_AR": "الإستراحات الشرقي",
    "DISTRICTNAME_EN": "Al Iistirahat Al Sharqi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "767",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "768",
    "DISTRICTNAME_AR": "الإستراحات الغربي",
    "DISTRICTNAME_EN": "Al Iistirahat Al Gharbi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "769",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "770",
    "DISTRICTNAME_AR": "الربة",
    "DISTRICTNAME_EN": "Al Rabah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "771",
    "DISTRICTNAME_AR": "القدس 5",
    "DISTRICTNAME_EN": "Al Quds 5",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "772",
    "DISTRICTNAME_AR": "الحزم 4",
    "DISTRICTNAME_EN": "Al Hazm 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "773",
    "DISTRICTNAME_AR": "القدس 6",
    "DISTRICTNAME_EN": "Al Quds 6",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "774",
    "DISTRICTNAME_AR": "الحزم 3",
    "DISTRICTNAME_EN": "Al Hazm 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "775",
    "DISTRICTNAME_AR": "القدس 3",
    "DISTRICTNAME_EN": "Al Quds 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "776",
    "DISTRICTNAME_AR": "القدس 4",
    "DISTRICTNAME_EN": "Al Quds 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "777",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "778",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "779",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "780",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "781",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "782",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "783",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "784",
    "DISTRICTNAME_AR": "الراجحي",
    "DISTRICTNAME_EN": "Al Rajhi",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "785",
    "DISTRICTNAME_AR": "المنطقة المركزية",
    "DISTRICTNAME_EN": "Central District",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "786",
    "DISTRICTNAME_AR": "الزاهر",
    "DISTRICTNAME_EN": "Az Zahir",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "787",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "788",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "789",
    "DISTRICTNAME_AR": "العريش",
    "DISTRICTNAME_EN": "Al Arish",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "790",
    "DISTRICTNAME_AR": "الوداد",
    "DISTRICTNAME_EN": "Al Widad",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "791",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "792",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "793",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "794",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "795",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "796",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "797",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "798",
    "DISTRICTNAME_AR": "الظرفة",
    "DISTRICTNAME_EN": "Al Dhurfah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "799",
    "DISTRICTNAME_AR": "الضياء",
    "DISTRICTNAME_EN": "Al Diya",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "800",
    "DISTRICTNAME_AR": "المعلاة",
    "DISTRICTNAME_EN": "Al Maealaa",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "801",
    "DISTRICTNAME_AR": "المراغة",
    "DISTRICTNAME_EN": "Al Muragha",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "802",
    "DISTRICTNAME_AR": "جرش الاثري",
    "DISTRICTNAME_EN": "Jarash Alathari",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "803",
    "DISTRICTNAME_AR": "وسط البلد",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "804",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "805",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "806",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "807",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "At Tahliyah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "808",
    "DISTRICTNAME_AR": "البركات",
    "DISTRICTNAME_EN": "Al Barakat",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "809",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "810",
    "DISTRICTNAME_AR": "المريديسية الشمالية",
    "DISTRICTNAME_EN": "Al Muraydeesiyah Al Shamaliyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "811",
    "DISTRICTNAME_AR": "الدرب",
    "DISTRICTNAME_EN": "Al Darb",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "812",
    "DISTRICTNAME_AR": "العرق الجنوبى",
    "DISTRICTNAME_EN": "Southern Al Eirq",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "813",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "814",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "815",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "816",
    "DISTRICTNAME_AR": "عكاظ",
    "DISTRICTNAME_EN": "Uqaz",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "817",
    "DISTRICTNAME_AR": "باب الساب",
    "DISTRICTNAME_EN": "Bab As Sab",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "818",
    "DISTRICTNAME_AR": "ال بريد",
    "DISTRICTNAME_EN": "Al Barid",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "819",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "820",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "821",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "822",
    "DISTRICTNAME_AR": "المروج 4",
    "DISTRICTNAME_EN": "Al Muruj 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "823",
    "DISTRICTNAME_AR": "المروج 2",
    "DISTRICTNAME_EN": "Al Muruj 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "824",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "825",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "826",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "827",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "828",
    "DISTRICTNAME_AR": "بجيلة",
    "DISTRICTNAME_EN": "Bjeila",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "829",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "830",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "831",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "832",
    "DISTRICTNAME_AR": "السودة",
    "DISTRICTNAME_EN": "Al Sawda",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "833",
    "DISTRICTNAME_AR": "الرونق",
    "DISTRICTNAME_EN": "Al Ruwnaq",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "834",
    "DISTRICTNAME_AR": "المنتزة الشرقي",
    "DISTRICTNAME_EN": "Al Muntazah Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "835",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "836",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "837",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "17419",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "838",
    "DISTRICTNAME_AR": "ضمد",
    "DISTRICTNAME_EN": "Damud",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "839",
    "DISTRICTNAME_AR": "بيروت",
    "DISTRICTNAME_EN": "Beirut",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "840",
    "DISTRICTNAME_AR": "السليل",
    "DISTRICTNAME_EN": "As Sulayyil",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "841",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "842",
    "DISTRICTNAME_AR": "النخيل الشرقي",
    "DISTRICTNAME_EN": "Al Nakhil Eastern",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "843",
    "DISTRICTNAME_AR": "النخيل الغربي",
    "DISTRICTNAME_EN": "Al Nakhil Western",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "844",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "845",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "846",
    "DISTRICTNAME_AR": "المروج 3",
    "DISTRICTNAME_EN": "Al Muruj 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "847",
    "DISTRICTNAME_AR": "القرحاء 1",
    "DISTRICTNAME_EN": "Al Qarha 1",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "848",
    "DISTRICTNAME_AR": "المضيق",
    "DISTRICTNAME_EN": "Al Madiq",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "849",
    "DISTRICTNAME_AR": "ال فقيع",
    "DISTRICTNAME_EN": "Al Faqie",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "850",
    "DISTRICTNAME_AR": "بني تميم",
    "DISTRICTNAME_EN": "Bani Tamim",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "851",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "852",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "853",
    "DISTRICTNAME_AR": "المدينة الجامعية",
    "DISTRICTNAME_EN": "University City",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "854",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "855",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "856",
    "DISTRICTNAME_AR": "القصيعة",
    "DISTRICTNAME_EN": "Al Qusayah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "857",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "858",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "859",
    "DISTRICTNAME_AR": "الطاهرية",
    "DISTRICTNAME_EN": "Al Tahiriyya",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "860",
    "DISTRICTNAME_AR": "السلوى",
    "DISTRICTNAME_EN": "Al Salwaa",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "861",
    "DISTRICTNAME_AR": "النورس",
    "DISTRICTNAME_EN": "An Nawras",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "862",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "863",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "864",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "865",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "866",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "867",
    "DISTRICTNAME_AR": "الخضراء 1",
    "DISTRICTNAME_EN": "Al Khadraa 1",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "868",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "869",
    "DISTRICTNAME_AR": "الحزم 1",
    "DISTRICTNAME_EN": "Al Hazm 1",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "870",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "871",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "872",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "873",
    "DISTRICTNAME_AR": "الجبل 2",
    "DISTRICTNAME_EN": "Al Jabal 2",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "874",
    "DISTRICTNAME_AR": "الجبل 1",
    "DISTRICTNAME_EN": "Al Jabal 1",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "875",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "876",
    "DISTRICTNAME_AR": "خضيراء الشمالية",
    "DISTRICTNAME_EN": "Khadira Al Shamaliyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "877",
    "DISTRICTNAME_AR": "رواق الغربي",
    "DISTRICTNAME_EN": "Rawaq Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "878",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "879",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "880",
    "DISTRICTNAME_AR": "القادسية الاول",
    "DISTRICTNAME_EN": "Al Qadisiyah 1",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "881",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "882",
    "DISTRICTNAME_AR": "الاستاد الرياضى",
    "DISTRICTNAME_EN": "Sports Stadium",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "883",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "884",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "885",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "886",
    "DISTRICTNAME_AR": "الصباخ",
    "DISTRICTNAME_EN": "Al Sabbakh",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "887",
    "DISTRICTNAME_AR": "الرضوان",
    "DISTRICTNAME_EN": "Al Radwan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "888",
    "DISTRICTNAME_AR": "الصوامع",
    "DISTRICTNAME_EN": "Al Sawamie",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "889",
    "DISTRICTNAME_AR": "البساتين الغربي",
    "DISTRICTNAME_EN": "Al Basatin Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "890",
    "DISTRICTNAME_AR": "المحمدية 3",
    "DISTRICTNAME_EN": "Al Muhammadiyah 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "891",
    "DISTRICTNAME_AR": "المودة",
    "DISTRICTNAME_EN": "Al Mawada",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "892",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "893",
    "DISTRICTNAME_AR": "الساحل",
    "DISTRICTNAME_EN": "As Sahil",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "894",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "895",
    "DISTRICTNAME_AR": "النورس 2",
    "DISTRICTNAME_EN": "An Nawras 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "896",
    "DISTRICTNAME_AR": "الأراك 2",
    "DISTRICTNAME_EN": "Al Arak 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "897",
    "DISTRICTNAME_AR": "النورس 1",
    "DISTRICTNAME_EN": "An Nawras 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "898",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "6099",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "899",
    "DISTRICTNAME_AR": "الخضراء 2",
    "DISTRICTNAME_EN": "Al Khadraa 2",
    "CITY_ID": "12293",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "900",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "901",
    "DISTRICTNAME_AR": "دمج",
    "DISTRICTNAME_EN": "Dumj",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "902",
    "DISTRICTNAME_AR": "شمال الجامعة",
    "DISTRICTNAME_EN": "North University",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "903",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "904",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "905",
    "DISTRICTNAME_AR": "الجامعة الشرقي",
    "DISTRICTNAME_EN": "Al Jamiah Al Sharqi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "906",
    "DISTRICTNAME_AR": "الجامعة الأوسط",
    "DISTRICTNAME_EN": "Al Jamiah Al Awsat",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "907",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "908",
    "DISTRICTNAME_AR": "القابل",
    "DISTRICTNAME_EN": "Al Qabil",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "909",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "910",
    "DISTRICTNAME_AR": "المنهل",
    "DISTRICTNAME_EN": "Al Manhal",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "911",
    "DISTRICTNAME_AR": "النازية",
    "DISTRICTNAME_EN": "Al Naziyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "912",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "913",
    "DISTRICTNAME_AR": "القمر",
    "DISTRICTNAME_EN": "Al Qamar",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "914",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "915",
    "DISTRICTNAME_AR": "القمراء",
    "DISTRICTNAME_EN": "Al Qamara",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "916",
    "DISTRICTNAME_AR": "الحفيرة الأوسط",
    "DISTRICTNAME_EN": "Al Hufayrah Al Awsat",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "917",
    "DISTRICTNAME_AR": "الجامعة الغربي",
    "DISTRICTNAME_EN": "Al Jamiah Al Gharbi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "918",
    "DISTRICTNAME_AR": "خضيرة",
    "DISTRICTNAME_EN": "Khudayra",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "919",
    "DISTRICTNAME_AR": "نجران",
    "DISTRICTNAME_EN": "Najran",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "920",
    "DISTRICTNAME_AR": "الباطنة",
    "DISTRICTNAME_EN": "Al Batinah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "921",
    "DISTRICTNAME_AR": "الظبية",
    "DISTRICTNAME_EN": "Al Dhabyah",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "922",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "923",
    "DISTRICTNAME_AR": "المعالي",
    "DISTRICTNAME_EN": "Al Maali",
    "CITY_ID": "13",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "924",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "925",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "926",
    "DISTRICTNAME_AR": "الحفيرة الغربي",
    "DISTRICTNAME_EN": "Al Hufayrah Al Sharqi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "927",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "928",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "929",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "930",
    "DISTRICTNAME_AR": "السمراء",
    "DISTRICTNAME_EN": "As Samra",
    "CITY_ID": "3458",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "931",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "932",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "933",
    "DISTRICTNAME_AR": "الشورى",
    "DISTRICTNAME_EN": "Ash Shura",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "934",
    "DISTRICTNAME_AR": "الرصف",
    "DISTRICTNAME_EN": "Ar Rasf",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "935",
    "DISTRICTNAME_AR": "سماح",
    "DISTRICTNAME_EN": "Samah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "936",
    "DISTRICTNAME_AR": "برزان",
    "DISTRICTNAME_EN": "Barzan",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "937",
    "DISTRICTNAME_AR": "المزعبر",
    "DISTRICTNAME_EN": "Al Muzaabar",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "938",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "939",
    "DISTRICTNAME_AR": "السويفلة",
    "DISTRICTNAME_EN": "As Suwayfilah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "940",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "941",
    "DISTRICTNAME_AR": "القديح",
    "DISTRICTNAME_EN": "Al Qadih",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "942",
    "DISTRICTNAME_AR": "الساحل",
    "DISTRICTNAME_EN": "As Sahil",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "943",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "944",
    "DISTRICTNAME_AR": "الهميلة",
    "DISTRICTNAME_EN": "Al Humila",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "945",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "947",
    "DISTRICTNAME_AR": "الغزود",
    "DISTRICTNAME_EN": "Al Ghazud",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "948",
    "DISTRICTNAME_AR": "الجبهان",
    "DISTRICTNAME_EN": "Al Jabhan",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "949",
    "DISTRICTNAME_AR": "الريبع",
    "DISTRICTNAME_EN": "Al Rabie",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "950",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "952",
    "DISTRICTNAME_AR": "التيسير",
    "DISTRICTNAME_EN": "At Taysir",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "953",
    "DISTRICTNAME_AR": "جبارة",
    "DISTRICTNAME_EN": "Jubarah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "954",
    "DISTRICTNAME_AR": "الراشديات",
    "DISTRICTNAME_EN": "Al Rashidiyyat",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "955",
    "DISTRICTNAME_AR": "خب الثنيان",
    "DISTRICTNAME_EN": "Khub Ath Thanyan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "956",
    "DISTRICTNAME_AR": "النقع الشرقي",
    "DISTRICTNAME_EN": "Al Naqa Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "957",
    "DISTRICTNAME_AR": "قمبر",
    "DISTRICTNAME_EN": "Qambar",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "958",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "959",
    "DISTRICTNAME_AR": "الخضر",
    "DISTRICTNAME_EN": "Al Khudar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "960",
    "DISTRICTNAME_AR": "المليداء",
    "DISTRICTNAME_EN": "Al Mulayda",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "961",
    "DISTRICTNAME_AR": "المرسلات",
    "DISTRICTNAME_EN": "Al Mursalat",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "962",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "963",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "964",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "965",
    "DISTRICTNAME_AR": "الوشم",
    "DISTRICTNAME_EN": "Al Washm",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "966",
    "DISTRICTNAME_AR": "العمارة",
    "DISTRICTNAME_EN": "Al Amaarah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "967",
    "DISTRICTNAME_AR": "الجميماء",
    "DISTRICTNAME_EN": "Al Jumayma",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "968",
    "DISTRICTNAME_AR": "الملز",
    "DISTRICTNAME_EN": "Al Malaz",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "969",
    "DISTRICTNAME_AR": "المساعدية",
    "DISTRICTNAME_EN": "Al Misadiyah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "970",
    "DISTRICTNAME_AR": "العرين",
    "DISTRICTNAME_EN": "Al Arin",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "971",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "972",
    "DISTRICTNAME_AR": "الشرقية",
    "DISTRICTNAME_EN": "Sharqia",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "973",
    "DISTRICTNAME_AR": "المنتزة الغربي",
    "DISTRICTNAME_EN": "Al Muntazah Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "974",
    "DISTRICTNAME_AR": "الصواري",
    "DISTRICTNAME_EN": "Al Sawari",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "975",
    "DISTRICTNAME_AR": "النقى",
    "DISTRICTNAME_EN": "An Naqa",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "977",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "978",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "979",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "980",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "981",
    "DISTRICTNAME_AR": "الزهر",
    "DISTRICTNAME_EN": "Al Zuhr",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "982",
    "DISTRICTNAME_AR": "الورد",
    "DISTRICTNAME_EN": "Al Ward",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "984",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "985",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "986",
    "DISTRICTNAME_AR": "القمة",
    "DISTRICTNAME_EN": "Al Qima",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "987",
    "DISTRICTNAME_AR": "الرمانة",
    "DISTRICTNAME_EN": "Ar Rumanah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "988",
    "DISTRICTNAME_AR": "البحيرة",
    "DISTRICTNAME_EN": "Al Buhayrah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "989",
    "DISTRICTNAME_AR": "ذرة",
    "DISTRICTNAME_EN": "Dharah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "990",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "991",
    "DISTRICTNAME_AR": "المملكة",
    "DISTRICTNAME_EN": "The Kingdom",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "992",
    "DISTRICTNAME_AR": "حلة محيش",
    "DISTRICTNAME_EN": "Halat Mahish",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "993",
    "DISTRICTNAME_AR": "الزين",
    "DISTRICTNAME_EN": "Al Zayn",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "994",
    "DISTRICTNAME_AR": "السوسن",
    "DISTRICTNAME_EN": "Al Sawsan",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "995",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "996",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "997",
    "DISTRICTNAME_AR": "الشقة",
    "DISTRICTNAME_EN": "Al Shiqah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "998",
    "DISTRICTNAME_AR": "الامارة",
    "DISTRICTNAME_EN": "Al Amarah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "999",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1000",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1001",
    "DISTRICTNAME_AR": "النقيب الجنوبي",
    "DISTRICTNAME_EN": "Al Naqeeb Al Janubi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1002",
    "DISTRICTNAME_AR": "الكويكب",
    "DISTRICTNAME_EN": "Kuwaikib",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1003",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1004",
    "DISTRICTNAME_AR": "الشفق",
    "DISTRICTNAME_EN": "Al Shafaq",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1005",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1006",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1007",
    "DISTRICTNAME_AR": "الحوراء",
    "DISTRICTNAME_EN": "Al Hawra",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1008",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1009",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1010",
    "DISTRICTNAME_AR": "شعبة الشيخ",
    "DISTRICTNAME_EN": "Shubat Al Shaykh",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1011",
    "DISTRICTNAME_AR": "الأمير مشعل أ",
    "DISTRICTNAME_EN": "Prince Mishal A",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1012",
    "DISTRICTNAME_AR": "مشار",
    "DISTRICTNAME_EN": "Mashar",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1013",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1014",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1015",
    "DISTRICTNAME_AR": "أم الساهك",
    "DISTRICTNAME_EN": "Umm As Sahik",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1016",
    "DISTRICTNAME_AR": "الخصاب",
    "DISTRICTNAME_EN": "Al Khisab",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1017",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1018",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1020",
    "DISTRICTNAME_AR": "ذهبان الشرقي",
    "DISTRICTNAME_EN": "Dhahban Al Sharqi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1021",
    "DISTRICTNAME_AR": "المعارض",
    "DISTRICTNAME_EN": "Al Maarid",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1022",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1023",
    "DISTRICTNAME_AR": "أحواش الأغنام",
    "DISTRICTNAME_EN": "Ahwash Al Aghnam",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1024",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1025",
    "DISTRICTNAME_AR": "المراث",
    "DISTRICTNAME_EN": "Al Mirath",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1026",
    "DISTRICTNAME_AR": "عين حمزة",
    "DISTRICTNAME_EN": "Ayn Hamzah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1027",
    "DISTRICTNAME_AR": "الصبيحية",
    "DISTRICTNAME_EN": "Al Subayhiyyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1028",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1029",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1030",
    "DISTRICTNAME_AR": "العمال",
    "DISTRICTNAME_EN": "Al Ummal",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1031",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1032",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1033",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1034",
    "DISTRICTNAME_AR": "العمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1035",
    "DISTRICTNAME_AR": "أرض وزارة الدفاع",
    "DISTRICTNAME_EN": "Land of the Ministry of Defense",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1036",
    "DISTRICTNAME_AR": "قرية اجا",
    "DISTRICTNAME_EN": "Qaryat Aja",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1038",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1039",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1040",
    "DISTRICTNAME_AR": "شكر",
    "DISTRICTNAME_EN": "Shakar",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1041",
    "DISTRICTNAME_AR": "الوطاة",
    "DISTRICTNAME_EN": "Al Watah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1042",
    "DISTRICTNAME_AR": "السلام الشرقي",
    "DISTRICTNAME_EN": "Al Salam Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1043",
    "DISTRICTNAME_AR": "الزمرد1",
    "DISTRICTNAME_EN": "Az Zomorod1",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1044",
    "DISTRICTNAME_AR": "الصناعية1",
    "DISTRICTNAME_EN": "Industrial1",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1045",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1047",
    "DISTRICTNAME_AR": "خب القبر",
    "DISTRICTNAME_EN": "Khub Al Qabar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1048",
    "DISTRICTNAME_AR": "حي ج14",
    "DISTRICTNAME_EN": "Dst C14",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1049",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1050",
    "DISTRICTNAME_AR": "العلم",
    "DISTRICTNAME_EN": "Al Eilm",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1051",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1053",
    "DISTRICTNAME_AR": "السلطانيه",
    "DISTRICTNAME_EN": "Al Sultanih",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1054",
    "DISTRICTNAME_AR": "المسعودية",
    "DISTRICTNAME_EN": "Al Masudiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1057",
    "DISTRICTNAME_AR": "الامارة",
    "DISTRICTNAME_EN": "Al Amarah",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1058",
    "DISTRICTNAME_AR": "الفيصلية أ",
    "DISTRICTNAME_EN": "Al Faisaliyah A",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1059",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1060",
    "DISTRICTNAME_AR": "الخالدية أ",
    "DISTRICTNAME_EN": "Al Khalidiyah A",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1061",
    "DISTRICTNAME_AR": "الروضة الشمالي",
    "DISTRICTNAME_EN": "Ar Rawdah Al Shamali",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1062",
    "DISTRICTNAME_AR": "الصناعية أ",
    "DISTRICTNAME_EN": "Industrial A",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1063",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1064",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1065",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1066",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1067",
    "DISTRICTNAME_AR": "الضيافة",
    "DISTRICTNAME_EN": "Ad Diyafah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1068",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1069",
    "DISTRICTNAME_AR": "شاطىء القطيف ",
    "DISTRICTNAME_EN": "Qatif Beach",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1070",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1074",
    "DISTRICTNAME_AR": "البطحاء",
    "DISTRICTNAME_EN": "Al Batha",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1075",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1076",
    "DISTRICTNAME_AR": "المريحل",
    "DISTRICTNAME_EN": "Al Murihil",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1077",
    "DISTRICTNAME_AR": "الحرة",
    "DISTRICTNAME_EN": "Al Hura",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1078",
    "DISTRICTNAME_AR": "البلدة القديمة",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1080",
    "DISTRICTNAME_AR": "الإسكان2",
    "DISTRICTNAME_EN": "Al Iskan 2",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1082",
    "DISTRICTNAME_AR": "أم الحمام",
    "DISTRICTNAME_EN": "Umm Al Hamam",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1083",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1084",
    "DISTRICTNAME_AR": "العيون",
    "DISTRICTNAME_EN": "Al Uyun",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1085",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1086",
    "DISTRICTNAME_AR": "الظهرة",
    "DISTRICTNAME_EN": "Adh Dhahrah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1087",
    "DISTRICTNAME_AR": "غرب الظهرة",
    "DISTRICTNAME_EN": "West Al Dhahra",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1088",
    "DISTRICTNAME_AR": "أم عنم",
    "DISTRICTNAME_EN": "Umm Anam",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1089",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1090",
    "DISTRICTNAME_AR": "الخبراء",
    "DISTRICTNAME_EN": "Al Khubara",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1091",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1092",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1093",
    "DISTRICTNAME_AR": "شمال ارامكو",
    "DISTRICTNAME_EN": "North Aramco",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1094",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1095",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1096",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1098",
    "DISTRICTNAME_AR": "الخامس",
    "DISTRICTNAME_EN": "Al Khamis",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1099",
    "DISTRICTNAME_AR": "الربيعية",
    "DISTRICTNAME_EN": "Ar Rabeiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1100",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1101",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1102",
    "DISTRICTNAME_AR": "الشماسية",
    "DISTRICTNAME_EN": "Ash Shamasiyyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1103",
    "DISTRICTNAME_AR": "الأزهر",
    "DISTRICTNAME_EN": "Al Azhar",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1104",
    "DISTRICTNAME_AR": "القافلة",
    "DISTRICTNAME_EN": "Al Qafilah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1105",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1107",
    "DISTRICTNAME_AR": "المنطقة الصناعية2",
    "DISTRICTNAME_EN": "Industrial Area 2",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1111",
    "DISTRICTNAME_AR": "حي ج21",
    "DISTRICTNAME_EN": "Dst C21",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1112",
    "DISTRICTNAME_AR": "المكيلي",
    "DISTRICTNAME_EN": "Al Mukaili",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1114",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1115",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1116",
    "DISTRICTNAME_AR": "حي ج11",
    "DISTRICTNAME_EN": "Dst C11",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1117",
    "DISTRICTNAME_AR": "السوق",
    "DISTRICTNAME_EN": "As Suq",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1118",
    "DISTRICTNAME_AR": "الأمير مشعل ب",
    "DISTRICTNAME_EN": "Prince Mishal B",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1119",
    "DISTRICTNAME_AR": "الهرير الشرقى",
    "DISTRICTNAME_EN": "Al Hurayr Al Sharqi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1120",
    "DISTRICTNAME_AR": "تارة",
    "DISTRICTNAME_EN": "Tara",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1121",
    "DISTRICTNAME_AR": "الراقي",
    "DISTRICTNAME_EN": "Al Raaqi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1122",
    "DISTRICTNAME_AR": "المعزاب",
    "DISTRICTNAME_EN": "Al Mizab",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1123",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1124",
    "DISTRICTNAME_AR": "الصمدة",
    "DISTRICTNAME_EN": "As Samdah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1125",
    "DISTRICTNAME_AR": "مصلوم",
    "DISTRICTNAME_EN": "Maslum",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1127",
    "DISTRICTNAME_AR": "ضحكان 2",
    "DISTRICTNAME_EN": "Dahkan 2",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1128",
    "DISTRICTNAME_AR": "الصفر",
    "DISTRICTNAME_EN": "As Safr",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1129",
    "DISTRICTNAME_AR": "السبخة",
    "DISTRICTNAME_EN": "Al Sabakhah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1130",
    "DISTRICTNAME_AR": "الحميضة",
    "DISTRICTNAME_EN": "Al Hamiduh",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1131",
    "DISTRICTNAME_AR": "حي ج17",
    "DISTRICTNAME_EN": "Dst C17",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1133",
    "DISTRICTNAME_AR": "الجبل",
    "DISTRICTNAME_EN": "Al Jabal",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1134",
    "DISTRICTNAME_AR": "الرصراص 1",
    "DISTRICTNAME_EN": "Al Rasras 1",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1135",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1136",
    "DISTRICTNAME_AR": "شعب ذهبان",
    "DISTRICTNAME_EN": "Shaeb Dhahaban",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1137",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1138",
    "DISTRICTNAME_AR": "القرص",
    "DISTRICTNAME_EN": "Al Qurs",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1139",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1140",
    "DISTRICTNAME_AR": "حي ج18",
    "DISTRICTNAME_EN": "Dst C18",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1143",
    "DISTRICTNAME_AR": "الغزلانى",
    "DISTRICTNAME_EN": "Al Ghazlani",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1144",
    "DISTRICTNAME_AR": "الدبابية",
    "DISTRICTNAME_EN": "Ad Dababiyyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1145",
    "DISTRICTNAME_AR": "مصنع الاسمنت",
    "DISTRICTNAME_EN": "Cement Factory",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1146",
    "DISTRICTNAME_AR": "محطة الكهرباء",
    "DISTRICTNAME_EN": "Electricity Station",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1147",
    "DISTRICTNAME_AR": "منطقة تحلية المياة",
    "DISTRICTNAME_EN": "Water desalination Area",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1148",
    "DISTRICTNAME_AR": "فوار",
    "DISTRICTNAME_EN": "Fawar",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1149",
    "DISTRICTNAME_AR": "المنح ج",
    "DISTRICTNAME_EN": "Al Minah J",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1150",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1151",
    "DISTRICTNAME_AR": "الصقور",
    "DISTRICTNAME_EN": "As Suqur",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1152",
    "DISTRICTNAME_AR": "النقرة",
    "DISTRICTNAME_EN": "An Naqrah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1153",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1154",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1155",
    "DISTRICTNAME_AR": "الخلود",
    "DISTRICTNAME_EN": "Al Khulud",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1156",
    "DISTRICTNAME_AR": "النقيب الشمالي",
    "DISTRICTNAME_EN": "Al Naqeeb Al Shamali",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1157",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1158",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1159",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1160",
    "DISTRICTNAME_AR": "الحمر الشمالي",
    "DISTRICTNAME_EN": "Al Humar Al Shamali",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1161",
    "DISTRICTNAME_AR": "الفريعة",
    "DISTRICTNAME_EN": "Al Farieah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1162",
    "DISTRICTNAME_AR": "حي ج8",
    "DISTRICTNAME_EN": "Dst C8",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1163",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1164",
    "DISTRICTNAME_AR": "جنوب الحميضه",
    "DISTRICTNAME_EN": "South Humidah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1165",
    "DISTRICTNAME_AR": "الوصل",
    "DISTRICTNAME_EN": "Al Wasl",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1166",
    "DISTRICTNAME_AR": "الجنوبية",
    "DISTRICTNAME_EN": "Al Janubiyah",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1167",
    "DISTRICTNAME_AR": "القلعة",
    "DISTRICTNAME_EN": "Al Qalah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1168",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1169",
    "DISTRICTNAME_AR": "حزم",
    "DISTRICTNAME_EN": "Hazm",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1170",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1171",
    "DISTRICTNAME_AR": "الرزيقية",
    "DISTRICTNAME_EN": "Al Ruzaiqiah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1172",
    "DISTRICTNAME_AR": "القطار",
    "DISTRICTNAME_EN": "Al Qitar",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1173",
    "DISTRICTNAME_AR": "الشرفية",
    "DISTRICTNAME_EN": "Ash Sharafiyah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1174",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1175",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1178",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1179",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1180",
    "DISTRICTNAME_AR": "حي ج6",
    "DISTRICTNAME_EN": "Dst C6",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1181",
    "DISTRICTNAME_AR": "حي ج7",
    "DISTRICTNAME_EN": "Dst C7",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1182",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1183",
    "DISTRICTNAME_AR": "الجبل",
    "DISTRICTNAME_EN": "Al Jabal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1184",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1185",
    "DISTRICTNAME_AR": "المروج 2",
    "DISTRICTNAME_EN": "Al Muruj 2",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1186",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1187",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1188",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1190",
    "DISTRICTNAME_AR": "المجيدية",
    "DISTRICTNAME_EN": "Al Majidiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1191",
    "DISTRICTNAME_AR": "الشويكة",
    "DISTRICTNAME_EN": "Ash Shweikah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1192",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1193",
    "DISTRICTNAME_AR": "حويلان",
    "DISTRICTNAME_EN": "Huwailan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1194",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1195",
    "DISTRICTNAME_AR": "التعليم",
    "DISTRICTNAME_EN": "At Taalim",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1196",
    "DISTRICTNAME_AR": "ابيار الماشي",
    "DISTRICTNAME_EN": "Abyar Al Mashi",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1197",
    "DISTRICTNAME_AR": "منطقة الارسال الاذاعي",
    "DISTRICTNAME_EN": "Radio Transmitter",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1198",
    "DISTRICTNAME_AR": "العند",
    "DISTRICTNAME_EN": "Al Eind",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1199",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "At Tahliyah",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1200",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1201",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1202",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1205",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1206",
    "DISTRICTNAME_AR": "قرية نقبين",
    "DISTRICTNAME_EN": "Qaryat Naqbin",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1207",
    "DISTRICTNAME_AR": "قفار",
    "DISTRICTNAME_EN": "Qafar",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1209",
    "DISTRICTNAME_AR": "البحيرة",
    "DISTRICTNAME_EN": "Al Buhayrah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1210",
    "DISTRICTNAME_AR": "شراف",
    "DISTRICTNAME_EN": "Sharaf",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1211",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1212",
    "DISTRICTNAME_AR": "عيرف",
    "DISTRICTNAME_EN": "Ayraf",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1213",
    "DISTRICTNAME_AR": "الوسيطاء",
    "DISTRICTNAME_EN": "Al Wusayta",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1214",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1215",
    "DISTRICTNAME_AR": "النعام",
    "DISTRICTNAME_EN": "Al Naeam",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1216",
    "DISTRICTNAME_AR": "صلاح الدين",
    "DISTRICTNAME_EN": "Salahuddin",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1217",
    "DISTRICTNAME_AR": "حدري البلاد",
    "DISTRICTNAME_EN": "Hadri Al Bilad",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1218",
    "DISTRICTNAME_AR": "قرية الجثامية",
    "DISTRICTNAME_EN": "Qaryat Al Jithamia",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1219",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1220",
    "DISTRICTNAME_AR": "قريان",
    "DISTRICTNAME_EN": "Quryan",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1223",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1224",
    "DISTRICTNAME_AR": "التل",
    "DISTRICTNAME_EN": "Al Tall",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1225",
    "DISTRICTNAME_AR": "النظير",
    "DISTRICTNAME_EN": "Al Nazir",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1226",
    "DISTRICTNAME_AR": "المعارض",
    "DISTRICTNAME_EN": "Al Maarid",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1227",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1228",
    "DISTRICTNAME_AR": "المنصورة",
    "DISTRICTNAME_EN": "Al Mansurah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1229",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1230",
    "DISTRICTNAME_AR": "الزاوية",
    "DISTRICTNAME_EN": "Al Zaawia",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1231",
    "DISTRICTNAME_AR": "الأمير مشعل",
    "DISTRICTNAME_EN": "Prince Mishal",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1232",
    "DISTRICTNAME_AR": "محطة الكهرباء",
    "DISTRICTNAME_EN": "Electricity Station",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1233",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1234",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1235",
    "DISTRICTNAME_AR": "الصفراء",
    "DISTRICTNAME_EN": "As Safra",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1236",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1237",
    "DISTRICTNAME_AR": "الريف",
    "DISTRICTNAME_EN": "Ar Rif",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1238",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1239",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1240",
    "DISTRICTNAME_AR": "المدينة",
    "DISTRICTNAME_EN": "Al Madinah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1241",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1242",
    "DISTRICTNAME_AR": "الصناعية الجديدة",
    "DISTRICTNAME_EN": "New Industrial",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1243",
    "DISTRICTNAME_AR": "المعمورة",
    "DISTRICTNAME_EN": "Al Mamurah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1244",
    "DISTRICTNAME_AR": "جنوب تندحة",
    "DISTRICTNAME_EN": "South of the villages Tandiha",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1245",
    "DISTRICTNAME_AR": "الشرف",
    "DISTRICTNAME_EN": "Al Sharaf",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1246",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1247",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1248",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1249",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1250",
    "DISTRICTNAME_AR": "الشعبة",
    "DISTRICTNAME_EN": "Al Shuaba",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1251",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1252",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1253",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1254",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1257",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1258",
    "DISTRICTNAME_AR": "الروضة الجنوبي",
    "DISTRICTNAME_EN": "Ar Rawdah Al Janubi",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1259",
    "DISTRICTNAME_AR": "المستشفى العسكري",
    "DISTRICTNAME_EN": "Military Hospital",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1260",
    "DISTRICTNAME_AR": "جنوب المستشفى العسكري",
    "DISTRICTNAME_EN": "Janub Al Mustashfa Al Askari",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1262",
    "DISTRICTNAME_AR": "1النرجس",
    "DISTRICTNAME_EN": "An Narjis1",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1263",
    "DISTRICTNAME_AR": "البحاري",
    "DISTRICTNAME_EN": "Al Bahari",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1264",
    "DISTRICTNAME_AR": "إسكان قوى الأمن",
    "DISTRICTNAME_EN": "Security Forces Housing",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1265",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1266",
    "DISTRICTNAME_AR": "قرية النيصية",
    "DISTRICTNAME_EN": "Qaryat Al Nyssa",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1267",
    "DISTRICTNAME_AR": "كورنيش حائل",
    "DISTRICTNAME_EN": "Hail Corniche",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1268",
    "DISTRICTNAME_AR": "الودي",
    "DISTRICTNAME_EN": "Al Wudi",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1269",
    "DISTRICTNAME_AR": "الطريفي",
    "DISTRICTNAME_EN": "Al Turifi",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1270",
    "DISTRICTNAME_AR": "المنتزة الشرقي",
    "DISTRICTNAME_EN": "Al Muntazah Ash Sharqi",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1271",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1272",
    "DISTRICTNAME_AR": "درة مشار",
    "DISTRICTNAME_EN": "Dora Machar",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1273",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1274",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1277",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1278",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1279",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1280",
    "DISTRICTNAME_AR": "الوسيطاء",
    "DISTRICTNAME_EN": "Al Wusayta",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1281",
    "DISTRICTNAME_AR": "الصيادلة",
    "DISTRICTNAME_EN": "al Sayadiluh",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1282",
    "DISTRICTNAME_AR": "حي ج3د",
    "DISTRICTNAME_EN": "Dst J3D",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1283",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1284",
    "DISTRICTNAME_AR": "المجمع",
    "DISTRICTNAME_EN": "Al Majma",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1285",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1286",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1288",
    "DISTRICTNAME_AR": "إدمان الشرقي",
    "DISTRICTNAME_EN": "Idman Al Sharqi",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1289",
    "DISTRICTNAME_AR": "العدوة",
    "DISTRICTNAME_EN": "Al Adwah",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1290",
    "DISTRICTNAME_AR": "الوديعه",
    "DISTRICTNAME_EN": "Al Wadiah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1291",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1293",
    "DISTRICTNAME_AR": "ضحكان 1",
    "DISTRICTNAME_EN": "Dahkan 1",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1294",
    "DISTRICTNAME_AR": "العرق الشمالي",
    "DISTRICTNAME_EN": "Northern Al Eirq",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1295",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1296",
    "DISTRICTNAME_AR": "أبو معن",
    "DISTRICTNAME_EN": "Abu Main",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1297",
    "DISTRICTNAME_AR": "قراقر",
    "DISTRICTNAME_EN": "Qaraqir",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1298",
    "DISTRICTNAME_AR": "العذيب",
    "DISTRICTNAME_EN": "Al Udhib",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1300",
    "DISTRICTNAME_AR": "منطقة خدمات 2",
    "DISTRICTNAME_EN": "Services  Area 2",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1302",
    "DISTRICTNAME_AR": "الشلال",
    "DISTRICTNAME_EN": "Ash Shalaal",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1303",
    "DISTRICTNAME_AR": "زرود",
    "DISTRICTNAME_EN": "Zaroud",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1304",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1305",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1306",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1307",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1308",
    "DISTRICTNAME_AR": "حي ج6",
    "DISTRICTNAME_EN": "Dst C6",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1309",
    "DISTRICTNAME_AR": "حي ج7",
    "DISTRICTNAME_EN": "Dst C7",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1310",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1311",
    "DISTRICTNAME_AR": "ذلالة",
    "DISTRICTNAME_EN": "Dhalalah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1312",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1313",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1314",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1315",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1316",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1317",
    "DISTRICTNAME_AR": "الخلصة",
    "DISTRICTNAME_EN": "Al Khulsa",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1318",
    "DISTRICTNAME_AR": "نشوان",
    "DISTRICTNAME_EN": "Nishwan",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1320",
    "DISTRICTNAME_AR": "الخامسة",
    "DISTRICTNAME_EN": "Al Khamisah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1321",
    "DISTRICTNAME_AR": "الزيتون",
    "DISTRICTNAME_EN": "Az Zaytun",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1322",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1323",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1324",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1325",
    "DISTRICTNAME_AR": "المصانع",
    "DISTRICTNAME_EN": "Al Masani",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1326",
    "DISTRICTNAME_AR": "المروج 1",
    "DISTRICTNAME_EN": "Al Muruj 1",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1327",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1328",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1329",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1333",
    "DISTRICTNAME_AR": "مخطط الملك عبدالله للإسكان",
    "DISTRICTNAME_EN": "King Abdullah Housing Subdivision",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1334",
    "DISTRICTNAME_AR": "الطلايع",
    "DISTRICTNAME_EN": "Al Talayie",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1335",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1336",
    "DISTRICTNAME_AR": "صفوان",
    "DISTRICTNAME_EN": "Safwan",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1337",
    "DISTRICTNAME_AR": "الحرابي",
    "DISTRICTNAME_EN": "Al Harabaa",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1338",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1339",
    "DISTRICTNAME_AR": "حي ج10",
    "DISTRICTNAME_EN": "Dst C10",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1340",
    "DISTRICTNAME_AR": "الخترشية",
    "DISTRICTNAME_EN": "Al Khatrashiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1341",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1342",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1343",
    "DISTRICTNAME_AR": "دارين",
    "DISTRICTNAME_EN": "Darin",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1344",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1345",
    "DISTRICTNAME_AR": "حي ج6",
    "DISTRICTNAME_EN": "Dst C6",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1349",
    "DISTRICTNAME_AR": "الخشبى",
    "DISTRICTNAME_EN": "Al Khushbi",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1350",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1352",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1353",
    "DISTRICTNAME_AR": "التلفزيون",
    "DISTRICTNAME_EN": "Al Tilfizyun",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1354",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1355",
    "DISTRICTNAME_AR": "الصويدرة",
    "DISTRICTNAME_EN": "Al Suwaydra",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1359",
    "DISTRICTNAME_AR": "وادي الهاشة",
    "DISTRICTNAME_EN": "Wadi Al hashah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1360",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1361",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1362",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1363",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1364",
    "DISTRICTNAME_AR": "الشماس",
    "DISTRICTNAME_EN": "Al Shammas",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1366",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1367",
    "DISTRICTNAME_AR": "الميداء",
    "DISTRICTNAME_EN": "Al Maida",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1368",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "1369",
    "DISTRICTNAME_AR": "اللجين",
    "DISTRICTNAME_EN": "Al Lajayn",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1371",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1372",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1376",
    "DISTRICTNAME_AR": "الديرة القديمة",
    "DISTRICTNAME_EN": "Ad Dirah Al Qadimah",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1377",
    "DISTRICTNAME_AR": "الدقم",
    "DISTRICTNAME_EN": "Al Duqm",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1378",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1379",
    "DISTRICTNAME_AR": "الرابع",
    "DISTRICTNAME_EN": "The Fourth",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1380",
    "DISTRICTNAME_AR": "التاسع",
    "DISTRICTNAME_EN": "Ninth",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1381",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1382",
    "DISTRICTNAME_AR": "أرض وزارة الاسكان",
    "DISTRICTNAME_EN": "Land of the Ministry of Housing",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1383",
    "DISTRICTNAME_AR": "الصناعية القديمة",
    "DISTRICTNAME_EN": "Old Industrial",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1384",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1385",
    "DISTRICTNAME_AR": "بقعاء الشرقية القديمة",
    "DISTRICTNAME_EN": "Old Eastern Baqaa",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1386",
    "DISTRICTNAME_AR": "أبو شجره",
    "DISTRICTNAME_EN": "Abu shajarah",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1387",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1388",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1389",
    "DISTRICTNAME_AR": "عكاظ",
    "DISTRICTNAME_EN": "Uqaz",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1390",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1391",
    "DISTRICTNAME_AR": "الشرف",
    "DISTRICTNAME_EN": "Al Sharaf",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1392",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1393",
    "DISTRICTNAME_AR": "الصمدة",
    "DISTRICTNAME_EN": "As Samdah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1394",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1395",
    "DISTRICTNAME_AR": "ساق",
    "DISTRICTNAME_EN": "Saq",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1396",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1397",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1398",
    "DISTRICTNAME_AR": "الضاحية",
    "DISTRICTNAME_EN": "Ad Dahiah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1399",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1400",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1401",
    "DISTRICTNAME_AR": "بقعاء القديمة",
    "DISTRICTNAME_EN": "Old Baqaa",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1402",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1403",
    "DISTRICTNAME_AR": "بحيرة تجميعية",
    "DISTRICTNAME_EN": "Pool Lake",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1404",
    "DISTRICTNAME_AR": "مخطط بقعاء الشرقية",
    "DISTRICTNAME_EN": "Baqa Al Sharqiah Subdivision",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1405",
    "DISTRICTNAME_AR": "حي ج13",
    "DISTRICTNAME_EN": "Dst C13",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1406",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1407",
    "DISTRICTNAME_AR": "حي ج9",
    "DISTRICTNAME_EN": "Dst C9",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1408",
    "DISTRICTNAME_AR": "الرويحة",
    "DISTRICTNAME_EN": "Ar Ruwaihah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1409",
    "DISTRICTNAME_AR": "حي ج4د",
    "DISTRICTNAME_EN": "Dst J4D",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1410",
    "DISTRICTNAME_AR": "المنخلي",
    "DISTRICTNAME_EN": "Al Munkhali",
    "CITY_ID": "3735",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1412",
    "DISTRICTNAME_AR": "السجدة",
    "DISTRICTNAME_EN": "Al Sajda",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1413",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1415",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1416",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1418",
    "DISTRICTNAME_AR": "المحفر",
    "DISTRICTNAME_EN": "Al Mahafr",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1419",
    "DISTRICTNAME_AR": "الضويحي",
    "DISTRICTNAME_EN": "Al Dawayhi",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1420",
    "DISTRICTNAME_AR": "حي ج7",
    "DISTRICTNAME_EN": "Dst C7",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1421",
    "DISTRICTNAME_AR": "الناصرية 6",
    "DISTRICTNAME_EN": "An Nasriyah 6",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1422",
    "DISTRICTNAME_AR": "الناصرية 5",
    "DISTRICTNAME_EN": "An Nasriyah 5",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1423",
    "DISTRICTNAME_AR": "الناصرية 4",
    "DISTRICTNAME_EN": "An Nasriyah 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1424",
    "DISTRICTNAME_AR": "الناصرية 3",
    "DISTRICTNAME_EN": "An Nasriyah 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1425",
    "DISTRICTNAME_AR": "الناصرية 2",
    "DISTRICTNAME_EN": "An Nasriyah 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1426",
    "DISTRICTNAME_AR": "الناصرية 1",
    "DISTRICTNAME_EN": "An Nasriyah 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1427",
    "DISTRICTNAME_AR": "الاحياء الجنوبية",
    "DISTRICTNAME_EN": "Southern Neighborhoods",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1428",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1430",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1431",
    "DISTRICTNAME_AR": "الوقواق",
    "DISTRICTNAME_EN": "Al Waqwaq",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1432",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1433",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1434",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1435",
    "DISTRICTNAME_AR": "المنتزة الغربي",
    "DISTRICTNAME_EN": "Al Muntazah Al Gharbi",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1436",
    "DISTRICTNAME_AR": "غرب الخالدية",
    "DISTRICTNAME_EN": "Khalidiyah West",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1437",
    "DISTRICTNAME_AR": "الشعبين",
    "DISTRICTNAME_EN": "Al Shuabin",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1438",
    "DISTRICTNAME_AR": "الصوامع",
    "DISTRICTNAME_EN": "Al Sawamie",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1439",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1440",
    "DISTRICTNAME_AR": "المنتزهات",
    "DISTRICTNAME_EN": "Al Mutanazahat",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1441",
    "DISTRICTNAME_AR": "مخطط الغزلاني",
    "DISTRICTNAME_EN": "Al Ghizlani Subdivision",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1442",
    "DISTRICTNAME_AR": "الشقة العليا",
    "DISTRICTNAME_EN": "Al Shiqah Al Ulya",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1443",
    "DISTRICTNAME_AR": "النقع الغربي",
    "DISTRICTNAME_EN": "Al Naqa Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1444",
    "DISTRICTNAME_AR": "الأمن",
    "DISTRICTNAME_EN": "Al Amn",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1445",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1446",
    "DISTRICTNAME_AR": "التخصصي",
    "DISTRICTNAME_EN": "Al Takhassusi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1447",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1448",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1449",
    "DISTRICTNAME_AR": "لزمه",
    "DISTRICTNAME_EN": "Lazmah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1450",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1451",
    "DISTRICTNAME_AR": "حي ج8",
    "DISTRICTNAME_EN": "Dst C8",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1452",
    "DISTRICTNAME_AR": "حي ج10",
    "DISTRICTNAME_EN": "Dst C10",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1453",
    "DISTRICTNAME_AR": "حي ج12",
    "DISTRICTNAME_EN": "Dst C12",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1454",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1458",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1459",
    "DISTRICTNAME_AR": "الكورنيش",
    "DISTRICTNAME_EN": "Al Kurnaish",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1460",
    "DISTRICTNAME_AR": "حي ج12",
    "DISTRICTNAME_EN": "Dst C12",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1463",
    "DISTRICTNAME_AR": "المحمدية 2",
    "DISTRICTNAME_EN": "Al Muhammadiyah 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1464",
    "DISTRICTNAME_AR": "الخالدية 3",
    "DISTRICTNAME_EN": "Al Khalidiyah 3",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1467",
    "DISTRICTNAME_AR": "مخطط السكراب",
    "DISTRICTNAME_EN": "Al Cakrab Subdivision",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1469",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1470",
    "DISTRICTNAME_AR": "الوفاء",
    "DISTRICTNAME_EN": "Al Wafa",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1471",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1472",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1473",
    "DISTRICTNAME_AR": "شباعة",
    "DISTRICTNAME_EN": "Shubaah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1474",
    "DISTRICTNAME_AR": "الرضا",
    "DISTRICTNAME_EN": "Ar Rida",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1478",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1479",
    "DISTRICTNAME_AR": "الاخاشيم",
    "DISTRICTNAME_EN": "Al Akhashim",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1480",
    "DISTRICTNAME_AR": "المنتزة رالشمالي",
    "DISTRICTNAME_EN": "Al Muntazah Al Shamali",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1481",
    "DISTRICTNAME_AR": "الجش",
    "DISTRICTNAME_EN": "Al Jish",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1482",
    "DISTRICTNAME_AR": "الخالدية 2",
    "DISTRICTNAME_EN": "Al Khalidiyah 2",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1483",
    "DISTRICTNAME_AR": "الخالدية 4",
    "DISTRICTNAME_EN": "Al Khalidiyah 4",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1484",
    "DISTRICTNAME_AR": "الخالدية 5",
    "DISTRICTNAME_EN": "Al Khalidiyah 5",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1485",
    "DISTRICTNAME_AR": "المفتاحة",
    "DISTRICTNAME_EN": "Al Muftahah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1486",
    "DISTRICTNAME_AR": "الخشع",
    "DISTRICTNAME_EN": "Al Khasha",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1487",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1488",
    "DISTRICTNAME_AR": "المناخ",
    "DISTRICTNAME_EN": "Al Manakh",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1489",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1490",
    "DISTRICTNAME_AR": "المدينة",
    "DISTRICTNAME_EN": "Al Madinah",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1492",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1493",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1494",
    "DISTRICTNAME_AR": "عين الخيف",
    "DISTRICTNAME_EN": "Ayn Al Khif",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1495",
    "DISTRICTNAME_AR": "الزور",
    "DISTRICTNAME_EN": "Az Zour",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1499",
    "DISTRICTNAME_AR": "وسط المدينة",
    "DISTRICTNAME_EN": "City Center",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1500",
    "DISTRICTNAME_AR": "الجبل",
    "DISTRICTNAME_EN": "Al Jabal",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1501",
    "DISTRICTNAME_AR": "درب العقيدة",
    "DISTRICTNAME_EN": "Darab Al Aqida",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1502",
    "DISTRICTNAME_AR": "القرحاء 2",
    "DISTRICTNAME_EN": "Al Qarha 2",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1503",
    "DISTRICTNAME_AR": "المربع",
    "DISTRICTNAME_EN": "Al Murabba",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1504",
    "DISTRICTNAME_AR": "المقيطع",
    "DISTRICTNAME_EN": "Al Muqayti",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1505",
    "DISTRICTNAME_AR": "عيانة 1",
    "DISTRICTNAME_EN": "Ayanah 1",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1506",
    "DISTRICTNAME_AR": "الغال",
    "DISTRICTNAME_EN": "Al Ghal",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1507",
    "DISTRICTNAME_AR": "المقرش",
    "DISTRICTNAME_EN": "Al Maqrash",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1508",
    "DISTRICTNAME_AR": "هداج",
    "DISTRICTNAME_EN": "Haddaj",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1509",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1510",
    "DISTRICTNAME_AR": "الثريا",
    "DISTRICTNAME_EN": "Ath Thuraiya",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1511",
    "DISTRICTNAME_AR": "الثاني",
    "DISTRICTNAME_EN": "The Second",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1512",
    "DISTRICTNAME_AR": "الثالث",
    "DISTRICTNAME_EN": "The Third",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1513",
    "DISTRICTNAME_AR": "المحار",
    "DISTRICTNAME_EN": "Al Mahar",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1514",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1515",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1516",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1517",
    "DISTRICTNAME_AR": "الوفاء",
    "DISTRICTNAME_EN": "Al Wafa",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1518",
    "DISTRICTNAME_AR": "عنقرة",
    "DISTRICTNAME_EN": "Anqara",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1523",
    "DISTRICTNAME_AR": "إدمان الغربي",
    "DISTRICTNAME_EN": "Idman Al Gharbi",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1524",
    "DISTRICTNAME_AR": "المفرق الخاص",
    "DISTRICTNAME_EN": "Al Mafriq Al Khas",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1526",
    "DISTRICTNAME_AR": "الأول",
    "DISTRICTNAME_EN": "Al Awal",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1527",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1528",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1529",
    "DISTRICTNAME_AR": "النفود",
    "DISTRICTNAME_EN": "An Nofoad",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1530",
    "DISTRICTNAME_AR": "الدهناء",
    "DISTRICTNAME_EN": "Al Dahnaa",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1531",
    "DISTRICTNAME_AR": "الخذامة",
    "DISTRICTNAME_EN": "Al Khudhama",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1532",
    "DISTRICTNAME_AR": "الفيصلية غرب",
    "DISTRICTNAME_EN": "Al Faisaliyah West",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1533",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1534",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1535",
    "DISTRICTNAME_AR": "الزارة",
    "DISTRICTNAME_EN": "Az Zarah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1536",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1537",
    "DISTRICTNAME_AR": "المنصورة",
    "DISTRICTNAME_EN": "Al Mansurah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1538",
    "DISTRICTNAME_AR": "الفهد أ",
    "DISTRICTNAME_EN": "Al Fahd A",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1539",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1540",
    "DISTRICTNAME_AR": "القلعة",
    "DISTRICTNAME_EN": "Al Qalah",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1541",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1542",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1543",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1544",
    "DISTRICTNAME_AR": "النجيل",
    "DISTRICTNAME_EN": "An Nujayl",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1545",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1547",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1548",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1549",
    "DISTRICTNAME_AR": "الجارودية",
    "DISTRICTNAME_EN": "Al Jarudiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1550",
    "DISTRICTNAME_AR": "الحضارى",
    "DISTRICTNAME_EN": "Al Hadari",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1551",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1552",
    "DISTRICTNAME_AR": "المساعدية",
    "DISTRICTNAME_EN": "Al Misadiyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1553",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1554",
    "DISTRICTNAME_AR": "عيانة 2",
    "DISTRICTNAME_EN": "Ayanah 2",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1555",
    "DISTRICTNAME_AR": "الذهب",
    "DISTRICTNAME_EN": "Al Dhahab",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1556",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1557",
    "DISTRICTNAME_AR": "منطقة المستودعات",
    "DISTRICTNAME_EN": "Warehouse Area",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1558",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1559",
    "DISTRICTNAME_AR": "التوبي",
    "DISTRICTNAME_EN": "At Tawbi",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1560",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1561",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1562",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1563",
    "DISTRICTNAME_AR": "الصريف",
    "DISTRICTNAME_EN": "Al Suraif",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1564",
    "DISTRICTNAME_AR": "المدينة الاقتصادية",
    "DISTRICTNAME_EN": "Economic City",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1566",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "Al Khazzan",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1567",
    "DISTRICTNAME_AR": "مخطط الظهيره",
    "DISTRICTNAME_EN": "Al Zahirih Subdivision",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1568",
    "DISTRICTNAME_AR": "الحميرة",
    "DISTRICTNAME_EN": "Al Hamriah",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1570",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1573",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1574",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12234",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "1575",
    "DISTRICTNAME_AR": "بطيحان الغربي",
    "DISTRICTNAME_EN": "Western Batihan",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1576",
    "DISTRICTNAME_AR": "المفرق",
    "DISTRICTNAME_EN": "Al Mafriq",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1577",
    "DISTRICTNAME_AR": "العقدة",
    "DISTRICTNAME_EN": "Al Uqdah",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1578",
    "DISTRICTNAME_AR": "مخطط صلاح الدين",
    "DISTRICTNAME_EN": "Salah Al Diyn Subdivision",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1581",
    "DISTRICTNAME_AR": "المدارس",
    "DISTRICTNAME_EN": "Al Madaris",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1583",
    "DISTRICTNAME_AR": "المنح ب",
    "DISTRICTNAME_EN": "Al Minah B",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1584",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1585",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1587",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1588",
    "DISTRICTNAME_AR": "حي ج9",
    "DISTRICTNAME_EN": "Dst C9",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1589",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1590",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1591",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1592",
    "DISTRICTNAME_AR": "مطار الأمير عبد المحسن بن عبدالعزيز",
    "DISTRICTNAME_EN": "Prince Abdulmohsen bin Abdulaziz Airport",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1593",
    "DISTRICTNAME_AR": "البحيرة",
    "DISTRICTNAME_EN": "Al Buhayrah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1595",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1596",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1597",
    "DISTRICTNAME_AR": "بديعة البصري",
    "DISTRICTNAME_EN": "Badiah Al Basri",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1598",
    "DISTRICTNAME_AR": "الغرابة",
    "DISTRICTNAME_EN": "Al Gharaba",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1599",
    "DISTRICTNAME_AR": "حي ج9",
    "DISTRICTNAME_EN": "Dst C9",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1603",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1604",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1605",
    "DISTRICTNAME_AR": "الجار",
    "DISTRICTNAME_EN": "Al Jar",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1606",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1607",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1608",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1609",
    "DISTRICTNAME_AR": "الشهباء",
    "DISTRICTNAME_EN": "Ash Shahba",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1610",
    "DISTRICTNAME_AR": "الشريعة",
    "DISTRICTNAME_EN": "Ash Shariah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1611",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1612",
    "DISTRICTNAME_AR": "الملاحة",
    "DISTRICTNAME_EN": "Al Milaha",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1614",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1620",
    "DISTRICTNAME_AR": "حي ج25",
    "DISTRICTNAME_EN": "Dst C25",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1621",
    "DISTRICTNAME_AR": "حي ج22",
    "DISTRICTNAME_EN": "Dst C22",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1622",
    "DISTRICTNAME_AR": "الرصراص 2",
    "DISTRICTNAME_EN": "Al Rasras 2",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1623",
    "DISTRICTNAME_AR": "المشتل",
    "DISTRICTNAME_EN": "Al Mashtal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1624",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1627",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1630",
    "DISTRICTNAME_AR": "حي ج20",
    "DISTRICTNAME_EN": "Dst C20",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1631",
    "DISTRICTNAME_AR": "أرامكو",
    "DISTRICTNAME_EN": "Aramco",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1632",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1633",
    "DISTRICTNAME_AR": "الرحمانية",
    "DISTRICTNAME_EN": "Ar Rahmanyah",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1634",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1635",
    "DISTRICTNAME_AR": "الفاو",
    "DISTRICTNAME_EN": "Al Faw",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1636",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1637",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1641",
    "DISTRICTNAME_AR": "وسط تندحة",
    "DISTRICTNAME_EN": "Centeral Tandaha",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1642",
    "DISTRICTNAME_AR": "شمال تندحة",
    "DISTRICTNAME_EN": "North Tandiha",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1643",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1644",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1645",
    "DISTRICTNAME_AR": "القاع",
    "DISTRICTNAME_EN": "Al Qaa",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1646",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1647",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1648",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1649",
    "DISTRICTNAME_AR": "الحسين",
    "DISTRICTNAME_EN": "Al Husain",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1651",
    "DISTRICTNAME_AR": "الخماشية",
    "DISTRICTNAME_EN": "Al Khamashiyah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1652",
    "DISTRICTNAME_AR": "الزبارة",
    "DISTRICTNAME_EN": "Az Zibarah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1653",
    "DISTRICTNAME_AR": "مغيضة",
    "DISTRICTNAME_EN": "Mughaydah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1654",
    "DISTRICTNAME_AR": "مياس",
    "DISTRICTNAME_EN": "Mayyas",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1655",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1656",
    "DISTRICTNAME_AR": "الشراعبة",
    "DISTRICTNAME_EN": "Al Shiraeiba",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1657",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1658",
    "DISTRICTNAME_AR": "حي ج11",
    "DISTRICTNAME_EN": "Dst C11",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1659",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1660",
    "DISTRICTNAME_AR": "السويفلة الجديد",
    "DISTRICTNAME_EN": "As Suwayfilah Al Jadid",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1661",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1662",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1664",
    "DISTRICTNAME_AR": "الدوحة",
    "DISTRICTNAME_EN": "Ad Dawhah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1665",
    "DISTRICTNAME_AR": "التسامح",
    "DISTRICTNAME_EN": "Al Tasamuh",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1666",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1667",
    "DISTRICTNAME_AR": "ذهبان الغربي",
    "DISTRICTNAME_EN": "Dhahban Western",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1668",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1669",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1670",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1672",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1673",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1674",
    "DISTRICTNAME_AR": "الإسكان1",
    "DISTRICTNAME_EN": "Al Iskan1",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1675",
    "DISTRICTNAME_AR": "حي ب",
    "DISTRICTNAME_EN": "Dst B",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1676",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1677",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1678",
    "DISTRICTNAME_AR": "الدوحة",
    "DISTRICTNAME_EN": "Ad Dawhah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1679",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1680",
    "DISTRICTNAME_AR": "السفن",
    "DISTRICTNAME_EN": "As Sufun",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1681",
    "DISTRICTNAME_AR": "عتود الجنوبي",
    "DISTRICTNAME_EN": "Eatud Al Janubi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1682",
    "DISTRICTNAME_AR": "عتود الشمالي",
    "DISTRICTNAME_EN": "Eatud Al ShamalI",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1683",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1684",
    "DISTRICTNAME_AR": "المثناه",
    "DISTRICTNAME_EN": "Al Mathnah ",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1685",
    "DISTRICTNAME_AR": "الجشرة",
    "DISTRICTNAME_EN": "Al Jashrah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1686",
    "DISTRICTNAME_AR": "آل عزيز",
    "DISTRICTNAME_EN": "Al Aziz",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1687",
    "DISTRICTNAME_AR": "الأمجاد",
    "DISTRICTNAME_EN": "Al Amjad",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1688",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1689",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1696",
    "DISTRICTNAME_AR": "المها",
    "DISTRICTNAME_EN": "Al Maha",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1697",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1698",
    "DISTRICTNAME_AR": "الشمال",
    "DISTRICTNAME_EN": "Ash Shamal",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1699",
    "DISTRICTNAME_AR": "الصخيرات",
    "DISTRICTNAME_EN": "As Sukhayrat",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1700",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1701",
    "DISTRICTNAME_AR": "المنشية",
    "DISTRICTNAME_EN": "Al Mansheya",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1702",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1703",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1704",
    "DISTRICTNAME_AR": "خضرا و عشيران",
    "DISTRICTNAME_EN": "Khudra and Ushayran",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1705",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1706",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1707",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1708",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1709",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1710",
    "DISTRICTNAME_AR": "الصناعية2",
    "DISTRICTNAME_EN": "Industrial2",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1711",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1712",
    "DISTRICTNAME_AR": "شبوك الاغنام",
    "DISTRICTNAME_EN": "Shabuk Al Aghnam",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1713",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1714",
    "DISTRICTNAME_AR": "الاداري",
    "DISTRICTNAME_EN": "Al Idari",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1715",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1716",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1717",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1718",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1719",
    "DISTRICTNAME_AR": "الصحنة",
    "DISTRICTNAME_EN": "Al Sahnah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1720",
    "DISTRICTNAME_AR": "زميقة",
    "DISTRICTNAME_EN": "Zamiqa",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1721",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1722",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1723",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1724",
    "DISTRICTNAME_AR": "القطار",
    "DISTRICTNAME_EN": "Al Qitar",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1725",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1726",
    "DISTRICTNAME_AR": "الوسادة",
    "DISTRICTNAME_EN": "Al Wasadah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1727",
    "DISTRICTNAME_AR": "شرق السويفلة",
    "DISTRICTNAME_EN": "East of Al Swefilah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1728",
    "DISTRICTNAME_AR": "شمال التضامن",
    "DISTRICTNAME_EN": "North Al Tadamun",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1729",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1730",
    "DISTRICTNAME_AR": "ضمك",
    "DISTRICTNAME_EN": "Damak",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1731",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1732",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1733",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1734",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1735",
    "DISTRICTNAME_AR": "الأنوار",
    "DISTRICTNAME_EN": "Al Anwar",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1736",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1737",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1740",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1741",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1742",
    "DISTRICTNAME_AR": "السلطانة",
    "DISTRICTNAME_EN": "Al Sultana",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1745",
    "DISTRICTNAME_AR": "صدر الغربية",
    "DISTRICTNAME_EN": "Sadr Al Gharbaiyah",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1746",
    "DISTRICTNAME_AR": "الحجر",
    "DISTRICTNAME_EN": "Al Hajar",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1747",
    "DISTRICTNAME_AR": "أم الجزم",
    "DISTRICTNAME_EN": "Umm Al Jazm",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1748",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1750",
    "DISTRICTNAME_AR": "حي ج19",
    "DISTRICTNAME_EN": "Dst C19",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1753",
    "DISTRICTNAME_AR": "لبدة",
    "DISTRICTNAME_EN": "Labdah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1754",
    "DISTRICTNAME_AR": "المحطة",
    "DISTRICTNAME_EN": "Al Mahattah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1755",
    "DISTRICTNAME_AR": "صبابة",
    "DISTRICTNAME_EN": "Sababah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1756",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1757",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12703",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1758",
    "DISTRICTNAME_AR": "العزيزية الغربي",
    "DISTRICTNAME_EN": "Western Al Aziziyah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1759",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1760",
    "DISTRICTNAME_AR": "الزمرد2",
    "DISTRICTNAME_EN": "Az Zomorod2",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1761",
    "DISTRICTNAME_AR": "البستان2",
    "DISTRICTNAME_EN": "Al Bustan2",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1762",
    "DISTRICTNAME_AR": "الهلالي",
    "DISTRICTNAME_EN": "Al Hilali",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1763",
    "DISTRICTNAME_AR": "منطقة خدمات 3",
    "DISTRICTNAME_EN": "Services  Area 3",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1765",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1766",
    "DISTRICTNAME_AR": "السامر",
    "DISTRICTNAME_EN": "As Samir",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1767",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1768",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1769",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1770",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1771",
    "DISTRICTNAME_AR": "قرية عقدة الشمالية",
    "DISTRICTNAME_EN": "Qaryat Uqdat Al Shamalia",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1772",
    "DISTRICTNAME_AR": "العوامية",
    "DISTRICTNAME_EN": "Al Awamiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1773",
    "DISTRICTNAME_AR": "الجراري",
    "DISTRICTNAME_EN": "Al Jarrari",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1774",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1775",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1776",
    "DISTRICTNAME_AR": "رضوى",
    "DISTRICTNAME_EN": "Radwa",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1777",
    "DISTRICTNAME_AR": "الطف",
    "DISTRICTNAME_EN": "At Tif",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1778",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1779",
    "DISTRICTNAME_AR": "عنك",
    "DISTRICTNAME_EN": "Anak",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1780",
    "DISTRICTNAME_AR": "نقرة قفار",
    "DISTRICTNAME_EN": "Naqrat Qafaar",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1781",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1782",
    "DISTRICTNAME_AR": "البحر",
    "DISTRICTNAME_EN": "Al Bahar",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1783",
    "DISTRICTNAME_AR": "الوسطى",
    "DISTRICTNAME_EN": "Al Wusta",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1784",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1785",
    "DISTRICTNAME_AR": "الاوجام",
    "DISTRICTNAME_EN": "Al Aujam",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1786",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1787",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1788",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1789",
    "DISTRICTNAME_AR": "البدع",
    "DISTRICTNAME_EN": "Al Bada",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1790",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1791",
    "DISTRICTNAME_AR": "صلاح الدين",
    "DISTRICTNAME_EN": "Salahuddin",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1792",
    "DISTRICTNAME_AR": "الصناعي",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1793",
    "DISTRICTNAME_AR": "حي ج",
    "DISTRICTNAME_EN": "Dst J",
    "CITY_ID": "10099",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1794",
    "DISTRICTNAME_AR": "مخطط الحفل",
    "DISTRICTNAME_EN": "Al Hafl Subdivision",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1795",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1796",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1797",
    "DISTRICTNAME_AR": "حي ج13",
    "DISTRICTNAME_EN": "Dst C13",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1798",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1799",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1800",
    "DISTRICTNAME_AR": "الغزوة",
    "DISTRICTNAME_EN": "Al Ghazwah",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1803",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1804",
    "DISTRICTNAME_AR": "الرياض",
    "DISTRICTNAME_EN": "Ar Riyadh",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1805",
    "DISTRICTNAME_AR": "البادية",
    "DISTRICTNAME_EN": "Al Badiyah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1806",
    "DISTRICTNAME_AR": "أجا",
    "DISTRICTNAME_EN": "Aja",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1807",
    "DISTRICTNAME_AR": "الجيل",
    "DISTRICTNAME_EN": "Al Jil",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1808",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1809",
    "DISTRICTNAME_AR": "المنارة",
    "DISTRICTNAME_EN": "Al Manarah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1810",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1811",
    "DISTRICTNAME_AR": "الصفق",
    "DISTRICTNAME_EN": "As Safq",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1813",
    "DISTRICTNAME_AR": "الضاحية",
    "DISTRICTNAME_EN": "Ad Dahiah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1814",
    "DISTRICTNAME_AR": "حضن قلوص",
    "DISTRICTNAME_EN": "Hidn Qulus",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1815",
    "DISTRICTNAME_AR": "الجميمة",
    "DISTRICTNAME_EN": "Al Jumaimah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1817",
    "DISTRICTNAME_AR": "منطقة خدمات 1",
    "DISTRICTNAME_EN": "Services  Area 1",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1819",
    "DISTRICTNAME_AR": "حي ج8",
    "DISTRICTNAME_EN": "Dst C8",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1820",
    "DISTRICTNAME_AR": "مخطط الربيع",
    "DISTRICTNAME_EN": "Al Rabie Subdivision",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1821",
    "DISTRICTNAME_AR": "القديم",
    "DISTRICTNAME_EN": "Al Qadim",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1822",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1823",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1824",
    "DISTRICTNAME_AR": "منطقة الايداع واعادة التصدير",
    "DISTRICTNAME_EN": "Deposit and Re-export Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1825",
    "DISTRICTNAME_AR": "منطقة خدمات حكومية",
    "DISTRICTNAME_EN": "Government Services Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1826",
    "DISTRICTNAME_AR": "الرابعة",
    "DISTRICTNAME_EN": "Ar Rabiah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1827",
    "DISTRICTNAME_AR": "الخويلدية",
    "DISTRICTNAME_EN": "Al Khuwaildiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1828",
    "DISTRICTNAME_AR": "الفريش",
    "DISTRICTNAME_EN": "Al Firaysh",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1829",
    "DISTRICTNAME_AR": "الدويج",
    "DISTRICTNAME_EN": "Ad Doij",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1830",
    "DISTRICTNAME_AR": "حي ج15",
    "DISTRICTNAME_EN": "Dst C15",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1831",
    "DISTRICTNAME_AR": "المدني",
    "DISTRICTNAME_EN": "Al Madani",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1833",
    "DISTRICTNAME_AR": "حي ج16",
    "DISTRICTNAME_EN": "Dst C16",
    "CITY_ID": "10061",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1834",
    "DISTRICTNAME_AR": "الثامن",
    "DISTRICTNAME_EN": "Al Thaamin",
    "CITY_ID": "15413",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1837",
    "DISTRICTNAME_AR": "النزهة1",
    "DISTRICTNAME_EN": "An Nuzhah1",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1838",
    "DISTRICTNAME_AR": "مخطط الإسكان1",
    "DISTRICTNAME_EN": "Al Iskan Subdivision1",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1839",
    "DISTRICTNAME_AR": "سنابس",
    "DISTRICTNAME_EN": "Sanabis",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1840",
    "DISTRICTNAME_AR": "النابية",
    "DISTRICTNAME_EN": "An Nabiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1841",
    "DISTRICTNAME_AR": "يدمة الاول و الثاني",
    "DISTRICTNAME_EN": "Yadmat Alawil w Althaani",
    "CITY_ID": "3755",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1842",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1843",
    "DISTRICTNAME_AR": "المدائن",
    "DISTRICTNAME_EN": "Al Madayin",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1844",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1847",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1848",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1849",
    "DISTRICTNAME_AR": "السميرى",
    "DISTRICTNAME_EN": "As Samira",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1850",
    "DISTRICTNAME_AR": "غرب أجا",
    "DISTRICTNAME_EN": "West Aja",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1851",
    "DISTRICTNAME_AR": "ضاحية الملك فهد",
    "DISTRICTNAME_EN": "Dahiyat Al Malik Fahd",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1852",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1853",
    "DISTRICTNAME_AR": "الأسواق",
    "DISTRICTNAME_EN": "Al Aswaq",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1854",
    "DISTRICTNAME_AR": "الفيصلية شرق",
    "DISTRICTNAME_EN": "Al Faisaliyah East",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "1855",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "1856",
    "DISTRICTNAME_AR": "اللقيطه",
    "DISTRICTNAME_EN": "Al Laqitah",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "1857",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1858",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1859",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "14304",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "1860",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1861",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1862",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1863",
    "DISTRICTNAME_AR": "محمية المانجروف",
    "DISTRICTNAME_EN": "Mangrove Reserve",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1864",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1865",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1866",
    "DISTRICTNAME_AR": "الجبنون",
    "DISTRICTNAME_EN": "Al Jabnun",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1869",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1870",
    "DISTRICTNAME_AR": "ثماني",
    "DISTRICTNAME_EN": "Thamani",
    "CITY_ID": "3739",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1871",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1872",
    "DISTRICTNAME_AR": "النزهة 1",
    "DISTRICTNAME_EN": "An Nuzhah 1",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "1873",
    "DISTRICTNAME_AR": "البلدة القديمة",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "1881",
    "DISTRICTNAME_AR": "الرونة",
    "DISTRICTNAME_EN": "Ar Rawnah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1882",
    "DISTRICTNAME_AR": "نعمان",
    "DISTRICTNAME_EN": "Numan",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "1883",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1884",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1885",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1886",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1887",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1888",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1889",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1890",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1891",
    "DISTRICTNAME_AR": "التروية",
    "DISTRICTNAME_EN": "Al Tarwia",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1892",
    "DISTRICTNAME_AR": "أم الجود",
    "DISTRICTNAME_EN": "Umm Al Jud",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1893",
    "DISTRICTNAME_AR": "الشبيكة الجديد",
    "DISTRICTNAME_EN": "Ash Shubayka Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1894",
    "DISTRICTNAME_AR": "المرسلات",
    "DISTRICTNAME_EN": "Al Mursalat",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1895",
    "DISTRICTNAME_AR": "العكيشية",
    "DISTRICTNAME_EN": "Al Ukayshiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1896",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1897",
    "DISTRICTNAME_AR": "الهجرة",
    "DISTRICTNAME_EN": "Al Hijrah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1898",
    "DISTRICTNAME_AR": "بطحاء قريش",
    "DISTRICTNAME_EN": "Batha Quraysh",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1899",
    "DISTRICTNAME_AR": "الهنداوية",
    "DISTRICTNAME_EN": "Al Hindawiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1900",
    "DISTRICTNAME_AR": "حضوضاء",
    "DISTRICTNAME_EN": "Hadawda'",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1901",
    "DISTRICTNAME_AR": "رؤى الحرم",
    "DISTRICTNAME_EN": "Haram visions",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1902",
    "DISTRICTNAME_AR": "البركة",
    "DISTRICTNAME_EN": "Al Barakah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1903",
    "DISTRICTNAME_AR": "أبو مرخة",
    "DISTRICTNAME_EN": "Abu Markha",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1904",
    "DISTRICTNAME_AR": "الصادقية",
    "DISTRICTNAME_EN": "As Sadiqiyah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1905",
    "DISTRICTNAME_AR": "العيون",
    "DISTRICTNAME_EN": "Al Uyun",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1906",
    "DISTRICTNAME_AR": "السوق الجديد",
    "DISTRICTNAME_EN": "As Suq Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1907",
    "DISTRICTNAME_AR": "النوارية",
    "DISTRICTNAME_EN": "An Nawwariyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1908",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1909",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1910",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1911",
    "DISTRICTNAME_AR": "كدي",
    "DISTRICTNAME_EN": "Kudy",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1912",
    "DISTRICTNAME_AR": "الحجون",
    "DISTRICTNAME_EN": "Al Hujun",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1913",
    "DISTRICTNAME_AR": "النقا الجديد",
    "DISTRICTNAME_EN": "An Naqa Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1914",
    "DISTRICTNAME_AR": "طوى",
    "DISTRICTNAME_EN": "Tawa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1915",
    "DISTRICTNAME_AR": "شعب عامر ",
    "DISTRICTNAME_EN": "Shaeb Amir Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1916",
    "DISTRICTNAME_AR": "الضيافة",
    "DISTRICTNAME_EN": "Ad Diyafah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1917",
    "DISTRICTNAME_AR": "التقوى",
    "DISTRICTNAME_EN": "At Taqwa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1918",
    "DISTRICTNAME_AR": "المغيسلة",
    "DISTRICTNAME_EN": "Al Mughaisilah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1919",
    "DISTRICTNAME_AR": "جبل عير",
    "DISTRICTNAME_EN": "Jabal Eir",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1920",
    "DISTRICTNAME_AR": "قربان",
    "DISTRICTNAME_EN": "Qurban",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1921",
    "DISTRICTNAME_AR": "العاقول",
    "DISTRICTNAME_EN": "Al Aqoul",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1922",
    "DISTRICTNAME_AR": "المدينة الصناعية",
    "DISTRICTNAME_EN": "Industrial City",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1923",
    "DISTRICTNAME_AR": "التنعيم",
    "DISTRICTNAME_EN": "At Tanim",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1924",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1925",
    "DISTRICTNAME_AR": "زمزم",
    "DISTRICTNAME_EN": "Zamzam",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1927",
    "DISTRICTNAME_AR": "الناصرة",
    "DISTRICTNAME_EN": "An Nasirah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1930",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1931",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1932",
    "DISTRICTNAME_AR": "ورقان",
    "DISTRICTNAME_EN": "Warqan",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1933",
    "DISTRICTNAME_AR": "حرة الوبرة",
    "DISTRICTNAME_EN": "Harat Al Wabara",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1934",
    "DISTRICTNAME_AR": "السقيا",
    "DISTRICTNAME_EN": "As Suqya",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1935",
    "DISTRICTNAME_AR": "خاخ",
    "DISTRICTNAME_EN": "Khakh",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1936",
    "DISTRICTNAME_AR": "شوران",
    "DISTRICTNAME_EN": "Shuran",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1937",
    "DISTRICTNAME_AR": "العجيبة",
    "DISTRICTNAME_EN": "Al Ujaybah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1938",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1939",
    "DISTRICTNAME_AR": "اللسيب",
    "DISTRICTNAME_EN": "Al Lusayb",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1940",
    "DISTRICTNAME_AR": "العريمضي الشمالي",
    "DISTRICTNAME_EN": "Al Uraymidi Al Shamali",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1941",
    "DISTRICTNAME_AR": "ضارج",
    "DISTRICTNAME_EN": "Darij",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1942",
    "DISTRICTNAME_AR": "المطار القديم",
    "DISTRICTNAME_EN": "Old Airport",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1943",
    "DISTRICTNAME_AR": "الكعكية",
    "DISTRICTNAME_EN": "Al Kakiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1944",
    "DISTRICTNAME_AR": "البركة",
    "DISTRICTNAME_EN": "Al Barakah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1945",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1946",
    "DISTRICTNAME_AR": "الفلق الجديد",
    "DISTRICTNAME_EN": "Al Falaq Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1947",
    "DISTRICTNAME_AR": "جعرانة",
    "DISTRICTNAME_EN": "Jarana",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1948",
    "DISTRICTNAME_AR": "شمال أوثال",
    "DISTRICTNAME_EN": "North Uthal",
    "CITY_ID": "11882",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "1949",
    "DISTRICTNAME_AR": "الأصيفرين",
    "DISTRICTNAME_EN": "Al Usayfirin",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1950",
    "DISTRICTNAME_AR": "أبو سدر",
    "DISTRICTNAME_EN": "Abu Sidr",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1951",
    "DISTRICTNAME_AR": "العنابس",
    "DISTRICTNAME_EN": "Al Anabis",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1952",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1953",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1954",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1955",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1956",
    "DISTRICTNAME_AR": "الحطيم",
    "DISTRICTNAME_EN": "Al Hatim",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1957",
    "DISTRICTNAME_AR": "حنين",
    "DISTRICTNAME_EN": "Hanin",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1958",
    "DISTRICTNAME_AR": "الجودرية الجديد",
    "DISTRICTNAME_EN": "Al Judriah Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1959",
    "DISTRICTNAME_AR": "البيبان",
    "DISTRICTNAME_EN": "Al Bayban",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1960",
    "DISTRICTNAME_AR": "جبل النور",
    "DISTRICTNAME_EN": "Jabal An Noor",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1961",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1962",
    "DISTRICTNAME_AR": "القرارة الجديد",
    "DISTRICTNAME_EN": "Al Qararat Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1963",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1964",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1965",
    "DISTRICTNAME_AR": "الجميزة",
    "DISTRICTNAME_EN": "Al Jummayzah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1966",
    "DISTRICTNAME_AR": "بنى ظفر",
    "DISTRICTNAME_EN": "Bani Zafar",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1967",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1968",
    "DISTRICTNAME_AR": "البلقاء",
    "DISTRICTNAME_EN": "Al Balqa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1969",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1970",
    "DISTRICTNAME_AR": "وادي الحمض",
    "DISTRICTNAME_EN": "Wadi al humd",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1971",
    "DISTRICTNAME_AR": "الجفيدرية",
    "DISTRICTNAME_EN": "Al Jufaidariyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "1972",
    "DISTRICTNAME_AR": "الحسينية",
    "DISTRICTNAME_EN": "Al Husainiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1973",
    "DISTRICTNAME_AR": "دار السلام",
    "DISTRICTNAME_EN": "Dar AI Salaam",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1974",
    "DISTRICTNAME_AR": "الزاهر",
    "DISTRICTNAME_EN": "Az Zahir",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1975",
    "DISTRICTNAME_AR": "بئر عثمان",
    "DISTRICTNAME_EN": "Bir Uthman",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1976",
    "DISTRICTNAME_AR": "الهدراء",
    "DISTRICTNAME_EN": "Al Hadra",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1977",
    "DISTRICTNAME_AR": "بنى معاوية",
    "DISTRICTNAME_EN": "Bani Muawiyah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1978",
    "DISTRICTNAME_AR": "الشرائع",
    "DISTRICTNAME_EN": "Asharai ",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1979",
    "DISTRICTNAME_AR": "الشريبات",
    "DISTRICTNAME_EN": "Ash Shuraybat",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1980",
    "DISTRICTNAME_AR": "أم السيوف",
    "DISTRICTNAME_EN": "Umm Al Suywf",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1981",
    "DISTRICTNAME_AR": "كتانة",
    "DISTRICTNAME_EN": "Kittanah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1982",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1983",
    "DISTRICTNAME_AR": "بضاعة",
    "DISTRICTNAME_EN": "Badaah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1984",
    "DISTRICTNAME_AR": "العصبة",
    "DISTRICTNAME_EN": "Al Usbah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1985",
    "DISTRICTNAME_AR": "بني النجار",
    "DISTRICTNAME_EN": "Bani Al Najjar",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1986",
    "DISTRICTNAME_AR": "الجصة",
    "DISTRICTNAME_EN": "Al Jassah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "1987",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1988",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "11044",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1989",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1990",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1991",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1992",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1993",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1994",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1995",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1996",
    "DISTRICTNAME_AR": "ريع اذاخر",
    "DISTRICTNAME_EN": "Rie Adhakhir",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1997",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "1998",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "1999",
    "DISTRICTNAME_AR": "المسيال الجديد",
    "DISTRICTNAME_EN": "Al misyal Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2001",
    "DISTRICTNAME_AR": "وادي جليل",
    "DISTRICTNAME_EN": "Wadi Jalil",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2002",
    "DISTRICTNAME_AR": "المعابده",
    "DISTRICTNAME_EN": "Al Maeabiduh",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2003",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2004",
    "DISTRICTNAME_AR": "الدار",
    "DISTRICTNAME_EN": "Ad Dar",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2005",
    "DISTRICTNAME_AR": "الحديقة",
    "DISTRICTNAME_EN": "Al Hadiqah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2006",
    "DISTRICTNAME_AR": "وعيرة",
    "DISTRICTNAME_EN": "Wairah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2007",
    "DISTRICTNAME_AR": "معاد",
    "DISTRICTNAME_EN": "Muead",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2008",
    "DISTRICTNAME_AR": "الخضراء",
    "DISTRICTNAME_EN": "Al Khadraa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2009",
    "DISTRICTNAME_AR": "دميغ الجنوبية",
    "DISTRICTNAME_EN": "South Demig",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2010",
    "DISTRICTNAME_AR": "دميغ الشمالية",
    "DISTRICTNAME_EN": "North Damig",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2011",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2012",
    "DISTRICTNAME_AR": "الرصيفة",
    "DISTRICTNAME_EN": "Ar Rusayfah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2013",
    "DISTRICTNAME_AR": "المسفلة",
    "DISTRICTNAME_EN": "Al Misfalah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2014",
    "DISTRICTNAME_AR": "العوالي",
    "DISTRICTNAME_EN": "Al Awaly",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2015",
    "DISTRICTNAME_AR": "الصفوة",
    "DISTRICTNAME_EN": "As safwa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2016",
    "DISTRICTNAME_AR": "جرول",
    "DISTRICTNAME_EN": "Jarwal",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2017",
    "DISTRICTNAME_AR": "شعب علي ",
    "DISTRICTNAME_EN": "Shaeb Ali Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2018",
    "DISTRICTNAME_AR": "الهجلة الجديد",
    "DISTRICTNAME_EN": "Al Hajla Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2019",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2020",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2021",
    "DISTRICTNAME_AR": "الشوقية",
    "DISTRICTNAME_EN": "Ash Shawqiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2022",
    "DISTRICTNAME_AR": "الخنساء",
    "DISTRICTNAME_EN": "Al Khansa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2023",
    "DISTRICTNAME_AR": "الجمعة",
    "DISTRICTNAME_EN": "Al Jummah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2024",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2025",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2026",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2027",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2028",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2029",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2030",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2031",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2032",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2033",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2034",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2035",
    "DISTRICTNAME_AR": "القبيبة",
    "DISTRICTNAME_EN": "Al Qabiba",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2036",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2037",
    "DISTRICTNAME_AR": "جبل أحد",
    "DISTRICTNAME_EN": "Jabal Uhud",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2038",
    "DISTRICTNAME_AR": "الظاهرة",
    "DISTRICTNAME_EN": "Az Zahirah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2039",
    "DISTRICTNAME_AR": "رهط",
    "DISTRICTNAME_EN": "Raht",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2040",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2041",
    "DISTRICTNAME_AR": "البلدة القديمة",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2042",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2043",
    "DISTRICTNAME_AR": "المزيين",
    "DISTRICTNAME_EN": "Al Muzayayn",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2044",
    "DISTRICTNAME_AR": "سكة الحديد",
    "DISTRICTNAME_EN": "As Sikkah Al Hadid",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2045",
    "DISTRICTNAME_AR": "الدفاع",
    "DISTRICTNAME_EN": "Ad Difa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2046",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2047",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2048",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2049",
    "DISTRICTNAME_AR": "وادي مهزور",
    "DISTRICTNAME_EN": "Wadi Mahzur",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2050",
    "DISTRICTNAME_AR": "النقمى",
    "DISTRICTNAME_EN": "Al Nuqame",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2052",
    "DISTRICTNAME_AR": "الدويخلة",
    "DISTRICTNAME_EN": "Ad Duwaikhilah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2053",
    "DISTRICTNAME_AR": "عروة",
    "DISTRICTNAME_EN": "Urwah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2054",
    "DISTRICTNAME_AR": "جماء أم خالد",
    "DISTRICTNAME_EN": "Jama Umm Khaled",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2055",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2056",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2057",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2058",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "11044",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2059",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2060",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2061",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2062",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2063",
    "DISTRICTNAME_AR": "المغمس",
    "DISTRICTNAME_EN": "Al Mughamas",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2064",
    "DISTRICTNAME_AR": "الشميسي",
    "DISTRICTNAME_EN": "Al Shumaisi",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2065",
    "DISTRICTNAME_AR": "حارة الباب الجديد",
    "DISTRICTNAME_EN": "Harat Al Bab Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2066",
    "DISTRICTNAME_AR": "الشامية الجديد",
    "DISTRICTNAME_EN": "Ash Shamiya Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2067",
    "DISTRICTNAME_AR": "القشاشية الجديد",
    "DISTRICTNAME_EN": "Al Qashashia Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2069",
    "DISTRICTNAME_AR": "البحيرات",
    "DISTRICTNAME_EN": "Al Buhayrat",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2070",
    "DISTRICTNAME_AR": "السنابل",
    "DISTRICTNAME_EN": "As Sanabel",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2072",
    "DISTRICTNAME_AR": "الراشدية",
    "DISTRICTNAME_EN": "Ar Rashidiyyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2074",
    "DISTRICTNAME_AR": "الورود2",
    "DISTRICTNAME_EN": "Al Wurud2",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "2076",
    "DISTRICTNAME_AR": "الياسمين1",
    "DISTRICTNAME_EN": "Al Yasmin1",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2077",
    "DISTRICTNAME_AR": "الجابرة",
    "DISTRICTNAME_EN": "Al Jabirah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2078",
    "DISTRICTNAME_AR": "الشهباء",
    "DISTRICTNAME_EN": "Ash Shahba",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2079",
    "DISTRICTNAME_AR": "المصانع",
    "DISTRICTNAME_EN": "Al Masani",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2080",
    "DISTRICTNAME_AR": "التيسير",
    "DISTRICTNAME_EN": "At Taysir",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2081",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2082",
    "DISTRICTNAME_AR": "المناخة",
    "DISTRICTNAME_EN": "Al Manakhah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2083",
    "DISTRICTNAME_AR": "الفرقان",
    "DISTRICTNAME_EN": "Al Furqan",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2084",
    "DISTRICTNAME_AR": "الثنية",
    "DISTRICTNAME_EN": "Al Thania",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2085",
    "DISTRICTNAME_AR": "المقام",
    "DISTRICTNAME_EN": "Al Maqam",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2086",
    "DISTRICTNAME_AR": "الحديبية",
    "DISTRICTNAME_EN": "Al Hudaybiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2087",
    "DISTRICTNAME_AR": "الشرائع",
    "DISTRICTNAME_EN": "Asharai ",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2088",
    "DISTRICTNAME_AR": "البيعة",
    "DISTRICTNAME_EN": "Al Bayea",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2089",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2090",
    "DISTRICTNAME_AR": "العسيلة",
    "DISTRICTNAME_EN": "Al Usaylah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2091",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2092",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2093",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2094",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2095",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2096",
    "DISTRICTNAME_AR": "نايف",
    "DISTRICTNAME_EN": "Nayef",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2097",
    "DISTRICTNAME_AR": "المستشفى",
    "DISTRICTNAME_EN": "The Hospital",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2098",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "7390",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2099",
    "DISTRICTNAME_AR": "بنى عبد الأشهل",
    "DISTRICTNAME_EN": "Bani Abdul Ashhal",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2100",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2101",
    "DISTRICTNAME_AR": "النقا",
    "DISTRICTNAME_EN": "An Naqa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2102",
    "DISTRICTNAME_AR": "نبلاء",
    "DISTRICTNAME_EN": "Nubala",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2103",
    "DISTRICTNAME_AR": "الشافية",
    "DISTRICTNAME_EN": "Ash Shafiyah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2104",
    "DISTRICTNAME_AR": "وادي البطان",
    "DISTRICTNAME_EN": "Wadi Al Battan",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2105",
    "DISTRICTNAME_AR": "العقبة",
    "DISTRICTNAME_EN": "Al Aqaba",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2106",
    "DISTRICTNAME_AR": "منطقة التنمية1",
    "DISTRICTNAME_EN": "lands suitable for development1",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "2108",
    "DISTRICTNAME_AR": "مخطط الإسكان2",
    "DISTRICTNAME_EN": "Al Iskan Subdivision2",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "2110",
    "DISTRICTNAME_AR": "الجماوات",
    "DISTRICTNAME_EN": "Al Jamawat",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2111",
    "DISTRICTNAME_AR": "المفرحات",
    "DISTRICTNAME_EN": "Al Mufrahat",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2112",
    "DISTRICTNAME_AR": "الحفيا",
    "DISTRICTNAME_EN": "Al Hafya",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2113",
    "DISTRICTNAME_AR": "السيح",
    "DISTRICTNAME_EN": "As Sih",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2114",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2115",
    "DISTRICTNAME_AR": "الخاتم",
    "DISTRICTNAME_EN": "Al Khatim",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2116",
    "DISTRICTNAME_AR": "الرانوناء",
    "DISTRICTNAME_EN": "Ar Ranuna",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2117",
    "DISTRICTNAME_AR": "الصهوة",
    "DISTRICTNAME_EN": "Al Sahwa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2118",
    "DISTRICTNAME_AR": "الاناهي",
    "DISTRICTNAME_EN": "Al Anahi",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2119",
    "DISTRICTNAME_AR": "المدينة",
    "DISTRICTNAME_EN": "Al Madinah",
    "CITY_ID": "2607",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "2120",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2121",
    "DISTRICTNAME_AR": "العابدية",
    "DISTRICTNAME_EN": "Al Abidiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2122",
    "DISTRICTNAME_AR": "المدعى الجديد",
    "DISTRICTNAME_EN": "Al Mudae Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2123",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2124",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2125",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2126",
    "DISTRICTNAME_AR": "حي ج14",
    "DISTRICTNAME_EN": "Dst C14",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2127",
    "DISTRICTNAME_AR": "مخطط رقم 1452",
    "DISTRICTNAME_EN": "Subdivision No. 1452",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2128",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2129",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2130",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2131",
    "DISTRICTNAME_AR": "القزاز",
    "DISTRICTNAME_EN": "Al Qazaz",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2132",
    "DISTRICTNAME_AR": "الأنوار",
    "DISTRICTNAME_EN": "Al Anwar",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2133",
    "DISTRICTNAME_AR": "البحيرة",
    "DISTRICTNAME_EN": "Al Buhayrah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2134",
    "DISTRICTNAME_AR": "قبيبان",
    "DISTRICTNAME_EN": "Qubiban",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2135",
    "DISTRICTNAME_AR": "مخطط 802",
    "DISTRICTNAME_EN": "Subdivision 802",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2136",
    "DISTRICTNAME_AR": "مخطط رقم 1141",
    "DISTRICTNAME_EN": "Subdivision No. 1141",
    "CITY_ID": "21072",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2137",
    "DISTRICTNAME_AR": "بنى بياضة",
    "DISTRICTNAME_EN": "Bani Bayadah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2138",
    "DISTRICTNAME_AR": "الدويمة",
    "DISTRICTNAME_EN": "Ad Duwaimah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2139",
    "DISTRICTNAME_AR": "صحارى",
    "DISTRICTNAME_EN": "Sahara",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2140",
    "DISTRICTNAME_AR": "الزعيبة",
    "DISTRICTNAME_EN": "Al Zaeiba",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2141",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2142",
    "DISTRICTNAME_AR": "العيبه",
    "DISTRICTNAME_EN": "Al Uaybah",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2143",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2144",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2145",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2146",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2147",
    "DISTRICTNAME_AR": "الدار البيضاء",
    "DISTRICTNAME_EN": "Al Dar Al Baida",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2148",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2149",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2150",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2151",
    "DISTRICTNAME_AR": "الجلوية",
    "DISTRICTNAME_EN": "Al Jalawiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2152",
    "DISTRICTNAME_AR": "العدامة",
    "DISTRICTNAME_EN": "Al Adamah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2153",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2154",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "Ar Rifa",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2155",
    "DISTRICTNAME_AR": "نجد",
    "DISTRICTNAME_EN": "Najd",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2156",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2157",
    "DISTRICTNAME_AR": "المثناه",
    "DISTRICTNAME_EN": "Al Mathnah ",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2158",
    "DISTRICTNAME_AR": "العشيرة",
    "DISTRICTNAME_EN": "Al Asheyrah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2159",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2160",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2161",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20276",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2162",
    "DISTRICTNAME_AR": "الملك عبدالله1",
    "DISTRICTNAME_EN": "King Abdullah1",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2163",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2166",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2167",
    "DISTRICTNAME_AR": "الملك",
    "DISTRICTNAME_EN": "Al Malik",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2168",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2169",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2171",
    "DISTRICTNAME_AR": "الساحل",
    "DISTRICTNAME_EN": "As Sahil",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2172",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2173",
    "DISTRICTNAME_AR": "الصناعية الثانية",
    "DISTRICTNAME_EN": "second industrial",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2174",
    "DISTRICTNAME_AR": "البصيرة",
    "DISTRICTNAME_EN": "Al Basirah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2175",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2176",
    "DISTRICTNAME_AR": "علقة",
    "DISTRICTNAME_EN": "Alkah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2177",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2184",
    "DISTRICTNAME_AR": "المسيل",
    "DISTRICTNAME_EN": "Al Masil",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2185",
    "DISTRICTNAME_AR": "المثالية",
    "DISTRICTNAME_EN": "Al Mithalia",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2186",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2187",
    "DISTRICTNAME_AR": "هجرة سليم",
    "DISTRICTNAME_EN": "Hijrat Salim",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2188",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2189",
    "DISTRICTNAME_AR": "الجابرية",
    "DISTRICTNAME_EN": "Al Jabriyah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2190",
    "DISTRICTNAME_AR": "البثنة",
    "DISTRICTNAME_EN": "Al Bathnah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2191",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2192",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2193",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2194",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2195",
    "DISTRICTNAME_AR": "الطبيشي",
    "DISTRICTNAME_EN": "At Tubayshi",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2196",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2197",
    "DISTRICTNAME_AR": "مدينة الملك فيصل الجامعية",
    "DISTRICTNAME_EN": "King Faisal University City",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2198",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2199",
    "DISTRICTNAME_AR": "القشلة",
    "DISTRICTNAME_EN": "Al Qashlah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2200",
    "DISTRICTNAME_AR": "البحيرة",
    "DISTRICTNAME_EN": "Al Buhayrah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2201",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2202",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2203",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2204",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2205",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2206",
    "DISTRICTNAME_AR": "الفرسان",
    "DISTRICTNAME_EN": "Al Fursan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2212",
    "DISTRICTNAME_AR": "الطرايشة",
    "DISTRICTNAME_EN": "Al Turshiah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2213",
    "DISTRICTNAME_AR": "البدوي",
    "DISTRICTNAME_EN": "Al Badawi",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2214",
    "DISTRICTNAME_AR": "البلدة القديمة",
    "DISTRICTNAME_EN": "Down Town",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2215",
    "DISTRICTNAME_AR": "آل مجلى",
    "DISTRICTNAME_EN": "Al Mijali",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2216",
    "DISTRICTNAME_AR": "البندر",
    "DISTRICTNAME_EN": "Al Bandar",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2218",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2219",
    "DISTRICTNAME_AR": "برق الخيل",
    "DISTRICTNAME_EN": "Barq Al Khayl",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2221",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2222",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2223",
    "DISTRICTNAME_AR": "مخطط البلدية",
    "DISTRICTNAME_EN": "Al Baladiyah Subdivision",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2224",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2225",
    "DISTRICTNAME_AR": "العرنية",
    "DISTRICTNAME_EN": "Al Urania",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2226",
    "DISTRICTNAME_AR": "مخطط رقم 1209",
    "DISTRICTNAME_EN": "Subdivision No. 1209",
    "CITY_ID": "21072",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2228",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2229",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2230",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2231",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2232",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2233",
    "DISTRICTNAME_AR": "الخالدية الشمالية",
    "DISTRICTNAME_EN": "Al Khalidiyah Ash Shamaliyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2234",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2235",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2236",
    "DISTRICTNAME_AR": "غرب الظهران",
    "DISTRICTNAME_EN": "Gharb Adh Dhahran",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2237",
    "DISTRICTNAME_AR": "الدانة الشمالية",
    "DISTRICTNAME_EN": "Ad Danah Ash Shamaliyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2238",
    "DISTRICTNAME_AR": "الثقبة",
    "DISTRICTNAME_EN": "Ath Thuqbah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2239",
    "DISTRICTNAME_AR": "638 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 638",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2240",
    "DISTRICTNAME_AR": "1075 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1075",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2241",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2242",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2243",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2244",
    "DISTRICTNAME_AR": "المفيجر",
    "DISTRICTNAME_EN": "Al Mufijir",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2245",
    "DISTRICTNAME_AR": "جامعة الملك فهد للبترول",
    "DISTRICTNAME_EN": "King Fahd University of Petroleum",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2246",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2247",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2248",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2249",
    "DISTRICTNAME_AR": "ميناء ينبع التجارى",
    "DISTRICTNAME_EN": "Yanbu Commercial Seaport",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2250",
    "DISTRICTNAME_AR": "الحدائق",
    "DISTRICTNAME_EN": "Al Hadaek",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2251",
    "DISTRICTNAME_AR": "منطقة مردم النفايات القديم",
    "DISTRICTNAME_EN": "Old landfill Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2252",
    "DISTRICTNAME_AR": "العهن",
    "DISTRICTNAME_EN": "Al Ihn",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2253",
    "DISTRICTNAME_AR": "الدرع",
    "DISTRICTNAME_EN": "Ad Dara",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2254",
    "DISTRICTNAME_AR": "أبوكبير",
    "DISTRICTNAME_EN": "Abu Kabir",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2255",
    "DISTRICTNAME_AR": "مخطط رقم 0832",
    "DISTRICTNAME_EN": "Subdivision No. 0832",
    "CITY_ID": "21072",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2256",
    "DISTRICTNAME_AR": "أم سمرة",
    "DISTRICTNAME_EN": "Umm Samra",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2257",
    "DISTRICTNAME_AR": "الادارية شرق",
    "DISTRICTNAME_EN": "East Administrative",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2258",
    "DISTRICTNAME_AR": "الشرافا",
    "DISTRICTNAME_EN": "Al Sharafa",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2259",
    "DISTRICTNAME_AR": "الزويراء",
    "DISTRICTNAME_EN": "Al Zuwayra",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2260",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2261",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2262",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2263",
    "DISTRICTNAME_AR": "صناعية الثقبة",
    "DISTRICTNAME_EN": "Sinaiyah Ath Thuqbah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2264",
    "DISTRICTNAME_AR": "المها",
    "DISTRICTNAME_EN": "Al Maha",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2265",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2267",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2268",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2269",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2270",
    "DISTRICTNAME_AR": "الملك سلمان",
    "DISTRICTNAME_EN": "King Salman",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2271",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2272",
    "DISTRICTNAME_AR": "عريعرة",
    "DISTRICTNAME_EN": "Urierah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2273",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2274",
    "DISTRICTNAME_AR": "ال غضاب",
    "DISTRICTNAME_EN": "Al Ghidab",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2275",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2276",
    "DISTRICTNAME_AR": "الفرعة الشمالي",
    "DISTRICTNAME_EN": "Al Farah Al Shamali",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2277",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2278",
    "DISTRICTNAME_AR": "الملقا",
    "DISTRICTNAME_EN": "Al Malqa",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2279",
    "DISTRICTNAME_AR": "معكال",
    "DISTRICTNAME_EN": "Meakal",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2280",
    "DISTRICTNAME_AR": "النسيم الغربي",
    "DISTRICTNAME_EN": "West Naseem",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2281",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2282",
    "DISTRICTNAME_AR": "الخور",
    "DISTRICTNAME_EN": "Al Khawr",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2283",
    "DISTRICTNAME_AR": "السوق",
    "DISTRICTNAME_EN": "As Suq",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2284",
    "DISTRICTNAME_AR": "مخطط الإتصالات",
    "DISTRICTNAME_EN": "Al Itsalat Subdivision",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2285",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2286",
    "DISTRICTNAME_AR": "السيف",
    "DISTRICTNAME_EN": "As Saif",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2287",
    "DISTRICTNAME_AR": "الأمواج",
    "DISTRICTNAME_EN": "Al Amwaj",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2288",
    "DISTRICTNAME_AR": "الدوحة الشمالية",
    "DISTRICTNAME_EN": "Ad Dawhah Ash Shamaliyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2289",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2290",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2291",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2292",
    "DISTRICTNAME_AR": "الرحبة",
    "DISTRICTNAME_EN": "Ar Rahbah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2293",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2294",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2295",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2296",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2297",
    "DISTRICTNAME_AR": "الجاضع",
    "DISTRICTNAME_EN": "Al Jadie",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2298",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "At Tahliyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2299",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2300",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2301",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2302",
    "DISTRICTNAME_AR": "الجرادية",
    "DISTRICTNAME_EN": "Al Jarradiyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2303",
    "DISTRICTNAME_AR": "البشائر",
    "DISTRICTNAME_EN": "Al Bashaer",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2304",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2305",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2306",
    "DISTRICTNAME_AR": "آل عويمر",
    "DISTRICTNAME_EN": "Al Awaimer",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2307",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2308",
    "DISTRICTNAME_AR": "ال سالم",
    "DISTRICTNAME_EN": "Al Salem",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2310",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2311",
    "DISTRICTNAME_AR": "الفرعة الشرقية",
    "DISTRICTNAME_EN": "Al Farah Al Sharqia",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2312",
    "DISTRICTNAME_AR": "الدرة",
    "DISTRICTNAME_EN": "Al Durra",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2313",
    "DISTRICTNAME_AR": "الشاطئ الشرقي",
    "DISTRICTNAME_EN": "Ash Shati Ash Sharqi",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2314",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2315",
    "DISTRICTNAME_AR": "ضاحية الملك فهد",
    "DISTRICTNAME_EN": "Dahiyat Al Malik Fahd",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2316",
    "DISTRICTNAME_AR": "اللؤلؤة",
    "DISTRICTNAME_EN": "Al Luluah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2317",
    "DISTRICTNAME_AR": "الرين الاعلى",
    "DISTRICTNAME_EN": "Al Riyn Al Aelaa",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2318",
    "DISTRICTNAME_AR": "السحاب",
    "DISTRICTNAME_EN": "Al Sahab",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2319",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2320",
    "DISTRICTNAME_AR": "مدينة الملك عبدالله للطاقة",
    "DISTRICTNAME_EN": "King Abdullah City for Energy",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2321",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2322",
    "DISTRICTNAME_AR": "المعيزيلة",
    "DISTRICTNAME_EN": "Al Maizialah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2323",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2324",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2325",
    "DISTRICTNAME_AR": "الصناعية الجديدة",
    "DISTRICTNAME_EN": "New Industrial",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2326",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2327",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2328",
    "DISTRICTNAME_AR": "النور والصفاء",
    "DISTRICTNAME_EN": "An Noor and Al Safa",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2329",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2330",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2331",
    "DISTRICTNAME_AR": "المحمدية ",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2332",
    "DISTRICTNAME_AR": "اللدام الشمالي",
    "DISTRICTNAME_EN": "Al Lidam Al Shamali",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2333",
    "DISTRICTNAME_AR": "صناعية الشرافا",
    "DISTRICTNAME_EN": "Sinaiyah Al Sharafa",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2334",
    "DISTRICTNAME_AR": "السعودية",
    "DISTRICTNAME_EN": "As Saudia",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2335",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2336",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2337",
    "DISTRICTNAME_AR": "الخير",
    "DISTRICTNAME_EN": "Al Khair",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2338",
    "DISTRICTNAME_AR": "الاخاء",
    "DISTRICTNAME_EN": "Al Ekhaa",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2339",
    "DISTRICTNAME_AR": "يبرين",
    "DISTRICTNAME_EN": "Yubrin",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2340",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2341",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2342",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2343",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2344",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "6605",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2345",
    "DISTRICTNAME_AR": "مخطط 326",
    "DISTRICTNAME_EN": "Subdivision 326",
    "CITY_ID": "19919",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2346",
    "DISTRICTNAME_AR": "التسعين",
    "DISTRICTNAME_EN": "Ninety",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2347",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2348",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2349",
    "DISTRICTNAME_AR": "سد الغابة",
    "DISTRICTNAME_EN": "Sad Al Ghaba",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2350",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2351",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2352",
    "DISTRICTNAME_AR": "العمامرة",
    "DISTRICTNAME_EN": "Al Amamrah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2353",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2354",
    "DISTRICTNAME_AR": "منطقة الخدمات المساندة",
    "DISTRICTNAME_EN": "Support Services",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2355",
    "DISTRICTNAME_AR": "صناعية الفوازية",
    "DISTRICTNAME_EN": "Sinaiyah Al Fawaziyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2356",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2357",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2358",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2359",
    "DISTRICTNAME_AR": "الأمير محمد بن سعود",
    "DISTRICTNAME_EN": "Prince Muhammad bin Saud",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2360",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2361",
    "DISTRICTNAME_AR": "البادية",
    "DISTRICTNAME_EN": "Al Badiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2362",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2363",
    "DISTRICTNAME_AR": "الصناعية الأولى",
    "DISTRICTNAME_EN": "1st Industrial",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2364",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2365",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2366",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2367",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2368",
    "DISTRICTNAME_AR": "الدوبية",
    "DISTRICTNAME_EN": "Al Dubiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2369",
    "DISTRICTNAME_AR": "الندوة",
    "DISTRICTNAME_EN": "Al Nadwah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2370",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2371",
    "DISTRICTNAME_AR": "السكب",
    "DISTRICTNAME_EN": "As Sakb",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2372",
    "DISTRICTNAME_AR": "الحساء",
    "DISTRICTNAME_EN": "Al Hussa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2373",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2374",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2375",
    "DISTRICTNAME_AR": "اللدام الجنوبي",
    "DISTRICTNAME_EN": "Al Lidam Al Janubi",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2376",
    "DISTRICTNAME_AR": "نعام",
    "DISTRICTNAME_EN": "Nieam",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2377",
    "DISTRICTNAME_AR": "المعتلا",
    "DISTRICTNAME_EN": "Al Muetala",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2378",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2379",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2381",
    "DISTRICTNAME_AR": "المدينة الجامعية",
    "DISTRICTNAME_EN": "University City",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2382",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2383",
    "DISTRICTNAME_AR": "مخطط رقم 980",
    "DISTRICTNAME_EN": "Subdivision No. 980",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2384",
    "DISTRICTNAME_AR": "مخطط رقم 250-أ",
    "DISTRICTNAME_EN": "Subdivision No. 250 A",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2386",
    "DISTRICTNAME_AR": "بنى حارثة",
    "DISTRICTNAME_EN": "Bani Harithah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2387",
    "DISTRICTNAME_AR": "المبعوث",
    "DISTRICTNAME_EN": "Al Mabuth",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2388",
    "DISTRICTNAME_AR": "بني خدرة",
    "DISTRICTNAME_EN": "Bani Khidrah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2391",
    "DISTRICTNAME_AR": "الضريب",
    "DISTRICTNAME_EN": "Ad Darib",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2392",
    "DISTRICTNAME_AR": "العريجاء",
    "DISTRICTNAME_EN": "Al Uraija",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2393",
    "DISTRICTNAME_AR": "المنصورية",
    "DISTRICTNAME_EN": "Mansuriyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2394",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2395",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2397",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2398",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2399",
    "DISTRICTNAME_AR": "الأمانة",
    "DISTRICTNAME_EN": "Al Amanah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2400",
    "DISTRICTNAME_AR": "الشاطئ الغربي",
    "DISTRICTNAME_EN": "Ash Shati Al Gharbi",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2401",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2402",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2403",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2404",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2407",
    "DISTRICTNAME_AR": "الزاوية",
    "DISTRICTNAME_EN": "Al Zaawia",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2408",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2409",
    "DISTRICTNAME_AR": "السوق",
    "DISTRICTNAME_EN": "As Suq",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2410",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2411",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2412",
    "DISTRICTNAME_AR": "المكرمية",
    "DISTRICTNAME_EN": "Al Mukramia",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2413",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2414",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2415",
    "DISTRICTNAME_AR": "القري",
    "DISTRICTNAME_EN": "Al Qura",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2416",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2417",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2418",
    "DISTRICTNAME_AR": "الإسكان الخيري",
    "DISTRICTNAME_EN": "Al Iskan Al Khairi",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2419",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2420",
    "DISTRICTNAME_AR": "جرير",
    "DISTRICTNAME_EN": "Jareer",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2422",
    "DISTRICTNAME_AR": "منفوحة",
    "DISTRICTNAME_EN": "Manfuhah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2423",
    "DISTRICTNAME_AR": "المرسلات",
    "DISTRICTNAME_EN": "Al Mursalat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2424",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2425",
    "DISTRICTNAME_AR": "المزروعية",
    "DISTRICTNAME_EN": "Al Mazruiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2426",
    "DISTRICTNAME_AR": "قصر الخليج",
    "DISTRICTNAME_EN": "Qasr Al Khalij",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2427",
    "DISTRICTNAME_AR": "الحسام",
    "DISTRICTNAME_EN": "Al Hussam",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2428",
    "DISTRICTNAME_AR": "تهامة",
    "DISTRICTNAME_EN": "Tihamah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2429",
    "DISTRICTNAME_AR": "البندرية",
    "DISTRICTNAME_EN": "Al Bandariyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2430",
    "DISTRICTNAME_AR": "مزعل",
    "DISTRICTNAME_EN": "Mazal ",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2431",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2432",
    "DISTRICTNAME_AR": "الفرسان",
    "DISTRICTNAME_EN": "Al Fursan",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2433",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2434",
    "DISTRICTNAME_AR": "العنود",
    "DISTRICTNAME_EN": "Al Anud",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2435",
    "DISTRICTNAME_AR": "مدينة العمال",
    "DISTRICTNAME_EN": "Madinat Al Ummal",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2436",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2437",
    "DISTRICTNAME_AR": "العقربية",
    "DISTRICTNAME_EN": "Al Aqrabiyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2438",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2439",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2440",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2441",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2443",
    "DISTRICTNAME_AR": "مخطط رقم 913",
    "DISTRICTNAME_EN": "Subdivision No. 913",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2444",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2445",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2446",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2447",
    "DISTRICTNAME_AR": "المشرق",
    "DISTRICTNAME_EN": "Al Mashriq",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2448",
    "DISTRICTNAME_AR": "السفارات",
    "DISTRICTNAME_EN": "Assafarat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2449",
    "DISTRICTNAME_AR": "الشموع",
    "DISTRICTNAME_EN": "Ash Shumu",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2450",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2451",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2452",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2453",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2454",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2455",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2456",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2457",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2458",
    "DISTRICTNAME_AR": "صقرة",
    "DISTRICTNAME_EN": "Saqra",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2459",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2460",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2461",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2462",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2463",
    "DISTRICTNAME_AR": "فيضة الفوازين",
    "DISTRICTNAME_EN": "Faydat Al Fawazin",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2464",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "20276",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2465",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2466",
    "DISTRICTNAME_AR": "الخماسين الشمالى",
    "DISTRICTNAME_EN": "The northern fiftieths",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2467",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2468",
    "DISTRICTNAME_AR": "الدانة الجنوبية",
    "DISTRICTNAME_EN": "Ad Danah Al Janubiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2469",
    "DISTRICTNAME_AR": "اللؤلؤ",
    "DISTRICTNAME_EN": "Al Loaloa",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2470",
    "DISTRICTNAME_AR": "الحزام الأخضر",
    "DISTRICTNAME_EN": "Al Hizam Al Akhdar",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2471",
    "DISTRICTNAME_AR": "الرجاء",
    "DISTRICTNAME_EN": "Ar Rajaa",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2472",
    "DISTRICTNAME_AR": "الجسر",
    "DISTRICTNAME_EN": "Al Jisr",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2473",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2474",
    "DISTRICTNAME_AR": "الخضراء",
    "DISTRICTNAME_EN": "Al Khadraa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2475",
    "DISTRICTNAME_AR": "القصواء",
    "DISTRICTNAME_EN": "Al Qaswa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2476",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2477",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2478",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2479",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2480",
    "DISTRICTNAME_AR": "المندسة",
    "DISTRICTNAME_EN": "Al Mundasa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2481",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2482",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2483",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2484",
    "DISTRICTNAME_AR": "النورس",
    "DISTRICTNAME_EN": "An Nawras",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2485",
    "DISTRICTNAME_AR": "الحرس الوطني",
    "DISTRICTNAME_EN": "National Guard",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2486",
    "DISTRICTNAME_AR": "الراكة الجنوبية",
    "DISTRICTNAME_EN": "Ar Rakah Al Janubiyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2487",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2488",
    "DISTRICTNAME_AR": "مدينة العمال",
    "DISTRICTNAME_EN": "Madinat Al Ummal",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2489",
    "DISTRICTNAME_AR": "البحر",
    "DISTRICTNAME_EN": "Al Bahar",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2490",
    "DISTRICTNAME_AR": "الهلالي",
    "DISTRICTNAME_EN": "Al Hilali",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2491",
    "DISTRICTNAME_AR": "بهدي",
    "DISTRICTNAME_EN": "Bihady",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2492",
    "DISTRICTNAME_AR": "العسيلة",
    "DISTRICTNAME_EN": "Al Usaylah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2493",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2494",
    "DISTRICTNAME_AR": "الفهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2495",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2496",
    "DISTRICTNAME_AR": "ال هويمل",
    "DISTRICTNAME_EN": "Al Hoymel",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2497",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2498",
    "DISTRICTNAME_AR": "مخطط 699",
    "DISTRICTNAME_EN": "Subdivision 699",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2499",
    "DISTRICTNAME_AR": "مخطط رقم 247",
    "DISTRICTNAME_EN": "Subdivision No. 247",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2500",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2502",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2503",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2504",
    "DISTRICTNAME_AR": "العود",
    "DISTRICTNAME_EN": "Al Oud",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2505",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2506",
    "DISTRICTNAME_AR": "المصانع",
    "DISTRICTNAME_EN": "Al Masani",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2507",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2508",
    "DISTRICTNAME_AR": "المونسية",
    "DISTRICTNAME_EN": "Al Munisiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2509",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2510",
    "DISTRICTNAME_AR": "مخطط رقم 232",
    "DISTRICTNAME_EN": "Subdivision No. 232",
    "CITY_ID": "16099",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2511",
    "DISTRICTNAME_AR": "الدرعية",
    "DISTRICTNAME_EN": "Al Diriyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2512",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2513",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2514",
    "DISTRICTNAME_AR": "مختارة",
    "DISTRICTNAME_EN": "Mukhtara",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2516",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2517",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2522",
    "DISTRICTNAME_AR": "الرائد",
    "DISTRICTNAME_EN": "Al Raed",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2523",
    "DISTRICTNAME_AR": "الصهلوج",
    "DISTRICTNAME_EN": "Al Sahluj",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2524",
    "DISTRICTNAME_AR": "الدوحة الجنوبية",
    "DISTRICTNAME_EN": "Ad Dawhah Al Janubiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2525",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2526",
    "DISTRICTNAME_AR": "الأثير",
    "DISTRICTNAME_EN": "Al Athir",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2527",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2528",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2529",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2530",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2531",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2532",
    "DISTRICTNAME_AR": "الخبرالشمالية",
    "DISTRICTNAME_EN": "Al Khabra Ash Shamalia",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2533",
    "DISTRICTNAME_AR": "الهضبة",
    "DISTRICTNAME_EN": "Al Hadabah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2534",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2535",
    "DISTRICTNAME_AR": "الراكة الشمالية",
    "DISTRICTNAME_EN": "Ar Rakah Ash Shamaliyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2536",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2537",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2538",
    "DISTRICTNAME_AR": "الخضرية",
    "DISTRICTNAME_EN": "Al Kuthriah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2539",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2540",
    "DISTRICTNAME_AR": "الخبر الجنوبية",
    "DISTRICTNAME_EN": "Al Khubar Al Janubiyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2541",
    "DISTRICTNAME_AR": "ابن سيناء",
    "DISTRICTNAME_EN": "Ibn Sina",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2542",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2543",
    "DISTRICTNAME_AR": "الدواسر",
    "DISTRICTNAME_EN": "Ad Dawasir",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2544",
    "DISTRICTNAME_AR": "المدينة الرياضية",
    "DISTRICTNAME_EN": "Sports City",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2547",
    "DISTRICTNAME_AR": "ذو الحليفة",
    "DISTRICTNAME_EN": "Dhu Al Hulayfah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2548",
    "DISTRICTNAME_AR": "ميناء الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz Seaport",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2549",
    "DISTRICTNAME_AR": "الصواري",
    "DISTRICTNAME_EN": "Al Sawari",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2550",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2551",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2552",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2553",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2554",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2555",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2556",
    "DISTRICTNAME_AR": "أبوبريقاء",
    "DISTRICTNAME_EN": "Abu Burayqa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2557",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2558",
    "DISTRICTNAME_AR": "الريف",
    "DISTRICTNAME_EN": "Ar Rif",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2559",
    "DISTRICTNAME_AR": "حي ج3",
    "DISTRICTNAME_EN": "Dst C3",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2560",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2561",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2562",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2563",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2564",
    "DISTRICTNAME_AR": "الكلية الحربية",
    "DISTRICTNAME_EN": "Military College",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2565",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2566",
    "DISTRICTNAME_AR": "السنبلة",
    "DISTRICTNAME_EN": "As Sunbulah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2567",
    "DISTRICTNAME_AR": "غبيرا",
    "DISTRICTNAME_EN": "Ghabira",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2568",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2569",
    "DISTRICTNAME_AR": "أم الحمام الغربي",
    "DISTRICTNAME_EN": "Umm Al Hamam Western",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2570",
    "DISTRICTNAME_AR": "المعذر",
    "DISTRICTNAME_EN": "Al Mathar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2571",
    "DISTRICTNAME_AR": "منفوحة الجديدة",
    "DISTRICTNAME_EN": "Manfuha Al Jadidah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2572",
    "DISTRICTNAME_AR": "الكورنيش",
    "DISTRICTNAME_EN": "Al Kurnaish",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2573",
    "DISTRICTNAME_AR": "الملك سلمان",
    "DISTRICTNAME_EN": "King Salman",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2574",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2575",
    "DISTRICTNAME_AR": "القميش",
    "DISTRICTNAME_EN": "Al Qumaysh",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2576",
    "DISTRICTNAME_AR": "مخطط رقم725",
    "DISTRICTNAME_EN": "Subdivision No. 725",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2577",
    "DISTRICTNAME_AR": "الجرادية",
    "DISTRICTNAME_EN": "Al Jarradiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2578",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2579",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2580",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2582",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2583",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2585",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2586",
    "DISTRICTNAME_AR": "لبن",
    "DISTRICTNAME_EN": "Laban",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2587",
    "DISTRICTNAME_AR": "عتيقة",
    "DISTRICTNAME_EN": "Utayqah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2588",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2589",
    "DISTRICTNAME_AR": "سكيرينة",
    "DISTRICTNAME_EN": "Skirinah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2590",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2591",
    "DISTRICTNAME_AR": "الغنامية",
    "DISTRICTNAME_EN": "Al Ghannamiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2592",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2593",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2594",
    "DISTRICTNAME_AR": "جشم",
    "DISTRICTNAME_EN": "Josham",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2595",
    "DISTRICTNAME_AR": "وادي مذينب",
    "DISTRICTNAME_EN": "Wadi Muzaynib",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2597",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2598",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2599",
    "DISTRICTNAME_AR": "العين",
    "DISTRICTNAME_EN": "Al Ain",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2600",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2601",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2602",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2603",
    "DISTRICTNAME_AR": "البجادية",
    "DISTRICTNAME_EN": "Al Bajadia",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2604",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2605",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2606",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2608",
    "DISTRICTNAME_AR": "ظهرة العودة شرق",
    "DISTRICTNAME_EN": "Dhahrat Al Awdat Sharq",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2609",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2610",
    "DISTRICTNAME_AR": "آل حميضان",
    "DISTRICTNAME_EN": "Al Humaydan",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2611",
    "DISTRICTNAME_AR": "ال طهيف وال هديب",
    "DISTRICTNAME_EN": "Al Tahyif and Al Hadeeb",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2612",
    "DISTRICTNAME_AR": "10411043 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 10411043",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2613",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2614",
    "DISTRICTNAME_AR": "الشلال",
    "DISTRICTNAME_EN": "Ash Shalaal",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2615",
    "DISTRICTNAME_AR": "المصبح",
    "DISTRICTNAME_EN": "Al Msbeh",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2616",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2617",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2618",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2619",
    "DISTRICTNAME_AR": "نوارة",
    "DISTRICTNAME_EN": "Nawara",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2620",
    "DISTRICTNAME_AR": "السبيعى",
    "DISTRICTNAME_EN": "Al Sabieaa",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2621",
    "DISTRICTNAME_AR": "الغويبة",
    "DISTRICTNAME_EN": "Al Ghuwaiba",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2622",
    "DISTRICTNAME_AR": "نزوى",
    "DISTRICTNAME_EN": "Nazwaa",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2623",
    "DISTRICTNAME_AR": "حي ج8",
    "DISTRICTNAME_EN": "Dst C8",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2624",
    "DISTRICTNAME_AR": "الشبانان",
    "DISTRICTNAME_EN": "Al Shubaanan",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2625",
    "DISTRICTNAME_AR": "المثناه",
    "DISTRICTNAME_EN": "Al Mathnah ",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2626",
    "DISTRICTNAME_AR": "الصحن",
    "DISTRICTNAME_EN": "As Sahn",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2627",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2628",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2629",
    "DISTRICTNAME_AR": "مخطط رقم 120",
    "DISTRICTNAME_EN": "Subdivision No. 120",
    "CITY_ID": "16161",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2631",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "20279",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2633",
    "DISTRICTNAME_AR": "منطقة المستودعات",
    "DISTRICTNAME_EN": "Warehouse Area",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2634",
    "DISTRICTNAME_AR": "بنبان",
    "DISTRICTNAME_EN": "Banban",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2635",
    "DISTRICTNAME_AR": "الذيابية",
    "DISTRICTNAME_EN": "Al Dhayabia",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2637",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2638",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2640",
    "DISTRICTNAME_AR": "الحنابجة",
    "DISTRICTNAME_EN": "Al Hanabija",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2641",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2642",
    "DISTRICTNAME_AR": "عرقة",
    "DISTRICTNAME_EN": "Irqah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2643",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2644",
    "DISTRICTNAME_AR": "المرزوقية",
    "DISTRICTNAME_EN": "Al Marzoukia",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2645",
    "DISTRICTNAME_AR": "آل حمد",
    "DISTRICTNAME_EN": "Al Hamad",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2646",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2647",
    "DISTRICTNAME_AR": "مرخ",
    "DISTRICTNAME_EN": "Markh ",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2648",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2649",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2651",
    "DISTRICTNAME_AR": "البدائع",
    "DISTRICTNAME_EN": "Al Badai'",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2652",
    "DISTRICTNAME_AR": "العقبانى",
    "DISTRICTNAME_EN": "Al Aqabani",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2653",
    "DISTRICTNAME_AR": "حي ج13",
    "DISTRICTNAME_EN": "Dst C13",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2654",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2656",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2657",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2658",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2659",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2660",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2661",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2662",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2663",
    "DISTRICTNAME_AR": "المثوية",
    "DISTRICTNAME_EN": "Al Mithawia",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2664",
    "DISTRICTNAME_AR": "الفايزية",
    "DISTRICTNAME_EN": "Al Fayziyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2665",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2666",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2667",
    "DISTRICTNAME_AR": "العمرة",
    "DISTRICTNAME_EN": "Al Umrah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2668",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2669",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2670",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2671",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2672",
    "DISTRICTNAME_AR": "الرواما",
    "DISTRICTNAME_EN": "Al Rawama",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2673",
    "DISTRICTNAME_AR": "الأمير سلطان",
    "DISTRICTNAME_EN": "Prince Sultan",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2674",
    "DISTRICTNAME_AR": "الملز",
    "DISTRICTNAME_EN": "Al Malaz",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2675",
    "DISTRICTNAME_AR": "ديراب",
    "DISTRICTNAME_EN": "Dirab",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2676",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2677",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2678",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2679",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2681",
    "DISTRICTNAME_AR": "أم عنيق",
    "DISTRICTNAME_EN": "Umm Aneq",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2682",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2683",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2684",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2685",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2686",
    "DISTRICTNAME_AR": "أم سريحة",
    "DISTRICTNAME_EN": "Umm Sariha",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2687",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2688",
    "DISTRICTNAME_AR": "الدحو",
    "DISTRICTNAME_EN": "Al Dahou",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2689",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2690",
    "DISTRICTNAME_AR": "العوالي",
    "DISTRICTNAME_EN": "Al Awaly",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2691",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2692",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2693",
    "DISTRICTNAME_AR": "جبرة",
    "DISTRICTNAME_EN": "Jabrah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2694",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "4393",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "2695",
    "DISTRICTNAME_AR": "الحلة",
    "DISTRICTNAME_EN": "Al Hilah",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2696",
    "DISTRICTNAME_AR": "اسفل الباطن الشمالي",
    "DISTRICTNAME_EN": "Asfil Al Batin North",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2697",
    "DISTRICTNAME_AR": "الصحافة",
    "DISTRICTNAME_EN": "Al Sahafah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2699",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2700",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2701",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2702",
    "DISTRICTNAME_AR": "المصارير",
    "DISTRICTNAME_EN": "Al Masarir",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2703",
    "DISTRICTNAME_AR": "المعتلا",
    "DISTRICTNAME_EN": "Al Muetala",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2704",
    "DISTRICTNAME_AR": "مخطط 525",
    "DISTRICTNAME_EN": "Subdivision 525",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2705",
    "DISTRICTNAME_AR": "مخطط794",
    "DISTRICTNAME_EN": "Subdivision 794",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2706",
    "DISTRICTNAME_AR": "مخطط 433",
    "DISTRICTNAME_EN": "Subdivision 433",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2707",
    "DISTRICTNAME_AR": "مخطط 331",
    "DISTRICTNAME_EN": "Subdivision 331",
    "CITY_ID": "19931",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2708",
    "DISTRICTNAME_AR": "زياد",
    "DISTRICTNAME_EN": "Ziyad",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2709",
    "DISTRICTNAME_AR": "خضار",
    "DISTRICTNAME_EN": "Khudar",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2710",
    "DISTRICTNAME_AR": "الوزارات",
    "DISTRICTNAME_EN": "Al Wizarat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2711",
    "DISTRICTNAME_AR": "البطحاء",
    "DISTRICTNAME_EN": "Al Batha",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2712",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2713",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2715",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2717",
    "DISTRICTNAME_AR": "المراجيم",
    "DISTRICTNAME_EN": "Al Marajim",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2718",
    "DISTRICTNAME_AR": "1029 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1029",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2719",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2720",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2721",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2722",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2723",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2724",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2725",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2726",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2727",
    "DISTRICTNAME_AR": "المدرسة العسكرية",
    "DISTRICTNAME_EN": "Military School",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2728",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2729",
    "DISTRICTNAME_AR": "مخطط 607",
    "DISTRICTNAME_EN": "Subdivision 607",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2730",
    "DISTRICTNAME_AR": "الفيضة 1 ",
    "DISTRICTNAME_EN": "Al Faydah 1",
    "CITY_ID": "20075",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2731",
    "DISTRICTNAME_AR": "مخطط رقم 653",
    "DISTRICTNAME_EN": "Subdivision No. 653",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2732",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2733",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2734",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2735",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2736",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2737",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2738",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2739",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2740",
    "DISTRICTNAME_AR": "الحزيمية",
    "DISTRICTNAME_EN": "Al Hazimia",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2741",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2742",
    "DISTRICTNAME_AR": "القرينة الجديدة",
    "DISTRICTNAME_EN": "Al Qarina Al Jadida",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2743",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2744",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2747",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2748",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2749",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2750",
    "DISTRICTNAME_AR": "نجد",
    "DISTRICTNAME_EN": "Najd",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2751",
    "DISTRICTNAME_AR": "الشلال",
    "DISTRICTNAME_EN": "Ash Shalaal",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2752",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2753",
    "DISTRICTNAME_AR": "1023 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1023",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2754",
    "DISTRICTNAME_AR": "الفروسية",
    "DISTRICTNAME_EN": "Al Frosyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2755",
    "DISTRICTNAME_AR": "مخطط رقم 1083",
    "DISTRICTNAME_EN": "Subdivision No. 1083",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2756",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2757",
    "DISTRICTNAME_AR": "1101 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1101",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2758",
    "DISTRICTNAME_AR": "الجنادرية",
    "DISTRICTNAME_EN": "Al Janadriyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2759",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2760",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2761",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2762",
    "DISTRICTNAME_AR": "الرين القديم",
    "DISTRICTNAME_EN": "Al Riyn Al Qadim",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2763",
    "DISTRICTNAME_AR": "الغزة الجديد",
    "DISTRICTNAME_EN": "Al Ghaza Al Jadid",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "2764",
    "DISTRICTNAME_AR": "مخطط رقم 621",
    "DISTRICTNAME_EN": "Subdivision No. 621",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2765",
    "DISTRICTNAME_AR": "السلي",
    "DISTRICTNAME_EN": "Al Sulay",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2766",
    "DISTRICTNAME_AR": "الزاهر",
    "DISTRICTNAME_EN": "Az Zahir",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2767",
    "DISTRICTNAME_AR": "العلا",
    "DISTRICTNAME_EN": "Al Ula",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2768",
    "DISTRICTNAME_AR": "النخبة",
    "DISTRICTNAME_EN": "Al Nakhbah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2769",
    "DISTRICTNAME_AR": "جفارة السعدان",
    "DISTRICTNAME_EN": "Jafara Al Saadan",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2770",
    "DISTRICTNAME_AR": "منطقة استراحات حمد الجبرين",
    "DISTRICTNAME_EN": "aistirahat Hamad Al Jibrin",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2771",
    "DISTRICTNAME_AR": "اليتيمة",
    "DISTRICTNAME_EN": "Al Yatimah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2772",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2773",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2774",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2775",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2776",
    "DISTRICTNAME_AR": "العذار",
    "DISTRICTNAME_EN": "Al Adhaar",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2777",
    "DISTRICTNAME_AR": "الحلة الطالعية",
    "DISTRICTNAME_EN": "Al Hilah Al Taalieia",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2778",
    "DISTRICTNAME_AR": "مخطط رقم 1039",
    "DISTRICTNAME_EN": "Subdivision No. 1039",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2779",
    "DISTRICTNAME_AR": "مخطط 749",
    "DISTRICTNAME_EN": "Subdivision 749",
    "CITY_ID": "20339",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2780",
    "DISTRICTNAME_AR": "الرماية",
    "DISTRICTNAME_EN": "Al Rimayah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2781",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2782",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2783",
    "DISTRICTNAME_AR": "الحائر",
    "DISTRICTNAME_EN": "Al Haer",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2784",
    "DISTRICTNAME_AR": "العمار",
    "DISTRICTNAME_EN": "Al Amaar",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2785",
    "DISTRICTNAME_AR": "الرحمانية",
    "DISTRICTNAME_EN": "Ar Rahmanyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2786",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2787",
    "DISTRICTNAME_AR": "النسيم الشرقي",
    "DISTRICTNAME_EN": "East Naseem",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2788",
    "DISTRICTNAME_AR": "جامعة الإمام محمد بن سعود الإسلامية",
    "DISTRICTNAME_EN": "Imam Muhammed Bin Saud Islamic University",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2789",
    "DISTRICTNAME_AR": "الرفايع",
    "DISTRICTNAME_EN": "Al Rafaye",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2791",
    "DISTRICTNAME_AR": "اسفل الباطن",
    "DISTRICTNAME_EN": "Asfil Al Batin",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2792",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2793",
    "DISTRICTNAME_AR": "الباطن",
    "DISTRICTNAME_EN": "Al Batin",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2794",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2795",
    "DISTRICTNAME_AR": "665 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 665",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2796",
    "DISTRICTNAME_AR": "مخطط رقم 1033",
    "DISTRICTNAME_EN": "Subdivision No. 1033",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2797",
    "DISTRICTNAME_AR": "مخطط رقم 985",
    "DISTRICTNAME_EN": "Subdivision No. 985",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2798",
    "DISTRICTNAME_AR": "1077 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1077",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2799",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2800",
    "DISTRICTNAME_AR": "الحزيمية",
    "DISTRICTNAME_EN": "Al Huzaymiyyah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2801",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2802",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2803",
    "DISTRICTNAME_AR": "العلب",
    "DISTRICTNAME_EN": "Al Olab",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2804",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2805",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "2806",
    "DISTRICTNAME_AR": "مشيرفة",
    "DISTRICTNAME_EN": "Mishyrifah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2807",
    "DISTRICTNAME_AR": "العارض",
    "DISTRICTNAME_EN": "Al Arid",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2808",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2809",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2811",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2812",
    "DISTRICTNAME_AR": "ال هشام",
    "DISTRICTNAME_EN": "Al Hisham",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2813",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2815",
    "DISTRICTNAME_AR": "هجرة بوضة",
    "DISTRICTNAME_EN": "Hijrat Buda",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2816",
    "DISTRICTNAME_AR": "قعضبا",
    "DISTRICTNAME_EN": "Qaedaba",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2818",
    "DISTRICTNAME_AR": "مركز الشرارة",
    "DISTRICTNAME_EN": "Al Sharara Center",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2819",
    "DISTRICTNAME_AR": "الوقف",
    "DISTRICTNAME_EN": "Al Wakf",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2820",
    "DISTRICTNAME_AR": "الملك سعود",
    "DISTRICTNAME_EN": "King Saud",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2821",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2822",
    "DISTRICTNAME_AR": "سوق الماشية",
    "DISTRICTNAME_EN": "Suq Al Mashiya",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2824",
    "DISTRICTNAME_AR": "الأمير سلطان بن عبدالعزيز",
    "DISTRICTNAME_EN": "Prince Sultan bin Abdulaziz",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2825",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2826",
    "DISTRICTNAME_AR": "برزان",
    "DISTRICTNAME_EN": "Barzan",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2827",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2829",
    "DISTRICTNAME_AR": "الرمل",
    "DISTRICTNAME_EN": "Al Raml",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2830",
    "DISTRICTNAME_AR": "الأمير سلطان",
    "DISTRICTNAME_EN": "Prince Sultan",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2831",
    "DISTRICTNAME_AR": "المدرجية",
    "DISTRICTNAME_EN": "Al Midrajia",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2832",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2833",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2834",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2835",
    "DISTRICTNAME_AR": "مخطط رقم 62",
    "DISTRICTNAME_EN": "Subdivision No. 62",
    "CITY_ID": "16161",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2837",
    "DISTRICTNAME_AR": "البديع الغربي",
    "DISTRICTNAME_EN": "Al Badi Al Gharbi",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2838",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2839",
    "DISTRICTNAME_AR": "سوق الماشية",
    "DISTRICTNAME_EN": "Suq Al Mashiya",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2840",
    "DISTRICTNAME_AR": "الرجع",
    "DISTRICTNAME_EN": "Ar Raja",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2841",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2842",
    "DISTRICTNAME_AR": "الأمير سلطان",
    "DISTRICTNAME_EN": "Prince Sultan",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2843",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2844",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2845",
    "DISTRICTNAME_AR": "بهجة",
    "DISTRICTNAME_EN": "Bahja",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2846",
    "DISTRICTNAME_AR": "التقنية",
    "DISTRICTNAME_EN": "Al Tiqniya",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2847",
    "DISTRICTNAME_AR": "الحنو",
    "DISTRICTNAME_EN": "Al Hunu",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2848",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2849",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2850",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2851",
    "DISTRICTNAME_AR": "العاصمة",
    "DISTRICTNAME_EN": "Al Asemah",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2852",
    "DISTRICTNAME_AR": "حي ج19",
    "DISTRICTNAME_EN": "Dst C19",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2853",
    "DISTRICTNAME_AR": "أم سليم",
    "DISTRICTNAME_EN": "Umm Selim",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2854",
    "DISTRICTNAME_AR": "حي ج11",
    "DISTRICTNAME_EN": "Dst C11",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2855",
    "DISTRICTNAME_AR": "ال قاران",
    "DISTRICTNAME_EN": "Al Qaran",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2856",
    "DISTRICTNAME_AR": "الحلة",
    "DISTRICTNAME_EN": "Al Hilah",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2857",
    "DISTRICTNAME_AR": "ال ظافر",
    "DISTRICTNAME_EN": "Al Zafer",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2858",
    "DISTRICTNAME_AR": "التوباد",
    "DISTRICTNAME_EN": "Al Tuwbad",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2859",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2860",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2862",
    "DISTRICTNAME_AR": "حي ج16",
    "DISTRICTNAME_EN": "Dst C16",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2863",
    "DISTRICTNAME_AR": "الوداعين",
    "DISTRICTNAME_EN": "Al Wadaein",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2864",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2865",
    "DISTRICTNAME_AR": "ثليم",
    "DISTRICTNAME_EN": "Thulaim",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2866",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2867",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2868",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2869",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2870",
    "DISTRICTNAME_AR": "السيح",
    "DISTRICTNAME_EN": "As Sih",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2871",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2872",
    "DISTRICTNAME_AR": "الوسطى",
    "DISTRICTNAME_EN": "Al Wusta",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2873",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2874",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2875",
    "DISTRICTNAME_AR": "الوشم",
    "DISTRICTNAME_EN": "Al Washm",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2876",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2877",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2878",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2879",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2880",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2881",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2882",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2883",
    "DISTRICTNAME_AR": "الفروسية",
    "DISTRICTNAME_EN": "Al Frosyah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2884",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2885",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2886",
    "DISTRICTNAME_AR": "الخرائق",
    "DISTRICTNAME_EN": "Al Kharayiq",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2887",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2888",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2889",
    "DISTRICTNAME_AR": "الأمير نايف",
    "DISTRICTNAME_EN": "Prince Naif",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2890",
    "DISTRICTNAME_AR": "الصديق",
    "DISTRICTNAME_EN": "As Sediq",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2891",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2892",
    "DISTRICTNAME_AR": "حي ج9",
    "DISTRICTNAME_EN": "Dst C9",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2893",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2895",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2896",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2897",
    "DISTRICTNAME_AR": "الوسطة",
    "DISTRICTNAME_EN": "Al Wasata",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2898",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2899",
    "DISTRICTNAME_AR": "القري",
    "DISTRICTNAME_EN": "Al Qura",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2900",
    "DISTRICTNAME_AR": "النموذجية",
    "DISTRICTNAME_EN": "An Namudhajiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2901",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2902",
    "DISTRICTNAME_AR": "الرسالة",
    "DISTRICTNAME_EN": "Ar Risalah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2903",
    "DISTRICTNAME_AR": "مطار الملك خالد الدولي",
    "DISTRICTNAME_EN": "King Khalid International Airport",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2905",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2906",
    "DISTRICTNAME_AR": "وادي لبن - طويق",
    "DISTRICTNAME_EN": "Wadi Laban",
    "CITY_ID": "21282",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "2907",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2908",
    "DISTRICTNAME_AR": "المشاعل",
    "DISTRICTNAME_EN": "Al Mishael",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2909",
    "DISTRICTNAME_AR": "نمار",
    "DISTRICTNAME_EN": "Namar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2910",
    "DISTRICTNAME_AR": "عليشة",
    "DISTRICTNAME_EN": "Olaishah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2911",
    "DISTRICTNAME_AR": "السويدي الغربي",
    "DISTRICTNAME_EN": "Al Suwaidi Al Gharbi",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2912",
    "DISTRICTNAME_AR": "العزيزية1",
    "DISTRICTNAME_EN": "Al Aziziyah1",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2913",
    "DISTRICTNAME_AR": "الأخضر",
    "DISTRICTNAME_EN": "Al Akhdar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2914",
    "DISTRICTNAME_AR": "البكر",
    "DISTRICTNAME_EN": "Al Bakr",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2915",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2916",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2917",
    "DISTRICTNAME_AR": "مخطط رقم 814",
    "DISTRICTNAME_EN": "Subdivision No. 814",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2918",
    "DISTRICTNAME_AR": "العمارية",
    "DISTRICTNAME_EN": "Al Ammariyah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2921",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2922",
    "DISTRICTNAME_AR": "المرجع",
    "DISTRICTNAME_EN": "Al Marja",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2923",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2924",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2925",
    "DISTRICTNAME_AR": "اسفل الباطن الجنوبي",
    "DISTRICTNAME_EN": "Asfil Al Batin Southern",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2926",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2928",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "At Tahliyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2929",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2930",
    "DISTRICTNAME_AR": "القصور",
    "DISTRICTNAME_EN": "Al Qusor",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2931",
    "DISTRICTNAME_AR": "الحزام الذهبي",
    "DISTRICTNAME_EN": "Al Hizam Adh Dhahabi",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2932",
    "DISTRICTNAME_AR": "ابن خلدون",
    "DISTRICTNAME_EN": "Ibn Khaldun",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2933",
    "DISTRICTNAME_AR": "الشراع",
    "DISTRICTNAME_EN": "Ash Sheraa",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2934",
    "DISTRICTNAME_AR": "الصدفة",
    "DISTRICTNAME_EN": "As Sadafah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2935",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2936",
    "DISTRICTNAME_AR": "ال راشد وال شايع",
    "DISTRICTNAME_EN": "Al Rashid and Shaye",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2937",
    "DISTRICTNAME_AR": "سحاب",
    "DISTRICTNAME_EN": "Sahab",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2938",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2939",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2940",
    "DISTRICTNAME_AR": "الديرة القديمة",
    "DISTRICTNAME_EN": "Ad Dirah Al Qadimah",
    "CITY_ID": "20279",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2941",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2942",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2943",
    "DISTRICTNAME_AR": "الادارية غرب",
    "DISTRICTNAME_EN": "West Administrative",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2944",
    "DISTRICTNAME_AR": "ال وحيد",
    "DISTRICTNAME_EN": "Al Wahid",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2945",
    "DISTRICTNAME_AR": "القويز",
    "DISTRICTNAME_EN": "Al Quayz",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2946",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2947",
    "DISTRICTNAME_AR": "غسلة",
    "DISTRICTNAME_EN": "Gaslah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2948",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2949",
    "DISTRICTNAME_AR": "مخطط 144",
    "DISTRICTNAME_EN": "Subdivision 144",
    "CITY_ID": "20075",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2951",
    "DISTRICTNAME_AR": "مخطط فرعة الرميثي",
    "DISTRICTNAME_EN": "Fireat Al Rumaithi Subdivision",
    "CITY_ID": "20317",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2952",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2953",
    "DISTRICTNAME_AR": "قريوة",
    "DISTRICTNAME_EN": "Qaryuh",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2954",
    "DISTRICTNAME_AR": "العقيلة الجنوبية",
    "DISTRICTNAME_EN": "South Al Uqaila",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "2955",
    "DISTRICTNAME_AR": "العطيفة",
    "DISTRICTNAME_EN": "Al Utifa",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2956",
    "DISTRICTNAME_AR": "الصناعية الأولى",
    "DISTRICTNAME_EN": "1st Industrial",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2958",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2959",
    "DISTRICTNAME_AR": "الفيحانية",
    "DISTRICTNAME_EN": "Al Fayhaniyah",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2960",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2961",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20620",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2962",
    "DISTRICTNAME_AR": "مخطط المعلق",
    "DISTRICTNAME_EN": "Al Muealiq Subdivision",
    "CITY_ID": "20339",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2963",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2964",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2965",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2966",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2967",
    "DISTRICTNAME_AR": "العيون",
    "DISTRICTNAME_EN": "Al Uyun",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2968",
    "DISTRICTNAME_AR": "العارض",
    "DISTRICTNAME_EN": "Al Arid",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2969",
    "DISTRICTNAME_AR": "مخطط رقم 822",
    "DISTRICTNAME_EN": "Subdivision No. 822",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2970",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2971",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2972",
    "DISTRICTNAME_AR": "القبالي",
    "DISTRICTNAME_EN": "Al Qabali",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2973",
    "DISTRICTNAME_AR": "المعتلا الجنوبى",
    "DISTRICTNAME_EN": "Southern Al Muetala",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2974",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2975",
    "DISTRICTNAME_AR": "1051 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1051",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2976",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2977",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2978",
    "DISTRICTNAME_AR": "الشرفية",
    "DISTRICTNAME_EN": "Ash Sharafiyah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2980",
    "DISTRICTNAME_AR": "مخطط 94",
    "DISTRICTNAME_EN": "Subdivision 94",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2981",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2982",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2983",
    "DISTRICTNAME_AR": "الياسمين - سدير",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21232",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "2984",
    "DISTRICTNAME_AR": "الاقرن",
    "DISTRICTNAME_EN": "Al Aqarn",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2985",
    "DISTRICTNAME_AR": "مخطط 315",
    "DISTRICTNAME_EN": "Subdivision 315",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2986",
    "DISTRICTNAME_AR": "حفيرة السبعة الشرقي",
    "DISTRICTNAME_EN": "Hafirat Al Sabeat Al Sharqi",
    "CITY_ID": "1808",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "2987",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "2988",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2990",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2991",
    "DISTRICTNAME_AR": "حي ج15",
    "DISTRICTNAME_EN": "Dst C15",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "2992",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "18760",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2993",
    "DISTRICTNAME_AR": "منطقة خدمات حكومية",
    "DISTRICTNAME_EN": "Government Services Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2994",
    "DISTRICTNAME_AR": "الحمر الجنوبي",
    "DISTRICTNAME_EN": "Al Humar Al Janubi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2995",
    "DISTRICTNAME_AR": "السويق",
    "DISTRICTNAME_EN": "Al Sawaiq",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "2996",
    "DISTRICTNAME_AR": "الدريدي",
    "DISTRICTNAME_EN": "Ad Duraydi",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "2997",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "2998",
    "DISTRICTNAME_AR": "الاحتفالات",
    "DISTRICTNAME_EN": "Alaihtifalat",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "2999",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3000",
    "DISTRICTNAME_AR": "المدينة",
    "DISTRICTNAME_EN": "Al Madinah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3001",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3002",
    "DISTRICTNAME_AR": "بهجة",
    "DISTRICTNAME_EN": "Bahja",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3003",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3004",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3005",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3006",
    "DISTRICTNAME_AR": "الملك خالد",
    "DISTRICTNAME_EN": "King Khalid",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3007",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3008",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3009",
    "DISTRICTNAME_AR": "الحوطة",
    "DISTRICTNAME_EN": "Al Hawta",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3010",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3011",
    "DISTRICTNAME_AR": "السلمية",
    "DISTRICTNAME_EN": "Al Silmia",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3012",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3013",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3014",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3015",
    "DISTRICTNAME_AR": "الرفايع",
    "DISTRICTNAME_EN": "Al Rafaye",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3019",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3020",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3022",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3023",
    "DISTRICTNAME_AR": "العصيلى",
    "DISTRICTNAME_EN": "Al Osaili",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3024",
    "DISTRICTNAME_AR": "حي أ",
    "DISTRICTNAME_EN": "Dst A",
    "CITY_ID": "12713",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3025",
    "DISTRICTNAME_AR": "حي ج5",
    "DISTRICTNAME_EN": "Dst C5",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3026",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3027",
    "DISTRICTNAME_AR": "الإسكان الخيري",
    "DISTRICTNAME_EN": "Al Iskan Al Khairi",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "3028",
    "DISTRICTNAME_AR": "النموذجية",
    "DISTRICTNAME_EN": "An Namudhajiyah",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "3029",
    "DISTRICTNAME_AR": "سلاح الحدود",
    "DISTRICTNAME_EN": "Land of Frontier Weapon",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "3030",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3031",
    "DISTRICTNAME_AR": "السمراء",
    "DISTRICTNAME_EN": "As Samra",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "3032",
    "DISTRICTNAME_AR": "مريفق",
    "DISTRICTNAME_EN": "Murifiq",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "3034",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3035",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3036",
    "DISTRICTNAME_AR": "التعليم",
    "DISTRICTNAME_EN": "At Taalim",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3037",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3038",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3039",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3040",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3041",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3042",
    "DISTRICTNAME_AR": "الجندل",
    "DISTRICTNAME_EN": "Al Jandal",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3043",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3044",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3045",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3046",
    "DISTRICTNAME_AR": "المطية",
    "DISTRICTNAME_EN": "Almatiuh",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3047",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3048",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3058",
    "DISTRICTNAME_AR": "مخطط الاراض البيضاء1",
    "DISTRICTNAME_EN": "White Land Organization Subdivision1",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3059",
    "DISTRICTNAME_AR": "مخطط الاراض البيضاء2",
    "DISTRICTNAME_EN": "White Land Organization Subdivision2",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3060",
    "DISTRICTNAME_AR": "مخطط الاراضي البيضاء3",
    "DISTRICTNAME_EN": "White Land Organization Subdivision3",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3062",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3063",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "1907",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3064",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3065",
    "DISTRICTNAME_AR": "العتيبية",
    "DISTRICTNAME_EN": "Al Utaybiyah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "3066",
    "DISTRICTNAME_AR": "وادي الرمة",
    "DISTRICTNAME_EN": "Wadi Alruma",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3067",
    "DISTRICTNAME_AR": "الشنانة الشمالية",
    "DISTRICTNAME_EN": "Alshannanuh Ash Shamaliyah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3068",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3069",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3070",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3071",
    "DISTRICTNAME_AR": "العبيل",
    "DISTRICTNAME_EN": "Al Obayel",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3072",
    "DISTRICTNAME_AR": "الجريف",
    "DISTRICTNAME_EN": "Al Gareef",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3073",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3074",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3075",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3076",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3077",
    "DISTRICTNAME_AR": "الغربي",
    "DISTRICTNAME_EN": "Al Gharbi",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3078",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3079",
    "DISTRICTNAME_AR": "الشنانة الجنوبية",
    "DISTRICTNAME_EN": "Alshannanuh Al Janubiyah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3080",
    "DISTRICTNAME_AR": "مناطق خاصة",
    "DISTRICTNAME_EN": "Private Areas",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3089",
    "DISTRICTNAME_AR": "البستان1",
    "DISTRICTNAME_EN": "Al Bustan1",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3090",
    "DISTRICTNAME_AR": "النزهة2",
    "DISTRICTNAME_EN": "An Nuzhah2",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3091",
    "DISTRICTNAME_AR": "غرب العزيزية",
    "DISTRICTNAME_EN": "West Al Aziziyah",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3092",
    "DISTRICTNAME_AR": "الديرة1",
    "DISTRICTNAME_EN": "Ad Dirah1",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3093",
    "DISTRICTNAME_AR": "الديرة2",
    "DISTRICTNAME_EN": "Ad Dirah2",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3095",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3096",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3097",
    "DISTRICTNAME_AR": "زمزم",
    "DISTRICTNAME_EN": "Zamzam",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3098",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3100",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3101",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3102",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3103",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3104",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3105",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3106",
    "DISTRICTNAME_AR": "سوق الماشية",
    "DISTRICTNAME_EN": "Suq Al Mashiya",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3107",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3108",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3110",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3114",
    "DISTRICTNAME_AR": "مخطط 820",
    "DISTRICTNAME_EN": "Subdivision 820",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3115",
    "DISTRICTNAME_AR": "مخطط 908",
    "DISTRICTNAME_EN": "Subdivision 908",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3116",
    "DISTRICTNAME_AR": "مخطط 740",
    "DISTRICTNAME_EN": "Subdivision 740",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3117",
    "DISTRICTNAME_AR": "مخطط 884",
    "DISTRICTNAME_EN": "Subdivision 884",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3118",
    "DISTRICTNAME_AR": "الملك سلمان1",
    "DISTRICTNAME_EN": "King Salman1",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3119",
    "DISTRICTNAME_AR": "الديرة القديمة1",
    "DISTRICTNAME_EN": "Ad Dirah Al Qadimah1",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3121",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3122",
    "DISTRICTNAME_AR": "المريكبات",
    "DISTRICTNAME_EN": "Al Muraikabat",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3124",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3125",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3126",
    "DISTRICTNAME_AR": "الدريهمية",
    "DISTRICTNAME_EN": "Al Duraihemiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3127",
    "DISTRICTNAME_AR": "مخطط رقم 4",
    "DISTRICTNAME_EN": "Subdivision No. 4",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3128",
    "DISTRICTNAME_AR": "مخطط رقم 133 ",
    "DISTRICTNAME_EN": "Refinement No. 133",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3129",
    "DISTRICTNAME_AR": "مخطط رقم 133 ",
    "DISTRICTNAME_EN": "Refinement No. 133",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3130",
    "DISTRICTNAME_AR": "مخطط 87",
    "DISTRICTNAME_EN": "Subdivision 87",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3131",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3132",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3133",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3134",
    "DISTRICTNAME_AR": "مخطط 886",
    "DISTRICTNAME_EN": "Subdivision 886",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3135",
    "DISTRICTNAME_AR": "مخطط 880",
    "DISTRICTNAME_EN": "Subdivision 880",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3136",
    "DISTRICTNAME_AR": "مخطط 906",
    "DISTRICTNAME_EN": "Subdivision 906",
    "CITY_ID": "20496",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3137",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3138",
    "DISTRICTNAME_AR": "مخطط رقم 144",
    "DISTRICTNAME_EN": "Subdivision No. 144",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3139",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3140",
    "DISTRICTNAME_AR": "مخطط رقم 210",
    "DISTRICTNAME_EN": "Subdivision No. 210",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3141",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "Al Khazzan",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3142",
    "DISTRICTNAME_AR": "الشبيلية2",
    "DISTRICTNAME_EN": "Ash Shabilia2",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3144",
    "DISTRICTNAME_AR": "الصدفة",
    "DISTRICTNAME_EN": "As Sadafah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3145",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3146",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3147",
    "DISTRICTNAME_AR": "الصحافة",
    "DISTRICTNAME_EN": "Al Sahafah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3148",
    "DISTRICTNAME_AR": "العواش",
    "DISTRICTNAME_EN": "Al Awash",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3149",
    "DISTRICTNAME_AR": "حي المنطقة المركزية - حي مانح",
    "DISTRICTNAME_EN": "Central District",
    "CITY_ID": "20264",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3150",
    "DISTRICTNAME_AR": "المنصورة",
    "DISTRICTNAME_EN": "Al Mansurah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3151",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3152",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3153",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3154",
    "DISTRICTNAME_AR": "الخالدية الشرقي",
    "DISTRICTNAME_EN": "Al Khalidiyah East",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3155",
    "DISTRICTNAME_AR": "المنطقة الصناعية1",
    "DISTRICTNAME_EN": "Industrial Area1",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3156",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3157",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3158",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3159",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3160",
    "DISTRICTNAME_AR": "الشمالي",
    "DISTRICTNAME_EN": "Ash Shamali",
    "CITY_ID": "20156",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3161",
    "DISTRICTNAME_AR": "المريديسية الجنوبية",
    "DISTRICTNAME_EN": "Al Muraydeesiyah Al Janubiyah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3162",
    "DISTRICTNAME_AR": "البساتين الشرقي",
    "DISTRICTNAME_EN": "Al Basatin Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3163",
    "DISTRICTNAME_AR": "العريض",
    "DISTRICTNAME_EN": "Al Ariyd",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3164",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "3165",
    "DISTRICTNAME_AR": "هجر",
    "DISTRICTNAME_EN": "Hajr",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3166",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3167",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3168",
    "DISTRICTNAME_AR": "السفن",
    "DISTRICTNAME_EN": "As Sufun",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3170",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3171",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3172",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11951",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3173",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3174",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3175",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "12536",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3176",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "12548",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3177",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "6145",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3178",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "12195",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3179",
    "DISTRICTNAME_AR": "مخطط2242",
    "DISTRICTNAME_EN": "Organizing The Lands Located East of The Subdivision 242 Subdivision",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3180",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3181",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3182",
    "DISTRICTNAME_AR": "الجنادرية",
    "DISTRICTNAME_EN": "Al Janadriyah",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3183",
    "DISTRICTNAME_AR": "الحدود",
    "DISTRICTNAME_EN": "Al Hudud",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3184",
    "DISTRICTNAME_AR": "البدر",
    "DISTRICTNAME_EN": "Al Badr",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3185",
    "DISTRICTNAME_AR": "أجياد",
    "DISTRICTNAME_EN": "Ajyad",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "3186",
    "DISTRICTNAME_AR": "باب الشمال",
    "DISTRICTNAME_EN": "Bab Madinah Ash Shamal",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3187",
    "DISTRICTNAME_AR": "البدور",
    "DISTRICTNAME_EN": "Al Budur",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3188",
    "DISTRICTNAME_AR": "الثريا",
    "DISTRICTNAME_EN": "Ath Thuraiya",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3189",
    "DISTRICTNAME_AR": "المعاقلة",
    "DISTRICTNAME_EN": "Al Muaqilah",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3190",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3191",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "12532",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "3192",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "1778",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "3193",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3195",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3196",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3197",
    "DISTRICTNAME_AR": "البديعة",
    "DISTRICTNAME_EN": "Al Badiah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3199",
    "DISTRICTNAME_AR": "الوقبة",
    "DISTRICTNAME_EN": "Al Waqba",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3200",
    "DISTRICTNAME_AR": "مخطط الاراضي الحكومية2",
    "DISTRICTNAME_EN": "Government Lands Subdivision2",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3201",
    "DISTRICTNAME_AR": "الهرير الغربى",
    "DISTRICTNAME_EN": "Al Hurayr Al Ghurbi",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3202",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "12694",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3204",
    "DISTRICTNAME_AR": "المبرز",
    "DISTRICTNAME_EN": "Al Mubarraz",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3205",
    "DISTRICTNAME_AR": "عقرباء",
    "DISTRICTNAME_EN": "Aqraba'",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3206",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3207",
    "DISTRICTNAME_AR": "الأفق",
    "DISTRICTNAME_EN": "Al Ufuq",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3208",
    "DISTRICTNAME_AR": "حي ج17",
    "DISTRICTNAME_EN": "Dst C17",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3210",
    "DISTRICTNAME_AR": "النهضة و بالشوك",
    "DISTRICTNAME_EN": "An Nahdah and Balshuwk",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3211",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3212",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3213",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3214",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3215",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3216",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3217",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3218",
    "DISTRICTNAME_AR": "سمحان",
    "DISTRICTNAME_EN": "Samhan",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3220",
    "DISTRICTNAME_AR": "مخطط 557",
    "DISTRICTNAME_EN": "Subdivision 557",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3221",
    "DISTRICTNAME_AR": "مخطط 415",
    "DISTRICTNAME_EN": "Subdivision 415",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3222",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3223",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3224",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18399",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3225",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "4177",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3226",
    "DISTRICTNAME_AR": "الجردة",
    "DISTRICTNAME_EN": "Al Jardah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3227",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3228",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3229",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3230",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3231",
    "DISTRICTNAME_AR": "الرويساء",
    "DISTRICTNAME_EN": "Al Ruwaysa",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3232",
    "DISTRICTNAME_AR": "النموذجية",
    "DISTRICTNAME_EN": "An Namudhajiyah",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3234",
    "DISTRICTNAME_AR": "الضبيعة",
    "DISTRICTNAME_EN": "Al Dabiea",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3235",
    "DISTRICTNAME_AR": "مخطط 923",
    "DISTRICTNAME_EN": "Subdivision 923",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3236",
    "DISTRICTNAME_AR": "مخطط 543",
    "DISTRICTNAME_EN": "Subdivision 543",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3237",
    "DISTRICTNAME_AR": "مخطط 476",
    "DISTRICTNAME_EN": "Subdivision 476",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3238",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3244",
    "DISTRICTNAME_AR": "البديع",
    "DISTRICTNAME_EN": "Al Badi",
    "CITY_ID": "11308",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3245",
    "DISTRICTNAME_AR": "القصر",
    "DISTRICTNAME_EN": "Al Qasr",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3246",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "3086",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3247",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "9192",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3248",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "4205",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3249",
    "DISTRICTNAME_AR": "الباحة",
    "DISTRICTNAME_EN": "AL Baha",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3250",
    "DISTRICTNAME_AR": "1045 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 1045",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3251",
    "DISTRICTNAME_AR": "لبب",
    "DISTRICTNAME_EN": "Lubib",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3252",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3253",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3254",
    "DISTRICTNAME_AR": "البرقة",
    "DISTRICTNAME_EN": "Al Barqah",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3255",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3256",
    "DISTRICTNAME_AR": "المعترضة",
    "DISTRICTNAME_EN": "Al Muutaridah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3257",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3259",
    "DISTRICTNAME_AR": "فرزان",
    "DISTRICTNAME_EN": "Farzan",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3260",
    "DISTRICTNAME_AR": "البدع",
    "DISTRICTNAME_EN": "Al Bada",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3267",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3268",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3269",
    "DISTRICTNAME_AR": "العريمضي الجنوبي",
    "DISTRICTNAME_EN": "Al Uraymidi Al Janubi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3270",
    "DISTRICTNAME_AR": "عشران",
    "DISTRICTNAME_EN": "Eashran",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3271",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3272",
    "DISTRICTNAME_AR": "الجواهر",
    "DISTRICTNAME_EN": "Al Jawahir",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3273",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3274",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3275",
    "DISTRICTNAME_AR": "الناصفة",
    "DISTRICTNAME_EN": "An Nasifah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3277",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3278",
    "DISTRICTNAME_AR": "أم السلم",
    "DISTRICTNAME_EN": "Umm Asalam",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3279",
    "DISTRICTNAME_AR": "عبلية",
    "DISTRICTNAME_EN": "Eiblia",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3280",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3285",
    "DISTRICTNAME_AR": "مشرف",
    "DISTRICTNAME_EN": "Mishrif",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3287",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3288",
    "DISTRICTNAME_AR": "السهباء",
    "DISTRICTNAME_EN": "Al Sahba",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3293",
    "DISTRICTNAME_AR": "العود",
    "DISTRICTNAME_EN": "Al Oud",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3294",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3295",
    "DISTRICTNAME_AR": "الثريا 1",
    "DISTRICTNAME_EN": "Ath Thuraiya 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3296",
    "DISTRICTNAME_AR": "السعادة",
    "DISTRICTNAME_EN": "Al Saadah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3297",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3298",
    "DISTRICTNAME_AR": "أم سرار",
    "DISTRICTNAME_EN": "Umm Srar",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3299",
    "DISTRICTNAME_AR": "الضاحي الغربي",
    "DISTRICTNAME_EN": "Ad Dahi Al Gharbi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3300",
    "DISTRICTNAME_AR": "النظيم",
    "DISTRICTNAME_EN": "An Nadheem",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3302",
    "DISTRICTNAME_AR": "الطلعة",
    "DISTRICTNAME_EN": "At Talaah",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3303",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3304",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3305",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3306",
    "DISTRICTNAME_AR": "مخطط رقم 928",
    "DISTRICTNAME_EN": "Subdivision No. 928",
    "CITY_ID": "21312",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3307",
    "DISTRICTNAME_AR": "آل عريمة",
    "DISTRICTNAME_EN": "Al Arima",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3308",
    "DISTRICTNAME_AR": "طويق ",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3309",
    "DISTRICTNAME_AR": "ال هذال",
    "DISTRICTNAME_EN": "Al Hadhal",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3310",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3311",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3313",
    "DISTRICTNAME_AR": "الزاهر",
    "DISTRICTNAME_EN": "Az Zahir",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3314",
    "DISTRICTNAME_AR": "العفجه",
    "DISTRICTNAME_EN": "Al Afajh",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3315",
    "DISTRICTNAME_AR": "القطار",
    "DISTRICTNAME_EN": "Al Qitar",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3317",
    "DISTRICTNAME_AR": "الناصفة",
    "DISTRICTNAME_EN": "An Nasifah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3324",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3325",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3326",
    "DISTRICTNAME_AR": "المليليح",
    "DISTRICTNAME_EN": "Al Mulialih",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3327",
    "DISTRICTNAME_AR": "النصبة",
    "DISTRICTNAME_EN": "An Nasbah",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3328",
    "DISTRICTNAME_AR": "الفرعة",
    "DISTRICTNAME_EN": "Al Farah",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3329",
    "DISTRICTNAME_AR": "نجد",
    "DISTRICTNAME_EN": "Najd",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3330",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3331",
    "DISTRICTNAME_AR": "المعيزيلة",
    "DISTRICTNAME_EN": "Al Maizialah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3332",
    "DISTRICTNAME_AR": "الجمالين",
    "DISTRICTNAME_EN": "Jamalin",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3333",
    "DISTRICTNAME_AR": "مخطط رقم 359",
    "DISTRICTNAME_EN": "Subdivision No. 359",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3334",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3335",
    "DISTRICTNAME_AR": "الاصفير",
    "DISTRICTNAME_EN": "Al Usfayr",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3338",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3340",
    "DISTRICTNAME_AR": "العدامة",
    "DISTRICTNAME_EN": "Al Adamah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3343",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3344",
    "DISTRICTNAME_AR": "العالية",
    "DISTRICTNAME_EN": "Al Aliyah",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3347",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "6166",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3348",
    "DISTRICTNAME_AR": "العوالى",
    "DISTRICTNAME_EN": "Al Awaly",
    "CITY_ID": "14722",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3349",
    "DISTRICTNAME_AR": "ال قيشه ",
    "DISTRICTNAME_EN": "Al Qayshah",
    "CITY_ID": "6053",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3350",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3351",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3352",
    "DISTRICTNAME_AR": "جامعة جازان",
    "DISTRICTNAME_EN": "Gazan University",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3353",
    "DISTRICTNAME_AR": "الخالدية 1",
    "DISTRICTNAME_EN": "Al Khalidiyah 1",
    "CITY_ID": "15716",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "3355",
    "DISTRICTNAME_AR": "البديع الشرقى",
    "DISTRICTNAME_EN": "Al Badi Al Sharqi",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3356",
    "DISTRICTNAME_AR": "قرية بديدة علو غثاة",
    "DISTRICTNAME_EN": "Village Badida Ealu Ghuthatan",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3357",
    "DISTRICTNAME_AR": "كميت",
    "DISTRICTNAME_EN": "Kumet",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3358",
    "DISTRICTNAME_AR": "الربعة",
    "DISTRICTNAME_EN": "Al Rubea",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3359",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3360",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3361",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3362",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3363",
    "DISTRICTNAME_AR": "سلام",
    "DISTRICTNAME_EN": "Salam",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3364",
    "DISTRICTNAME_AR": "المعذر الشمالي",
    "DISTRICTNAME_EN": "North Mathar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3365",
    "DISTRICTNAME_AR": "المدينة الصناعية الجديدة",
    "DISTRICTNAME_EN": "New Industrial City",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3366",
    "DISTRICTNAME_AR": "الوسيطاء",
    "DISTRICTNAME_EN": "Al Wusayta",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3367",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3368",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "Al Marqab",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3369",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3370",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3371",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3372",
    "DISTRICTNAME_AR": "شخيب",
    "DISTRICTNAME_EN": "Shukhib",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3373",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3374",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3375",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3376",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3377",
    "DISTRICTNAME_AR": "مخطط 590",
    "DISTRICTNAME_EN": "Subdivision 590",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3378",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3379",
    "DISTRICTNAME_AR": "جفارة الجبيري",
    "DISTRICTNAME_EN": "Jfara Al Jubeiry",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3380",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3381",
    "DISTRICTNAME_AR": "اللؤلؤة",
    "DISTRICTNAME_EN": "Al Luluah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3382",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3383",
    "DISTRICTNAME_AR": "البقاع",
    "DISTRICTNAME_EN": "Al Biqa",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3384",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3385",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3386",
    "DISTRICTNAME_AR": "مخطط 907 مشرفة",
    "DISTRICTNAME_EN": "Subdivision 907 Musharafa",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3387",
    "DISTRICTNAME_AR": "روافة",
    "DISTRICTNAME_EN": "Rawafa",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3388",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3389",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3390",
    "DISTRICTNAME_AR": "الضباط",
    "DISTRICTNAME_EN": "Al Dhubbat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3391",
    "DISTRICTNAME_AR": "السبخة",
    "DISTRICTNAME_EN": "Al Sabakhah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3392",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3393",
    "DISTRICTNAME_AR": "الخليفة",
    "DISTRICTNAME_EN": "Al Khalifah",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3394",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3395",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3396",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3397",
    "DISTRICTNAME_AR": "517 مخطط رقم",
    "DISTRICTNAME_EN": "Subdivision 517",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3398",
    "DISTRICTNAME_AR": "البلدية",
    "DISTRICTNAME_EN": "Al Baladiyah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3399",
    "DISTRICTNAME_AR": "الريعانية",
    "DISTRICTNAME_EN": "Al Ra'aniyah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3400",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3402",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3403",
    "DISTRICTNAME_AR": "المستشفى الجامعي",
    "DISTRICTNAME_EN": "University Hospital",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3404",
    "DISTRICTNAME_AR": "الصناعية الأولى",
    "DISTRICTNAME_EN": "1st Industrial",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3405",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3406",
    "DISTRICTNAME_AR": "مصدة",
    "DISTRICTNAME_EN": "Masida",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3407",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3408",
    "DISTRICTNAME_AR": "المعارض",
    "DISTRICTNAME_EN": "Al Maarid",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3409",
    "DISTRICTNAME_AR": "البليدا",
    "DISTRICTNAME_EN": "Al Blaida",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3410",
    "DISTRICTNAME_AR": "الرديفة",
    "DISTRICTNAME_EN": "Ar Radifah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3411",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3412",
    "DISTRICTNAME_AR": "الشتينية",
    "DISTRICTNAME_EN": "Al Shatinia",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3413",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "21296",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3414",
    "DISTRICTNAME_AR": "العلوات",
    "DISTRICTNAME_EN": "Al Ealawat",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3415",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3416",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3417",
    "DISTRICTNAME_AR": "الدرع",
    "DISTRICTNAME_EN": "Ad Dara",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3419",
    "DISTRICTNAME_AR": "القيسية",
    "DISTRICTNAME_EN": "Al Qaisiya",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3420",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3421",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3422",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3423",
    "DISTRICTNAME_AR": "الحديدية",
    "DISTRICTNAME_EN": "Al Hadidia",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3424",
    "DISTRICTNAME_AR": "أبو سليم",
    "DISTRICTNAME_EN": "Abu Sleem",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3425",
    "DISTRICTNAME_AR": "الديرة القديمة",
    "DISTRICTNAME_EN": "Ad Dirah Al Qadimah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3426",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3427",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3428",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3429",
    "DISTRICTNAME_AR": "صناعية الحديدية",
    "DISTRICTNAME_EN": "Sinaiyah Al Hadidia",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3430",
    "DISTRICTNAME_AR": "الركية",
    "DISTRICTNAME_EN": "Ar Rukiyah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3431",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3432",
    "DISTRICTNAME_AR": "سوق الماشية1",
    "DISTRICTNAME_EN": "Suq Al Mashiya1",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3433",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3434",
    "DISTRICTNAME_AR": "العماجية",
    "DISTRICTNAME_EN": "Al Ammajiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3435",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3436",
    "DISTRICTNAME_AR": "أم سليم",
    "DISTRICTNAME_EN": "Umm Selim",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3437",
    "DISTRICTNAME_AR": "الجزر",
    "DISTRICTNAME_EN": "Al Jazur",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3438",
    "DISTRICTNAME_AR": "الوسيطاء",
    "DISTRICTNAME_EN": "Al Wusayta",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3439",
    "DISTRICTNAME_AR": "نتيقة",
    "DISTRICTNAME_EN": "Netica",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3440",
    "DISTRICTNAME_AR": "وسيلة",
    "DISTRICTNAME_EN": "Wasila",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3441",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3442",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3443",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3444",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3445",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3446",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3447",
    "DISTRICTNAME_AR": "الدفاع",
    "DISTRICTNAME_EN": "Ad Difa",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3448",
    "DISTRICTNAME_AR": "المربع",
    "DISTRICTNAME_EN": "Al Murabba",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3449",
    "DISTRICTNAME_AR": "مخطط الفيضة القديم",
    "DISTRICTNAME_EN": "Al Faydat Al Qadim Subdivision",
    "CITY_ID": "20075",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3450",
    "DISTRICTNAME_AR": "مخطط 423",
    "DISTRICTNAME_EN": "Subdivision 423",
    "CITY_ID": "19939",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3451",
    "DISTRICTNAME_AR": "مخطط 303",
    "DISTRICTNAME_EN": "Subdivision 303",
    "CITY_ID": "19913",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3452",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3453",
    "DISTRICTNAME_AR": "واسط",
    "DISTRICTNAME_EN": "Wasit",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3454",
    "DISTRICTNAME_AR": "العريجاء الغربي",
    "DISTRICTNAME_EN": "West Al Uraija",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3455",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3456",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3457",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3458",
    "DISTRICTNAME_AR": "الوشام",
    "DISTRICTNAME_EN": "Al Wisham",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3459",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3460",
    "DISTRICTNAME_AR": "المناخ",
    "DISTRICTNAME_EN": "Al Manakh",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3461",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "An Nafl",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3462",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3463",
    "DISTRICTNAME_AR": "القارة",
    "DISTRICTNAME_EN": "Al Qarah",
    "CITY_ID": "20066",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3464",
    "DISTRICTNAME_AR": "البوطة",
    "DISTRICTNAME_EN": "Al Butah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3465",
    "DISTRICTNAME_AR": "ظهره العودة غرب",
    "DISTRICTNAME_EN": "Dhahrat Al Awdat Gharb",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3466",
    "DISTRICTNAME_AR": "الصايغية",
    "DISTRICTNAME_EN": "Al Saayghia",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3467",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3468",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3469",
    "DISTRICTNAME_AR": "مخطط رقم 1067",
    "DISTRICTNAME_EN": "Subdivision No. 1067",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3470",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3471",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3472",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3474",
    "DISTRICTNAME_AR": "القسيمة",
    "DISTRICTNAME_EN": "Al Qasima",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3475",
    "DISTRICTNAME_AR": "الملك سلمان",
    "DISTRICTNAME_EN": "King Salman",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3476",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3477",
    "DISTRICTNAME_AR": "مخطط 144",
    "DISTRICTNAME_EN": "Subdivision 144",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3478",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3479",
    "DISTRICTNAME_AR": "الفيضة",
    "DISTRICTNAME_EN": "Al Faydah ",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3480",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3481",
    "DISTRICTNAME_AR": "عشيرة",
    "DISTRICTNAME_EN": "Ushayra",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3482",
    "DISTRICTNAME_AR": "مخطط 739",
    "DISTRICTNAME_EN": "Subdivision 739",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3483",
    "DISTRICTNAME_AR": "مخطط541",
    "DISTRICTNAME_EN": "Subdivision 541",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3484",
    "DISTRICTNAME_AR": "رواق الشرقي",
    "DISTRICTNAME_EN": "Rawaq Al Sharqi",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3485",
    "DISTRICTNAME_AR": "مخطط رقم 1111",
    "DISTRICTNAME_EN": "Subdivision No. 1111",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3486",
    "DISTRICTNAME_AR": "حي ج18",
    "DISTRICTNAME_EN": "Dst C18",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3487",
    "DISTRICTNAME_AR": "الطريف",
    "DISTRICTNAME_EN": "Al Turaif",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3488",
    "DISTRICTNAME_AR": "الثليما",
    "DISTRICTNAME_EN": "Al Thilaima ",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3489",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3490",
    "DISTRICTNAME_AR": "الغطغط",
    "DISTRICTNAME_EN": "Al Ghataght",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3491",
    "DISTRICTNAME_AR": "حضار",
    "DISTRICTNAME_EN": "Hadar",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3492",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3493",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3494",
    "DISTRICTNAME_AR": "صقرة الشمالي",
    "DISTRICTNAME_EN": "Saqra Al Shamali",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3496",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "10105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3497",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3498",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3499",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3500",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3501",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3502",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3503",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3504",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3505",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3506",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3507",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3508",
    "DISTRICTNAME_AR": "الشرمان",
    "DISTRICTNAME_EN": "Al Sharman",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3509",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3510",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3511",
    "DISTRICTNAME_AR": "الحرمين",
    "DISTRICTNAME_EN": "Al Haramain",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3512",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3513",
    "DISTRICTNAME_AR": "حي ج7",
    "DISTRICTNAME_EN": "Dst C7",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3514",
    "DISTRICTNAME_AR": "النويعمة",
    "DISTRICTNAME_EN": "Al Nowema",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3515",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3516",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3517",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3518",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3519",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3520",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3521",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3522",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3523",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3524",
    "DISTRICTNAME_AR": "الشفاء - القارة",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "20066",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3525",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3526",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3527",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3528",
    "DISTRICTNAME_AR": "السويدي",
    "DISTRICTNAME_EN": "Al Suwaidi",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3529",
    "DISTRICTNAME_AR": "المرسى",
    "DISTRICTNAME_EN": "Al Marsaa",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3530",
    "DISTRICTNAME_AR": "ينبع البلد",
    "DISTRICTNAME_EN": "Yanbu Al Balad",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3531",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3532",
    "DISTRICTNAME_AR": "السلام ",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3533",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3535",
    "DISTRICTNAME_AR": "مخطط رقم 603",
    "DISTRICTNAME_EN": "Subdivision No. 603",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3536",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3537",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "17735",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3538",
    "DISTRICTNAME_AR": "الراشدية",
    "DISTRICTNAME_EN": "Ar Rashidyah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3539",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3540",
    "DISTRICTNAME_AR": "المسلخ",
    "DISTRICTNAME_EN": "Al Maslakh",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3541",
    "DISTRICTNAME_AR": "الدار البيضاء",
    "DISTRICTNAME_EN": "Al Dar Al Baida",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3542",
    "DISTRICTNAME_AR": "الفرعة",
    "DISTRICTNAME_EN": "Al Farah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3543",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3544",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3545",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3546",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3547",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3548",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3549",
    "DISTRICTNAME_AR": "البيان",
    "DISTRICTNAME_EN": "Al Bayan",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3550",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3551",
    "DISTRICTNAME_AR": "ظهرة لبن",
    "DISTRICTNAME_EN": "Dhahrat Laban",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3552",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3553",
    "DISTRICTNAME_AR": "المزرعة",
    "DISTRICTNAME_EN": "Al Mazraa",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3554",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3555",
    "DISTRICTNAME_AR": "مخطط رقم 946",
    "DISTRICTNAME_EN": "Subdivision No. 946",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3556",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3557",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3558",
    "DISTRICTNAME_AR": "1النخيل",
    "DISTRICTNAME_EN": "Al Nakhil1",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3559",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3560",
    "DISTRICTNAME_AR": "القرينة القديمة",
    "DISTRICTNAME_EN": "Al Qarina Al Qadima",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3561",
    "DISTRICTNAME_AR": "الاذاعة",
    "DISTRICTNAME_EN": "Al Idhaah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3562",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3563",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3564",
    "DISTRICTNAME_AR": "مخطط الاشعرية",
    "DISTRICTNAME_EN": "Ash'ari Subdivision",
    "CITY_ID": "20334",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3565",
    "DISTRICTNAME_AR": "مخطط الرميثي",
    "DISTRICTNAME_EN": "Al Rumaythi Subdivision",
    "CITY_ID": "20317",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3566",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3567",
    "DISTRICTNAME_AR": "البطين",
    "DISTRICTNAME_EN": "Al Batiyn",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3568",
    "DISTRICTNAME_AR": "عكاظ",
    "DISTRICTNAME_EN": "Uqaz",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3569",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3570",
    "DISTRICTNAME_AR": "سدير",
    "DISTRICTNAME_EN": "Sudayr",
    "CITY_ID": "21232",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3571",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3572",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3573",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "20320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3576",
    "DISTRICTNAME_AR": "العلاوة",
    "DISTRICTNAME_EN": "Al Allawah",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3577",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3578",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3579",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3580",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3581",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3582",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3583",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "21182",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3584",
    "DISTRICTNAME_AR": "سمنان",
    "DISTRICTNAME_EN": "Semnan",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3585",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "18088",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3586",
    "DISTRICTNAME_AR": "مخطط رقم 1037",
    "DISTRICTNAME_EN": "Subdivision No. 1037",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3587",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3588",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3589",
    "DISTRICTNAME_AR": "الهده",
    "DISTRICTNAME_EN": "Al Hadah",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3590",
    "DISTRICTNAME_AR": "ال وقيان",
    "DISTRICTNAME_EN": "Al Waqian",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3591",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3592",
    "DISTRICTNAME_AR": "الصقورية",
    "DISTRICTNAME_EN": "As Suquriyyah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3593",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3594",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3595",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3596",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3597",
    "DISTRICTNAME_AR": "الفروسية",
    "DISTRICTNAME_EN": "Al Frosyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3598",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3599",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3600",
    "DISTRICTNAME_AR": "العثمانية",
    "DISTRICTNAME_EN": "Al Uthmaniyah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3601",
    "DISTRICTNAME_AR": "غرب النابية",
    "DISTRICTNAME_EN": "Gharb An Nabiyah",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3602",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3603",
    "DISTRICTNAME_AR": "ام طلحة",
    "DISTRICTNAME_EN": "Umm Talha",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3604",
    "DISTRICTNAME_AR": "مخطط رقم 32",
    "DISTRICTNAME_EN": "Subdivision No. 32",
    "CITY_ID": "16105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3605",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3606",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3607",
    "DISTRICTNAME_AR": "الخزيمي",
    "DISTRICTNAME_EN": "Al Khuzimi",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3608",
    "DISTRICTNAME_AR": "غصيبة",
    "DISTRICTNAME_EN": "Ghusaiba",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3609",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3610",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3611",
    "DISTRICTNAME_AR": "أم سريحة",
    "DISTRICTNAME_EN": "Umm Sariha",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3612",
    "DISTRICTNAME_AR": "الجنوبي",
    "DISTRICTNAME_EN": "Al Janubi",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3613",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20633",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3614",
    "DISTRICTNAME_AR": "حي ج10",
    "DISTRICTNAME_EN": "Dst C10",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3615",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3616",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20276",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3617",
    "DISTRICTNAME_AR": "حي ج12",
    "DISTRICTNAME_EN": "Dst C12",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3618",
    "DISTRICTNAME_AR": "الديرة القديمة",
    "DISTRICTNAME_EN": "Ad Dirah Al Qadimah",
    "CITY_ID": "20276",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3619",
    "DISTRICTNAME_AR": "الزيتونة",
    "DISTRICTNAME_EN": "Al Zaytunah",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3620",
    "DISTRICTNAME_AR": "السدرة",
    "DISTRICTNAME_EN": "Al Sidrah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3621",
    "DISTRICTNAME_AR": "شبرا",
    "DISTRICTNAME_EN": "Shubra",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3622",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3623",
    "DISTRICTNAME_AR": "صياح",
    "DISTRICTNAME_EN": "Siyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3624",
    "DISTRICTNAME_AR": "العمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3625",
    "DISTRICTNAME_AR": "المؤتمرات",
    "DISTRICTNAME_EN": "Al Mutamarat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3626",
    "DISTRICTNAME_AR": "العريجاء الأوسط",
    "DISTRICTNAME_EN": "Middle Al Uraija",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3627",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3628",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3629",
    "DISTRICTNAME_AR": "المهدية",
    "DISTRICTNAME_EN": "Al Mahdiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3630",
    "DISTRICTNAME_AR": "مخطط رقم 456",
    "DISTRICTNAME_EN": "Subdivision No. 456",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3631",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3632",
    "DISTRICTNAME_AR": "مخطط رقم 0941",
    "DISTRICTNAME_EN": "Subdivision No. 0941",
    "CITY_ID": "21072",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3633",
    "DISTRICTNAME_AR": "مخطط رقم 0824",
    "DISTRICTNAME_EN": "Subdivision No. 0824",
    "CITY_ID": "21072",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3634",
    "DISTRICTNAME_AR": "مخطط رقم 126",
    "DISTRICTNAME_EN": "Subdivision No. 126",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3635",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3636",
    "DISTRICTNAME_AR": "الشعيفان",
    "DISTRICTNAME_EN": "Al Shaaifan",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3637",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3638",
    "DISTRICTNAME_AR": "خشم العان",
    "DISTRICTNAME_EN": "Khashm Al An",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3639",
    "DISTRICTNAME_AR": "هيت",
    "DISTRICTNAME_EN": "Hyt",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3640",
    "DISTRICTNAME_AR": "الضبية",
    "DISTRICTNAME_EN": "Al Dabih",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3641",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3642",
    "DISTRICTNAME_AR": "البرية",
    "DISTRICTNAME_EN": "Al Bariyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3643",
    "DISTRICTNAME_AR": "السرحية",
    "DISTRICTNAME_EN": "Al Sarhiyah",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3644",
    "DISTRICTNAME_AR": "الملقا",
    "DISTRICTNAME_EN": "Al Malqa",
    "CITY_ID": "21200",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3645",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3646",
    "DISTRICTNAME_AR": "الخماسين الجنوبي",
    "DISTRICTNAME_EN": "Southern Fifties",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3647",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3648",
    "DISTRICTNAME_AR": "آل ناهش",
    "DISTRICTNAME_EN": "Al Nahsh",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3649",
    "DISTRICTNAME_AR": "أم الحمام الشرقي",
    "DISTRICTNAME_EN": "Umm Al Hamam Eastern",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3650",
    "DISTRICTNAME_AR": "مغرزات",
    "DISTRICTNAME_EN": "Al Mughrazat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3651",
    "DISTRICTNAME_AR": "الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3652",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3653",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "Tuwaiq",
    "CITY_ID": "21282",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3654",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3655",
    "DISTRICTNAME_AR": "الملك سلمان",
    "DISTRICTNAME_EN": "King Salman",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3656",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3657",
    "DISTRICTNAME_AR": "ال فهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3658",
    "DISTRICTNAME_AR": "مخطط رقم 18",
    "DISTRICTNAME_EN": "Subdivision No. 18",
    "CITY_ID": "16105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3659",
    "DISTRICTNAME_AR": "حي 1156",
    "DISTRICTNAME_EN": "Dst 1156",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3660",
    "DISTRICTNAME_AR": "مخطط 555",
    "DISTRICTNAME_EN": "Subdivision 555",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3661",
    "DISTRICTNAME_AR": "العمدة",
    "DISTRICTNAME_EN": "Al Umda",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3662",
    "DISTRICTNAME_AR": "صناعية مزعل",
    "DISTRICTNAME_EN": "Sinaiyah Mazal",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3663",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3664",
    "DISTRICTNAME_AR": "رميثان",
    "DISTRICTNAME_EN": "Rumaythan",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3665",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3666",
    "DISTRICTNAME_AR": "حي ج15",
    "DISTRICTNAME_EN": "Dst C15",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3667",
    "DISTRICTNAME_AR": "الهفوف",
    "DISTRICTNAME_EN": "Al Hafuf",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3668",
    "DISTRICTNAME_AR": "الفحيل",
    "DISTRICTNAME_EN": "Al Fahil",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3669",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3670",
    "DISTRICTNAME_AR": "آل معني",
    "DISTRICTNAME_EN": "Al Maeni",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3671",
    "DISTRICTNAME_AR": "مخطط 1149",
    "DISTRICTNAME_EN": "Subdivision 1149",
    "CITY_ID": "21232",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3674",
    "DISTRICTNAME_AR": "مخطط منيفة",
    "DISTRICTNAME_EN": "Munifa Subdivision",
    "CITY_ID": "20740",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3675",
    "DISTRICTNAME_AR": "الجفونية",
    "DISTRICTNAME_EN": "Al Jaffuniyyah",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3676",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3678",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3680",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3681",
    "DISTRICTNAME_AR": "زبدة",
    "DISTRICTNAME_EN": "Zubada",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3682",
    "DISTRICTNAME_AR": "جامعة الملك سعود",
    "DISTRICTNAME_EN": "King Saud University",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3683",
    "DISTRICTNAME_AR": "مخطط رقم 24",
    "DISTRICTNAME_EN": "Subdivision No. 24",
    "CITY_ID": "16161",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3684",
    "DISTRICTNAME_AR": "مخطط آل سلطان",
    "DISTRICTNAME_EN": "Subdivision Al Sultan",
    "CITY_ID": "21319",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3685",
    "DISTRICTNAME_AR": "مانح",
    "DISTRICTNAME_EN": "Manih",
    "CITY_ID": "20264",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3686",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "21323",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3687",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3688",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3689",
    "DISTRICTNAME_AR": "مخطط رقم 956",
    "DISTRICTNAME_EN": "Subdivision No. 956",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3690",
    "DISTRICTNAME_AR": "أم الشعال",
    "DISTRICTNAME_EN": "Umm Al Shaeal",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3691",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "6013",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "3692",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3693",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3694",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3695",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "21248",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3696",
    "DISTRICTNAME_AR": "ال دحيم",
    "DISTRICTNAME_EN": "Al Dahim",
    "CITY_ID": "21143",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3697",
    "DISTRICTNAME_AR": "النظيم",
    "DISTRICTNAME_EN": "An Nadheem",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3698",
    "DISTRICTNAME_AR": "ضاحية نمار",
    "DISTRICTNAME_EN": "Dahiyat Namar",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3699",
    "DISTRICTNAME_AR": "عريض",
    "DISTRICTNAME_EN": "Oraid",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3700",
    "DISTRICTNAME_AR": "الولامين",
    "DISTRICTNAME_EN": "Al Walamin",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3701",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "20451",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3702",
    "DISTRICTNAME_AR": "حي ج6",
    "DISTRICTNAME_EN": "Dst C6",
    "CITY_ID": "20297",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3703",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3704",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "King Abdullah",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3706",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "19352",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3707",
    "DISTRICTNAME_AR": "المصفاة",
    "DISTRICTNAME_EN": "Al Misfat",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3708",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3709",
    "DISTRICTNAME_AR": "المقابل",
    "DISTRICTNAME_EN": "Al Muqabil",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3710",
    "DISTRICTNAME_AR": "المستشفى",
    "DISTRICTNAME_EN": "The Hospital",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3711",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "17743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3712",
    "DISTRICTNAME_AR": "الازهار",
    "DISTRICTNAME_EN": "Al Azhar",
    "CITY_ID": "19269",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3713",
    "DISTRICTNAME_AR": "الشعيبة",
    "DISTRICTNAME_EN": "Al Shueayba",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3714",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3715",
    "DISTRICTNAME_AR": "سلام",
    "DISTRICTNAME_EN": "Salam",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3716",
    "DISTRICTNAME_AR": "الوصيل",
    "DISTRICTNAME_EN": "Al Wasil",
    "CITY_ID": "21194",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3717",
    "DISTRICTNAME_AR": "مخطط رقم 571 - مخطط رقم 928",
    "DISTRICTNAME_EN": "Subdivision No. 571",
    "CITY_ID": "21312",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "3718",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3719",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3720",
    "DISTRICTNAME_AR": "الخثلان",
    "DISTRICTNAME_EN": "Al Khathlan",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3721",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "19960",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3722",
    "DISTRICTNAME_AR": "الملك فيصل",
    "DISTRICTNAME_EN": "King Faisal ",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3723",
    "DISTRICTNAME_AR": "مخطط 679",
    "DISTRICTNAME_EN": "Subdivision 679",
    "CITY_ID": "11858",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3724",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3725",
    "DISTRICTNAME_AR": "الشرفية",
    "DISTRICTNAME_EN": "Ash Sharafiyah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3727",
    "DISTRICTNAME_AR": "الطرف",
    "DISTRICTNAME_EN": "Al Taraf",
    "CITY_ID": "21135",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3728",
    "DISTRICTNAME_AR": "مخطط رقم 30",
    "DISTRICTNAME_EN": "Subdivision No. 30",
    "CITY_ID": "16105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3729",
    "DISTRICTNAME_AR": "مخطط رقم 242",
    "DISTRICTNAME_EN": "Subdivision No. 242",
    "CITY_ID": "16105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3730",
    "DISTRICTNAME_AR": "مخطط رقم 214",
    "DISTRICTNAME_EN": "Subdivision No. 214",
    "CITY_ID": "16105",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3731",
    "DISTRICTNAME_AR": "مشيرفة",
    "DISTRICTNAME_EN": "Mishyrifah",
    "CITY_ID": "21151",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3732",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3733",
    "DISTRICTNAME_AR": "الصدر",
    "DISTRICTNAME_EN": "Al Sadr",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3734",
    "DISTRICTNAME_AR": "مخطط رقم 333",
    "DISTRICTNAME_EN": "Subdivision No. 333",
    "CITY_ID": "21312",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3735",
    "DISTRICTNAME_AR": "الغريبية",
    "DISTRICTNAME_EN": "Al Gharibia",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3736",
    "DISTRICTNAME_AR": "النقيعه",
    "DISTRICTNAME_EN": "Al Naqieuh",
    "CITY_ID": "20883",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3737",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "20264",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3738",
    "DISTRICTNAME_AR": "السمرية",
    "DISTRICTNAME_EN": "Al Suwmaria",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3740",
    "DISTRICTNAME_AR": "وسط المدينة",
    "DISTRICTNAME_EN": "City Center",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3741",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3742",
    "DISTRICTNAME_AR": "مطار الأمير سلطان بن عبدالعزيز الدولى",
    "DISTRICTNAME_EN": "Prince Sultan bin Abdulaziz International Airport",
    "CITY_ID": "19375",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "3743",
    "DISTRICTNAME_AR": "الغراء",
    "DISTRICTNAME_EN": "Al Gharaa",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3744",
    "DISTRICTNAME_AR": "الرمانة",
    "DISTRICTNAME_EN": "Ar Rumanah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3745",
    "DISTRICTNAME_AR": "التلعة",
    "DISTRICTNAME_EN": "Al Taliea",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3746",
    "DISTRICTNAME_AR": "الغابة",
    "DISTRICTNAME_EN": "Al Ghabah",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3747",
    "DISTRICTNAME_AR": "القبلتين",
    "DISTRICTNAME_EN": "Al Qiblatayn",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3748",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3749",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3750",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3751",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3752",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3753",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3754",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3755",
    "DISTRICTNAME_AR": "الحرم ",
    "DISTRICTNAME_EN": "Al Haram",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "3756",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3757",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3758",
    "DISTRICTNAME_AR": "الأشرفية",
    "DISTRICTNAME_EN": "Alashrafia",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3759",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3760",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3761",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3762",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "Ohod",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3763",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3764",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3765",
    "DISTRICTNAME_AR": "النظيم",
    "DISTRICTNAME_EN": "An Nadheem",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3766",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3767",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3768",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3769",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3770",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3771",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3772",
    "DISTRICTNAME_AR": "سماح",
    "DISTRICTNAME_EN": "Samah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3773",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3774",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3775",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3776",
    "DISTRICTNAME_AR": "الوفاء",
    "DISTRICTNAME_EN": "Al Wafa",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3777",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3778",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3779",
    "DISTRICTNAME_AR": "الملك خالد",
    "DISTRICTNAME_EN": "King Khalid",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3780",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3781",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3782",
    "DISTRICTNAME_AR": "الجلدة",
    "DISTRICTNAME_EN": "Aj Jilda",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3783",
    "DISTRICTNAME_AR": "الجامعي",
    "DISTRICTNAME_EN": "Al Jamie",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3784",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3785",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3786",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3787",
    "DISTRICTNAME_AR": "شراف",
    "DISTRICTNAME_EN": "Sharaf",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3788",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3789",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3790",
    "DISTRICTNAME_AR": "الرياضي",
    "DISTRICTNAME_EN": "Sports",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3791",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3792",
    "DISTRICTNAME_AR": "الدرة",
    "DISTRICTNAME_EN": "Al Durra",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3793",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "15718",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3794",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3795",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3796",
    "DISTRICTNAME_AR": "العيون",
    "DISTRICTNAME_EN": "Al Uyun",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3797",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3799",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3800",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "14237",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3802",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3803",
    "DISTRICTNAME_AR": "المدينة الجديدة",
    "DISTRICTNAME_EN": "Al Madinah Al Jadidah",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3804",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "20210",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3805",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3806",
    "DISTRICTNAME_AR": "الفاخرية",
    "DISTRICTNAME_EN": "Al Fakhriyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3807",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3808",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "Al Khazzan",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3809",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3814",
    "DISTRICTNAME_AR": "الحنابجة",
    "DISTRICTNAME_EN": "Al Hanabija",
    "CITY_ID": "20743",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3815",
    "DISTRICTNAME_AR": "ظهرة البديعة",
    "DISTRICTNAME_EN": "Dhahrat Al Badeah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3816",
    "DISTRICTNAME_AR": "الهدية",
    "DISTRICTNAME_EN": "Al Hadia",
    "CITY_ID": "21116",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3817",
    "DISTRICTNAME_AR": "زبيدة",
    "DISTRICTNAME_EN": "Zubaydah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3818",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3819",
    "DISTRICTNAME_AR": "النفيد",
    "DISTRICTNAME_EN": "Al Nafid",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3820",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3821",
    "DISTRICTNAME_AR": "الشمالية",
    "DISTRICTNAME_EN": "Ash Shamaliyah",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3823",
    "DISTRICTNAME_AR": "العقيشية",
    "DISTRICTNAME_EN": "Al Aqishiya",
    "CITY_ID": "20659",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3824",
    "DISTRICTNAME_AR": "مخطط رقم 995",
    "DISTRICTNAME_EN": "Subdivision No. 995",
    "CITY_ID": "20806",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3825",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "21348",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3826",
    "DISTRICTNAME_AR": "الصفراء",
    "DISTRICTNAME_EN": "Al Safra",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3827",
    "DISTRICTNAME_AR": "البويطن",
    "DISTRICTNAME_EN": "Al Buaiten",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3829",
    "DISTRICTNAME_AR": "الملك سلمان بن عبد العزيز",
    "DISTRICTNAME_EN": "King Salman bin Abdulaziz",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3830",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3831",
    "DISTRICTNAME_AR": "وسط المدينة",
    "DISTRICTNAME_EN": "City Center",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3832",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3833",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "20505",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3834",
    "DISTRICTNAME_AR": "الشميسي",
    "DISTRICTNAME_EN": "Al Shumaisi",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3835",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "21298",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3836",
    "DISTRICTNAME_AR": "الفوطة",
    "DISTRICTNAME_EN": "Al Futah",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3837",
    "DISTRICTNAME_AR": "المعالي",
    "DISTRICTNAME_EN": "Al Maali",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3838",
    "DISTRICTNAME_AR": "الوهلان",
    "DISTRICTNAME_EN": "Al Wahlan",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3839",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3840",
    "DISTRICTNAME_AR": "العونية",
    "DISTRICTNAME_EN": "Al Awniyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3841",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3842",
    "DISTRICTNAME_AR": "مخطط رقم 198",
    "DISTRICTNAME_EN": "Subdivision No. 198",
    "CITY_ID": "20308",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "3843",
    "DISTRICTNAME_AR": "الحاجب",
    "DISTRICTNAME_EN": "Al-Hajib",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3844",
    "DISTRICTNAME_AR": "الدوحة",
    "DISTRICTNAME_EN": "Ad Dawhah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3845",
    "DISTRICTNAME_AR": "الرايسية",
    "DISTRICTNAME_EN": "Ar Raaysia",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3846",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3847",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3848",
    "DISTRICTNAME_AR": "سنام",
    "DISTRICTNAME_EN": "Sonam",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3849",
    "DISTRICTNAME_AR": "الحفيرة",
    "DISTRICTNAME_EN": "Al Hufayrah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3850",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3851",
    "DISTRICTNAME_AR": "المهندسين",
    "DISTRICTNAME_EN": "Al Muhandisin",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3852",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3853",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3854",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "11877",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3855",
    "DISTRICTNAME_AR": "الرحمانية",
    "DISTRICTNAME_EN": "Ar Rahmanyah",
    "CITY_ID": "6114",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "3856",
    "DISTRICTNAME_AR": "البدرية",
    "DISTRICTNAME_EN": "Al Badriyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3857",
    "DISTRICTNAME_AR": "بلدة القارة",
    "DISTRICTNAME_EN": "Al Qara Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3858",
    "DISTRICTNAME_AR": "قباء",
    "DISTRICTNAME_EN": "Quba",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3859",
    "DISTRICTNAME_AR": "الرقيقة",
    "DISTRICTNAME_EN": "Ar Ruqaiqah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3860",
    "DISTRICTNAME_AR": "العليا1",
    "DISTRICTNAME_EN": "Al Olaya1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3861",
    "DISTRICTNAME_AR": "البستان1",
    "DISTRICTNAME_EN": "Al Bustan1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3862",
    "DISTRICTNAME_AR": "قرية العمران الغربية",
    "DISTRICTNAME_EN": "Qaryat Al Omran West",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3865",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3866",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3870",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3871",
    "DISTRICTNAME_AR": "الصفاة",
    "DISTRICTNAME_EN": "Al Safat",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3872",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3878",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3887",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3888",
    "DISTRICTNAME_AR": "البحر",
    "DISTRICTNAME_EN": "Al Bahar",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3889",
    "DISTRICTNAME_AR": "الطوية",
    "DISTRICTNAME_EN": "Al Tawyah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3893",
    "DISTRICTNAME_AR": "المرقاب",
    "DISTRICTNAME_EN": "Al Merqab",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3894",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3895",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3896",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3897",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3898",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3899",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3900",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3907",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3908",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3909",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3910",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3911",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3914",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3915",
    "DISTRICTNAME_AR": "الأول",
    "DISTRICTNAME_EN": "Al Awal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3916",
    "DISTRICTNAME_AR": "المدينة المنورة",
    "DISTRICTNAME_EN": "Al Madinah Al Munawwarah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3917",
    "DISTRICTNAME_AR": "محاسن ارامكو الخامس",
    "DISTRICTNAME_EN": "Mahasin Aramco 5th ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3918",
    "DISTRICTNAME_AR": "الحزم الشمالي",
    "DISTRICTNAME_EN": "Al Hazm Al Shimali",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3919",
    "DISTRICTNAME_AR": "بلدة غمسي",
    "DISTRICTNAME_EN": "Ghomsi Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3920",
    "DISTRICTNAME_AR": "المباركية",
    "DISTRICTNAME_EN": "Al Mubarkiah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3921",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3922",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3923",
    "DISTRICTNAME_AR": "منطقة الغويبة الزراعية",
    "DISTRICTNAME_EN": "Alghawbia Agricultural Area",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3924",
    "DISTRICTNAME_AR": "الخيام",
    "DISTRICTNAME_EN": "Al Khiam",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3925",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3927",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3928",
    "DISTRICTNAME_AR": "بلدة الجبيل",
    "DISTRICTNAME_EN": "Jubail Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3929",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3930",
    "DISTRICTNAME_AR": "السهلة",
    "DISTRICTNAME_EN": "Al Sahlah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3931",
    "DISTRICTNAME_AR": "الكلابية",
    "DISTRICTNAME_EN": "Alkilabia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3932",
    "DISTRICTNAME_AR": "الرويضة",
    "DISTRICTNAME_EN": "Ar Ruwaida",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3933",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3934",
    "DISTRICTNAME_AR": "قرية العمران الشرقية",
    "DISTRICTNAME_EN": "Qaryat Al Omran East",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3935",
    "DISTRICTNAME_AR": "الزهراء1",
    "DISTRICTNAME_EN": "Az Zahra1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3936",
    "DISTRICTNAME_AR": "هجر الثالث",
    "DISTRICTNAME_EN": "Hajr Al Thalith",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3937",
    "DISTRICTNAME_AR": "بلدة الطرف",
    "DISTRICTNAME_EN": "Al Taraf Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3938",
    "DISTRICTNAME_AR": "بلدة العقار",
    "DISTRICTNAME_EN": "Al Eaqar Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3939",
    "DISTRICTNAME_AR": "القادسية1",
    "DISTRICTNAME_EN": "Al Qadisiyah1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3940",
    "DISTRICTNAME_AR": "المزروعية",
    "DISTRICTNAME_EN": "Al Mazruiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3941",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3942",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3943",
    "DISTRICTNAME_AR": "أم خريسان",
    "DISTRICTNAME_EN": "Umm Khraisan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3944",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3945",
    "DISTRICTNAME_AR": "وسط المبرز التاريخي",
    "DISTRICTNAME_EN": "Historical Center Al Mubarraz ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3946",
    "DISTRICTNAME_AR": "مجمع الدوائر الحكومية الثاني",
    "DISTRICTNAME_EN": "Government Departments Complex 2nd ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3947",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3948",
    "DISTRICTNAME_AR": "منسوبي التعليم الثاني",
    "DISTRICTNAME_EN": "Mansubi Al Taleem 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3949",
    "DISTRICTNAME_AR": "بلدة الوزية",
    "DISTRICTNAME_EN": "Al Waziah Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3950",
    "DISTRICTNAME_AR": "الصحافة",
    "DISTRICTNAME_EN": "Al Sahafah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3951",
    "DISTRICTNAME_AR": "المعلمين",
    "DISTRICTNAME_EN": "Al Muallimeen ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3952",
    "DISTRICTNAME_AR": "الحي الأكاديمي",
    "DISTRICTNAME_EN": "Academic Quarter",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3953",
    "DISTRICTNAME_AR": "بلدة المركز",
    "DISTRICTNAME_EN": "Al Markaz Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3954",
    "DISTRICTNAME_AR": "الحمراء الثالث",
    "DISTRICTNAME_EN": "Al Hamra 3rd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3955",
    "DISTRICTNAME_AR": "بلدة المطيرفي",
    "DISTRICTNAME_EN": "Al-Mutarifi Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3956",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3957",
    "DISTRICTNAME_AR": "البندرية",
    "DISTRICTNAME_EN": "Al Bandariyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3958",
    "DISTRICTNAME_AR": "النعاثل",
    "DISTRICTNAME_EN": "Al Naeathil",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3959",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3960",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3961",
    "DISTRICTNAME_AR": "الصفاء الثالث",
    "DISTRICTNAME_EN": "As Safa Al Thaalith",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3962",
    "DISTRICTNAME_AR": "النزهة1",
    "DISTRICTNAME_EN": "An Nuzhah1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3963",
    "DISTRICTNAME_AR": "الصفاء الرابع",
    "DISTRICTNAME_EN": "As Safa Al Raabie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3964",
    "DISTRICTNAME_AR": "الزهرة",
    "DISTRICTNAME_EN": "Az Zahrah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3965",
    "DISTRICTNAME_AR": "الراشدية الأول",
    "DISTRICTNAME_EN": "Ar Rashidyah 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3966",
    "DISTRICTNAME_AR": "السنيدية",
    "DISTRICTNAME_EN": "As Sunaidah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3967",
    "DISTRICTNAME_AR": "هجر السابع",
    "DISTRICTNAME_EN": "Hajar Al Saabie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3968",
    "DISTRICTNAME_AR": "البساتين1",
    "DISTRICTNAME_EN": "Al Basatin1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3969",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "Goverment Departments",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3970",
    "DISTRICTNAME_AR": "محاسن ارامكو الأول",
    "DISTRICTNAME_EN": "Mahasin Aramco 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3971",
    "DISTRICTNAME_AR": "بلدة التويثير",
    "DISTRICTNAME_EN": "Altwether Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3972",
    "DISTRICTNAME_AR": "الشعبة",
    "DISTRICTNAME_EN": "Al Shuaba",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3973",
    "DISTRICTNAME_AR": "الصقور",
    "DISTRICTNAME_EN": "As Suqur",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3974",
    "DISTRICTNAME_AR": "الورود الأول",
    "DISTRICTNAME_EN": "Al Wurud 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3975",
    "DISTRICTNAME_AR": "جامعة الملك فيصل الثاني",
    "DISTRICTNAME_EN": "King Faisal University 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3976",
    "DISTRICTNAME_AR": "لذة",
    "DISTRICTNAME_EN": "Ladhah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3977",
    "DISTRICTNAME_AR": "بلدة التهيمية",
    "DISTRICTNAME_EN": "Tahimiya Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3978",
    "DISTRICTNAME_AR": "المروج الجنوبي",
    "DISTRICTNAME_EN": "Al Muruj Al Junubi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3979",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3980",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3981",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3982",
    "DISTRICTNAME_AR": "هجر الثامن",
    "DISTRICTNAME_EN": "Hajr Al Thamin",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3983",
    "DISTRICTNAME_AR": "المعامرة",
    "DISTRICTNAME_EN": "Al Mueamara",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3984",
    "DISTRICTNAME_AR": "الفيصلية الأول",
    "DISTRICTNAME_EN": "Al Faisaliyah 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3985",
    "DISTRICTNAME_AR": "الرياض الثالث",
    "DISTRICTNAME_EN": "Ar Riyadh Al Thaalith",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3987",
    "DISTRICTNAME_AR": "هجر الثاني عشر",
    "DISTRICTNAME_EN": "Hajar Al Thani ashar",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3988",
    "DISTRICTNAME_AR": "مدينة التمور",
    "DISTRICTNAME_EN": "Madinat Al Tumur",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3989",
    "DISTRICTNAME_AR": "بلدة الشهارين",
    "DISTRICTNAME_EN": "Al Shaharayn Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3990",
    "DISTRICTNAME_AR": "هجر الأول",
    "DISTRICTNAME_EN": "Hajr Al Awal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3991",
    "DISTRICTNAME_AR": "بلدة المقدام",
    "DISTRICTNAME_EN": "Al Muqdam Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3992",
    "DISTRICTNAME_AR": "قرية الحوطة",
    "DISTRICTNAME_EN": "Qaryat Al Hawta",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3993",
    "DISTRICTNAME_AR": "بلدة المراح",
    "DISTRICTNAME_EN": "Al Marah Town",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3994",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3995",
    "DISTRICTNAME_AR": "السلام الأول",
    "DISTRICTNAME_EN": "As Salam 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3996",
    "DISTRICTNAME_AR": "الروضة2",
    "DISTRICTNAME_EN": "Ar Rawdah2",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3997",
    "DISTRICTNAME_AR": "بلدة الجفر",
    "DISTRICTNAME_EN": "Al Jafr Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3998",
    "DISTRICTNAME_AR": "منسوبي التعليم الأول",
    "DISTRICTNAME_EN": "Mansubi Al Taleem 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "3999",
    "DISTRICTNAME_AR": "بلدة الجشة",
    "DISTRICTNAME_EN": "Al Jishah Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4000",
    "DISTRICTNAME_AR": "الورود الثالث",
    "DISTRICTNAME_EN": "Al Wurud 3rd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4001",
    "DISTRICTNAME_AR": "الكادي",
    "DISTRICTNAME_EN": "Al Kadi",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4002",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4003",
    "DISTRICTNAME_AR": "الريف",
    "DISTRICTNAME_EN": "Ar Rif",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4004",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4005",
    "DISTRICTNAME_AR": "الخزام",
    "DISTRICTNAME_EN": "Al Khuzam",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4006",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4007",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4008",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4009",
    "DISTRICTNAME_AR": "الملك فهد",
    "DISTRICTNAME_EN": "King Fahd",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4010",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4011",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4012",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4017",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4018",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4019",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4021",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4022",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4023",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4030",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4031",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4035",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4038",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4040",
    "DISTRICTNAME_AR": "السلوى",
    "DISTRICTNAME_EN": "Al Salwaa",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4041",
    "DISTRICTNAME_AR": "الشعلة",
    "DISTRICTNAME_EN": "Al Sholah",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4042",
    "DISTRICTNAME_AR": "الأجيال",
    "DISTRICTNAME_EN": "Al Ajyal",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4043",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4044",
    "DISTRICTNAME_AR": "التيسير",
    "DISTRICTNAME_EN": "At Taysir",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4045",
    "DISTRICTNAME_AR": "التسامح",
    "DISTRICTNAME_EN": "Al Tasamuh",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4046",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "Al Qirawan",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4047",
    "DISTRICTNAME_AR": "الأريج",
    "DISTRICTNAME_EN": "Al Areej",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4048",
    "DISTRICTNAME_AR": "السراة",
    "DISTRICTNAME_EN": "As Sarat",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4049",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4050",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4051",
    "DISTRICTNAME_AR": "الميدان",
    "DISTRICTNAME_EN": "Al-Maydan",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4052",
    "DISTRICTNAME_AR": "الأفق",
    "DISTRICTNAME_EN": "Al Ufuq",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4053",
    "DISTRICTNAME_AR": "المدينة الصناعية",
    "DISTRICTNAME_EN": "Industrial City",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4054",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4055",
    "DISTRICTNAME_AR": "القاع 2",
    "DISTRICTNAME_EN": "Al Qaa 2",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4056",
    "DISTRICTNAME_AR": "البرج",
    "DISTRICTNAME_EN": "Al Burj",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4057",
    "DISTRICTNAME_AR": "الشاطئ 2",
    "DISTRICTNAME_EN": "Ash Shati 2",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4058",
    "DISTRICTNAME_AR": "الشاطئ 3",
    "DISTRICTNAME_EN": "Ash Shati 3",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4059",
    "DISTRICTNAME_AR": "آل زياد 2",
    "DISTRICTNAME_EN": "Al Ziyad 2",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4060",
    "DISTRICTNAME_AR": "منطقة الخدمات المركزية",
    "DISTRICTNAME_EN": "Central Services",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4061",
    "DISTRICTNAME_AR": "أحد بني زيد",
    "DISTRICTNAME_EN": "Ohod Bani Zayd",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4062",
    "DISTRICTNAME_AR": "القوز",
    "DISTRICTNAME_EN": "Al Quoz",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4063",
    "DISTRICTNAME_AR": "عنيكر",
    "DISTRICTNAME_EN": "Unaykar",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4064",
    "DISTRICTNAME_AR": "الجبيل-قنبور",
    "DISTRICTNAME_EN": "Al Jubail-Qanbour",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4065",
    "DISTRICTNAME_AR": "الجرد",
    "DISTRICTNAME_EN": "Al Jard",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4066",
    "DISTRICTNAME_AR": "مشرف",
    "DISTRICTNAME_EN": "Mishrif",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4067",
    "DISTRICTNAME_AR": "الجميعات",
    "DISTRICTNAME_EN": "Al Jumaymat",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4068",
    "DISTRICTNAME_AR": "ثلاثاء يبة",
    "DISTRICTNAME_EN": "Thulatha' ybaah",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4069",
    "DISTRICTNAME_AR": "الحبيلة",
    "DISTRICTNAME_EN": "Al Habila",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4070",
    "DISTRICTNAME_AR": "هشيمة",
    "DISTRICTNAME_EN": "Hashima",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4071",
    "DISTRICTNAME_AR": "السمرة",
    "DISTRICTNAME_EN": "As Sumra",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4072",
    "DISTRICTNAME_AR": "المناديل",
    "DISTRICTNAME_EN": "Al Manadil",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4073",
    "DISTRICTNAME_AR": "مخطط الخدمات المركزية",
    "DISTRICTNAME_EN": "Central Services Subdivision",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4074",
    "DISTRICTNAME_AR": "القحمان",
    "DISTRICTNAME_EN": "Al Qahman",
    "CITY_ID": "16877",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4075",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4076",
    "DISTRICTNAME_AR": "البرقاء",
    "DISTRICTNAME_EN": "Al Barqa",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4077",
    "DISTRICTNAME_AR": "المغاربة",
    "DISTRICTNAME_EN": "Al Magharbah",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4078",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4079",
    "DISTRICTNAME_AR": "الطلعة1",
    "DISTRICTNAME_EN": "At Talaah 1",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4081",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4082",
    "DISTRICTNAME_AR": "غران",
    "DISTRICTNAME_EN": "Gharan",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4083",
    "DISTRICTNAME_AR": "اليمانية",
    "DISTRICTNAME_EN": "Al Yamania",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4084",
    "DISTRICTNAME_AR": "صناعية اليمانية",
    "DISTRICTNAME_EN": "Sinaiyah Al Yamania",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4085",
    "DISTRICTNAME_AR": "الهجرة2",
    "DISTRICTNAME_EN": "Al Hijrah 2",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4086",
    "DISTRICTNAME_AR": "الهجرة1",
    "DISTRICTNAME_EN": "Al Hijrah 1",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4087",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4088",
    "DISTRICTNAME_AR": "الطلعة2",
    "DISTRICTNAME_EN": "At Talaah 2",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4089",
    "DISTRICTNAME_AR": "الحرة",
    "DISTRICTNAME_EN": "Al Hura",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4090",
    "DISTRICTNAME_AR": "الحرازات",
    "DISTRICTNAME_EN": "Al Harazat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4091",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4092",
    "DISTRICTNAME_AR": "البحيرات",
    "DISTRICTNAME_EN": "Al Buhayrat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4093",
    "DISTRICTNAME_AR": "جوهرة ثول",
    "DISTRICTNAME_EN": "Jawharat Thul",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4094",
    "DISTRICTNAME_AR": "المعرفة",
    "DISTRICTNAME_EN": "Al Maerifa",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4095",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4096",
    "DISTRICTNAME_AR": "القوس",
    "DISTRICTNAME_EN": "Al Qaws",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4097",
    "DISTRICTNAME_AR": "صناعي1",
    "DISTRICTNAME_EN": "Industrial1",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4098",
    "DISTRICTNAME_AR": "المزيرعة",
    "DISTRICTNAME_EN": "Al Muzayraa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4099",
    "DISTRICTNAME_AR": "حكومي3",
    "DISTRICTNAME_EN": "Governmental3",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4100",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4101",
    "DISTRICTNAME_AR": "العشيرية",
    "DISTRICTNAME_EN": "Al Ushiria",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4102",
    "DISTRICTNAME_AR": "الهزاعية",
    "DISTRICTNAME_EN": "Al Hazaeia",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4103",
    "DISTRICTNAME_AR": "المرسلات",
    "DISTRICTNAME_EN": "Al Mursalat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4104",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4105",
    "DISTRICTNAME_AR": "الخالدية ب",
    "DISTRICTNAME_EN": "Al Khalidiyah B",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4106",
    "DISTRICTNAME_AR": "الغربية",
    "DISTRICTNAME_EN": "Al Gharbia",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4107",
    "DISTRICTNAME_AR": "الخالدية أ",
    "DISTRICTNAME_EN": "Al Khalidiyah A",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4108",
    "DISTRICTNAME_AR": "الشرقية",
    "DISTRICTNAME_EN": "Sharqia",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4109",
    "DISTRICTNAME_AR": "عمرات",
    "DISTRICTNAME_EN": "Umrat",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4110",
    "DISTRICTNAME_AR": "الصالحي",
    "DISTRICTNAME_EN": "Al Salhi",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4111",
    "DISTRICTNAME_AR": "القاع 1",
    "DISTRICTNAME_EN": "Al Qaa 1",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4112",
    "DISTRICTNAME_AR": "آل زياد 1",
    "DISTRICTNAME_EN": "Al Ziyad 1",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4113",
    "DISTRICTNAME_AR": "الكورنيش",
    "DISTRICTNAME_EN": "Al Kurnaish",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4114",
    "DISTRICTNAME_AR": "رحمان",
    "DISTRICTNAME_EN": "Rahman",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4115",
    "DISTRICTNAME_AR": "الدعدي",
    "DISTRICTNAME_EN": "Al daadi",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4116",
    "DISTRICTNAME_AR": "وادي هارون",
    "DISTRICTNAME_EN": "Wadi Haroun",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4117",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4118",
    "DISTRICTNAME_AR": "الشاطئ 1",
    "DISTRICTNAME_EN": "Ash Shati 1",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4119",
    "DISTRICTNAME_AR": "الصدر",
    "DISTRICTNAME_EN": "Al Sadr",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4120",
    "DISTRICTNAME_AR": "الدف",
    "DISTRICTNAME_EN": "Ad Duf",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4121",
    "DISTRICTNAME_AR": "داربن جدة",
    "DISTRICTNAME_EN": "Darbin Jeddah",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4122",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "16655",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4123",
    "DISTRICTNAME_AR": "الشيوخ",
    "DISTRICTNAME_EN": "Ash Shiyukh",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4124",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4125",
    "DISTRICTNAME_AR": "السالمية",
    "DISTRICTNAME_EN": "As Salmiya",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4126",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4128",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4129",
    "DISTRICTNAME_AR": "الملك فهد بن عبدالعزيز",
    "DISTRICTNAME_EN": "King Fahd bin Abdulaziz",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4130",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4131",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4132",
    "DISTRICTNAME_AR": "الحفنة",
    "DISTRICTNAME_EN": "Al Hafna",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4133",
    "DISTRICTNAME_AR": "الحجاز",
    "DISTRICTNAME_EN": "Al Hijaz",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4134",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4135",
    "DISTRICTNAME_AR": "الوئام",
    "DISTRICTNAME_EN": "Al Wiam",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4136",
    "DISTRICTNAME_AR": "الثعالبة",
    "DISTRICTNAME_EN": "Ath Thaalibah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4137",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4138",
    "DISTRICTNAME_AR": "المحاميد",
    "DISTRICTNAME_EN": "Al Mahamid",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4139",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4140",
    "DISTRICTNAME_AR": "القوزين",
    "DISTRICTNAME_EN": "Al Qouzeen",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4141",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4142",
    "DISTRICTNAME_AR": "مطار الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz International Airport",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4143",
    "DISTRICTNAME_AR": "الهجرة",
    "DISTRICTNAME_EN": "Al Hijrah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4144",
    "DISTRICTNAME_AR": "الرواسي",
    "DISTRICTNAME_EN": "Ar Rawasi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4145",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4146",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4147",
    "DISTRICTNAME_AR": "البركة",
    "DISTRICTNAME_EN": "Al Barakah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4148",
    "DISTRICTNAME_AR": "الرحمة",
    "DISTRICTNAME_EN": "Ar Rahmah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4149",
    "DISTRICTNAME_AR": "الأمير عبدالمجيد",
    "DISTRICTNAME_EN": "Prince Abdulmajeed",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4150",
    "DISTRICTNAME_AR": "الكرامة",
    "DISTRICTNAME_EN": "Al Karamah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4151",
    "DISTRICTNAME_AR": "التضامن",
    "DISTRICTNAME_EN": "Al Tadamun",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4152",
    "DISTRICTNAME_AR": "المنتزهات",
    "DISTRICTNAME_EN": "Al Mutanazahat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4153",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4154",
    "DISTRICTNAME_AR": "مريخ",
    "DISTRICTNAME_EN": "Mraykh",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4155",
    "DISTRICTNAME_AR": "قباء",
    "DISTRICTNAME_EN": "Quba",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4156",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4157",
    "DISTRICTNAME_AR": "الغربية",
    "DISTRICTNAME_EN": "Al Gharbia",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4158",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4159",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4160",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4161",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4162",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4163",
    "DISTRICTNAME_AR": "البشائر",
    "DISTRICTNAME_EN": "Al Bashaer",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4164",
    "DISTRICTNAME_AR": "المحجر",
    "DISTRICTNAME_EN": "Al Mahjar",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4165",
    "DISTRICTNAME_AR": "الحمراء",
    "DISTRICTNAME_EN": "Al Hamra",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4166",
    "DISTRICTNAME_AR": "السرورية",
    "DISTRICTNAME_EN": "As Surooriyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4167",
    "DISTRICTNAME_AR": "الصفحة",
    "DISTRICTNAME_EN": "As Safha",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4168",
    "DISTRICTNAME_AR": "بلدة ذهبان",
    "DISTRICTNAME_EN": "Dhahban Town",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4169",
    "DISTRICTNAME_AR": "الشويضي",
    "DISTRICTNAME_EN": "Ash Shuwaidi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4170",
    "DISTRICTNAME_AR": "الدرة",
    "DISTRICTNAME_EN": "Al Durra",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4171",
    "DISTRICTNAME_AR": "النجمة",
    "DISTRICTNAME_EN": "Al Najma",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4172",
    "DISTRICTNAME_AR": "الخمرة",
    "DISTRICTNAME_EN": "Al Khomrah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4173",
    "DISTRICTNAME_AR": "الشمائل",
    "DISTRICTNAME_EN": "Ash Shamayil",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4174",
    "DISTRICTNAME_AR": "سليتة",
    "DISTRICTNAME_EN": "Salita",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4175",
    "DISTRICTNAME_AR": "المليساء",
    "DISTRICTNAME_EN": "Al Moulysaa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4176",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4177",
    "DISTRICTNAME_AR": "صناعي2",
    "DISTRICTNAME_EN": "Industrial2",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4178",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4179",
    "DISTRICTNAME_AR": "الضاحية",
    "DISTRICTNAME_EN": "Ad Dahiah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4180",
    "DISTRICTNAME_AR": "الصحيفة",
    "DISTRICTNAME_EN": "As Sahifah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4181",
    "DISTRICTNAME_AR": "الرغامة",
    "DISTRICTNAME_EN": "Ar Rughamah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4182",
    "DISTRICTNAME_AR": "المنارات",
    "DISTRICTNAME_EN": "Al Manarat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4183",
    "DISTRICTNAME_AR": "الكورنيش",
    "DISTRICTNAME_EN": "Al Kurnaish",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4184",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4185",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4186",
    "DISTRICTNAME_AR": "الرحمانية",
    "DISTRICTNAME_EN": "Ar Rahmanyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4187",
    "DISTRICTNAME_AR": "قاعدة الملك فيصل البحرية",
    "DISTRICTNAME_EN": "King Faisal Naval Base",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4188",
    "DISTRICTNAME_AR": "الساحل",
    "DISTRICTNAME_EN": "As Sahil",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4189",
    "DISTRICTNAME_AR": "الوداد",
    "DISTRICTNAME_EN": "Al Widad",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4190",
    "DISTRICTNAME_AR": "البلد",
    "DISTRICTNAME_EN": "Al Balad",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4191",
    "DISTRICTNAME_AR": "الوزيرية",
    "DISTRICTNAME_EN": "Al Waziriyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4192",
    "DISTRICTNAME_AR": "القرينية",
    "DISTRICTNAME_EN": "Al Qryniah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4193",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4194",
    "DISTRICTNAME_AR": "بترومين",
    "DISTRICTNAME_EN": "Petromin",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4195",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4196",
    "DISTRICTNAME_AR": "أم السلم",
    "DISTRICTNAME_EN": "Umm Asalam",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4197",
    "DISTRICTNAME_AR": "الرويس",
    "DISTRICTNAME_EN": "Ar Ruwais",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4198",
    "DISTRICTNAME_AR": "أبحر الجنوبية",
    "DISTRICTNAME_EN": "Abhur Al Janubiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4199",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4200",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4201",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4202",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4203",
    "DISTRICTNAME_AR": "بني مالك",
    "DISTRICTNAME_EN": "Bani Malik",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4204",
    "DISTRICTNAME_AR": "بريمان",
    "DISTRICTNAME_EN": "Bryman",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4205",
    "DISTRICTNAME_AR": "جامعة",
    "DISTRICTNAME_EN": "University",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4206",
    "DISTRICTNAME_AR": "الفرقان",
    "DISTRICTNAME_EN": "Al Furqan",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4207",
    "DISTRICTNAME_AR": "طابة",
    "DISTRICTNAME_EN": "Tabah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4208",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4209",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4210",
    "DISTRICTNAME_AR": "أبو جعالة",
    "DISTRICTNAME_EN": "Abu Jaala",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4211",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4212",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4213",
    "DISTRICTNAME_AR": "أم سدرة",
    "DISTRICTNAME_EN": "Umm Sidra",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4214",
    "DISTRICTNAME_AR": "مدائن الفهد",
    "DISTRICTNAME_EN": "Madain Al Fahd",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4215",
    "DISTRICTNAME_AR": "المودة",
    "DISTRICTNAME_EN": "Al Mawada",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4216",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4217",
    "DISTRICTNAME_AR": "الأمواج",
    "DISTRICTNAME_EN": "Al Amwaj",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4218",
    "DISTRICTNAME_AR": "الأمير فواز الجنوبى",
    "DISTRICTNAME_EN": "Prince Fawaz Al Janoubi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4219",
    "DISTRICTNAME_AR": "كتانة",
    "DISTRICTNAME_EN": "Kittanah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4220",
    "DISTRICTNAME_AR": "أم حبلين الشرقية",
    "DISTRICTNAME_EN": "Umm Hablain Al Sharqia",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4221",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4222",
    "DISTRICTNAME_AR": "البدور",
    "DISTRICTNAME_EN": "Al Budur",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4223",
    "DISTRICTNAME_AR": "الأصالة",
    "DISTRICTNAME_EN": "Al Asala",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4224",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4225",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4226",
    "DISTRICTNAME_AR": "الأمير فواز الشمالى",
    "DISTRICTNAME_EN": "Prince Fawaz Al Shamali",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4227",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4228",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4229",
    "DISTRICTNAME_AR": "اليسر",
    "DISTRICTNAME_EN": "Al Yusr",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4230",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4231",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4232",
    "DISTRICTNAME_AR": "الفاروق",
    "DISTRICTNAME_EN": "Al Farouk",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4233",
    "DISTRICTNAME_AR": "البغددية الشرقية",
    "DISTRICTNAME_EN": "Al Baghdadiyah Al Sharqiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4234",
    "DISTRICTNAME_AR": "الأجواد",
    "DISTRICTNAME_EN": "Al Ajwad",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4235",
    "DISTRICTNAME_AR": "غليل",
    "DISTRICTNAME_EN": "Ghulail",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4236",
    "DISTRICTNAME_AR": "الفضيلة",
    "DISTRICTNAME_EN": "Al Fadeylah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4237",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4238",
    "DISTRICTNAME_AR": "السنابل",
    "DISTRICTNAME_EN": "As Sanabel",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4239",
    "DISTRICTNAME_AR": "الكندرة",
    "DISTRICTNAME_EN": "Al Kandarah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4240",
    "DISTRICTNAME_AR": "الفروسية",
    "DISTRICTNAME_EN": "Al Frosyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4241",
    "DISTRICTNAME_AR": "الموج",
    "DISTRICTNAME_EN": "Al Mawj",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4242",
    "DISTRICTNAME_AR": "النزلة اليمانية",
    "DISTRICTNAME_EN": "An Nazlah Al Yamaniyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4243",
    "DISTRICTNAME_AR": "الثغر",
    "DISTRICTNAME_EN": "Ath Thaghr",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4244",
    "DISTRICTNAME_AR": "السبيل",
    "DISTRICTNAME_EN": "As Sabil",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4245",
    "DISTRICTNAME_AR": "الهنداوية",
    "DISTRICTNAME_EN": "Al Hindawiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4246",
    "DISTRICTNAME_AR": "المرسى",
    "DISTRICTNAME_EN": "Al Marsaa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4247",
    "DISTRICTNAME_AR": "العسيلة",
    "DISTRICTNAME_EN": "Al Usaylah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4248",
    "DISTRICTNAME_AR": "العلاء",
    "DISTRICTNAME_EN": "Al Ulaa",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4249",
    "DISTRICTNAME_AR": "الأجاويد",
    "DISTRICTNAME_EN": "Al Ajaweed",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4250",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "16265",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4251",
    "DISTRICTNAME_AR": "بلدة ثول",
    "DISTRICTNAME_EN": "Thule Town",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4252",
    "DISTRICTNAME_AR": "الشرائع",
    "DISTRICTNAME_EN": "Asharai ",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4253",
    "DISTRICTNAME_AR": "المعيزيلة",
    "DISTRICTNAME_EN": "Al Maizialah",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4254",
    "DISTRICTNAME_AR": "أم حبلين الغربية",
    "DISTRICTNAME_EN": "Umm Hablain Al Gharbia",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4255",
    "DISTRICTNAME_AR": "القريات",
    "DISTRICTNAME_EN": "Al Quraiyat",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4256",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4257",
    "DISTRICTNAME_AR": "جامعة الملك عبدالعزيز",
    "DISTRICTNAME_EN": "King Abdulaziz University",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4258",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4259",
    "DISTRICTNAME_AR": "الشرفية",
    "DISTRICTNAME_EN": "Ash Sharafiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4260",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4261",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4262",
    "DISTRICTNAME_AR": "العسلاء",
    "DISTRICTNAME_EN": "Al Usalaa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4263",
    "DISTRICTNAME_AR": "الوسامي",
    "DISTRICTNAME_EN": "Al Wasami",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4267",
    "DISTRICTNAME_AR": "النزلة الشرقية",
    "DISTRICTNAME_EN": "An Nazlah Ash Sharqiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4268",
    "DISTRICTNAME_AR": "البهجة",
    "DISTRICTNAME_EN": "Al Bahja",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4269",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4270",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4271",
    "DISTRICTNAME_AR": "الودية",
    "DISTRICTNAME_EN": "Al Wudiya",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4272",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4273",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4274",
    "DISTRICTNAME_AR": "العبير",
    "DISTRICTNAME_EN": "Al Abeer",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4275",
    "DISTRICTNAME_AR": "الأثير",
    "DISTRICTNAME_EN": "Al Athir",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4276",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4277",
    "DISTRICTNAME_AR": "السهل",
    "DISTRICTNAME_EN": "Al Sahl",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4278",
    "DISTRICTNAME_AR": "جامعة",
    "DISTRICTNAME_EN": "University",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4279",
    "DISTRICTNAME_AR": "الرياض",
    "DISTRICTNAME_EN": "Ar Riyadh",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4280",
    "DISTRICTNAME_AR": "حكومي3",
    "DISTRICTNAME_EN": "Governmental3",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4281",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4282",
    "DISTRICTNAME_AR": "الزمرد",
    "DISTRICTNAME_EN": "Az Zomorod",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4283",
    "DISTRICTNAME_AR": "التوفيق",
    "DISTRICTNAME_EN": "Al Tawfiq",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4284",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4285",
    "DISTRICTNAME_AR": "البيان",
    "DISTRICTNAME_EN": "Al Bayan",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4286",
    "DISTRICTNAME_AR": "رهناء",
    "DISTRICTNAME_EN": "Rahna'",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4287",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4288",
    "DISTRICTNAME_AR": "السامر",
    "DISTRICTNAME_EN": "As Samir",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4289",
    "DISTRICTNAME_AR": "البوادر",
    "DISTRICTNAME_EN": "Al Bawadir",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4290",
    "DISTRICTNAME_AR": "الحمدانية",
    "DISTRICTNAME_EN": "Al Hamadaniyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4291",
    "DISTRICTNAME_AR": "الشراع",
    "DISTRICTNAME_EN": "Ash Sheraa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4292",
    "DISTRICTNAME_AR": "العمارية",
    "DISTRICTNAME_EN": "Al Ammariyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4293",
    "DISTRICTNAME_AR": "ميناء جدة الاسلامي",
    "DISTRICTNAME_EN": "Jeddah Eslamic Seaport",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4294",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4295",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4296",
    "DISTRICTNAME_AR": "الشرقية",
    "DISTRICTNAME_EN": "Sharqia",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4297",
    "DISTRICTNAME_AR": "رضوى",
    "DISTRICTNAME_EN": "Radwa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4298",
    "DISTRICTNAME_AR": "المجامع",
    "DISTRICTNAME_EN": "Al Majamie",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4299",
    "DISTRICTNAME_AR": "حكومي1",
    "DISTRICTNAME_EN": "Governmental1",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4300",
    "DISTRICTNAME_AR": "حكومي2",
    "DISTRICTNAME_EN": "Governmental2",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4301",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4302",
    "DISTRICTNAME_AR": "البغدادية الغربية",
    "DISTRICTNAME_EN": "Al Baghdadiyah Al Gharbiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4303",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4304",
    "DISTRICTNAME_AR": "المسرة",
    "DISTRICTNAME_EN": "Al Masarah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4305",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4306",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4307",
    "DISTRICTNAME_AR": "ترفيهي",
    "DISTRICTNAME_EN": "Entertaining",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4308",
    "DISTRICTNAME_AR": "الفضل",
    "DISTRICTNAME_EN": "Al Fadel",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4309",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4310",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4311",
    "DISTRICTNAME_AR": "أبحر الشمالية",
    "DISTRICTNAME_EN": "Abhur Ash Shamaliyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4312",
    "DISTRICTNAME_AR": "الصواري",
    "DISTRICTNAME_EN": "Al Sawari",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4313",
    "DISTRICTNAME_AR": "البساتين2",
    "DISTRICTNAME_EN": "Al Basatin2",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4314",
    "DISTRICTNAME_AR": "الرفعة الجنوبية",
    "DISTRICTNAME_EN": "Al Rafeat Al Janubia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4315",
    "DISTRICTNAME_AR": "السحيمية",
    "DISTRICTNAME_EN": "As Suhaymiyyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4316",
    "DISTRICTNAME_AR": "مدينة الملك عبدالله العسكرية",
    "DISTRICTNAME_EN": "King Abdullah Military City",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4317",
    "DISTRICTNAME_AR": "الخدود",
    "DISTRICTNAME_EN": "Al Khudud",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4318",
    "DISTRICTNAME_AR": "المرج",
    "DISTRICTNAME_EN": "Al Marj",
    "CITY_ID": "18396",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4319",
    "DISTRICTNAME_AR": "عين نجم",
    "DISTRICTNAME_EN": "Ayn Najm",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4320",
    "DISTRICTNAME_AR": "ضاحية الامير سلطان الجنوبية",
    "DISTRICTNAME_EN": "Dahiyat Al Amir Sultan Al Janubia",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4321",
    "DISTRICTNAME_AR": "المطيرفي الغربي",
    "DISTRICTNAME_EN": "Al Mutayrifi Al Garbi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4322",
    "DISTRICTNAME_AR": "ضاحية الامير سلطان الشمالية",
    "DISTRICTNAME_EN": "Dahiyat Al Amir Sultan Al Shamalia",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4323",
    "DISTRICTNAME_AR": "الوفاء",
    "DISTRICTNAME_EN": "Al Wafa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4324",
    "DISTRICTNAME_AR": "حكومي1",
    "DISTRICTNAME_EN": "Governmental1",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4325",
    "DISTRICTNAME_AR": "حكومي2",
    "DISTRICTNAME_EN": "Governmental2",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4326",
    "DISTRICTNAME_AR": "اللؤلؤ",
    "DISTRICTNAME_EN": "Al Loaloa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4327",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4328",
    "DISTRICTNAME_AR": "الصفوة",
    "DISTRICTNAME_EN": "As safwa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4329",
    "DISTRICTNAME_AR": "العويجاء",
    "DISTRICTNAME_EN": "Al Uwayjaa",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4330",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4331",
    "DISTRICTNAME_AR": "محاسن ارامكو الثاني",
    "DISTRICTNAME_EN": "Mahasin Aramco 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4332",
    "DISTRICTNAME_AR": "مدينة الحجاج",
    "DISTRICTNAME_EN": "Madinat Al Hujaaj",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4333",
    "DISTRICTNAME_AR": "المثلث",
    "DISTRICTNAME_EN": "Al Muthalath",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4334",
    "DISTRICTNAME_AR": "قرية السيايرة",
    "DISTRICTNAME_EN": "Qaryat Al Sayyara",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4335",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4336",
    "DISTRICTNAME_AR": "بلدة الفضول",
    "DISTRICTNAME_EN": "Al Fudul Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4338",
    "DISTRICTNAME_AR": "الطالعية",
    "DISTRICTNAME_EN": "Al Taalieia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4339",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4340",
    "DISTRICTNAME_AR": "العاصمة",
    "DISTRICTNAME_EN": "Al Asemah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4342",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4344",
    "DISTRICTNAME_AR": "الرياض الاول",
    "DISTRICTNAME_EN": "Ar Riyadh Al Awal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4345",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4346",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4347",
    "DISTRICTNAME_AR": "الثريا",
    "DISTRICTNAME_EN": "Ath Thuraiya",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4348",
    "DISTRICTNAME_AR": "الصفاء الاول",
    "DISTRICTNAME_EN": "As Safa Al Awl",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4349",
    "DISTRICTNAME_AR": "الشهابية",
    "DISTRICTNAME_EN": "Ash Shihabiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4350",
    "DISTRICTNAME_AR": "نادي الفروسية",
    "DISTRICTNAME_EN": "Equestrian Club",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4351",
    "DISTRICTNAME_AR": "الرفعة الشمالية",
    "DISTRICTNAME_EN": "Al Rafeat Al Shamalia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4353",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4354",
    "DISTRICTNAME_AR": "البدور",
    "DISTRICTNAME_EN": "Al Budur",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4355",
    "DISTRICTNAME_AR": "المسعودي",
    "DISTRICTNAME_EN": "Al Masudi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4356",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "Ar Rafiah",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4357",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4359",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4363",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4366",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4368",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4370",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4372",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "4374",
    "DISTRICTNAME_AR": "الرابع",
    "DISTRICTNAME_EN": "The Fourth",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4375",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4376",
    "DISTRICTNAME_AR": "هجر الرابع",
    "DISTRICTNAME_EN": "Hajar Al Raabie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4377",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4378",
    "DISTRICTNAME_AR": "العزيزية الأول",
    "DISTRICTNAME_EN": "Al Aziziyah 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4379",
    "DISTRICTNAME_AR": "الحزم الجنوبي",
    "DISTRICTNAME_EN": "Al Hazm Al Junubi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4380",
    "DISTRICTNAME_AR": "الصفاء الثاني",
    "DISTRICTNAME_EN": "As Safa Al Thaani",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4381",
    "DISTRICTNAME_AR": "هجر السادس",
    "DISTRICTNAME_EN": "Hajar Al Saadis",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4382",
    "DISTRICTNAME_AR": "مثلث الشرقية",
    "DISTRICTNAME_EN": "Muthalath Al Sharqia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4383",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4384",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4385",
    "DISTRICTNAME_AR": "الشراع الشمالي",
    "DISTRICTNAME_EN": "Ash Shara Al Shimali",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4386",
    "DISTRICTNAME_AR": "أم سبعة",
    "DISTRICTNAME_EN": "Umm Sabah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4387",
    "DISTRICTNAME_AR": "الرياض الثاني",
    "DISTRICTNAME_EN": "Ar Riyadh Al Thaani",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4388",
    "DISTRICTNAME_AR": "الراشدية الثاني",
    "DISTRICTNAME_EN": "Ar Rashidyah 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4389",
    "DISTRICTNAME_AR": "قرية العمران الجنوبي",
    "DISTRICTNAME_EN": "Qaryat Al Omran South",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4390",
    "DISTRICTNAME_AR": "الإسكان الأول",
    "DISTRICTNAME_EN": "Al Iskan 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4391",
    "DISTRICTNAME_AR": "مشرفة",
    "DISTRICTNAME_EN": "Mishrifah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4392",
    "DISTRICTNAME_AR": "الواحة1",
    "DISTRICTNAME_EN": "Al Wahah1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4393",
    "DISTRICTNAME_AR": "الروضة1",
    "DISTRICTNAME_EN": "Ar Rawdah1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4394",
    "DISTRICTNAME_AR": "البصيرة",
    "DISTRICTNAME_EN": "Al Basirah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4395",
    "DISTRICTNAME_AR": "الكوت",
    "DISTRICTNAME_EN": "Al Kut",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4396",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4397",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4398",
    "DISTRICTNAME_AR": "محيرس",
    "DISTRICTNAME_EN": "Mahairis",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4399",
    "DISTRICTNAME_AR": "أحد2",
    "DISTRICTNAME_EN": "Ohod2",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4400",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4401",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4402",
    "DISTRICTNAME_AR": "السلمانية الرابع",
    "DISTRICTNAME_EN": "Al Salmaniyah Forth",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4403",
    "DISTRICTNAME_AR": "المجصة",
    "DISTRICTNAME_EN": "Al Mijasa",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4404",
    "DISTRICTNAME_AR": "بلدة الشقيق الشرقية",
    "DISTRICTNAME_EN": "Al Shuqaiq Eastern Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4405",
    "DISTRICTNAME_AR": "بلدة الجرن",
    "DISTRICTNAME_EN": "Al Jurn Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4406",
    "DISTRICTNAME_AR": "مجمع الدوائر الحكومية",
    "DISTRICTNAME_EN": "Government Departments Complex",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4407",
    "DISTRICTNAME_AR": "أحد1",
    "DISTRICTNAME_EN": "Ohod1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4408",
    "DISTRICTNAME_AR": "المزروع الاول",
    "DISTRICTNAME_EN": "Al Mazrooa 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4409",
    "DISTRICTNAME_AR": "جوبا",
    "DISTRICTNAME_EN": "Juba",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4410",
    "DISTRICTNAME_AR": "هجر التاسع",
    "DISTRICTNAME_EN": "Hajr Al Taasie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4411",
    "DISTRICTNAME_AR": "الراشدية الثالث",
    "DISTRICTNAME_EN": "Ar Rashidyah 3rd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4412",
    "DISTRICTNAME_AR": "الثاني",
    "DISTRICTNAME_EN": "The Second",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4413",
    "DISTRICTNAME_AR": "الإسكان الثاني",
    "DISTRICTNAME_EN": "Al Iskan 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4414",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "Al Olaya",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4415",
    "DISTRICTNAME_AR": "الحمراء الأول",
    "DISTRICTNAME_EN": "Al Hamra 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4416",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4417",
    "DISTRICTNAME_AR": "المدينة الرياضية",
    "DISTRICTNAME_EN": "Sports City",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4418",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4419",
    "DISTRICTNAME_AR": "بلدة العوضية",
    "DISTRICTNAME_EN": "Al Awadhiya Town",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4420",
    "DISTRICTNAME_AR": "بلدة المنيزلة",
    "DISTRICTNAME_EN": "Al Munayzala Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4421",
    "DISTRICTNAME_AR": "بلدة الطريبيل",
    "DISTRICTNAME_EN": "Al Traibil Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4422",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4423",
    "DISTRICTNAME_AR": "بلدة المنصورة",
    "DISTRICTNAME_EN": "Al Mansoura Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4424",
    "DISTRICTNAME_AR": "النخيل1",
    "DISTRICTNAME_EN": "Al Nakhil1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4425",
    "DISTRICTNAME_AR": "الثليثية",
    "DISTRICTNAME_EN": "Al Thulaythiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4426",
    "DISTRICTNAME_AR": "الفيصل",
    "DISTRICTNAME_EN": "Al Faisal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4427",
    "DISTRICTNAME_AR": "الحوراء",
    "DISTRICTNAME_EN": "Al Hawra",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4428",
    "DISTRICTNAME_AR": "مجمع الدوائر الحكومية",
    "DISTRICTNAME_EN": "Government Departments Complex",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4429",
    "DISTRICTNAME_AR": "بلدة أبو الحصى",
    "DISTRICTNAME_EN": "Abu Al Hasab Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4430",
    "DISTRICTNAME_AR": "العيون الشمالية",
    "DISTRICTNAME_EN": "North Al Uyun",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4431",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4432",
    "DISTRICTNAME_AR": "الحزام الذهبي",
    "DISTRICTNAME_EN": "Al Hizam Adh Dhahabi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4433",
    "DISTRICTNAME_AR": "الحفيرة",
    "DISTRICTNAME_EN": "Al Hufayrah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4434",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4435",
    "DISTRICTNAME_AR": "اليرموك1",
    "DISTRICTNAME_EN": "Al Yarmuk1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4436",
    "DISTRICTNAME_AR": "اللويمي",
    "DISTRICTNAME_EN": "Al Luwaymi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4437",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4438",
    "DISTRICTNAME_AR": "قرية العرامية",
    "DISTRICTNAME_EN": "Qaryat Al Arameya",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4439",
    "DISTRICTNAME_AR": "العدوة",
    "DISTRICTNAME_EN": "Al Adwah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4440",
    "DISTRICTNAME_AR": "جواثا",
    "DISTRICTNAME_EN": "Juwatha",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4441",
    "DISTRICTNAME_AR": "قرية الرميلة",
    "DISTRICTNAME_EN": "Qaryat Al Rumaila",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4442",
    "DISTRICTNAME_AR": "الحمراء الثاني",
    "DISTRICTNAME_EN": "Al Hamra 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4443",
    "DISTRICTNAME_AR": "الورود الثاني",
    "DISTRICTNAME_EN": "Al Wurud 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4444",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4445",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "Ar Rifa",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4446",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4447",
    "DISTRICTNAME_AR": "المربدية",
    "DISTRICTNAME_EN": "Al Marbidia",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4448",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "As Salhiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4449",
    "DISTRICTNAME_AR": "الشروفية",
    "DISTRICTNAME_EN": "Al Shurofiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4450",
    "DISTRICTNAME_AR": "الثالث",
    "DISTRICTNAME_EN": "The Third",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4451",
    "DISTRICTNAME_AR": "الإمام محمد بن سعود",
    "DISTRICTNAME_EN": "Al Imam Muhammed Ibn Saud",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4452",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4453",
    "DISTRICTNAME_AR": "إسكان الكلابية",
    "DISTRICTNAME_EN": "Al Kilabiyah Housing",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4454",
    "DISTRICTNAME_AR": "العسيلة",
    "DISTRICTNAME_EN": "Al Usaylah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4455",
    "DISTRICTNAME_AR": "المزروع الثاني",
    "DISTRICTNAME_EN": "Al Mazrooa 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4456",
    "DISTRICTNAME_AR": "برزان",
    "DISTRICTNAME_EN": "Barzan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4457",
    "DISTRICTNAME_AR": "المرقاب",
    "DISTRICTNAME_EN": "Al Merqab",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4458",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4459",
    "DISTRICTNAME_AR": "بو سحبل الثاني",
    "DISTRICTNAME_EN": "bu Sahbal 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4460",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4461",
    "DISTRICTNAME_AR": "التمور",
    "DISTRICTNAME_EN": "Al Tumur",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4462",
    "DISTRICTNAME_AR": "الديوان",
    "DISTRICTNAME_EN": "Ad Daywan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4463",
    "DISTRICTNAME_AR": "بلدة الدالوة",
    "DISTRICTNAME_EN": "Dalouh Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4464",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4465",
    "DISTRICTNAME_AR": "العويمرية",
    "DISTRICTNAME_EN": "Al Uwaymiriyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4466",
    "DISTRICTNAME_AR": "بلدة القرين",
    "DISTRICTNAME_EN": "Al Qurain Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4467",
    "DISTRICTNAME_AR": "محاسن الثاني",
    "DISTRICTNAME_EN": "Mahasin 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4468",
    "DISTRICTNAME_AR": "النايفية",
    "DISTRICTNAME_EN": "An Nayfiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4469",
    "DISTRICTNAME_AR": "بو سحبل الأول",
    "DISTRICTNAME_EN": "bu Sahbal 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4470",
    "DISTRICTNAME_AR": "هجر الثاني",
    "DISTRICTNAME_EN": "Hajr Al Thaani",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4471",
    "DISTRICTNAME_AR": "محاسن ارامكو الثالث",
    "DISTRICTNAME_EN": "Mahasin Aramco 3rd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4472",
    "DISTRICTNAME_AR": "محاسن الأول",
    "DISTRICTNAME_EN": "Mahasin 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4473",
    "DISTRICTNAME_AR": "منيفة",
    "DISTRICTNAME_EN": "Munifah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4474",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4475",
    "DISTRICTNAME_AR": "السلمانية الأول",
    "DISTRICTNAME_EN": "As Sulaymaniyah First",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4476",
    "DISTRICTNAME_AR": "صويدرة",
    "DISTRICTNAME_EN": "As Suwaidirah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4477",
    "DISTRICTNAME_AR": "محاسن ارامكو الرابع",
    "DISTRICTNAME_EN": "Mahasin Aramco 4th ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4478",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4479",
    "DISTRICTNAME_AR": "أبو حريف",
    "DISTRICTNAME_EN": "Abu Harif",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4480",
    "DISTRICTNAME_AR": "الشغيبية",
    "DISTRICTNAME_EN": "Al Shagaibiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4481",
    "DISTRICTNAME_AR": "الأمانة",
    "DISTRICTNAME_EN": "Al Amanah",
    "CITY_ID": "13789",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "4482",
    "DISTRICTNAME_AR": "وسط العيون",
    "DISTRICTNAME_EN": "Wasat Aleuyun",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4483",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4484",
    "DISTRICTNAME_AR": "الجفر الشمالي",
    "DISTRICTNAME_EN": "Al Jafar Al Shamali",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4485",
    "DISTRICTNAME_AR": "المقابل",
    "DISTRICTNAME_EN": "Al Muqabil",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4486",
    "DISTRICTNAME_AR": "الدوحة",
    "DISTRICTNAME_EN": "Ad Dawhah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4487",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4488",
    "DISTRICTNAME_AR": "بلدة الصبايخ",
    "DISTRICTNAME_EN": "Al Sabaykh Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4489",
    "DISTRICTNAME_AR": "الحزام الأخضر",
    "DISTRICTNAME_EN": "Al Hizam Al Akhdar",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4490",
    "DISTRICTNAME_AR": "جامعة الملك فيصل الأول",
    "DISTRICTNAME_EN": "King Faisal University 1st",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4491",
    "DISTRICTNAME_AR": "بلدة السباط",
    "DISTRICTNAME_EN": "Al Sibat Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4492",
    "DISTRICTNAME_AR": "بلدة البطالية",
    "DISTRICTNAME_EN": "Al Bataliyah Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4493",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4494",
    "DISTRICTNAME_AR": "مجمع الدوائر الحكومية الأول",
    "DISTRICTNAME_EN": "Government Departments Complex 1st ",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4495",
    "DISTRICTNAME_AR": "الفيصلية الثاني",
    "DISTRICTNAME_EN": "Al Faisaliyah 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4496",
    "DISTRICTNAME_AR": "السلام الثاني",
    "DISTRICTNAME_EN": "As Salam 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4497",
    "DISTRICTNAME_AR": "هجر الحادي عشر",
    "DISTRICTNAME_EN": "Hajar Al Hadi ashar",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4498",
    "DISTRICTNAME_AR": "السيفة",
    "DISTRICTNAME_EN": "As Saifa",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4499",
    "DISTRICTNAME_AR": "العزيزية الثاني",
    "DISTRICTNAME_EN": "Al Aziziyah 2nd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4500",
    "DISTRICTNAME_AR": "عين موسى",
    "DISTRICTNAME_EN": "Ayn Musa",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4501",
    "DISTRICTNAME_AR": "السلمانية الثالث",
    "DISTRICTNAME_EN": "As Sulaymaniyah Third",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4502",
    "DISTRICTNAME_AR": "بلدة أبو ثور",
    "DISTRICTNAME_EN": "Abu Thawr Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4503",
    "DISTRICTNAME_AR": "الجبل",
    "DISTRICTNAME_EN": "Al Jabal",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4504",
    "DISTRICTNAME_AR": "الإسكان1",
    "DISTRICTNAME_EN": "Al Iskan1",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4505",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4506",
    "DISTRICTNAME_AR": "الرياض الرابع",
    "DISTRICTNAME_EN": "Ar Riyadh Al Raabie",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4507",
    "DISTRICTNAME_AR": "بلدة الشقيق الغربية",
    "DISTRICTNAME_EN": "Al Shuqaiq Western Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4508",
    "DISTRICTNAME_AR": "عين مرجان",
    "DISTRICTNAME_EN": "Ayn Marjan",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4509",
    "DISTRICTNAME_AR": "الوسيطة",
    "DISTRICTNAME_EN": "Al Wasitah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4510",
    "DISTRICTNAME_AR": "بلدة جليجلة",
    "DISTRICTNAME_EN": "Jalijila Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4511",
    "DISTRICTNAME_AR": "هجر العاشر",
    "DISTRICTNAME_EN": "Hajar Al Ashir",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4512",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4513",
    "DISTRICTNAME_AR": "هجر",
    "DISTRICTNAME_EN": "Hajr",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4514",
    "DISTRICTNAME_AR": "الجامعيين",
    "DISTRICTNAME_EN": "Al Jamiyin",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4515",
    "DISTRICTNAME_AR": "المحار",
    "DISTRICTNAME_EN": "Al Mahar",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4516",
    "DISTRICTNAME_AR": "جامعة الملك فيصل الثالث",
    "DISTRICTNAME_EN": "King Faisal University 3rd",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4517",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "Ar Rabiyah",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4518",
    "DISTRICTNAME_AR": "المروج الشمالي",
    "DISTRICTNAME_EN": "Al Muruj Al Shimali",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4519",
    "DISTRICTNAME_AR": "بلدة الحليلة",
    "DISTRICTNAME_EN": "Al Hulaila Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4520",
    "DISTRICTNAME_AR": "الأمراء",
    "DISTRICTNAME_EN": "Al Omaraa",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4521",
    "DISTRICTNAME_AR": "الإسكان2",
    "DISTRICTNAME_EN": "Al Iskan2",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4522",
    "DISTRICTNAME_AR": "السلمانية الثاني",
    "DISTRICTNAME_EN": "As Sulaymaniyah Second",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4523",
    "DISTRICTNAME_AR": "هجر الخامس",
    "DISTRICTNAME_EN": "Hajar Al Khamis",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4524",
    "DISTRICTNAME_AR": "بلدة بني معن",
    "DISTRICTNAME_EN": "Bani Ma'an Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4525",
    "DISTRICTNAME_AR": "الركاز",
    "DISTRICTNAME_EN": "Al Rakaz",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4526",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4527",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4528",
    "DISTRICTNAME_AR": "المهندسين",
    "DISTRICTNAME_EN": "Al Muhandisin",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4529",
    "DISTRICTNAME_AR": "مخطط الإتصالات",
    "DISTRICTNAME_EN": "Al Itsalat Subdivision",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4530",
    "DISTRICTNAME_AR": "المجيدية",
    "DISTRICTNAME_EN": "Al Majidiyah",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4531",
    "DISTRICTNAME_AR": "بلدة المزاوي",
    "DISTRICTNAME_EN": "Al Mazawi Town",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4532",
    "DISTRICTNAME_AR": "العيون الجنوبية",
    "DISTRICTNAME_EN": "South Al Uyun",
    "CITY_ID": "13769",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4533",
    "DISTRICTNAME_AR": "الشراع الجنوبي",
    "DISTRICTNAME_EN": "Ash Shara Al Junubi",
    "CITY_ID": "13789",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4534",
    "DISTRICTNAME_AR": "الواصلية",
    "DISTRICTNAME_EN": "Al Wasilia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4535",
    "DISTRICTNAME_AR": "الرحبة",
    "DISTRICTNAME_EN": "Ar Rahbah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4536",
    "DISTRICTNAME_AR": "اليسرا",
    "DISTRICTNAME_EN": "Al Yusra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4537",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4538",
    "DISTRICTNAME_AR": "إشبيلية",
    "DISTRICTNAME_EN": "Ishbiliyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4539",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4540",
    "DISTRICTNAME_AR": "أوالة",
    "DISTRICTNAME_EN": "Awala",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4541",
    "DISTRICTNAME_AR": "السر",
    "DISTRICTNAME_EN": "Al Sir",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4542",
    "DISTRICTNAME_AR": "الجفر",
    "DISTRICTNAME_EN": "Al Jafar",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4543",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4544",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4545",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4546",
    "DISTRICTNAME_AR": "مسرة",
    "DISTRICTNAME_EN": "Masrah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4547",
    "DISTRICTNAME_AR": "الرويدف",
    "DISTRICTNAME_EN": "Al Ruwaidaf",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4548",
    "DISTRICTNAME_AR": "إسكان الحرس",
    "DISTRICTNAME_EN": "Guard Housing",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4549",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4550",
    "DISTRICTNAME_AR": "كلاخ",
    "DISTRICTNAME_EN": "Kalakh",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4551",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4552",
    "DISTRICTNAME_AR": "القطبية الغربية",
    "DISTRICTNAME_EN": "Al Qutbiah Al Gharbia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4553",
    "DISTRICTNAME_AR": "الوكرة",
    "DISTRICTNAME_EN": "Al Wakra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4554",
    "DISTRICTNAME_AR": "المضباع",
    "DISTRICTNAME_EN": "Al Mudhbah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4555",
    "DISTRICTNAME_AR": "السناح",
    "DISTRICTNAME_EN": "As Snah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4556",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4557",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4558",
    "DISTRICTNAME_AR": "الشاطئ",
    "DISTRICTNAME_EN": "Ash Shati",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4559",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4560",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4561",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4562",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4563",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "4492",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4564",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4565",
    "DISTRICTNAME_AR": "سوق عكاظ",
    "DISTRICTNAME_EN": "Suq Ukadh",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4566",
    "DISTRICTNAME_AR": "معشي",
    "DISTRICTNAME_EN": "Maashi ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4567",
    "DISTRICTNAME_AR": "نخب",
    "DISTRICTNAME_EN": "Nakhab ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4568",
    "DISTRICTNAME_AR": "سلطانة",
    "DISTRICTNAME_EN": "Sultanah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4569",
    "DISTRICTNAME_AR": "الضحى",
    "DISTRICTNAME_EN": "Al Duhaa",
    "CITY_ID": "4492",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4570",
    "DISTRICTNAME_AR": "الضبيعة",
    "DISTRICTNAME_EN": "Al Dabiea",
    "CITY_ID": "4492",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4571",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "4492",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4572",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4573",
    "DISTRICTNAME_AR": "محطة الارصاد",
    "DISTRICTNAME_EN": "Weather Station",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4574",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4575",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4576",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4577",
    "DISTRICTNAME_AR": "الصفراء",
    "DISTRICTNAME_EN": "As Safra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4578",
    "DISTRICTNAME_AR": "الواسط",
    "DISTRICTNAME_EN": "Al Wasit",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4579",
    "DISTRICTNAME_AR": "الدهماء",
    "DISTRICTNAME_EN": "Al Dahmaa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4580",
    "DISTRICTNAME_AR": "القويسم",
    "DISTRICTNAME_EN": "Al Quaysam",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4581",
    "DISTRICTNAME_AR": "الحياه الفطرية",
    "DISTRICTNAME_EN": "Wildlife",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4582",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4583",
    "DISTRICTNAME_AR": "المرجانية",
    "DISTRICTNAME_EN": "Al Murjaniyah",
    "CITY_ID": "16594",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4584",
    "DISTRICTNAME_AR": "قرن المنازل",
    "DISTRICTNAME_EN": "Qarn Al Manazil",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4585",
    "DISTRICTNAME_AR": "العواجية",
    "DISTRICTNAME_EN": "Al Awajia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4586",
    "DISTRICTNAME_AR": "لية العليا",
    "DISTRICTNAME_EN": "Liat Al Ulya",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4587",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4588",
    "DISTRICTNAME_AR": "المنطقة التاريخية",
    "DISTRICTNAME_EN": "Historic District",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4589",
    "DISTRICTNAME_AR": "نهيقة",
    "DISTRICTNAME_EN": "Nahiqah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4590",
    "DISTRICTNAME_AR": "العواكل",
    "DISTRICTNAME_EN": "Al Awakel",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4591",
    "DISTRICTNAME_AR": "القابل",
    "DISTRICTNAME_EN": "Al Qabil",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4592",
    "DISTRICTNAME_AR": "الموفجة",
    "DISTRICTNAME_EN": "Al Mawfijah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4593",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4594",
    "DISTRICTNAME_AR": "الغمير",
    "DISTRICTNAME_EN": "Al Ghumayr",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4595",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4596",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4597",
    "DISTRICTNAME_AR": "القديرة",
    "DISTRICTNAME_EN": "Al Qadirah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4598",
    "DISTRICTNAME_AR": "المليساء",
    "DISTRICTNAME_EN": "Al Moulysaa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4599",
    "DISTRICTNAME_AR": "القدس",
    "DISTRICTNAME_EN": "Al Quds",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4600",
    "DISTRICTNAME_AR": "طيبة",
    "DISTRICTNAME_EN": "Taibah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4601",
    "DISTRICTNAME_AR": "الجودية",
    "DISTRICTNAME_EN": "Al Judia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4602",
    "DISTRICTNAME_AR": "العرفاء الشرقية",
    "DISTRICTNAME_EN": "Eastern Al Urafa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4603",
    "DISTRICTNAME_AR": "زور ال الحارث",
    "DISTRICTNAME_EN": "Zur Al Al Harith",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4604",
    "DISTRICTNAME_AR": "صاغر",
    "DISTRICTNAME_EN": "Saaghir",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4605",
    "DISTRICTNAME_AR": "عويره",
    "DISTRICTNAME_EN": "Awira",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4606",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4607",
    "DISTRICTNAME_AR": "الحمر",
    "DISTRICTNAME_EN": "Al Humar",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4608",
    "DISTRICTNAME_AR": "الحلقة الغربية",
    "DISTRICTNAME_EN": "Al Halqah Al Gharbia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4609",
    "DISTRICTNAME_AR": "القيم الأعلى",
    "DISTRICTNAME_EN": "Al Qayam Al Aala",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4610",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4611",
    "DISTRICTNAME_AR": "الدفاع الجوي",
    "DISTRICTNAME_EN": "Air Defense",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4612",
    "DISTRICTNAME_AR": "الحلاه",
    "DISTRICTNAME_EN": "Al Halah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4613",
    "DISTRICTNAME_AR": "القميع",
    "DISTRICTNAME_EN": "Qami",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4614",
    "DISTRICTNAME_AR": "ريحه",
    "DISTRICTNAME_EN": "Riha ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4615",
    "DISTRICTNAME_AR": "الحوية",
    "DISTRICTNAME_EN": "Al Huwaya",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4616",
    "DISTRICTNAME_AR": "عدوان",
    "DISTRICTNAME_EN": "Eudwan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4617",
    "DISTRICTNAME_AR": "القمرية",
    "DISTRICTNAME_EN": "Al Qumariyyah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4618",
    "DISTRICTNAME_AR": "الشهداء الشمالية",
    "DISTRICTNAME_EN": "Ashuhada Alshamaliyyah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4619",
    "DISTRICTNAME_AR": "السيل الكبير",
    "DISTRICTNAME_EN": "As Sail Al Kabeer ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4620",
    "DISTRICTNAME_AR": "الحلقة الشرقية",
    "DISTRICTNAME_EN": "Al Halqah Asharqiyyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4621",
    "DISTRICTNAME_AR": "الشرفية",
    "DISTRICTNAME_EN": "Ash Sharafiyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4622",
    "DISTRICTNAME_AR": "القيم الأسفل",
    "DISTRICTNAME_EN": "Al Qayam Al Asfal",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4623",
    "DISTRICTNAME_AR": "الجال",
    "DISTRICTNAME_EN": "Al Jal",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4624",
    "DISTRICTNAME_AR": "الشرقية",
    "DISTRICTNAME_EN": "Sharqia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4625",
    "DISTRICTNAME_AR": "العبلاء",
    "DISTRICTNAME_EN": "Al Ablaa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4626",
    "DISTRICTNAME_AR": "واحة الإسكان",
    "DISTRICTNAME_EN": "Al Iskan oasis",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4627",
    "DISTRICTNAME_AR": "شبرا",
    "DISTRICTNAME_EN": "Shubra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4628",
    "DISTRICTNAME_AR": "البوادي",
    "DISTRICTNAME_EN": "Al Bawadi",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4629",
    "DISTRICTNAME_AR": "ميدان الهجن",
    "DISTRICTNAME_EN": "Camel Field",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4630",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4631",
    "DISTRICTNAME_AR": "المطار الدولي الجديد",
    "DISTRICTNAME_EN": "New International Airport",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4632",
    "DISTRICTNAME_AR": "الهدا",
    "DISTRICTNAME_EN": "Al Hada",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4633",
    "DISTRICTNAME_AR": "جبال القنة",
    "DISTRICTNAME_EN": "Qena Mountains",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4634",
    "DISTRICTNAME_AR": "الكدى",
    "DISTRICTNAME_EN": "Al Kudaa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4635",
    "DISTRICTNAME_AR": "وادي محرم الأسفل",
    "DISTRICTNAME_EN": "Wadi Muharam Al Asfal",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4636",
    "DISTRICTNAME_AR": "الردف",
    "DISTRICTNAME_EN": "Ar Ruddaf",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4637",
    "DISTRICTNAME_AR": "أم الدروب",
    "DISTRICTNAME_EN": "Umm Al Durub",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4638",
    "DISTRICTNAME_AR": "أم العطف",
    "DISTRICTNAME_EN": "Umm Al Atf",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4639",
    "DISTRICTNAME_AR": "ضاحية الإسكان",
    "DISTRICTNAME_EN": "Dahiyat AI Iskan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4640",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4641",
    "DISTRICTNAME_AR": "القهيب",
    "DISTRICTNAME_EN": "Al Quhaib",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4642",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4643",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4644",
    "DISTRICTNAME_AR": "الكوكب",
    "DISTRICTNAME_EN": "Al-Kawkab",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4645",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4646",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "Al Bustan",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4647",
    "DISTRICTNAME_AR": "الشذا",
    "DISTRICTNAME_EN": "Al Shadha",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4648",
    "DISTRICTNAME_AR": "الرجاء",
    "DISTRICTNAME_EN": "Ar Rajaa",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4649",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "Al Majd",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4650",
    "DISTRICTNAME_AR": "المسميات 2",
    "DISTRICTNAME_EN": "Al Musamayat 2",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4651",
    "DISTRICTNAME_AR": "الغضا",
    "DISTRICTNAME_EN": "Al Kada",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4652",
    "DISTRICTNAME_AR": "العطيان",
    "DISTRICTNAME_EN": "Al Atyan",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4653",
    "DISTRICTNAME_AR": "المسميات 1",
    "DISTRICTNAME_EN": "Al Musamayat 1",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4654",
    "DISTRICTNAME_AR": "الحلوة",
    "DISTRICTNAME_EN": "Al Hulwa",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4655",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4656",
    "DISTRICTNAME_AR": "البيداء",
    "DISTRICTNAME_EN": "Albayda'",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4657",
    "DISTRICTNAME_AR": "الغيوم",
    "DISTRICTNAME_EN": "Al Ghyom ",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4658",
    "DISTRICTNAME_AR": "الضيافة",
    "DISTRICTNAME_EN": "Ad Diyafah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4659",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4660",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4661",
    "DISTRICTNAME_AR": "القويع",
    "DISTRICTNAME_EN": "Al Qawye",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4662",
    "DISTRICTNAME_AR": "الكريس",
    "DISTRICTNAME_EN": "Al Kris",
    "CITY_ID": "21274",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "4663",
    "DISTRICTNAME_AR": "تهامة",
    "DISTRICTNAME_EN": "Tihamah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4664",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "Al Qadisiyah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4665",
    "DISTRICTNAME_AR": "الإبتسام",
    "DISTRICTNAME_EN": "Al Ebtesam",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4666",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4667",
    "DISTRICTNAME_AR": "القفيل",
    "DISTRICTNAME_EN": "Al Qafayl",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4668",
    "DISTRICTNAME_AR": "الوئام",
    "DISTRICTNAME_EN": "Al Wiam",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4669",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4670",
    "DISTRICTNAME_AR": "اليمامة",
    "DISTRICTNAME_EN": "Al Yamamah",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4671",
    "DISTRICTNAME_AR": "الفيصل",
    "DISTRICTNAME_EN": "Al Faisal",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4672",
    "DISTRICTNAME_AR": "الجوهرة",
    "DISTRICTNAME_EN": "Al Jawharah",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4673",
    "DISTRICTNAME_AR": "الشلال",
    "DISTRICTNAME_EN": "Ash Shalaal",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4674",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "Ar Rimal",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4675",
    "DISTRICTNAME_AR": "القلعة",
    "DISTRICTNAME_EN": "Al Qalah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4676",
    "DISTRICTNAME_AR": "الوسام",
    "DISTRICTNAME_EN": "Al Wasam",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4677",
    "DISTRICTNAME_AR": "البهجة",
    "DISTRICTNAME_EN": "Al Bahja",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4678",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4679",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4680",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4681",
    "DISTRICTNAME_AR": "الوادي الأخضر",
    "DISTRICTNAME_EN": "Al Wadi Al Akhdar",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4682",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4683",
    "DISTRICTNAME_AR": "الجود",
    "DISTRICTNAME_EN": "Al Jud",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4684",
    "DISTRICTNAME_AR": "العبير",
    "DISTRICTNAME_EN": "Al Abeer",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4685",
    "DISTRICTNAME_AR": "الرحابة",
    "DISTRICTNAME_EN": "Ar Rehabah",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4686",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "At Taawun",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4687",
    "DISTRICTNAME_AR": "الازهار",
    "DISTRICTNAME_EN": "Al Azhar",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4688",
    "DISTRICTNAME_AR": "الرهوة",
    "DISTRICTNAME_EN": "Ar Rawh",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4689",
    "DISTRICTNAME_AR": "الواحات",
    "DISTRICTNAME_EN": "AlWahat",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4690",
    "DISTRICTNAME_AR": "البشائر",
    "DISTRICTNAME_EN": "Al Bashaer",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4691",
    "DISTRICTNAME_AR": "الوفاق",
    "DISTRICTNAME_EN": "Al Wefaq",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4692",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "Al Muntazah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4693",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4694",
    "DISTRICTNAME_AR": "الفهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4695",
    "DISTRICTNAME_AR": "الأرجوان",
    "DISTRICTNAME_EN": "Al Arjiwan",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4696",
    "DISTRICTNAME_AR": "الزمرد",
    "DISTRICTNAME_EN": "Az Zomorod",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4697",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4698",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4699",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4700",
    "DISTRICTNAME_AR": "الرونة",
    "DISTRICTNAME_EN": "Ar Rawnah",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4701",
    "DISTRICTNAME_AR": "الزاهر",
    "DISTRICTNAME_EN": "Az Zahir",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4702",
    "DISTRICTNAME_AR": "الجواهر",
    "DISTRICTNAME_EN": "Al Jawahir",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4703",
    "DISTRICTNAME_AR": "العين",
    "DISTRICTNAME_EN": "Al Ain",
    "CITY_ID": "3696",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4704",
    "DISTRICTNAME_AR": "الأمل",
    "DISTRICTNAME_EN": "Al Amal",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4705",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4706",
    "DISTRICTNAME_AR": "الرائد",
    "DISTRICTNAME_EN": "Al Raed",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4707",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4708",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4709",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4710",
    "DISTRICTNAME_AR": "النور",
    "DISTRICTNAME_EN": "An Noor",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4711",
    "DISTRICTNAME_AR": "السد",
    "DISTRICTNAME_EN": "As Sad",
    "CITY_ID": "8768",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4712",
    "DISTRICTNAME_AR": "الغيم",
    "DISTRICTNAME_EN": "Al Ghe'm",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4713",
    "DISTRICTNAME_AR": "المصائف",
    "DISTRICTNAME_EN": "Al Masayif",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4714",
    "DISTRICTNAME_AR": "الهجرة",
    "DISTRICTNAME_EN": "Al Hijrah",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4715",
    "DISTRICTNAME_AR": "السهول",
    "DISTRICTNAME_EN": "Alsuhul",
    "CITY_ID": "1463",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4716",
    "DISTRICTNAME_AR": "النجاح",
    "DISTRICTNAME_EN": "Al Najah",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4717",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "3554",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4718",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "3554",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "4719",
    "DISTRICTNAME_AR": "الرضوان",
    "DISTRICTNAME_EN": "Al Radwan",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4720",
    "DISTRICTNAME_AR": "البدر",
    "DISTRICTNAME_EN": "Al Badr",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4721",
    "DISTRICTNAME_AR": "عين شمس",
    "DISTRICTNAME_EN": "Eayan Shams",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4722",
    "DISTRICTNAME_AR": "الغزيات",
    "DISTRICTNAME_EN": "Al Ghaziat",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4723",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4724",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "Ashuhada",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4725",
    "DISTRICTNAME_AR": "الغروب",
    "DISTRICTNAME_EN": "Al Ghurub",
    "CITY_ID": "12042",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4726",
    "DISTRICTNAME_AR": "السدر",
    "DISTRICTNAME_EN": "Al Sudr",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4727",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4728",
    "DISTRICTNAME_AR": "النقابة",
    "DISTRICTNAME_EN": "Al Naqabah",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4729",
    "DISTRICTNAME_AR": "أبو عروه",
    "DISTRICTNAME_EN": "Abo Aurwa",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4730",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4731",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4732",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4733",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "16515",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4734",
    "DISTRICTNAME_AR": "حداء",
    "DISTRICTNAME_EN": "Hada'",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4735",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4736",
    "DISTRICTNAME_AR": "الطيبات",
    "DISTRICTNAME_EN": "Al Tayebat",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4737",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4738",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4739",
    "DISTRICTNAME_AR": "الصفوة",
    "DISTRICTNAME_EN": "As safwa",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4740",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4741",
    "DISTRICTNAME_AR": "الكوثر",
    "DISTRICTNAME_EN": "Al Kawthar",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4742",
    "DISTRICTNAME_AR": "المدار",
    "DISTRICTNAME_EN": "Al Madar",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4743",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4744",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4745",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4746",
    "DISTRICTNAME_AR": "الأصالة",
    "DISTRICTNAME_EN": "Al Asala",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4747",
    "DISTRICTNAME_AR": "المستقبل",
    "DISTRICTNAME_EN": "Al Mustaqbal",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4748",
    "DISTRICTNAME_AR": "العين",
    "DISTRICTNAME_EN": "Al Ain",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4749",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4750",
    "DISTRICTNAME_AR": "الفنار",
    "DISTRICTNAME_EN": "Al Fanar",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4751",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "Az Zahra",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4752",
    "DISTRICTNAME_AR": "الندوة",
    "DISTRICTNAME_EN": "Al Nadwah",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4753",
    "DISTRICTNAME_AR": "الجزيرة",
    "DISTRICTNAME_EN": "Al Jazirah",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4754",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4755",
    "DISTRICTNAME_AR": "الشفق",
    "DISTRICTNAME_EN": "Al Shafaq",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4756",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4757",
    "DISTRICTNAME_AR": "العذوبة",
    "DISTRICTNAME_EN": "AlOthoba",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4758",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4759",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4760",
    "DISTRICTNAME_AR": "الشرفة",
    "DISTRICTNAME_EN": "Al Shurfa",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4761",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "15618",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4762",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "Al Izdihar",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4763",
    "DISTRICTNAME_AR": "الجوزاء",
    "DISTRICTNAME_EN": "Al Jawza'a",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4764",
    "DISTRICTNAME_AR": "الديرة",
    "DISTRICTNAME_EN": "Ad Dirah",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4765",
    "DISTRICTNAME_AR": "الانصار",
    "DISTRICTNAME_EN": "Al Ansar",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4766",
    "DISTRICTNAME_AR": "الريم",
    "DISTRICTNAME_EN": "Alriym",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4767",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4768",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "Al Basatin",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4769",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4770",
    "DISTRICTNAME_AR": "البيرق",
    "DISTRICTNAME_EN": "Al Bayraq",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4771",
    "DISTRICTNAME_AR": "البيعة",
    "DISTRICTNAME_EN": "Al Bayea",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4772",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4773",
    "DISTRICTNAME_AR": "السعيد",
    "DISTRICTNAME_EN": "As Saaid",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4774",
    "DISTRICTNAME_AR": "الوداد",
    "DISTRICTNAME_EN": "Al Widad",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4775",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "Ar Rayah",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4776",
    "DISTRICTNAME_AR": "الريحان",
    "DISTRICTNAME_EN": "Ar Raihan",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4777",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "Al Fath",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4778",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4779",
    "DISTRICTNAME_AR": "المعالي",
    "DISTRICTNAME_EN": "Al Maali",
    "CITY_ID": "3553",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4780",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4781",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "An Nada",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4782",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4783",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4784",
    "DISTRICTNAME_AR": "الدرة",
    "DISTRICTNAME_EN": "Al Durra",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4785",
    "DISTRICTNAME_AR": "مطارالملك سعود",
    "DISTRICTNAME_EN": "King Saud Airport",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4786",
    "DISTRICTNAME_AR": "المكارم",
    "DISTRICTNAME_EN": "Al Mukaram",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4787",
    "DISTRICTNAME_AR": "الديوان",
    "DISTRICTNAME_EN": "Ad Daywan",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4788",
    "DISTRICTNAME_AR": "الأمواج",
    "DISTRICTNAME_EN": "Al Amwaj",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4789",
    "DISTRICTNAME_AR": "المنتزهات",
    "DISTRICTNAME_EN": "Al Mutanazahat",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4790",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4791",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4792",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4793",
    "DISTRICTNAME_AR": "الضباب",
    "DISTRICTNAME_EN": "Al Dabab",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4794",
    "DISTRICTNAME_AR": "الحجاز",
    "DISTRICTNAME_EN": "Al Hijaz",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4795",
    "DISTRICTNAME_AR": "الحسام",
    "DISTRICTNAME_EN": "Al Hussam",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4796",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4797",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4798",
    "DISTRICTNAME_AR": "الرحمة",
    "DISTRICTNAME_EN": "Ar Rahmah",
    "CITY_ID": "18095",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4799",
    "DISTRICTNAME_AR": "الفجر",
    "DISTRICTNAME_EN": "Al-Fajr",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4800",
    "DISTRICTNAME_AR": "الفلاح",
    "DISTRICTNAME_EN": "Al Falah",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4801",
    "DISTRICTNAME_AR": "السوق",
    "DISTRICTNAME_EN": "As Suq",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4802",
    "DISTRICTNAME_AR": "السروات",
    "DISTRICTNAME_EN": "As Sarawat",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4803",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4804",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4805",
    "DISTRICTNAME_AR": "السندس",
    "DISTRICTNAME_EN": "Al Sundus",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4806",
    "DISTRICTNAME_AR": "الياقوت",
    "DISTRICTNAME_EN": "Al Yaqoot",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4807",
    "DISTRICTNAME_AR": "السنابل",
    "DISTRICTNAME_EN": "As Sanabel",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4808",
    "DISTRICTNAME_AR": "السوسن",
    "DISTRICTNAME_EN": "Al Sawsan",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4809",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "7978",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4810",
    "DISTRICTNAME_AR": "المودة",
    "DISTRICTNAME_EN": "Al Mawada",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4811",
    "DISTRICTNAME_AR": "الرمان",
    "DISTRICTNAME_EN": "Al Rumaan",
    "CITY_ID": "18224",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4812",
    "DISTRICTNAME_AR": "السحاب",
    "DISTRICTNAME_EN": "Al Sahab",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4813",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "Al Manar",
    "CITY_ID": "14244",
    "REGION_ID": 12
   },
   {
    "DISTRICT_ID": "4814",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4815",
    "DISTRICTNAME_AR": "دحضة",
    "DISTRICTNAME_EN": "Dahdah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4816",
    "DISTRICTNAME_AR": "ماقان",
    "DISTRICTNAME_EN": "Maqan",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4818",
    "DISTRICTNAME_AR": "المنتزة1",
    "DISTRICTNAME_EN": "Al Muntazah1",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4819",
    "DISTRICTNAME_AR": "المطار",
    "DISTRICTNAME_EN": "Airport",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4820",
    "DISTRICTNAME_AR": "المركب",
    "DISTRICTNAME_EN": "Al Markab",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4821",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4822",
    "DISTRICTNAME_AR": "صحيفات",
    "DISTRICTNAME_EN": "Sahifat",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4823",
    "DISTRICTNAME_AR": "أبو غبار",
    "DISTRICTNAME_EN": "Abu Ghbar",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4824",
    "DISTRICTNAME_AR": "تصلال",
    "DISTRICTNAME_EN": "Taslal",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4825",
    "DISTRICTNAME_AR": "الشرفة",
    "DISTRICTNAME_EN": "Al Shurfa",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4826",
    "DISTRICTNAME_AR": "المخيم",
    "DISTRICTNAME_EN": "The Camp",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4827",
    "DISTRICTNAME_AR": "ال منجم",
    "DISTRICTNAME_EN": "Al Manjam",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4828",
    "DISTRICTNAME_AR": "المخباة",
    "DISTRICTNAME_EN": "Al Makhaba",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4829",
    "DISTRICTNAME_AR": "شعب بران",
    "DISTRICTNAME_EN": "Shuab Bran",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4830",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4831",
    "DISTRICTNAME_AR": "المسماه",
    "DISTRICTNAME_EN": "Al Musmaah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4832",
    "DISTRICTNAME_AR": "الدارة",
    "DISTRICTNAME_EN": "Al Daara",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4833",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4834",
    "DISTRICTNAME_AR": "المعامر",
    "DISTRICTNAME_EN": "Al Maamir",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4835",
    "DISTRICTNAME_AR": "الأمير مشعل",
    "DISTRICTNAME_EN": "Prince Mishal",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4836",
    "DISTRICTNAME_AR": "الجربة",
    "DISTRICTNAME_EN": "Al Jurbah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4837",
    "DISTRICTNAME_AR": "بئر عسكر",
    "DISTRICTNAME_EN": "Askar well",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4838",
    "DISTRICTNAME_AR": "المراطة",
    "DISTRICTNAME_EN": "Al Maratah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4839",
    "DISTRICTNAME_AR": "زور العماري",
    "DISTRICTNAME_EN": "Zawr Al Ammari",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4840",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4842",
    "DISTRICTNAME_AR": "الحايرة",
    "DISTRICTNAME_EN": "Al Hayirah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4843",
    "DISTRICTNAME_AR": "عكام",
    "DISTRICTNAME_EN": "Ukaam",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4844",
    "DISTRICTNAME_AR": "البطحاء",
    "DISTRICTNAME_EN": "Al Batha",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4845",
    "DISTRICTNAME_AR": "بني سلمان",
    "DISTRICTNAME_EN": "Bani Salman",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4846",
    "DISTRICTNAME_AR": "العان",
    "DISTRICTNAME_EN": "Al Aan",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4847",
    "DISTRICTNAME_AR": "صهبان",
    "DISTRICTNAME_EN": "Sahban",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4848",
    "DISTRICTNAME_AR": "النصلة",
    "DISTRICTNAME_EN": "An Naslah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4849",
    "DISTRICTNAME_AR": "الغويلا",
    "DISTRICTNAME_EN": "Al Ghiwila",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4850",
    "DISTRICTNAME_AR": "الشبهان",
    "DISTRICTNAME_EN": "Al Shabhan",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4851",
    "DISTRICTNAME_AR": "برك",
    "DISTRICTNAME_EN": "Barak",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4852",
    "DISTRICTNAME_AR": "ريمان",
    "DISTRICTNAME_EN": "Rayman",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4853",
    "DISTRICTNAME_AR": "فواز",
    "DISTRICTNAME_EN": "Fawaz",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4854",
    "DISTRICTNAME_AR": "الجفة",
    "DISTRICTNAME_EN": "Al Jafah",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4855",
    "DISTRICTNAME_AR": "المجمع",
    "DISTRICTNAME_EN": "Al Majma",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4856",
    "DISTRICTNAME_AR": "بني هميم",
    "DISTRICTNAME_EN": "Bani Hamim",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4858",
    "DISTRICTNAME_AR": "سلوة",
    "DISTRICTNAME_EN": "Sulwa",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4859",
    "DISTRICTNAME_AR": "المنتشر",
    "DISTRICTNAME_EN": "Al Muntashir",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4860",
    "DISTRICTNAME_AR": "القابل",
    "DISTRICTNAME_EN": "Al Qabil",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4861",
    "DISTRICTNAME_AR": "حمود السمال",
    "DISTRICTNAME_EN": "Hammoud Al Smal",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4862",
    "DISTRICTNAME_AR": "الحضن",
    "DISTRICTNAME_EN": "Al Hadn",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4863",
    "DISTRICTNAME_AR": "الخرعاء",
    "DISTRICTNAME_EN": "Al Khuraa",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4864",
    "DISTRICTNAME_AR": "بئر أبين",
    "DISTRICTNAME_EN": "Bir Abayn",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4865",
    "DISTRICTNAME_AR": "منطقة الأخدود الأثرية",
    "DISTRICTNAME_EN": "Al Okhdood Archaeological Area",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4866",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4867",
    "DISTRICTNAME_AR": "الفهد",
    "DISTRICTNAME_EN": "Al Fahd",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4868",
    "DISTRICTNAME_AR": "الرويكبة",
    "DISTRICTNAME_EN": "Al Ruwaikibah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4869",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4870",
    "DISTRICTNAME_AR": "بطا",
    "DISTRICTNAME_EN": "Bata",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4871",
    "DISTRICTNAME_AR": "الحرشت",
    "DISTRICTNAME_EN": "Al Harasht",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4872",
    "DISTRICTNAME_AR": "السرح",
    "DISTRICTNAME_EN": "Al Sarh",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4873",
    "DISTRICTNAME_AR": "الملحين",
    "DISTRICTNAME_EN": "Al Milhayn",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4874",
    "DISTRICTNAME_AR": "الجنجت 1",
    "DISTRICTNAME_EN": "Al Janjat 1",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4875",
    "DISTRICTNAME_AR": "حبونا",
    "DISTRICTNAME_EN": "Habuwna",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4877",
    "DISTRICTNAME_AR": "سقام",
    "DISTRICTNAME_EN": "Suqam",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4878",
    "DISTRICTNAME_AR": "أبا السعود",
    "DISTRICTNAME_EN": "Aba Al Saud",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4879",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4880",
    "DISTRICTNAME_AR": "الشروق",
    "DISTRICTNAME_EN": "Ash Shrouk",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4881",
    "DISTRICTNAME_AR": "شعب رير",
    "DISTRICTNAME_EN": "Shiab Reer",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4882",
    "DISTRICTNAME_AR": "الحصين",
    "DISTRICTNAME_EN": "Al Husayn",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4883",
    "DISTRICTNAME_AR": "زور وادعة",
    "DISTRICTNAME_EN": "Zawr Wadiah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4884",
    "DISTRICTNAME_AR": "الضيافة",
    "DISTRICTNAME_EN": "Ad Diyafah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4885",
    "DISTRICTNAME_AR": "نهوقة",
    "DISTRICTNAME_EN": "Nahuqah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4886",
    "DISTRICTNAME_AR": "أبا الرشاش",
    "DISTRICTNAME_EN": "Aba Al Rashash",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4887",
    "DISTRICTNAME_AR": "الغيضة",
    "DISTRICTNAME_EN": "Al Ghayda",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4888",
    "DISTRICTNAME_AR": "بني كلب",
    "DISTRICTNAME_EN": "Bani Kalb",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4889",
    "DISTRICTNAME_AR": "الأثايبة",
    "DISTRICTNAME_EN": "Al Athayiba",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4890",
    "DISTRICTNAME_AR": "الخضراء",
    "DISTRICTNAME_EN": "Al Khadraa",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4891",
    "DISTRICTNAME_AR": "الخليف",
    "DISTRICTNAME_EN": "Al Khalif",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4892",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4893",
    "DISTRICTNAME_AR": "سايلة غزال",
    "DISTRICTNAME_EN": "Saylat Ghazal",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4894",
    "DISTRICTNAME_AR": "الحصينية",
    "DISTRICTNAME_EN": "Al Husayniyah",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4895",
    "DISTRICTNAME_AR": "جنوب الحصينية",
    "DISTRICTNAME_EN": "South of Al Husayniyah",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4896",
    "DISTRICTNAME_AR": "مليحة",
    "DISTRICTNAME_EN": "Mlihah",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4897",
    "DISTRICTNAME_AR": "منتزة الملك فهد بن عبدالعزيز",
    "DISTRICTNAME_EN": "King Fahd bin Abdulaziz Park",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4898",
    "DISTRICTNAME_AR": "الفاجي",
    "DISTRICTNAME_EN": "Al Faji",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4899",
    "DISTRICTNAME_AR": "رجلا",
    "DISTRICTNAME_EN": "Rajula",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4900",
    "DISTRICTNAME_AR": "ال منيف",
    "DISTRICTNAME_EN": "Al Munif",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4901",
    "DISTRICTNAME_AR": "شمال الحصينية",
    "DISTRICTNAME_EN": "North  Al Husayniyah",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4902",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4903",
    "DISTRICTNAME_AR": "الغدير",
    "DISTRICTNAME_EN": "Al Ghadir",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4904",
    "DISTRICTNAME_AR": "الفردوس",
    "DISTRICTNAME_EN": "Al Ferdous",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4905",
    "DISTRICTNAME_AR": "الياسمين",
    "DISTRICTNAME_EN": "Al Yasmin",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4906",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4907",
    "DISTRICTNAME_AR": "القعصوم",
    "DISTRICTNAME_EN": "Al Qasoom",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4908",
    "DISTRICTNAME_AR": "العريسة",
    "DISTRICTNAME_EN": "Al Uraysah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4909",
    "DISTRICTNAME_AR": "الأملاح",
    "DISTRICTNAME_EN": "Al Amalah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4910",
    "DISTRICTNAME_AR": "الضباط",
    "DISTRICTNAME_EN": "Al Dhubbat",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4911",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4912",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "Al Murjan",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4913",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4914",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4915",
    "DISTRICTNAME_AR": "العروبة",
    "DISTRICTNAME_EN": "Al Urubah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4916",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4919",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4920",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "Al Zuhur",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4921",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "19373",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "4922",
    "DISTRICTNAME_AR": "الدانة",
    "DISTRICTNAME_EN": "Ad Danah ",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4923",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "Al Andalus",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4924",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4925",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4926",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4927",
    "DISTRICTNAME_AR": "أبا الخريت",
    "DISTRICTNAME_EN": "Aba Al Kharit",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "4936",
    "DISTRICTNAME_AR": "الحدائق",
    "DISTRICTNAME_EN": "Al Hadaek",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4937",
    "DISTRICTNAME_AR": "أم السباع",
    "DISTRICTNAME_EN": "Umm Al Sabaa ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4938",
    "DISTRICTNAME_AR": "الوشحاء",
    "DISTRICTNAME_EN": "Al Washha",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4939",
    "DISTRICTNAME_AR": "المعارض",
    "DISTRICTNAME_EN": "Al Maarid",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4940",
    "DISTRICTNAME_AR": "المثناه",
    "DISTRICTNAME_EN": "Al Mathnah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4941",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "Industrial Area",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4942",
    "DISTRICTNAME_AR": "القرشيات",
    "DISTRICTNAME_EN": "Al Qurashiaat",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4943",
    "DISTRICTNAME_AR": "سديرة",
    "DISTRICTNAME_EN": "Sudayrah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4951",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "Al Nakhil",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4952",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "Ar Rabwah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4953",
    "DISTRICTNAME_AR": "الفيحاء",
    "DISTRICTNAME_EN": "Al Fayha",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4954",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "Al Wadi",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4955",
    "DISTRICTNAME_AR": "النايفية",
    "DISTRICTNAME_EN": "An Nayfiyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4956",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "Ar Rayaan",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4957",
    "DISTRICTNAME_AR": "قروى",
    "DISTRICTNAME_EN": "Qurwa ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4958",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "An Narjis",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4959",
    "DISTRICTNAME_AR": "حوايا الشمالية",
    "DISTRICTNAME_EN": "Huwaya Ash Shamaliyyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4960",
    "DISTRICTNAME_AR": "النعيم",
    "DISTRICTNAME_EN": "An Naim",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4961",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4962",
    "DISTRICTNAME_AR": "لية السفلي",
    "DISTRICTNAME_EN": "Liat Al Sufli",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4963",
    "DISTRICTNAME_AR": "القراحين",
    "DISTRICTNAME_EN": "Alqrahin",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4964",
    "DISTRICTNAME_AR": "مطار الطائف الدولي",
    "DISTRICTNAME_EN": "Taif International Airport",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4965",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4966",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "Ash Shifa",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4967",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4968",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4969",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "Al Masif",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4970",
    "DISTRICTNAME_AR": "البلدية",
    "DISTRICTNAME_EN": "Al Baladiyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4971",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4972",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "Al Faisaliyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4973",
    "DISTRICTNAME_AR": "فليج",
    "DISTRICTNAME_EN": "Fulaij",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4974",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4975",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "Ar Rabie",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4976",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "As Safa",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4977",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "Al Muruj",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4978",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4979",
    "DISTRICTNAME_AR": "الواحة",
    "DISTRICTNAME_EN": "Al Wahah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4980",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4981",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "As Salamah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4982",
    "DISTRICTNAME_AR": "الحامدية",
    "DISTRICTNAME_EN": "Al Hamidiyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4983",
    "DISTRICTNAME_AR": "مثملة",
    "DISTRICTNAME_EN": "Masmla",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4984",
    "DISTRICTNAME_AR": "القطبية الشرقية",
    "DISTRICTNAME_EN": "Al Qutbiah Al Sharqia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4985",
    "DISTRICTNAME_AR": "الفريدة",
    "DISTRICTNAME_EN": "Al Farida",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4986",
    "DISTRICTNAME_AR": "القاعدة الجوية",
    "DISTRICTNAME_EN": "The Air Base",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4987",
    "DISTRICTNAME_AR": "الجفيجف",
    "DISTRICTNAME_EN": "Al Jafijif",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4988",
    "DISTRICTNAME_AR": "جباجب",
    "DISTRICTNAME_EN": "Jabajib",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4989",
    "DISTRICTNAME_AR": "(حي رقم (8",
    "DISTRICTNAME_EN": "District No. 8",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4990",
    "DISTRICTNAME_AR": "(حي رقم (1",
    "DISTRICTNAME_EN": "District No. 1",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4991",
    "DISTRICTNAME_AR": "(حي رقم (3",
    "DISTRICTNAME_EN": "District No. 3",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "4992",
    "DISTRICTNAME_AR": "أبو موسى الأشعري",
    "DISTRICTNAME_EN": "Abu Musa Al Ashari",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4993",
    "DISTRICTNAME_AR": "الباطن",
    "DISTRICTNAME_EN": "Al Batin",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4994",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4995",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4996",
    "DISTRICTNAME_AR": "التلال",
    "DISTRICTNAME_EN": "Al Talal",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4997",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4998",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "Qurtubah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "4999",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5000",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5001",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "Al Yarmuk",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5008",
    "DISTRICTNAME_AR": "الشهداء الجنوبية",
    "DISTRICTNAME_EN": "Ashuhada Aljanubiyyah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5009",
    "DISTRICTNAME_AR": "شهار",
    "DISTRICTNAME_EN": "Shihar ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5010",
    "DISTRICTNAME_AR": "المروة",
    "DISTRICTNAME_EN": "Al Marwah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5011",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5012",
    "DISTRICTNAME_AR": "(حي رقم (9",
    "DISTRICTNAME_EN": "District No. 9",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5013",
    "DISTRICTNAME_AR": "(حي رقم (6",
    "DISTRICTNAME_EN": "District No. 6",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5014",
    "DISTRICTNAME_AR": "(حي رقم (10",
    "DISTRICTNAME_EN": "District No. 10",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5015",
    "DISTRICTNAME_AR": "(حي رقم (4",
    "DISTRICTNAME_EN": "District No. 4",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5016",
    "DISTRICTNAME_AR": "(حي رقم (5",
    "DISTRICTNAME_EN": "District No. 5",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5017",
    "DISTRICTNAME_AR": "(حي رقم (7",
    "DISTRICTNAME_EN": "District No. 7",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5018",
    "DISTRICTNAME_AR": "(حي رقم (2",
    "DISTRICTNAME_EN": "District No. 2",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5019",
    "DISTRICTNAME_AR": "الرميدة",
    "DISTRICTNAME_EN": "Al Rumeida",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5025",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5029",
    "DISTRICTNAME_AR": "عطف الوزران",
    "DISTRICTNAME_EN": "Ataf Al Wizran",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5033",
    "DISTRICTNAME_AR": "العرج الأعلى",
    "DISTRICTNAME_EN": "Al Urj Al Aala",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5034",
    "DISTRICTNAME_AR": "العرج الأسفل",
    "DISTRICTNAME_EN": "Al Urj Al Asfal",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5036",
    "DISTRICTNAME_AR": "الاغر",
    "DISTRICTNAME_EN": "Al Aghar",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5037",
    "DISTRICTNAME_AR": "المستشفى",
    "DISTRICTNAME_EN": "The Hospital",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5041",
    "DISTRICTNAME_AR": "بني سهيم",
    "DISTRICTNAME_EN": "Bani Seheem",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5042",
    "DISTRICTNAME_AR": "غليل",
    "DISTRICTNAME_EN": "Ghulail",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5043",
    "DISTRICTNAME_AR": "مخطط المدارات",
    "DISTRICTNAME_EN": "Al Madarat Subdivision",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5045",
    "DISTRICTNAME_AR": "الصبغان",
    "DISTRICTNAME_EN": "Al Sabghan",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5046",
    "DISTRICTNAME_AR": "الخزامى",
    "DISTRICTNAME_EN": "Al Khuzama",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5047",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5055",
    "DISTRICTNAME_AR": "حي ج2",
    "DISTRICTNAME_EN": "Dst 2C",
    "CITY_ID": "16791",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5056",
    "DISTRICTNAME_AR": "الذنبة",
    "DISTRICTNAME_EN": "Al Dhanbuh",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5058",
    "DISTRICTNAME_AR": "المبني الجنوبي",
    "DISTRICTNAME_EN": "South Building",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5060",
    "DISTRICTNAME_AR": "البارك",
    "DISTRICTNAME_EN": "Al Bark",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5061",
    "DISTRICTNAME_AR": "المبني الشمالي",
    "DISTRICTNAME_EN": "North Building",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5062",
    "DISTRICTNAME_AR": "نمرة",
    "DISTRICTNAME_EN": "Namaruh",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5063",
    "DISTRICTNAME_AR": "مخطط الحمراني",
    "DISTRICTNAME_EN": "South Namrah Subdivision",
    "CITY_ID": "18543",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5064",
    "DISTRICTNAME_AR": "السلم",
    "DISTRICTNAME_EN": "Al Sulam",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5065",
    "DISTRICTNAME_AR": "الجدر الغربي",
    "DISTRICTNAME_EN": "Al Judr Al Gharbi",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5066",
    "DISTRICTNAME_AR": "الملعب",
    "DISTRICTNAME_EN": "Al Maleab",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5067",
    "DISTRICTNAME_AR": "اكتان",
    "DISTRICTNAME_EN": "Aktan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5068",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "Ar Rehab",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5073",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5074",
    "DISTRICTNAME_AR": "امتداد الشمال",
    "DISTRICTNAME_EN": "North Extension",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5075",
    "DISTRICTNAME_AR": "الملحة 2",
    "DISTRICTNAME_EN": "Al Muliha 2",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5078",
    "DISTRICTNAME_AR": "النطوف",
    "DISTRICTNAME_EN": "Al Nutuf",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5080",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5081",
    "DISTRICTNAME_AR": "كويكب",
    "DISTRICTNAME_EN": "Kuaykib",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5082",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "Badr",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5083",
    "DISTRICTNAME_AR": "أم ثمام",
    "DISTRICTNAME_EN": "Umm Thamam",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5084",
    "DISTRICTNAME_AR": "العرفاء الغربية",
    "DISTRICTNAME_EN": "Western Al Urafa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5085",
    "DISTRICTNAME_AR": "منتزة الطائف الوطني بسيسد",
    "DISTRICTNAME_EN": "Taif National Park in Sisad",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5087",
    "DISTRICTNAME_AR": "الجدر الشرقي",
    "DISTRICTNAME_EN": "Al Judr Al Sharqiu",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5088",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5089",
    "DISTRICTNAME_AR": "أم راكة",
    "DISTRICTNAME_EN": "Umm Rakah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5090",
    "DISTRICTNAME_AR": "حي ج4",
    "DISTRICTNAME_EN": "Dst C4",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5092",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "Al Khalij",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5093",
    "DISTRICTNAME_AR": "الناصرية",
    "DISTRICTNAME_EN": "An Nasriyah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5094",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5095",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "Al Hazm",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5098",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5099",
    "DISTRICTNAME_AR": "السوق القديم",
    "DISTRICTNAME_EN": "As Suq Al Qadim",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5101",
    "DISTRICTNAME_AR": "جامعة الطائف الجديدة",
    "DISTRICTNAME_EN": "New Taif University",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5102",
    "DISTRICTNAME_AR": "الحجيرة",
    "DISTRICTNAME_EN": "Al Hujayra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5103",
    "DISTRICTNAME_AR": "قنة وقدان",
    "DISTRICTNAME_EN": "Qanat and Qdan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5105",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "Al Muhammadiyah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5106",
    "DISTRICTNAME_AR": "أم حشانة",
    "DISTRICTNAME_EN": "Umm Hashana",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5107",
    "DISTRICTNAME_AR": "المعيزيلة",
    "DISTRICTNAME_EN": "Al Maizialah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5108",
    "DISTRICTNAME_AR": "الهجرة",
    "DISTRICTNAME_EN": "Al Hijrah",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5109",
    "DISTRICTNAME_AR": "حي ج1",
    "DISTRICTNAME_EN": "Dst C1",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5114",
    "DISTRICTNAME_AR": "الأخباب",
    "DISTRICTNAME_EN": "Al Akhbab",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5115",
    "DISTRICTNAME_AR": "الحرس الوطني",
    "DISTRICTNAME_EN": "National Guard",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5116",
    "DISTRICTNAME_AR": "البيهيتة",
    "DISTRICTNAME_EN": "Al Bihita",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5117",
    "DISTRICTNAME_AR": "أم الرصف",
    "DISTRICTNAME_EN": "Umm Al Rasf",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5118",
    "DISTRICTNAME_AR": "عثواء",
    "DISTRICTNAME_EN": "Euthawa'",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5119",
    "DISTRICTNAME_AR": "قلعة شنقل",
    "DISTRICTNAME_EN": "Qaleat Shanqal",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5120",
    "DISTRICTNAME_AR": "الإسكان",
    "DISTRICTNAME_EN": "Al Iskan",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5121",
    "DISTRICTNAME_AR": "ضاحية القويعية",
    "DISTRICTNAME_EN": "Dahiyat Al-Quway'iyah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5122",
    "DISTRICTNAME_AR": "ضاحية الحائرية",
    "DISTRICTNAME_EN": "Dahiyat Ha'iriyah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5123",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5124",
    "DISTRICTNAME_AR": "جبرة",
    "DISTRICTNAME_EN": "Jabrah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5125",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "Al Aqiq",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5126",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "Ghirnatah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5127",
    "DISTRICTNAME_AR": "اللبط",
    "DISTRICTNAME_EN": "Al Labt",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5128",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "Al Naseem",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5129",
    "DISTRICTNAME_AR": "العلاوة الغربية",
    "DISTRICTNAME_EN": "Western Al Eilawah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5130",
    "DISTRICTNAME_AR": "السليمانية",
    "DISTRICTNAME_EN": "As Sulaymaniyah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5131",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "Al Khalidiyah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5132",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "An Nahdah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5133",
    "DISTRICTNAME_AR": "مزارع حمدان",
    "DISTRICTNAME_EN": "Hamdan Farms",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5134",
    "DISTRICTNAME_AR": "القلت",
    "DISTRICTNAME_EN": "Al Qult",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5135",
    "DISTRICTNAME_AR": "خد الحاج",
    "DISTRICTNAME_EN": "Khada Al Haji",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5136",
    "DISTRICTNAME_AR": "مزارع بن خشمان",
    "DISTRICTNAME_EN": "Bin Khashman Farms",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5137",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "Industrial",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5138",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "An Nuzhah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5139",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "Al Aziziyah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5140",
    "DISTRICTNAME_AR": "العلاوة الشرقية",
    "DISTRICTNAME_EN": "Eastern Al Eilawah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5141",
    "DISTRICTNAME_AR": "العدل",
    "DISTRICTNAME_EN": "Al Adel",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5142",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "Al Wurud",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5143",
    "DISTRICTNAME_AR": "وادي جليل",
    "DISTRICTNAME_EN": "Wadi Jalil",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5144",
    "DISTRICTNAME_AR": "وادي محرم الأعلي",
    "DISTRICTNAME_EN": "Wadi Muharam Al Aaela",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5145",
    "DISTRICTNAME_AR": "الشقرة",
    "DISTRICTNAME_EN": "Ash Shuqra",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5146",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "At Tahliyah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5147",
    "DISTRICTNAME_AR": "سمنان",
    "DISTRICTNAME_EN": "Semnan",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5148",
    "DISTRICTNAME_AR": "الجامعة",
    "DISTRICTNAME_EN": "Al Jamiah",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5149",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "Ar Rawabi",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5150",
    "DISTRICTNAME_AR": "القديم",
    "DISTRICTNAME_EN": "Al Qadim",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5151",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "As Salam",
    "CITY_ID": "18485",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5152",
    "DISTRICTNAME_AR": "ريحان",
    "DISTRICTNAME_EN": "Rayhan",
    "CITY_ID": "18485",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5154",
    "DISTRICTNAME_AR": "الصيانة",
    "DISTRICTNAME_EN": "Al Siana",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5155",
    "DISTRICTNAME_AR": "عودة",
    "DISTRICTNAME_EN": "Awdah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5156",
    "DISTRICTNAME_AR": "حوايا الجنوبية",
    "DISTRICTNAME_EN": "Huwaya Al Janoubiyah ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5157",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "Ar Rawdah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5158",
    "DISTRICTNAME_AR": "واحة التقنية",
    "DISTRICTNAME_EN": "Technology Oasis",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5159",
    "DISTRICTNAME_AR": "الخضيرة",
    "DISTRICTNAME_EN": "Hadera ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5171",
    "DISTRICTNAME_AR": "أم العراد",
    "DISTRICTNAME_EN": "Umm Alarad ",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5172",
    "DISTRICTNAME_AR": "عكرمة",
    "DISTRICTNAME_EN": "Eikrima",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5173",
    "DISTRICTNAME_AR": "غدير البنات",
    "DISTRICTNAME_EN": "Ghadeer Al Banat",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5174",
    "DISTRICTNAME_AR": "بن سويلم",
    "DISTRICTNAME_EN": "Ben Swailem",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5175",
    "DISTRICTNAME_AR": "الخمايل",
    "DISTRICTNAME_EN": "Al Khamayel",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5176",
    "DISTRICTNAME_AR": "الثابتية",
    "DISTRICTNAME_EN": "Al Thaabitia",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5177",
    "DISTRICTNAME_AR": "حطين",
    "DISTRICTNAME_EN": "Hittin",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5187",
    "DISTRICTNAME_AR": "الاخاضر",
    "DISTRICTNAME_EN": "Al Akhadir",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5188",
    "DISTRICTNAME_AR": "وادي الشرب",
    "DISTRICTNAME_EN": "Wadi Al Shurb",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5189",
    "DISTRICTNAME_AR": "العارمية",
    "DISTRICTNAME_EN": "Al Armiya",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5190",
    "DISTRICTNAME_AR": "السداد",
    "DISTRICTNAME_EN": "As Sadad",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5191",
    "DISTRICTNAME_AR": "الصخيرة",
    "DISTRICTNAME_EN": "As Sukhayrah",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5192",
    "DISTRICTNAME_AR": "المعترض",
    "DISTRICTNAME_EN": "Al Muutarid",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5193",
    "DISTRICTNAME_AR": "ضاحية العرفاء",
    "DISTRICTNAME_EN": "Dahiyat Al Arfa",
    "CITY_ID": "19403",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5200",
    "DISTRICTNAME_AR": "حى المغترة",
    "DISTRICTNAME_EN": "حى المغترة",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5201",
    "DISTRICTNAME_AR": "الربوة",
    "DISTRICTNAME_EN": "الربوة",
    "CITY_ID": "20106",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5202",
    "DISTRICTNAME_AR": "حي مخطط مصلى العيدين",
    "DISTRICTNAME_EN": "حي مخطط مصلى العيدين",
    "CITY_ID": "20075",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5203",
    "DISTRICTNAME_AR": "حي الحفاير",
    "DISTRICTNAME_EN": "حي الحفاير",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5204",
    "DISTRICTNAME_AR": "حي الهيرة",
    "DISTRICTNAME_EN": "حي الهيرة",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5205",
    "DISTRICTNAME_AR": "حي العبيديات",
    "DISTRICTNAME_EN": "حي العبيديات",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5206",
    "DISTRICTNAME_AR": "حي أم سليم",
    "DISTRICTNAME_EN": "حي أم سليم",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5207",
    "DISTRICTNAME_AR": "مركز مصدة",
    "DISTRICTNAME_EN": "مركز مصدة",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5208",
    "DISTRICTNAME_AR": "حي بوضان",
    "DISTRICTNAME_EN": "حي بوضان",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5209",
    "DISTRICTNAME_AR": "حي الثميلة",
    "DISTRICTNAME_EN": "حي الثميلة",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5210",
    "DISTRICTNAME_AR": "حي الساحبة",
    "DISTRICTNAME_EN": "حي الساحبة",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5211",
    "DISTRICTNAME_AR": "قرية الرجبة",
    "DISTRICTNAME_EN": "قرية الرجبة",
    "CITY_ID": "21321",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5212",
    "DISTRICTNAME_AR": "حي الطوال",
    "DISTRICTNAME_EN": "حي الطوال",
    "CITY_ID": "21320",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5213",
    "DISTRICTNAME_AR": "قرية النهيتية",
    "DISTRICTNAME_EN": "قرية النهيتية",
    "CITY_ID": "21319",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5214",
    "DISTRICTNAME_AR": "حي الوسيطا",
    "DISTRICTNAME_EN": "حي الوسيطا",
    "CITY_ID": "21319",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5215",
    "DISTRICTNAME_AR": "حي آل هادي",
    "DISTRICTNAME_EN": "حي آل هادي",
    "CITY_ID": "21319",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5216",
    "DISTRICTNAME_AR": "حي ام حرمل",
    "DISTRICTNAME_EN": "حي ام حرمل",
    "CITY_ID": "21319",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5217",
    "DISTRICTNAME_AR": "حي الحيانية القديمة",
    "DISTRICTNAME_EN": "حي الحيانية القديمة",
    "CITY_ID": "21315",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5218",
    "DISTRICTNAME_AR": "حي الحيانية الشمالية",
    "DISTRICTNAME_EN": "حي الحيانية الشمالية",
    "CITY_ID": "21315",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5219",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "المجد",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5220",
    "DISTRICTNAME_AR": "أحد",
    "DISTRICTNAME_EN": "أحد",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5221",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "الشهداء",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5222",
    "DISTRICTNAME_AR": "الشرق",
    "DISTRICTNAME_EN": "الشرق",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5223",
    "DISTRICTNAME_AR": "الراية",
    "DISTRICTNAME_EN": "الراية",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5224",
    "DISTRICTNAME_AR": "نجد",
    "DISTRICTNAME_EN": "نجد",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5225",
    "DISTRICTNAME_AR": "الإزدهار",
    "DISTRICTNAME_EN": "الإزدهار",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5226",
    "DISTRICTNAME_AR": "العقيق",
    "DISTRICTNAME_EN": "العقيق",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5227",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "البساتين",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5228",
    "DISTRICTNAME_AR": "الريف",
    "DISTRICTNAME_EN": "الريف",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5229",
    "DISTRICTNAME_AR": "العارض",
    "DISTRICTNAME_EN": "العارض",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5230",
    "DISTRICTNAME_AR": "السيح",
    "DISTRICTNAME_EN": "السيح",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5231",
    "DISTRICTNAME_AR": "القيروان",
    "DISTRICTNAME_EN": "القيروان",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5232",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "بدر",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5233",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "الرحاب",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5234",
    "DISTRICTNAME_AR": "طويق",
    "DISTRICTNAME_EN": "طويق",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5235",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "النرجس",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5236",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5237",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5238",
    "DISTRICTNAME_AR": "الثليماء",
    "DISTRICTNAME_EN": "الثليماء",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5239",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "الرفاع",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5240",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "الرمال",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5241",
    "DISTRICTNAME_AR": "التعاون",
    "DISTRICTNAME_EN": "التعاون",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5242",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "الندى",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5243",
    "DISTRICTNAME_AR": "النفل",
    "DISTRICTNAME_EN": "النفل",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5244",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "النخيل",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5245",
    "DISTRICTNAME_AR": "غياضة",
    "DISTRICTNAME_EN": "غياضة",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5246",
    "DISTRICTNAME_AR": "الملقا",
    "DISTRICTNAME_EN": "الملقا",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5247",
    "DISTRICTNAME_AR": "الصافي",
    "DISTRICTNAME_EN": "الصافي",
    "CITY_ID": "21207",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5248",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية السلي",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية السلي",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5249",
    "DISTRICTNAME_AR": "حي_001022088",
    "DISTRICTNAME_EN": "حي_001022088",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5250",
    "DISTRICTNAME_AR": "البشائر",
    "DISTRICTNAME_EN": "البشائر",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5251",
    "DISTRICTNAME_AR": "حي_001022142",
    "DISTRICTNAME_EN": "حي_001022142",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5252",
    "DISTRICTNAME_AR": "حي_001022143",
    "DISTRICTNAME_EN": "حي_001022143",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5253",
    "DISTRICTNAME_AR": "الملك سلمان",
    "DISTRICTNAME_EN": "الملك سلمان",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5254",
    "DISTRICTNAME_AR": "سدرة",
    "DISTRICTNAME_EN": "سدرة",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5255",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية الروضة",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية الروضة",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5256",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية نمار",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية نمار",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5257",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية الشرق",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية الشرق",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5258",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية العزيزية",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية العزيزية",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5259",
    "DISTRICTNAME_AR": "منطقة مفتوحة تابعة لبلدية عرقة وبلدية حائر",
    "DISTRICTNAME_EN": "منطقة مفتوحة تابعة لبلدية عرقة وبلدية حائر",
    "CITY_ID": "21282",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5260",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "الحزم",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5261",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5262",
    "DISTRICTNAME_AR": "الغروب",
    "DISTRICTNAME_EN": "الغروب",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5263",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "الريان",
    "CITY_ID": "20309",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5264",
    "DISTRICTNAME_AR": "حي الصناعية الاولي",
    "DISTRICTNAME_EN": "حي الصناعية الاولي",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5265",
    "DISTRICTNAME_AR": "حي التعاون",
    "DISTRICTNAME_EN": "حي التعاون",
    "CITY_ID": "20066",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5266",
    "DISTRICTNAME_AR": "حي الصناعية الثانية",
    "DISTRICTNAME_EN": "حي الصناعية الثانية",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5267",
    "DISTRICTNAME_AR": "مدينة الانعام",
    "DISTRICTNAME_EN": "مدينة الانعام",
    "CITY_ID": "20066",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5268",
    "DISTRICTNAME_AR": "مسجد العيد برماح",
    "DISTRICTNAME_EN": "مسجد العيد برماح",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5269",
    "DISTRICTNAME_AR": "حي مخطط الارض الحكومية الواقعة جنوب شرق المخطط 287",
    "DISTRICTNAME_EN": "حي مخطط الارض الحكومية الواقعة جنوب شرق المخطط 287",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5270",
    "DISTRICTNAME_AR": "حي مخطط الاراضي الحكوميه شمال المخطط التهذيبي رقم 73في مدينة رماح",
    "DISTRICTNAME_EN": "حي مخطط الاراضي الحكوميه شمال المخطط التهذيبي رقم 73في مدينة رماح",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5271",
    "DISTRICTNAME_AR": "حي مخطط  الاراضي الحكوميه",
    "DISTRICTNAME_EN": "حي مخطط  الاراضي الحكوميه",
    "CITY_ID": "18074",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5272",
    "DISTRICTNAME_AR": "حي مخطط تنظيم الاراضي الحكوميه الواقعه شرق المخطط المعتمد 152 ببلده رغبه",
    "DISTRICTNAME_EN": "حي مخطط تنظيم الاراضي الحكوميه الواقعه شرق المخطط المعتمد 152 ببلده رغبه",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5273",
    "DISTRICTNAME_AR": "حي مخطط تنظيم احواش الاغنام و سوق الاعلاف و الشعير بمحافظة ثادق",
    "DISTRICTNAME_EN": "حي مخطط تنظيم احواش الاغنام و سوق الاعلاف و الشعير بمحافظة ثادق",
    "CITY_ID": "20280",
    "REGION_ID": 1
   },
   {
    "DISTRICT_ID": "5274",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "اليرموك",
    "CITY_ID": "18394",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5275",
    "DISTRICTNAME_AR": "الأصيل",
    "DISTRICTNAME_EN": "الأصيل",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5276",
    "DISTRICTNAME_AR": "العروس",
    "DISTRICTNAME_EN": "العروس",
    "CITY_ID": "18394",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5277",
    "DISTRICTNAME_AR": "حي الجدر",
    "DISTRICTNAME_EN": "حي الجدر",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5278",
    "DISTRICTNAME_AR": "السوق القديم",
    "DISTRICTNAME_EN": "السوق القديم",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5279",
    "DISTRICTNAME_AR": "حي ملهي",
    "DISTRICTNAME_EN": "حي ملهي",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5280",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "الحزم",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5281",
    "DISTRICTNAME_AR": "حى السلم القديم",
    "DISTRICTNAME_EN": "حى السلم القديم",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5282",
    "DISTRICTNAME_AR": "البروج",
    "DISTRICTNAME_EN": "البروج",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5283",
    "DISTRICTNAME_AR": "الملحة 1",
    "DISTRICTNAME_EN": "الملحة 1",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5284",
    "DISTRICTNAME_AR": "حي الجزء الشمالي",
    "DISTRICTNAME_EN": "حي الجزء الشمالي",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5285",
    "DISTRICTNAME_AR": "الصالحية",
    "DISTRICTNAME_EN": "الصالحية",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5286",
    "DISTRICTNAME_AR": "الدعيكة",
    "DISTRICTNAME_EN": "الدعيكة",
    "CITY_ID": "20038",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5287",
    "DISTRICTNAME_AR": "حي حوقان",
    "DISTRICTNAME_EN": "حي حوقان",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5288",
    "DISTRICTNAME_AR": "حي سوق الانعام",
    "DISTRICTNAME_EN": "حي سوق الانعام",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5289",
    "DISTRICTNAME_AR": "حي الصناعية",
    "DISTRICTNAME_EN": "حي الصناعية",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5290",
    "DISTRICTNAME_AR": "الحي الصناعي",
    "DISTRICTNAME_EN": "الحي الصناعي",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5291",
    "DISTRICTNAME_AR": "الدغمية",
    "DISTRICTNAME_EN": "الدغمية",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5292",
    "DISTRICTNAME_AR": "حي الإسكان",
    "DISTRICTNAME_EN": "حي الإسكان",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5293",
    "DISTRICTNAME_AR": "حى رياض الدغمية",
    "DISTRICTNAME_EN": "حى رياض الدغمية",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5294",
    "DISTRICTNAME_AR": "حي الحديقة",
    "DISTRICTNAME_EN": "حي الحديقة",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5295",
    "DISTRICTNAME_AR": "حى الدغمية القديمة",
    "DISTRICTNAME_EN": "حى الدغمية القديمة",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5296",
    "DISTRICTNAME_AR": "حى الدبيلة",
    "DISTRICTNAME_EN": "حى الدبيلة",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5297",
    "DISTRICTNAME_AR": "حي التنمية الحضاري",
    "DISTRICTNAME_EN": "حي التنمية الحضاري",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5298",
    "DISTRICTNAME_AR": "حي الفصيلية",
    "DISTRICTNAME_EN": "حي الفصيلية",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5299",
    "DISTRICTNAME_AR": "حي الصالحية",
    "DISTRICTNAME_EN": "حي الصالحية",
    "CITY_ID": "19963",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5300",
    "DISTRICTNAME_AR": "حي النزهة",
    "DISTRICTNAME_EN": "حي النزهة",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5301",
    "DISTRICTNAME_AR": "حي السلامة",
    "DISTRICTNAME_EN": "حي السلامة",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5302",
    "DISTRICTNAME_AR": "حي الغدير",
    "DISTRICTNAME_EN": "حي الغدير",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5303",
    "DISTRICTNAME_AR": "حي النسيم",
    "DISTRICTNAME_EN": "حي النسيم",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5304",
    "DISTRICTNAME_AR": "حي الخالدية",
    "DISTRICTNAME_EN": "حي الخالدية",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5305",
    "DISTRICTNAME_AR": "حي الفيصلية",
    "DISTRICTNAME_EN": "حي الفيصلية",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5306",
    "DISTRICTNAME_AR": "حي العزيزية",
    "DISTRICTNAME_EN": "حي العزيزية",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5307",
    "DISTRICTNAME_AR": "حي المنتزة",
    "DISTRICTNAME_EN": "حي المنتزة",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5308",
    "DISTRICTNAME_AR": "حي الريان",
    "DISTRICTNAME_EN": "حي الريان",
    "CITY_ID": "16166",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5309",
    "DISTRICTNAME_AR": "حي جرهم الشمالي",
    "DISTRICTNAME_EN": "حي جرهم الشمالي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5310",
    "DISTRICTNAME_AR": "حي الصفرة",
    "DISTRICTNAME_EN": "حي الصفرة",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5311",
    "DISTRICTNAME_AR": "حي حنين الشمالي",
    "DISTRICTNAME_EN": "حي حنين الشمالي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5312",
    "DISTRICTNAME_AR": "حي السليمانية الشرقي",
    "DISTRICTNAME_EN": "حي السليمانية الشرقي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5313",
    "DISTRICTNAME_AR": "حي السليمانية الغربي",
    "DISTRICTNAME_EN": "حي السليمانية الغربي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5314",
    "DISTRICTNAME_AR": "حي المروة الشمالي",
    "DISTRICTNAME_EN": "حي المروة الشمالي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5315",
    "DISTRICTNAME_AR": "حي العقبة الشمالي",
    "DISTRICTNAME_EN": "حي العقبة الشمالي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5316",
    "DISTRICTNAME_AR": "حي جرهم الجنوبي",
    "DISTRICTNAME_EN": "حي جرهم الجنوبي",
    "CITY_ID": "15423",
    "REGION_ID": 2
   },
   {
    "DISTRICT_ID": "5317",
    "DISTRICTNAME_AR": "حي مخطط المفرق للدخل المحدود",
    "DISTRICTNAME_EN": "حي مخطط المفرق للدخل المحدود",
    "CITY_ID": "15902",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5318",
    "DISTRICTNAME_AR": "مخطط الآمتداد الغربي",
    "DISTRICTNAME_EN": "مخطط الآمتداد الغربي",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5319",
    "DISTRICTNAME_AR": "حي حزرة الجنوب",
    "DISTRICTNAME_EN": "حي حزرة الجنوب",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5320",
    "DISTRICTNAME_AR": "مخطط وزارة الإسكان",
    "DISTRICTNAME_EN": "مخطط وزارة الإسكان",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5321",
    "DISTRICTNAME_AR": "مخطط البطيحان",
    "DISTRICTNAME_EN": "مخطط البطيحان",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5322",
    "DISTRICTNAME_AR": "حي الضريس",
    "DISTRICTNAME_EN": "حي الضريس",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5323",
    "DISTRICTNAME_AR": "مخطط المنح",
    "DISTRICTNAME_EN": "مخطط المنح",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5324",
    "DISTRICTNAME_AR": "حي غراب",
    "DISTRICTNAME_EN": "حي غراب",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5325",
    "DISTRICTNAME_AR": "مخطط الحناكية العام",
    "DISTRICTNAME_EN": "مخطط الحناكية العام",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5326",
    "DISTRICTNAME_AR": "حي البطيحان الشرقي",
    "DISTRICTNAME_EN": "حي البطيحان الشرقي",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5327",
    "DISTRICTNAME_AR": "حي ضعة",
    "DISTRICTNAME_EN": "حي ضعة",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5328",
    "DISTRICTNAME_AR": "حي الضميرية",
    "DISTRICTNAME_EN": "حي الضميرية",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5329",
    "DISTRICTNAME_AR": "حي المسبعة",
    "DISTRICTNAME_EN": "حي المسبعة",
    "CITY_ID": "14001",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5330",
    "DISTRICTNAME_AR": "الآمتداد الجنوبي لمخطط الحناكية",
    "DISTRICTNAME_EN": "الآمتداد الجنوبي لمخطط الحناكية",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5331",
    "DISTRICTNAME_AR": "مخطط الآمتداد العمراني",
    "DISTRICTNAME_EN": "مخطط الآمتداد العمراني",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5332",
    "DISTRICTNAME_AR": "حي الحمادة",
    "DISTRICTNAME_EN": "حي الحمادة",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5333",
    "DISTRICTNAME_AR": "مخطط الاستيطان",
    "DISTRICTNAME_EN": "مخطط الاستيطان",
    "CITY_ID": "19300",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5334",
    "DISTRICTNAME_AR": "حى الوادى",
    "DISTRICTNAME_EN": "حى الوادى",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5335",
    "DISTRICTNAME_AR": "حى الصفق الأحمر",
    "DISTRICTNAME_EN": "حى الصفق الأحمر",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5336",
    "DISTRICTNAME_AR": "حى النهضة",
    "DISTRICTNAME_EN": "حى النهضة",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5337",
    "DISTRICTNAME_AR": "حى سلطانة",
    "DISTRICTNAME_EN": "حى سلطانة",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5338",
    "DISTRICTNAME_AR": "حى الطوالعة",
    "DISTRICTNAME_EN": "حى الطوالعة",
    "CITY_ID": "15984",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5339",
    "DISTRICTNAME_AR": "المطار والصناعية",
    "DISTRICTNAME_EN": "المطار والصناعية",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5340",
    "DISTRICTNAME_AR": "حى الجديدة ",
    "DISTRICTNAME_EN": "حى الجديدة ",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5341",
    "DISTRICTNAME_AR": "ج 6",
    "DISTRICTNAME_EN": "ج 6",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5342",
    "DISTRICTNAME_AR": "المنح ا",
    "DISTRICTNAME_EN": "المنح ا",
    "CITY_ID": "2017",
    "REGION_ID": 3
   },
   {
    "DISTRICT_ID": "5343",
    "DISTRICTNAME_AR": "حي القلعة",
    "DISTRICTNAME_EN": "حي القلعة",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5344",
    "DISTRICTNAME_AR": "حي الأندلس",
    "DISTRICTNAME_EN": "حي الأندلس",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5345",
    "DISTRICTNAME_AR": "حي المئوية",
    "DISTRICTNAME_EN": "حي المئوية",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5346",
    "DISTRICTNAME_AR": "حي البدع",
    "DISTRICTNAME_EN": "حي البدع",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5347",
    "DISTRICTNAME_AR": "حي برزة",
    "DISTRICTNAME_EN": "حي برزة",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5348",
    "DISTRICTNAME_AR": "حي الواسط",
    "DISTRICTNAME_EN": "حي الواسط",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5349",
    "DISTRICTNAME_AR": "حي الصناعية",
    "DISTRICTNAME_EN": "حي الصناعية",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5350",
    "DISTRICTNAME_AR": "حي المتوكل",
    "DISTRICTNAME_EN": "حي المتوكل",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5351",
    "DISTRICTNAME_AR": "حي الصفاء",
    "DISTRICTNAME_EN": "حي الصفاء",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5352",
    "DISTRICTNAME_AR": "حي السلام",
    "DISTRICTNAME_EN": "حي السلام",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5353",
    "DISTRICTNAME_AR": "حي القادسية",
    "DISTRICTNAME_EN": "حي القادسية",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5354",
    "DISTRICTNAME_AR": "حي النخيل",
    "DISTRICTNAME_EN": "حي النخيل",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5355",
    "DISTRICTNAME_AR": "حي الرفيعة",
    "DISTRICTNAME_EN": "حي الرفيعة",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5356",
    "DISTRICTNAME_AR": "حي الوزير",
    "DISTRICTNAME_EN": "حي الوزير",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5357",
    "DISTRICTNAME_AR": "حي العقدة",
    "DISTRICTNAME_EN": "حي العقدة",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5358",
    "DISTRICTNAME_AR": "حي الفاروق",
    "DISTRICTNAME_EN": "حي الفاروق",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5359",
    "DISTRICTNAME_AR": "حي المملكة",
    "DISTRICTNAME_EN": "حي المملكة",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5360",
    "DISTRICTNAME_AR": "حي الخبيب",
    "DISTRICTNAME_EN": "حي الخبيب",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5361",
    "DISTRICTNAME_AR": "حي الغرسات",
    "DISTRICTNAME_EN": "حي الغرسات",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5362",
    "DISTRICTNAME_AR": "حي الجال",
    "DISTRICTNAME_EN": "حي الجال",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5363",
    "DISTRICTNAME_AR": "حي الشماس",
    "DISTRICTNAME_EN": "حي الشماس",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5364",
    "DISTRICTNAME_AR": "حي البلاد",
    "DISTRICTNAME_EN": "حي البلاد",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5365",
    "DISTRICTNAME_AR": "حي القدس",
    "DISTRICTNAME_EN": "حي القدس",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5366",
    "DISTRICTNAME_AR": "حي الشفاء",
    "DISTRICTNAME_EN": "حي الشفاء",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5367",
    "DISTRICTNAME_AR": "حي المتحف",
    "DISTRICTNAME_EN": "حي المتحف",
    "CITY_ID": "17989",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5368",
    "DISTRICTNAME_AR": "حي العزيزية",
    "DISTRICTNAME_EN": "حي العزيزية",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5369",
    "DISTRICTNAME_AR": "حي الاستراحات",
    "DISTRICTNAME_EN": "حي الاستراحات",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5370",
    "DISTRICTNAME_AR": "حي الربوة",
    "DISTRICTNAME_EN": "حي الربوة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5371",
    "DISTRICTNAME_AR": "حي الروضة",
    "DISTRICTNAME_EN": "حي الروضة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5372",
    "DISTRICTNAME_AR": "حي الازهار",
    "DISTRICTNAME_EN": "حي الازهار",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5373",
    "DISTRICTNAME_AR": "حي العليا",
    "DISTRICTNAME_EN": "حي العليا",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5374",
    "DISTRICTNAME_AR": "حي النزهة",
    "DISTRICTNAME_EN": "حي النزهة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5375",
    "DISTRICTNAME_AR": "حي الجزيرة",
    "DISTRICTNAME_EN": "حي الجزيرة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5376",
    "DISTRICTNAME_AR": "حي المنتزة",
    "DISTRICTNAME_EN": "حي المنتزة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5377",
    "DISTRICTNAME_AR": "حي البساتين",
    "DISTRICTNAME_EN": "حي البساتين",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5378",
    "DISTRICTNAME_AR": "حي الزهرة",
    "DISTRICTNAME_EN": "حي الزهرة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5379",
    "DISTRICTNAME_AR": "حي النهضة",
    "DISTRICTNAME_EN": "حي النهضة",
    "CITY_ID": "14301",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5380",
    "DISTRICTNAME_AR": "حى النخيل",
    "DISTRICTNAME_EN": "حى النخيل",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5381",
    "DISTRICTNAME_AR": "حى البرقاء",
    "DISTRICTNAME_EN": "حى البرقاء",
    "CITY_ID": "14283",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5382",
    "DISTRICTNAME_AR": "قصر العبدالله",
    "DISTRICTNAME_EN": "قصر العبدالله",
    "CITY_ID": "14283",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5383",
    "DISTRICTNAME_AR": "عين ابن فهيد ",
    "DISTRICTNAME_EN": "عين ابن فهيد ",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5384",
    "DISTRICTNAME_AR": "طريف الأسياح ",
    "DISTRICTNAME_EN": "طريف الأسياح ",
    "CITY_ID": "14293",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5385",
    "DISTRICTNAME_AR": "التنومة",
    "DISTRICTNAME_EN": "التنومة",
    "CITY_ID": "14295",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5386",
    "DISTRICTNAME_AR": "البرود",
    "DISTRICTNAME_EN": "البرود",
    "CITY_ID": "14294",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5387",
    "DISTRICTNAME_AR": "خصيبة ",
    "DISTRICTNAME_EN": "خصيبة ",
    "CITY_ID": "14286",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5388",
    "DISTRICTNAME_AR": "حى العيون",
    "DISTRICTNAME_EN": "حى العيون",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5389",
    "DISTRICTNAME_AR": "حى الفهيد",
    "DISTRICTNAME_EN": "حى الفهيد",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5390",
    "DISTRICTNAME_AR": "حى السلام",
    "DISTRICTNAME_EN": "حى السلام",
    "CITY_ID": "14283",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5391",
    "DISTRICTNAME_AR": "حى التعاون",
    "DISTRICTNAME_EN": "حى التعاون",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5392",
    "DISTRICTNAME_AR": "حي عين بن فهيد",
    "DISTRICTNAME_EN": "حي عين بن فهيد",
    "CITY_ID": "14282",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5393",
    "DISTRICTNAME_AR": "حى الرياض",
    "DISTRICTNAME_EN": "حى الرياض",
    "CITY_ID": "14283",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5394",
    "DISTRICTNAME_AR": "الشنانة",
    "DISTRICTNAME_EN": "الشنانة",
    "CITY_ID": "14293",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5395",
    "DISTRICTNAME_AR": "الشورقية",
    "DISTRICTNAME_EN": "الشورقية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5396",
    "DISTRICTNAME_AR": "الجحانية",
    "DISTRICTNAME_EN": "الجحانية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5397",
    "DISTRICTNAME_AR": "الجراية",
    "DISTRICTNAME_EN": "الجراية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5398",
    "DISTRICTNAME_AR": "العليا والهيشة",
    "DISTRICTNAME_EN": "العليا والهيشة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5399",
    "DISTRICTNAME_AR": "نبعة",
    "DISTRICTNAME_EN": "نبعة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5400",
    "DISTRICTNAME_AR": "الديرة القديمة",
    "DISTRICTNAME_EN": "الديرة القديمة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5401",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "المروج",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5402",
    "DISTRICTNAME_AR": "قليطة",
    "DISTRICTNAME_EN": "قليطة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5403",
    "DISTRICTNAME_AR": "الملك عبدالله",
    "DISTRICTNAME_EN": "الملك عبدالله",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5404",
    "DISTRICTNAME_AR": "المقاطر",
    "DISTRICTNAME_EN": "المقاطر",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5405",
    "DISTRICTNAME_AR": "سهلة المطلق",
    "DISTRICTNAME_EN": "سهلة المطلق",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5406",
    "DISTRICTNAME_AR": "النويديس",
    "DISTRICTNAME_EN": "النويديس",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5407",
    "DISTRICTNAME_AR": "حي المقبرة",
    "DISTRICTNAME_EN": "حي المقبرة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5408",
    "DISTRICTNAME_AR": "محطة معالجة",
    "DISTRICTNAME_EN": "محطة معالجة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5409",
    "DISTRICTNAME_AR": "ام عشيرة",
    "DISTRICTNAME_EN": "ام عشيرة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5410",
    "DISTRICTNAME_AR": "قرطبة",
    "DISTRICTNAME_EN": "قرطبة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5411",
    "DISTRICTNAME_AR": "اللصافة",
    "DISTRICTNAME_EN": "اللصافة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5412",
    "DISTRICTNAME_AR": "صفية",
    "DISTRICTNAME_EN": "صفية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5413",
    "DISTRICTNAME_AR": "الرفيعة",
    "DISTRICTNAME_EN": "الرفيعة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5414",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5415",
    "DISTRICTNAME_AR": "السليمية",
    "DISTRICTNAME_EN": "السليمية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5416",
    "DISTRICTNAME_AR": "مزارع العدان",
    "DISTRICTNAME_EN": "مزارع العدان",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5417",
    "DISTRICTNAME_AR": "الفايزية",
    "DISTRICTNAME_EN": "الفايزية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5418",
    "DISTRICTNAME_AR": "مزرعة جنوب",
    "DISTRICTNAME_EN": "مزرعة جنوب",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5419",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5420",
    "DISTRICTNAME_AR": "أم الحمام",
    "DISTRICTNAME_EN": "أم الحمام",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5421",
    "DISTRICTNAME_AR": "القاع",
    "DISTRICTNAME_EN": "القاع",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5422",
    "DISTRICTNAME_AR": "شورقية الجارالله",
    "DISTRICTNAME_EN": "شورقية الجارالله",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5423",
    "DISTRICTNAME_AR": "منتزهات خرطم",
    "DISTRICTNAME_EN": "منتزهات خرطم",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5424",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "الورود",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5425",
    "DISTRICTNAME_AR": "مزرعة شمال",
    "DISTRICTNAME_EN": "مزرعة شمال",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5426",
    "DISTRICTNAME_AR": "المجصة",
    "DISTRICTNAME_EN": "المجصة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5427",
    "DISTRICTNAME_AR": "الحصان",
    "DISTRICTNAME_EN": "الحصان",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5428",
    "DISTRICTNAME_AR": "عين العقيلي",
    "DISTRICTNAME_EN": "عين العقيلي",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5429",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "البستان",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5430",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "القادسية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5431",
    "DISTRICTNAME_AR": "الدوائر الحكومي",
    "DISTRICTNAME_EN": "الدوائر الحكومي",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5432",
    "DISTRICTNAME_AR": "الجادة",
    "DISTRICTNAME_EN": "الجادة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5433",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "الروضة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5434",
    "DISTRICTNAME_AR": "سمحة",
    "DISTRICTNAME_EN": "سمحة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5435",
    "DISTRICTNAME_AR": "النهير",
    "DISTRICTNAME_EN": "النهير",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5436",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "الحزم",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5437",
    "DISTRICTNAME_AR": "الجديدة",
    "DISTRICTNAME_EN": "الجديدة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5438",
    "DISTRICTNAME_AR": "عسيلة",
    "DISTRICTNAME_EN": "عسيلة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5439",
    "DISTRICTNAME_AR": "الخزان",
    "DISTRICTNAME_EN": "الخزان",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5440",
    "DISTRICTNAME_AR": "التحلية",
    "DISTRICTNAME_EN": "التحلية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5441",
    "DISTRICTNAME_AR": "الرميلة",
    "DISTRICTNAME_EN": "الرميلة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5442",
    "DISTRICTNAME_AR": "مزارع السفالة",
    "DISTRICTNAME_EN": "مزارع السفالة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5443",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5444",
    "DISTRICTNAME_AR": "النصيرة",
    "DISTRICTNAME_EN": "النصيرة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5445",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "النخيل",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5446",
    "DISTRICTNAME_AR": "العلاوة",
    "DISTRICTNAME_EN": "العلاوة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5447",
    "DISTRICTNAME_AR": "خزامى",
    "DISTRICTNAME_EN": "خزامى",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5448",
    "DISTRICTNAME_AR": "الاندلس",
    "DISTRICTNAME_EN": "الاندلس",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5449",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "المنتزة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5450",
    "DISTRICTNAME_AR": "المنيقع",
    "DISTRICTNAME_EN": "المنيقع",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5451",
    "DISTRICTNAME_AR": "القفيفة",
    "DISTRICTNAME_EN": "القفيفة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5452",
    "DISTRICTNAME_AR": "الثليماء",
    "DISTRICTNAME_EN": "الثليماء",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5453",
    "DISTRICTNAME_AR": "شيحة",
    "DISTRICTNAME_EN": "شيحة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5454",
    "DISTRICTNAME_AR": "الفضيلة",
    "DISTRICTNAME_EN": "الفضيلة",
    "CITY_ID": "14261",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5455",
    "DISTRICTNAME_AR": "حي الملك عبد العزيز",
    "DISTRICTNAME_EN": "حي الملك عبد العزيز",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5456",
    "DISTRICTNAME_AR": "حي الملك سلمان",
    "DISTRICTNAME_EN": "حي الملك سلمان",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5457",
    "DISTRICTNAME_AR": "حي الامير فهد الفيصل",
    "DISTRICTNAME_EN": "حي الامير فهد الفيصل",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5458",
    "DISTRICTNAME_AR": "حي الملك عبدالله",
    "DISTRICTNAME_EN": "حي الملك عبدالله",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5459",
    "DISTRICTNAME_AR": "حي الملك خالد",
    "DISTRICTNAME_EN": "حي الملك خالد",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5460",
    "DISTRICTNAME_AR": "حي الملك سعود",
    "DISTRICTNAME_EN": "حي الملك سعود",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5461",
    "DISTRICTNAME_AR": "حي الملك فهد",
    "DISTRICTNAME_EN": "حي الملك فهد",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5462",
    "DISTRICTNAME_AR": "حي الملك فيصل",
    "DISTRICTNAME_EN": "حي الملك فيصل",
    "CITY_ID": "3581",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5463",
    "DISTRICTNAME_AR": "33ج",
    "DISTRICTNAME_EN": "33ج",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5464",
    "DISTRICTNAME_AR": "الامية",
    "DISTRICTNAME_EN": "الامية",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5465",
    "DISTRICTNAME_AR": "الغماس2",
    "DISTRICTNAME_EN": "الغماس2",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5466",
    "DISTRICTNAME_AR": "الغماس1",
    "DISTRICTNAME_EN": "الغماس1",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5467",
    "DISTRICTNAME_AR": "ضب روضان",
    "DISTRICTNAME_EN": "ضب روضان",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5468",
    "DISTRICTNAME_AR": "34ج",
    "DISTRICTNAME_EN": "34ج",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5469",
    "DISTRICTNAME_AR": "البصر3",
    "DISTRICTNAME_EN": "البصر3",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5470",
    "DISTRICTNAME_AR": "الجديدات",
    "DISTRICTNAME_EN": "الجديدات",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5471",
    "DISTRICTNAME_AR": "ضراس",
    "DISTRICTNAME_EN": "ضراس",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5472",
    "DISTRICTNAME_AR": "العقيلات",
    "DISTRICTNAME_EN": "العقيلات",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5473",
    "DISTRICTNAME_AR": "31ج",
    "DISTRICTNAME_EN": "31ج",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5474",
    "DISTRICTNAME_AR": "الدعيشة",
    "DISTRICTNAME_EN": "الدعيشة",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5475",
    "DISTRICTNAME_AR": "البصر2",
    "DISTRICTNAME_EN": "البصر2",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5476",
    "DISTRICTNAME_AR": "العاقول",
    "DISTRICTNAME_EN": "العاقول",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5477",
    "DISTRICTNAME_AR": "البصر1",
    "DISTRICTNAME_EN": "البصر1",
    "CITY_ID": "13976",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5478",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "غرناطة",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5479",
    "DISTRICTNAME_AR": "الريان",
    "DISTRICTNAME_EN": "الريان",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5480",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "البستان",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5481",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "الخالدية",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5482",
    "DISTRICTNAME_AR": "المصيف",
    "DISTRICTNAME_EN": "المصيف",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5483",
    "DISTRICTNAME_AR": "النرجس",
    "DISTRICTNAME_EN": "النرجس",
    "CITY_ID": "11983",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5484",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "حي الورود",
    "CITY_ID": "11969",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5485",
    "DISTRICTNAME_AR": "حي النخيل",
    "DISTRICTNAME_EN": "حي النخيل",
    "CITY_ID": "11969",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5486",
    "DISTRICTNAME_AR": "حي الياسمين",
    "DISTRICTNAME_EN": "حي الياسمين",
    "CITY_ID": "11969",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5487",
    "DISTRICTNAME_AR": "حي الازدهار",
    "DISTRICTNAME_EN": "حي الازدهار",
    "CITY_ID": "11969",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5488",
    "DISTRICTNAME_AR": "حي الملك فهد ",
    "DISTRICTNAME_EN": "حي الملك فهد ",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5489",
    "DISTRICTNAME_AR": "حي النهضة",
    "DISTRICTNAME_EN": "حي النهضة",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5490",
    "DISTRICTNAME_AR": "حي السلام",
    "DISTRICTNAME_EN": "حي السلام",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5491",
    "DISTRICTNAME_AR": "حي القدس",
    "DISTRICTNAME_EN": "حي القدس",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5492",
    "DISTRICTNAME_AR": "حي الملك عبدالله ",
    "DISTRICTNAME_EN": "حي الملك عبدالله ",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5493",
    "DISTRICTNAME_AR": "حي الربوة",
    "DISTRICTNAME_EN": "حي الربوة",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5494",
    "DISTRICTNAME_AR": "حي الأندلس",
    "DISTRICTNAME_EN": "حي الأندلس",
    "CITY_ID": "11890",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5495",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5496",
    "DISTRICTNAME_AR": "المرقب",
    "DISTRICTNAME_EN": "المرقب",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5497",
    "DISTRICTNAME_AR": "البساتين",
    "DISTRICTNAME_EN": "البساتين",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5498",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "الورود",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5499",
    "DISTRICTNAME_AR": "الوادي",
    "DISTRICTNAME_EN": "الوادي",
    "CITY_ID": "11875",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5500",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "السلام",
    "CITY_ID": "11875",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5501",
    "DISTRICTNAME_AR": "الندى",
    "DISTRICTNAME_EN": "الندى",
    "CITY_ID": "14254",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5502",
    "DISTRICTNAME_AR": "التراثي",
    "DISTRICTNAME_EN": "التراثي",
    "CITY_ID": "11880",
    "REGION_ID": 4
   },
   {
    "DISTRICT_ID": "5503",
    "DISTRICTNAME_AR": "المدارس",
    "DISTRICTNAME_EN": "المدارس",
    "CITY_ID": "6121",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5504",
    "DISTRICTNAME_AR": "مخطط هجرة النظيم",
    "DISTRICTNAME_EN": "مخطط هجرة النظيم",
    "CITY_ID": "13432",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5505",
    "DISTRICTNAME_AR": "درب الإبل",
    "DISTRICTNAME_EN": "درب الإبل",
    "CITY_ID": "13428",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5506",
    "DISTRICTNAME_AR": "القلت",
    "DISTRICTNAME_EN": "القلت",
    "CITY_ID": "13429",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5507",
    "DISTRICTNAME_AR": "حي هجرة السليمانية",
    "DISTRICTNAME_EN": "حي هجرة السليمانية",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5508",
    "DISTRICTNAME_AR": " الوايليه",
    "DISTRICTNAME_EN": " الوايليه",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5509",
    "DISTRICTNAME_AR": "الصفيرى",
    "DISTRICTNAME_EN": "الصفيرى",
    "CITY_ID": "13434",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5510",
    "DISTRICTNAME_AR": "الفاو",
    "DISTRICTNAME_EN": "الفاو",
    "CITY_ID": "11035",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5511",
    "DISTRICTNAME_AR": "سامودة",
    "DISTRICTNAME_EN": "سامودة",
    "CITY_ID": "11032",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5512",
    "DISTRICTNAME_AR": "النايفية",
    "DISTRICTNAME_EN": "النايفية",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5513",
    "DISTRICTNAME_AR": "الذيبية",
    "DISTRICTNAME_EN": "الذيبية",
    "CITY_ID": "11031",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5514",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "13427",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5515",
    "DISTRICTNAME_AR": "البطيحانية",
    "DISTRICTNAME_EN": "البطيحانية",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5516",
    "DISTRICTNAME_AR": "أم عشر",
    "DISTRICTNAME_EN": "أم عشر",
    "CITY_ID": "11034",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5517",
    "DISTRICTNAME_AR": "أم قليب",
    "DISTRICTNAME_EN": "أم قليب",
    "CITY_ID": "11028",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5518",
    "DISTRICTNAME_AR": "أحواش الأغنام",
    "DISTRICTNAME_EN": "أحواش الأغنام",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5519",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5520",
    "DISTRICTNAME_AR": "الشفاء",
    "DISTRICTNAME_EN": "الشفاء",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5521",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "الروضة",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5522",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5523",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "الفيصلية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5524",
    "DISTRICTNAME_AR": "المحمدية",
    "DISTRICTNAME_EN": "المحمدية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5525",
    "DISTRICTNAME_AR": "الأندلس",
    "DISTRICTNAME_EN": "الأندلس",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5526",
    "DISTRICTNAME_AR": "الهداية",
    "DISTRICTNAME_EN": "الهداية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5527",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "العزيزية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5528",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "الخالدية",
    "CITY_ID": "11027",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5529",
    "DISTRICTNAME_AR": "الرابية",
    "DISTRICTNAME_EN": "الرابية",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5530",
    "DISTRICTNAME_AR": "المنار",
    "DISTRICTNAME_EN": "المنار",
    "CITY_ID": "13427",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5531",
    "DISTRICTNAME_AR": "الرائد",
    "DISTRICTNAME_EN": "الرائد",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5532",
    "DISTRICTNAME_AR": "الفيوان",
    "DISTRICTNAME_EN": "الفيوان",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5533",
    "DISTRICTNAME_AR": "الصداوي",
    "DISTRICTNAME_EN": "الصداوي",
    "CITY_ID": "11023",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5534",
    "DISTRICTNAME_AR": "ام كداد",
    "DISTRICTNAME_EN": "ام كداد",
    "CITY_ID": "11024",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5535",
    "DISTRICTNAME_AR": "المتياهة الجنوبية",
    "DISTRICTNAME_EN": "المتياهة الجنوبية",
    "CITY_ID": "11022",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5536",
    "DISTRICTNAME_AR": "الحيرة",
    "DISTRICTNAME_EN": "الحيرة",
    "CITY_ID": "11026",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5537",
    "DISTRICTNAME_AR": "مناخ",
    "DISTRICTNAME_EN": "مناخ",
    "CITY_ID": "11025",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5538",
    "DISTRICTNAME_AR": "معرج السوبان",
    "DISTRICTNAME_EN": "معرج السوبان",
    "CITY_ID": "11019",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5539",
    "DISTRICTNAME_AR": "خبيراء",
    "DISTRICTNAME_EN": "خبيراء",
    "CITY_ID": "11020",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5540",
    "DISTRICTNAME_AR": "السعيرة",
    "DISTRICTNAME_EN": "السعيرة",
    "CITY_ID": "11018",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5541",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "الخليج",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5542",
    "DISTRICTNAME_AR": "مركز الحماطيات",
    "DISTRICTNAME_EN": "مركز الحماطيات",
    "CITY_ID": "13433",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5543",
    "DISTRICTNAME_AR": "مركز الجبو",
    "DISTRICTNAME_EN": "مركز الجبو",
    "CITY_ID": "13433",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5544",
    "DISTRICTNAME_AR": "مركز المسناه",
    "DISTRICTNAME_EN": "مركز المسناه",
    "CITY_ID": "13433",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5545",
    "DISTRICTNAME_AR": "مركز أم عمارة",
    "DISTRICTNAME_EN": "مركز أم عمارة",
    "CITY_ID": "13430",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5546",
    "DISTRICTNAME_AR": "الرقعي",
    "DISTRICTNAME_EN": "الرقعي",
    "CITY_ID": "13431",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5547",
    "DISTRICTNAME_AR": "حي مخطط أرض المستودعات المعدل",
    "DISTRICTNAME_EN": "حي مخطط أرض المستودعات المعدل",
    "CITY_ID": "13427",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5548",
    "DISTRICTNAME_AR": "الصناعية الأولي و الثانية - رأس تنوره",
    "DISTRICTNAME_EN": "الصناعية الأولي و الثانية - رأس تنوره",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5549",
    "DISTRICTNAME_AR": "الصناعية الثالثة - رأس تنوره",
    "DISTRICTNAME_EN": "الصناعية الثالثة - رأس تنوره",
    "CITY_ID": "19373",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5550",
    "DISTRICTNAME_AR": "عبدالله فؤاد",
    "DISTRICTNAME_EN": "عبدالله فؤاد",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5551",
    "DISTRICTNAME_AR": "الجود",
    "DISTRICTNAME_EN": "الجود",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5552",
    "DISTRICTNAME_AR": "الإسكان الشمالي",
    "DISTRICTNAME_EN": "الإسكان الشمالي",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5553",
    "DISTRICTNAME_AR": "الإسكان الجنوبي",
    "DISTRICTNAME_EN": "الإسكان الجنوبي",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5554",
    "DISTRICTNAME_AR": "الحزم",
    "DISTRICTNAME_EN": "الحزم",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5555",
    "DISTRICTNAME_AR": "الصناعية الثانية",
    "DISTRICTNAME_EN": "الصناعية الثانية",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5556",
    "DISTRICTNAME_AR": "حي البساتين - بقيق",
    "DISTRICTNAME_EN": "حي البساتين - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5557",
    "DISTRICTNAME_AR": "حي أشبيلية - بقيق",
    "DISTRICTNAME_EN": "حي أشبيلية - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5558",
    "DISTRICTNAME_AR": "حي الربوة - بقيق",
    "DISTRICTNAME_EN": "حي الربوة - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5559",
    "DISTRICTNAME_AR": "حي غرناطة- بقيق",
    "DISTRICTNAME_EN": "حي غرناطة- بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5560",
    "DISTRICTNAME_AR": "حي قرطبة - بقيق",
    "DISTRICTNAME_EN": "حي قرطبة - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5561",
    "DISTRICTNAME_AR": "حي الروضة - بقيق",
    "DISTRICTNAME_EN": "حي الروضة - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5562",
    "DISTRICTNAME_AR": "حي الصناعية - بقيق",
    "DISTRICTNAME_EN": "حي الصناعية - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5563",
    "DISTRICTNAME_AR": "حي الزهور - بقيق",
    "DISTRICTNAME_EN": "حي الزهور - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5564",
    "DISTRICTNAME_AR": "حي النخيل - بقيق",
    "DISTRICTNAME_EN": "حي النخيل - بقيق",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5565",
    "DISTRICTNAME_AR": "تابع ل ارامكو",
    "DISTRICTNAME_EN": "تابع ل ارامكو",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5566",
    "DISTRICTNAME_AR": "مركز هدباء",
    "DISTRICTNAME_EN": "مركز هدباء",
    "CITY_ID": "13433",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5567",
    "DISTRICTNAME_AR": "نادي الفروسية",
    "DISTRICTNAME_EN": "نادي الفروسية",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5568",
    "DISTRICTNAME_AR": "عمليات الخفجي المشتركة",
    "DISTRICTNAME_EN": "عمليات الخفجي المشتركة",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5569",
    "DISTRICTNAME_AR": "أحواش الابل والغنام",
    "DISTRICTNAME_EN": "أحواش الابل والغنام",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5570",
    "DISTRICTNAME_AR": "مخطط مجمع الكليات",
    "DISTRICTNAME_EN": "مخطط مجمع الكليات",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5571",
    "DISTRICTNAME_AR": "مخطط 71",
    "DISTRICTNAME_EN": "مخطط 71",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5572",
    "DISTRICTNAME_AR": "الدوائر الحكومية",
    "DISTRICTNAME_EN": "الدوائر الحكومية",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5573",
    "DISTRICTNAME_AR": "مدينة الملك عبد العزيز",
    "DISTRICTNAME_EN": "مدينة الملك عبد العزيز",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5574",
    "DISTRICTNAME_AR": "حي الصناعية الاولى",
    "DISTRICTNAME_EN": "حي الصناعية الاولى",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5575",
    "DISTRICTNAME_AR": "حي اللؤلؤة",
    "DISTRICTNAME_EN": "حي اللؤلؤة",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5576",
    "DISTRICTNAME_AR": "حي الحمراء",
    "DISTRICTNAME_EN": "حي الحمراء",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5577",
    "DISTRICTNAME_AR": "حي الأمل",
    "DISTRICTNAME_EN": "حي الأمل",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5578",
    "DISTRICTNAME_AR": "حي الأمير نايف",
    "DISTRICTNAME_EN": "حي الأمير نايف",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5579",
    "DISTRICTNAME_AR": "حي الحرس الوطني",
    "DISTRICTNAME_EN": "حي الحرس الوطني",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5580",
    "DISTRICTNAME_AR": "حي الأندلس",
    "DISTRICTNAME_EN": "حي الأندلس",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5581",
    "DISTRICTNAME_AR": "حي اليرموك",
    "DISTRICTNAME_EN": "حي اليرموك",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5582",
    "DISTRICTNAME_AR": "حي الخالدية",
    "DISTRICTNAME_EN": "حي الخالدية",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5583",
    "DISTRICTNAME_AR": "حي الدرة",
    "DISTRICTNAME_EN": "حي الدرة",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5584",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "حي الورود",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5585",
    "DISTRICTNAME_AR": "حي الياسمين",
    "DISTRICTNAME_EN": "حي الياسمين",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5586",
    "DISTRICTNAME_AR": "حي الخزامي",
    "DISTRICTNAME_EN": "حي الخزامي",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5587",
    "DISTRICTNAME_AR": "إسكان الجمرك",
    "DISTRICTNAME_EN": "إسكان الجمرك",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5588",
    "DISTRICTNAME_AR": "مخطط 87",
    "DISTRICTNAME_EN": "مخطط 87",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5589",
    "DISTRICTNAME_AR": "حي_005033041",
    "DISTRICTNAME_EN": "حي_005033041",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5590",
    "DISTRICTNAME_AR": "حي005033042",
    "DISTRICTNAME_EN": "حي005033042",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5591",
    "DISTRICTNAME_AR": "حي_005033043",
    "DISTRICTNAME_EN": "حي_005033043",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5592",
    "DISTRICTNAME_AR": "حي_005033044",
    "DISTRICTNAME_EN": "حي_005033044",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5593",
    "DISTRICTNAME_AR": "ارض مملوكة",
    "DISTRICTNAME_EN": "ارض مملوكة",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5594",
    "DISTRICTNAME_AR": "حي الزهور ",
    "DISTRICTNAME_EN": "حي الزهور ",
    "CITY_ID": "11868",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5595",
    "DISTRICTNAME_AR": "حي سوق الماشية _النعيرية",
    "DISTRICTNAME_EN": "حي سوق الماشية _النعيرية",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5596",
    "DISTRICTNAME_AR": "حي الروضة",
    "DISTRICTNAME_EN": "حي الروضة",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5597",
    "DISTRICTNAME_AR": "حي الروابي",
    "DISTRICTNAME_EN": "حي الروابي",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5598",
    "DISTRICTNAME_AR": "حي المروج",
    "DISTRICTNAME_EN": "حي المروج",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5599",
    "DISTRICTNAME_AR": "حي الشهداء",
    "DISTRICTNAME_EN": "حي الشهداء",
    "CITY_ID": "11073",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5600",
    "DISTRICTNAME_AR": "حي اشبيليا",
    "DISTRICTNAME_EN": "حي اشبيليا",
    "CITY_ID": "11036",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5601",
    "DISTRICTNAME_AR": "صناعية العريفي - الجبيل ",
    "DISTRICTNAME_EN": "صناعية العريفي - الجبيل",
    "CITY_ID": "19366",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5602",
    "DISTRICTNAME_AR": "شاطئ نصف القمر",
    "DISTRICTNAME_EN": "شاطئ نصف القمر",
    "CITY_ID": "11045",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5603",
    "DISTRICTNAME_AR": "حي صناعية الظهران",
    "DISTRICTNAME_EN": "حي صناعية الظهران",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5604",
    "DISTRICTNAME_AR": "شاطئ نصف القمر",
    "DISTRICTNAME_EN": "شاطئ نصف القمر",
    "CITY_ID": "11048",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5605",
    "DISTRICTNAME_AR": "حي الروضة - عين دار القديمة ",
    "DISTRICTNAME_EN": "حي الروضة - عين دار القديمة ",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5606",
    "DISTRICTNAME_AR": "حي الأثير- عين دار القديمة",
    "DISTRICTNAME_EN": "حي الأثير- عين دار القديمة",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5607",
    "DISTRICTNAME_AR": "حي النخيل - عين دار القديمة ",
    "DISTRICTNAME_EN": "حي النخيل - عين دار القديمة ",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5608",
    "DISTRICTNAME_AR": "حي المنار - عين دار القديمة ",
    "DISTRICTNAME_EN": "حي المنار - عين دار القديمة ",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5609",
    "DISTRICTNAME_AR": "حي الجزيرة - عين دار القديمة ",
    "DISTRICTNAME_EN": "حي الجزيرة - عين دار القديمة ",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5610",
    "DISTRICTNAME_AR": "حي الفيحاء - عين دار القديمة",
    "DISTRICTNAME_EN": "حي الفيحاء - عين دار القديمة",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5611",
    "DISTRICTNAME_AR": "حي الندى - عين دار القديمة ",
    "DISTRICTNAME_EN": "حي الندى - عين دار القديمة ",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5612",
    "DISTRICTNAME_AR": "حي الفردوس - عين دار القديمة",
    "DISTRICTNAME_EN": "حي الفردوس - عين دار القديمة",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5613",
    "DISTRICTNAME_AR": "حي المنتزه",
    "DISTRICTNAME_EN": "حي المنتزه",
    "CITY_ID": "11056",
    "REGION_ID": 5
   },
   {
    "DISTRICT_ID": "5614",
    "DISTRICTNAME_AR": "العزيزية",
    "DISTRICTNAME_EN": "العزيزية",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5615",
    "DISTRICTNAME_AR": "الشبوة",
    "DISTRICTNAME_EN": "الشبوة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5616",
    "DISTRICTNAME_AR": "حليبة",
    "DISTRICTNAME_EN": "حليبة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5617",
    "DISTRICTNAME_AR": "الرفايع",
    "DISTRICTNAME_EN": "الرفايع",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5618",
    "DISTRICTNAME_AR": "القاع",
    "DISTRICTNAME_EN": "القاع",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5619",
    "DISTRICTNAME_AR": "الجنينة",
    "DISTRICTNAME_EN": "الجنينة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5620",
    "DISTRICTNAME_AR": "الشريفة",
    "DISTRICTNAME_EN": "الشريفة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5621",
    "DISTRICTNAME_AR": "عيدان",
    "DISTRICTNAME_EN": "عيدان",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5622",
    "DISTRICTNAME_AR": "عقلان",
    "DISTRICTNAME_EN": "عقلان",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5623",
    "DISTRICTNAME_AR": "دويرج",
    "DISTRICTNAME_EN": "دويرج",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5624",
    "DISTRICTNAME_AR": "ندوان",
    "DISTRICTNAME_EN": "ندوان",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5625",
    "DISTRICTNAME_AR": "الثنوء",
    "DISTRICTNAME_EN": "الثنوء",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5626",
    "DISTRICTNAME_AR": "الخيبر",
    "DISTRICTNAME_EN": "الخيبر",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5627",
    "DISTRICTNAME_AR": "البرقاء",
    "DISTRICTNAME_EN": "البرقاء",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5628",
    "DISTRICTNAME_AR": "النجادي",
    "DISTRICTNAME_EN": "النجادي",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5629",
    "DISTRICTNAME_AR": "الخرسعة",
    "DISTRICTNAME_EN": "الخرسعة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5630",
    "DISTRICTNAME_AR": "النقيع",
    "DISTRICTNAME_EN": "النقيع",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5631",
    "DISTRICTNAME_AR": "الحشرج",
    "DISTRICTNAME_EN": "الحشرج",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5632",
    "DISTRICTNAME_AR": "الشقيقة",
    "DISTRICTNAME_EN": "الشقيقة",
    "CITY_ID": "9010",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5633",
    "DISTRICTNAME_AR": "النجاح",
    "DISTRICTNAME_EN": "النجاح",
    "CITY_ID": "1339",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5634",
    "DISTRICTNAME_AR": "وادى بن هشبل",
    "DISTRICTNAME_EN": "وادى بن هشبل",
    "CITY_ID": "1338",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5635",
    "DISTRICTNAME_AR": "القوباء",
    "DISTRICTNAME_EN": "القوباء",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5636",
    "DISTRICTNAME_AR": "قرية الشط",
    "DISTRICTNAME_EN": "قرية الشط",
    "CITY_ID": "2694",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5637",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "الزهور",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5638",
    "DISTRICTNAME_AR": "القادسية",
    "DISTRICTNAME_EN": "القادسية",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5639",
    "DISTRICTNAME_AR": "النزهة",
    "DISTRICTNAME_EN": "النزهة",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5640",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5641",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "السلام",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5642",
    "DISTRICTNAME_AR": "النهضة",
    "DISTRICTNAME_EN": "النهضة",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5643",
    "DISTRICTNAME_AR": "الرحاب",
    "DISTRICTNAME_EN": "الرحاب",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5644",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "الروابي",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5645",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "المروج",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5646",
    "DISTRICTNAME_AR": "غرناطة",
    "DISTRICTNAME_EN": "غرناطة",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5647",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "الفيصلية",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5648",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "الخالدية",
    "CITY_ID": "8584",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5649",
    "DISTRICTNAME_AR": "حي العزيزيه",
    "DISTRICTNAME_EN": "حي العزيزيه",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5650",
    "DISTRICTNAME_AR": "حي الضباب",
    "DISTRICTNAME_EN": "حي الضباب",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5651",
    "DISTRICTNAME_AR": "حي الفيصليه",
    "DISTRICTNAME_EN": "حي الفيصليه",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5652",
    "DISTRICTNAME_AR": "حي المنهل قري ال نامس",
    "DISTRICTNAME_EN": "حي المنهل قري ال نامس",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5653",
    "DISTRICTNAME_AR": "حي الشرف",
    "DISTRICTNAME_EN": "حي الشرف",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5654",
    "DISTRICTNAME_AR": "حي المروج",
    "DISTRICTNAME_EN": "حي المروج",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5655",
    "DISTRICTNAME_AR": "حي الاندلس",
    "DISTRICTNAME_EN": "حي الاندلس",
    "CITY_ID": "6011",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5656",
    "DISTRICTNAME_AR": "النهضه",
    "DISTRICTNAME_EN": "النهضه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5657",
    "DISTRICTNAME_AR": "العزيزيه",
    "DISTRICTNAME_EN": "العزيزيه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5658",
    "DISTRICTNAME_AR": "النزهه",
    "DISTRICTNAME_EN": "النزهه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5659",
    "DISTRICTNAME_AR": "السلامة",
    "DISTRICTNAME_EN": "السلامة",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5660",
    "DISTRICTNAME_AR": "الروضه",
    "DISTRICTNAME_EN": "الروضه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5661",
    "DISTRICTNAME_AR": "الشهداء",
    "DISTRICTNAME_EN": "الشهداء",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5662",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "الزهور",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5663",
    "DISTRICTNAME_AR": "الخالديه",
    "DISTRICTNAME_EN": "الخالديه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5664",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "الربيع",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5665",
    "DISTRICTNAME_AR": "الفتح",
    "DISTRICTNAME_EN": "الفتح",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5666",
    "DISTRICTNAME_AR": "الفيصليه",
    "DISTRICTNAME_EN": "الفيصليه",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5667",
    "DISTRICTNAME_AR": "الربوه 1",
    "DISTRICTNAME_EN": "الربوه 1",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5668",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "المروج",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5669",
    "DISTRICTNAME_AR": "بدر",
    "DISTRICTNAME_EN": "بدر",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5670",
    "DISTRICTNAME_AR": "الأنصار",
    "DISTRICTNAME_EN": "الأنصار",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5671",
    "DISTRICTNAME_AR": "المنتزه 1",
    "DISTRICTNAME_EN": "المنتزه 1",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5672",
    "DISTRICTNAME_AR": "المنتزه 2",
    "DISTRICTNAME_EN": "المنتزه 2",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5673",
    "DISTRICTNAME_AR": "الربوه 2",
    "DISTRICTNAME_EN": "الربوه 2",
    "CITY_ID": "2792",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5674",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "الروضة",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5675",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5676",
    "DISTRICTNAME_AR": "الشرف",
    "DISTRICTNAME_EN": "الشرف",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5677",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "السلام",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5678",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5679",
    "DISTRICTNAME_AR": "الخالدية",
    "DISTRICTNAME_EN": "الخالدية",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5680",
    "DISTRICTNAME_AR": "الفيصلية",
    "DISTRICTNAME_EN": "الفيصلية",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5681",
    "DISTRICTNAME_AR": "الخليج",
    "DISTRICTNAME_EN": "الخليج",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5682",
    "DISTRICTNAME_AR": "المنتزة",
    "DISTRICTNAME_EN": "المنتزة",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5683",
    "DISTRICTNAME_AR": "العدالة",
    "DISTRICTNAME_EN": "العدالة",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5684",
    "DISTRICTNAME_AR": "الاندلس",
    "DISTRICTNAME_EN": "الاندلس",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5685",
    "DISTRICTNAME_AR": "الزهراء",
    "DISTRICTNAME_EN": "الزهراء",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5686",
    "DISTRICTNAME_AR": "البستان",
    "DISTRICTNAME_EN": "البستان",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5687",
    "DISTRICTNAME_AR": "المجد",
    "DISTRICTNAME_EN": "المجد",
    "CITY_ID": "7453",
    "REGION_ID": 6
   },
   {
    "DISTRICT_ID": "5688",
    "DISTRICTNAME_AR": "العليا",
    "DISTRICTNAME_EN": "العليا",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5689",
    "DISTRICTNAME_AR": "السلام",
    "DISTRICTNAME_EN": "السلام",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5690",
    "DISTRICTNAME_AR": "الصفاء",
    "DISTRICTNAME_EN": "الصفاء",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5691",
    "DISTRICTNAME_AR": "المرجان",
    "DISTRICTNAME_EN": "المرجان",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5692",
    "DISTRICTNAME_AR": "المروج",
    "DISTRICTNAME_EN": "المروج",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5693",
    "DISTRICTNAME_AR": "الاندلس",
    "DISTRICTNAME_EN": "الاندلس",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5694",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5695",
    "DISTRICTNAME_AR": "الورود",
    "DISTRICTNAME_EN": "الورود",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5696",
    "DISTRICTNAME_AR": "الشعب",
    "DISTRICTNAME_EN": "الشعب",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5697",
    "DISTRICTNAME_AR": "الاحمر",
    "DISTRICTNAME_EN": "الاحمر",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5698",
    "DISTRICTNAME_AR": "الشفا",
    "DISTRICTNAME_EN": "الشفا",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5699",
    "DISTRICTNAME_AR": "الربيع",
    "DISTRICTNAME_EN": "الربيع",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5700",
    "DISTRICTNAME_AR": "النسيم",
    "DISTRICTNAME_EN": "النسيم",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5701",
    "DISTRICTNAME_AR": "النخيل",
    "DISTRICTNAME_EN": "النخيل",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5702",
    "DISTRICTNAME_AR": "وادي الدغيدج",
    "DISTRICTNAME_EN": "وادي الدغيدج",
    "CITY_ID": "12587",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5703",
    "DISTRICTNAME_AR": "الحى التجارى",
    "DISTRICTNAME_EN": "الحى التجارى",
    "CITY_ID": "12578",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5704",
    "DISTRICTNAME_AR": "حي المصيف",
    "DISTRICTNAME_EN": "حي المصيف",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5705",
    "DISTRICTNAME_AR": "حي الروضة",
    "DISTRICTNAME_EN": "حي الروضة",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5706",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "حي الورود",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5707",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5708",
    "DISTRICTNAME_AR": "ارض سلاح الحدود",
    "DISTRICTNAME_EN": "ارض سلاح الحدود",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5709",
    "DISTRICTNAME_AR": "سوق الاغنام",
    "DISTRICTNAME_EN": "سوق الاغنام",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5710",
    "DISTRICTNAME_AR": "حي اليرموك",
    "DISTRICTNAME_EN": "حي اليرموك",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5711",
    "DISTRICTNAME_AR": "حي الضاحية",
    "DISTRICTNAME_EN": "حي الضاحية",
    "CITY_ID": "12679",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5712",
    "DISTRICTNAME_AR": "القري السياحية بالسجدة",
    "DISTRICTNAME_EN": "القري السياحية بالسجدة",
    "CITY_ID": "12655",
    "REGION_ID": 7
   },
   {
    "DISTRICT_ID": "5713",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "المنطقة الصناعية",
    "CITY_ID": "13674",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5714",
    "DISTRICTNAME_AR": "سوق المواشى",
    "DISTRICTNAME_EN": "سوق المواشى",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5715",
    "DISTRICTNAME_AR": "شرق الصناعية",
    "DISTRICTNAME_EN": "شرق الصناعية",
    "CITY_ID": "13674",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5716",
    "DISTRICTNAME_AR": "المنطقة الصناعية",
    "DISTRICTNAME_EN": "المنطقة الصناعية",
    "CITY_ID": "13674",
    "REGION_ID": "NULL"
   },
   {
    "DISTRICT_ID": "5717",
    "DISTRICTNAME_AR": "حى البلد",
    "DISTRICTNAME_EN": "حى البلد",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5718",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5719",
    "DISTRICTNAME_AR": "سوق الماشية",
    "DISTRICTNAME_EN": "سوق الماشية",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5720",
    "DISTRICTNAME_AR": "حى النخيل",
    "DISTRICTNAME_EN": "حى النخيل",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5721",
    "DISTRICTNAME_AR": "حى الوسيطاء",
    "DISTRICTNAME_EN": "حى الوسيطاء",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5722",
    "DISTRICTNAME_AR": "حى صلاح الدين",
    "DISTRICTNAME_EN": "حى صلاح الدين",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5723",
    "DISTRICTNAME_AR": "حى السلام",
    "DISTRICTNAME_EN": "حى السلام",
    "CITY_ID": "15883",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5724",
    "DISTRICTNAME_AR": "شبيكان",
    "DISTRICTNAME_EN": "شبيكان",
    "CITY_ID": "15817",
    "REGION_ID": 8
   },
   {
    "DISTRICT_ID": "5725",
    "DISTRICTNAME_AR": "حي المصيف",
    "DISTRICTNAME_EN": "حي المصيف",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5726",
    "DISTRICTNAME_AR": "مخطط المنطقة الصناعية بمدينة العويقيلة",
    "DISTRICTNAME_EN": "مخطط المنطقة الصناعية بمدينة العويقيلة",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5727",
    "DISTRICTNAME_AR": "حي الورود",
    "DISTRICTNAME_EN": "حي الورود",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5728",
    "DISTRICTNAME_AR": "هجرة نعيجان",
    "DISTRICTNAME_EN": "هجرة نعيجان",
    "CITY_ID": "12526",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5729",
    "DISTRICTNAME_AR": "هجرة المركوز",
    "DISTRICTNAME_EN": "هجرة المركوز",
    "CITY_ID": "12517",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5730",
    "DISTRICTNAME_AR": "هجرة ابن عايش",
    "DISTRICTNAME_EN": "هجرة ابن عايش",
    "CITY_ID": "12519",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5731",
    "DISTRICTNAME_AR": "المربع الخدمي",
    "DISTRICTNAME_EN": "المربع الخدمي",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5732",
    "DISTRICTNAME_AR": "مخطط  شرق مدينة العويقيلة",
    "DISTRICTNAME_EN": "مخطط  شرق مدينة العويقيلة",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5733",
    "DISTRICTNAME_AR": "مهاجع حرس الحدود",
    "DISTRICTNAME_EN": "مهاجع حرس الحدود",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5734",
    "DISTRICTNAME_AR": "ادارة التعليم",
    "DISTRICTNAME_EN": "ادارة التعليم",
    "CITY_ID": "12518",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5735",
    "DISTRICTNAME_AR": "هجرة ابن ثنيان",
    "DISTRICTNAME_EN": "هجرة ابن ثنيان",
    "CITY_ID": "12523",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5736",
    "DISTRICTNAME_AR": "هجرة الكاسب",
    "DISTRICTNAME_EN": "هجرة الكاسب",
    "CITY_ID": "12522",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5737",
    "DISTRICTNAME_AR": "مخطط استثماري جنوب طريف",
    "DISTRICTNAME_EN": "مخطط استثماري جنوب طريف",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5738",
    "DISTRICTNAME_AR": "حي المنتزه",
    "DISTRICTNAME_EN": "حي المنتزه",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5739",
    "DISTRICTNAME_AR": "حي مخطط الاستراحات",
    "DISTRICTNAME_EN": "حي مخطط الاستراحات",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5740",
    "DISTRICTNAME_AR": "الحي الصناعي",
    "DISTRICTNAME_EN": "الحي الصناعي",
    "CITY_ID": "12190",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5741",
    "DISTRICTNAME_AR": "حي الصناعية",
    "DISTRICTNAME_EN": "حي الصناعية",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5742",
    "DISTRICTNAME_AR": "حي هجرة لوقة",
    "DISTRICTNAME_EN": "حي هجرة لوقة",
    "CITY_ID": "12225",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5743",
    "DISTRICTNAME_AR": "حي المعارض",
    "DISTRICTNAME_EN": "حي المعارض",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5744",
    "DISTRICTNAME_AR": "مخطط الأعلاف و حظائر المواشى بطلعة التمياط",
    "DISTRICTNAME_EN": "مخطط الأعلاف و حظائر المواشى بطلعة التمياط",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5745",
    "DISTRICTNAME_AR": "حي مخطط  فيصل",
    "DISTRICTNAME_EN": "حي مخطط  فيصل",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5746",
    "DISTRICTNAME_AR": "حي المنطقه السكنية نموذجية طلعة التمياط",
    "DISTRICTNAME_EN": "حي المنطقه السكنية نموذجية طلعة التمياط",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5747",
    "DISTRICTNAME_AR": "هجرة طلعة التمياط",
    "DISTRICTNAME_EN": "هجرة طلعة التمياط",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5748",
    "DISTRICTNAME_AR": "مخطط التوسعي",
    "DISTRICTNAME_EN": "مخطط التوسعي",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5749",
    "DISTRICTNAME_AR": "حي المخطط الاداري ",
    "DISTRICTNAME_EN": "حي المخطط الاداري ",
    "CITY_ID": "1776",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5750",
    "DISTRICTNAME_AR": "العجرمية",
    "DISTRICTNAME_EN": "العجرمية",
    "CITY_ID": "12223",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5751",
    "DISTRICTNAME_AR": "حي مخطط هجرة الحدقه",
    "DISTRICTNAME_EN": "حي مخطط هجرة الحدقه",
    "CITY_ID": "15894",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5752",
    "DISTRICTNAME_AR": "مخطط سوق الماشية والأعلاف",
    "DISTRICTNAME_EN": "مخطط سوق الماشية والأعلاف",
    "CITY_ID": "12200",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5753",
    "DISTRICTNAME_AR": "مخطط تنظيمي لأرض المواطن متعب بن هباس",
    "DISTRICTNAME_EN": "مخطط تنظيمي لأرض المواطن متعب بن هباس",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5754",
    "DISTRICTNAME_AR": "مركز خدمة روضة هباس أبو صور",
    "DISTRICTNAME_EN": "مركز خدمة روضة هباس أبو صور",
    "CITY_ID": "15897",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5755",
    "DISTRICTNAME_AR": "مخطط هجرة أبو صور",
    "DISTRICTNAME_EN": "مخطط هجرة أبو صور",
    "CITY_ID": "15897",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5756",
    "DISTRICTNAME_AR": "مخطط حكومي",
    "DISTRICTNAME_EN": "مخطط حكومي",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5757",
    "DISTRICTNAME_AR": "مخطط سكني معتمد",
    "DISTRICTNAME_EN": "مخطط سكني معتمد",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5758",
    "DISTRICTNAME_AR": "مركز خدمة روضة هباس طريق رفحاء",
    "DISTRICTNAME_EN": "مركز خدمة روضة هباس طريق رفحاء",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5759",
    "DISTRICTNAME_AR": "مخطط استثماري",
    "DISTRICTNAME_EN": "مخطط استثماري",
    "CITY_ID": "15900",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5760",
    "DISTRICTNAME_AR": "مخطط تهذيبي لهجرة القصوريات",
    "DISTRICTNAME_EN": "مخطط تهذيبي لهجرة القصوريات",
    "CITY_ID": "15898",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5761",
    "DISTRICTNAME_AR": "مخطط تنظيمي لموقع الإيواء",
    "DISTRICTNAME_EN": "مخطط تنظيمي لموقع الإيواء",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5762",
    "DISTRICTNAME_AR": "مخطط هجرة القصوريات",
    "DISTRICTNAME_EN": "مخطط هجرة القصوريات",
    "CITY_ID": "15898",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5763",
    "DISTRICTNAME_AR": "مخطط هجرة الخشيبي",
    "DISTRICTNAME_EN": "مخطط هجرة الخشيبي",
    "CITY_ID": "15900",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5764",
    "DISTRICTNAME_AR": "مخطط استثماري الاستراحات",
    "DISTRICTNAME_EN": "مخطط استثماري الاستراحات",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5765",
    "DISTRICTNAME_AR": "ملحق الصناعية والمستودعات ومعارض السيارات",
    "DISTRICTNAME_EN": "ملحق الصناعية والمستودعات ومعارض السيارات",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5766",
    "DISTRICTNAME_AR": "مخطط الصناعية",
    "DISTRICTNAME_EN": "مخطط الصناعية",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5767",
    "DISTRICTNAME_AR": "مخطط سوق الماشية والأعلاف",
    "DISTRICTNAME_EN": "مخطط سوق الماشية والأعلاف",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5768",
    "DISTRICTNAME_AR": "مخطط روضة هباس ",
    "DISTRICTNAME_EN": "مخطط روضة هباس ",
    "CITY_ID": "15899",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5769",
    "DISTRICTNAME_AR": "حي هجرة قيصومة فيحان",
    "DISTRICTNAME_EN": "حي هجرة قيصومة فيحان",
    "CITY_ID": "12224",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5770",
    "DISTRICTNAME_AR": "مخطط الخرسانة و الدراكيل",
    "DISTRICTNAME_EN": "مخطط الخرسانة و الدراكيل",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5771",
    "DISTRICTNAME_AR": "حي مخطط المستودعات",
    "DISTRICTNAME_EN": "حي مخطط المستودعات",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5772",
    "DISTRICTNAME_AR": "حي الدوائر الحكومي",
    "DISTRICTNAME_EN": "حي الدوائر الحكومي",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5773",
    "DISTRICTNAME_AR": "مخطط احواش الاغنام",
    "DISTRICTNAME_EN": "مخطط احواش الاغنام",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5774",
    "DISTRICTNAME_AR": "مخطط معارض السيارات",
    "DISTRICTNAME_EN": "مخطط معارض السيارات",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5775",
    "DISTRICTNAME_AR": "مخطط استثماري",
    "DISTRICTNAME_EN": "مخطط استثماري",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5776",
    "DISTRICTNAME_AR": "حي الضاحية",
    "DISTRICTNAME_EN": "حي الضاحية",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5777",
    "DISTRICTNAME_AR": "حي التشاليح",
    "DISTRICTNAME_EN": "حي التشاليح",
    "CITY_ID": "12226",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5778",
    "DISTRICTNAME_AR": "حي أم خنصر القديمة",
    "DISTRICTNAME_EN": "حي أم خنصر القديمة",
    "CITY_ID": "12515",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5779",
    "DISTRICTNAME_AR": "مخطط هجرة السليمانية ",
    "DISTRICTNAME_EN": "مخطط هجرة السليمانية ",
    "CITY_ID": "12516",
    "REGION_ID": 13
   },
   {
    "DISTRICT_ID": "5780",
    "DISTRICTNAME_AR": "الحي الاداري",
    "DISTRICTNAME_EN": "الحي الاداري",
    "CITY_ID": "12515",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5781",
    "DISTRICTNAME_AR": "مخطط سكني ",
    "DISTRICTNAME_EN": "مخطط سكني ",
    "CITY_ID": "12515",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5782",
    "DISTRICTNAME_AR": "مخطط استثماري الاستراحات",
    "DISTRICTNAME_EN": "مخطط استثماري الاستراحات",
    "CITY_ID": "12515",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5783",
    "DISTRICTNAME_AR": "حي لينة القديمة",
    "DISTRICTNAME_EN": "حي لينة القديمة",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5784",
    "DISTRICTNAME_AR": "اعيوج لينه المخطط",
    "DISTRICTNAME_EN": "اعيوج لينه المخطط",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5785",
    "DISTRICTNAME_AR": "رغوة",
    "DISTRICTNAME_EN": "رغوة",
    "CITY_ID": "15889",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5786",
    "DISTRICTNAME_AR": "حي لينة الجديدة",
    "DISTRICTNAME_EN": "حي لينة الجديدة",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5787",
    "DISTRICTNAME_AR": "حي_009008006",
    "DISTRICTNAME_EN": "حي_009008006",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5788",
    "DISTRICTNAME_AR": "حي أحواش الأغنام",
    "DISTRICTNAME_EN": "حي أحواش الأغنام",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5789",
    "DISTRICTNAME_AR": "حي الصناعية",
    "DISTRICTNAME_EN": "حي الصناعية",
    "CITY_ID": "15888",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5790",
    "DISTRICTNAME_AR": "حي هجرة ابن بكر المخطط",
    "DISTRICTNAME_EN": "حي هجرة ابن بكر المخطط",
    "CITY_ID": "1779",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5791",
    "DISTRICTNAME_AR": "مخطط سوق الماشية والأعلاف بحزم الجلاميد ",
    "DISTRICTNAME_EN": "مخطط سوق الماشية والأعلاف بحزم الجلاميد ",
    "CITY_ID": "1782",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5792",
    "DISTRICTNAME_AR": "مخطط مركز حزم الجلاميد",
    "DISTRICTNAME_EN": "مخطط مركز حزم الجلاميد",
    "CITY_ID": "1782",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5793",
    "DISTRICTNAME_AR": "مخطط المنطقة الصناعية بحزم الجلاميد  ",
    "DISTRICTNAME_EN": "مخطط المنطقة الصناعية بحزم الجلاميد  ",
    "CITY_ID": "1782",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5794",
    "DISTRICTNAME_AR": "مخطط استثماري ",
    "DISTRICTNAME_EN": "مخطط استثماري ",
    "CITY_ID": "1782",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5795",
    "DISTRICTNAME_AR": "منفذ الجديدة",
    "DISTRICTNAME_EN": "منفذ الجديدة",
    "CITY_ID": "1781",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5796",
    "DISTRICTNAME_AR": "الرمال",
    "DISTRICTNAME_EN": "الرمال",
    "CITY_ID": "12220",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5797",
    "DISTRICTNAME_AR": "الملقا",
    "DISTRICTNAME_EN": "الملقا",
    "CITY_ID": "12220",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5798",
    "DISTRICTNAME_AR": "الرفاع",
    "DISTRICTNAME_EN": "الرفاع",
    "CITY_ID": "12220",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5799",
    "DISTRICTNAME_AR": "اليرموك",
    "DISTRICTNAME_EN": "اليرموك",
    "CITY_ID": "12220",
    "REGION_ID": 9
   },
   {
    "DISTRICT_ID": "5800",
    "DISTRICTNAME_AR": "مختارة والمراكز الحضرية",
    "DISTRICTNAME_EN": "مختارة والمراكز الحضرية",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5801",
    "DISTRICTNAME_AR": "ج",
    "DISTRICTNAME_EN": "ج",
    "CITY_ID": "14463",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5802",
    "DISTRICTNAME_AR": "البلدية",
    "DISTRICTNAME_EN": "البلدية",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5803",
    "DISTRICTNAME_AR": "الحصامة",
    "DISTRICTNAME_EN": "الحصامة",
    "CITY_ID": "14567",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5804",
    "DISTRICTNAME_AR": "الصناعية",
    "DISTRICTNAME_EN": "الصناعية",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5805",
    "DISTRICTNAME_AR": "الصفا",
    "DISTRICTNAME_EN": "الصفا",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5806",
    "DISTRICTNAME_AR": "العمارية",
    "DISTRICTNAME_EN": "العمارية",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5807",
    "DISTRICTNAME_AR": "الروضة",
    "DISTRICTNAME_EN": "الروضة",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5808",
    "DISTRICTNAME_AR": "المنجارة",
    "DISTRICTNAME_EN": "المنجارة",
    "CITY_ID": "14500",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5809",
    "DISTRICTNAME_AR": "البيطارية",
    "DISTRICTNAME_EN": "البيطارية",
    "CITY_ID": "14494",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5810",
    "DISTRICTNAME_AR": "الروابي",
    "DISTRICTNAME_EN": "الروابي",
    "CITY_ID": "14495",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5811",
    "DISTRICTNAME_AR": "القسوم",
    "DISTRICTNAME_EN": "القسوم",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5812",
    "DISTRICTNAME_AR": "القايم",
    "DISTRICTNAME_EN": "القايم",
    "CITY_ID": "14495",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5813",
    "DISTRICTNAME_AR": "الواسط",
    "DISTRICTNAME_EN": "الواسط",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5814",
    "DISTRICTNAME_AR": "المطلع",
    "DISTRICTNAME_EN": "المطلع",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5815",
    "DISTRICTNAME_AR": "العز",
    "DISTRICTNAME_EN": "العز",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5816",
    "DISTRICTNAME_AR": "المنزم",
    "DISTRICTNAME_EN": "المنزم",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5817",
    "DISTRICTNAME_AR": "الغصينية",
    "DISTRICTNAME_EN": "الغصينية",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5818",
    "DISTRICTNAME_AR": "الميزاب",
    "DISTRICTNAME_EN": "الميزاب",
    "CITY_ID": "14490",
    "REGION_ID": 10
   },
   {
    "DISTRICT_ID": "5819",
    "DISTRICTNAME_AR": "مخطط منح الأوامر السامية",
    "DISTRICTNAME_EN": "مخطط منح الأوامر السامية",
    "CITY_ID": "3736",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5820",
    "DISTRICTNAME_AR": "حى التلال",
    "DISTRICTNAME_EN": "حى التلال",
    "CITY_ID": "4278",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5821",
    "DISTRICTNAME_AR": "الامير سلطان",
    "DISTRICTNAME_EN": "الامير سلطان",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5822",
    "DISTRICTNAME_AR": "الشعبة",
    "DISTRICTNAME_EN": "الشعبة",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5823",
    "DISTRICTNAME_AR": "النهضة 2",
    "DISTRICTNAME_EN": "النهضة 2",
    "CITY_ID": "4014",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5824",
    "DISTRICTNAME_AR": "خباش",
    "DISTRICTNAME_EN": "خباش",
    "CITY_ID": "4001",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5825",
    "DISTRICTNAME_AR": "الوفاء",
    "DISTRICTNAME_EN": "الوفاء",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5826",
    "DISTRICTNAME_AR": "الضيقة",
    "DISTRICTNAME_EN": "الضيقة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5827",
    "DISTRICTNAME_AR": "الحبابة",
    "DISTRICTNAME_EN": "الحبابة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5828",
    "DISTRICTNAME_AR": "عصيم",
    "DISTRICTNAME_EN": "عصيم",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5829",
    "DISTRICTNAME_AR": "النقعاء",
    "DISTRICTNAME_EN": "النقعاء",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5830",
    "DISTRICTNAME_AR": "الفيض",
    "DISTRICTNAME_EN": "الفيض",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5831",
    "DISTRICTNAME_AR": "تريمة",
    "DISTRICTNAME_EN": "تريمة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5832",
    "DISTRICTNAME_AR": "الحرشف",
    "DISTRICTNAME_EN": "الحرشف",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5833",
    "DISTRICTNAME_AR": "الشطين",
    "DISTRICTNAME_EN": "الشطين",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5834",
    "DISTRICTNAME_AR": "مريخة",
    "DISTRICTNAME_EN": "مريخة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5835",
    "DISTRICTNAME_AR": "الوسيعة",
    "DISTRICTNAME_EN": "الوسيعة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5836",
    "DISTRICTNAME_AR": "السريحة",
    "DISTRICTNAME_EN": "السريحة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5837",
    "DISTRICTNAME_AR": "حشوة",
    "DISTRICTNAME_EN": "حشوة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5838",
    "DISTRICTNAME_AR": "كتنة",
    "DISTRICTNAME_EN": "كتنة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5839",
    "DISTRICTNAME_AR": "وادى بطا",
    "DISTRICTNAME_EN": "وادى بطا",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5840",
    "DISTRICTNAME_AR": "سبوحة",
    "DISTRICTNAME_EN": "سبوحة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5841",
    "DISTRICTNAME_AR": "وادي عرقان",
    "DISTRICTNAME_EN": "وادي عرقان",
    "CITY_ID": "3731",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5842",
    "DISTRICTNAME_AR": "وادى صخى",
    "DISTRICTNAME_EN": "وادى صخى",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5843",
    "DISTRICTNAME_AR": "السبت",
    "DISTRICTNAME_EN": "السبت",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5844",
    "DISTRICTNAME_AR": "الوحى",
    "DISTRICTNAME_EN": "الوحى",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5845",
    "DISTRICTNAME_AR": "قشيعة",
    "DISTRICTNAME_EN": "قشيعة",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5846",
    "DISTRICTNAME_AR": "ابا الطحين",
    "DISTRICTNAME_EN": "ابا الطحين",
    "CITY_ID": "3795",
    "REGION_ID": 11
   },
   {
    "DISTRICT_ID": "5847",
    "DISTRICTNAME_AR": "الزهور",
    "DISTRICTNAME_EN": "الزهور",
    "CITY_ID": "3755",
    "REGION_ID": 11
   }
  ];

export const DEMO_VENDORS = ['amakkncomapny']; //amakknbroker

export const MAX_VIDEO_SIZE = 10;

export const DEVSA2_DOMAINS = ['amakkncompany.com','alkhozamaoffice.com'];
