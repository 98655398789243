import { Dialog, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getListOfContactedPeople,markContactUsAsRead } from "../../../api/userApi";
import Loadingdata from '../../../components/global-components/loading-data';
import NoData from '../../../components/global-components/no-data';
import { Close } from "@mui/icons-material";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ClientsMessagesPage = (props) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const { token, setUnreadMessagesCount } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState('');
  const { t, i18n } = useTranslation();

  const getData = () => {
    getListOfContactedPeople({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then(res => {
      if (res.resCode === 0) {
        setData(res.response.contactedList);
        const sum = res.response.contactedList.reduce(
          (accumulator, message) => accumulator + (message.isRead === "0" ? 1 : 0), 0
        );
        setUnreadMessagesCount(sum);
      }
      setLoadingData(false);
    });
  };

  useEffect(() => {
    getData();
  },// eslint-disable-next-line
    [token, i18n.language]);
  
  return (
    <>
      <SubHeader OKElement={<></>} />
      <TableContainer className="table-container mb-5" component={Paper}>
        <Table sx={{ minWidth: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell align="right">{t("Name")}</TableCell>
              <TableCell align="right">{t("Email")}</TableCell>
              <TableCell align="right">{t("PhoneNumber")}</TableCell>
              <TableCell align="right">{t("Subject")}</TableCell>
              <TableCell align="right">{t("Status")}</TableCell>
              <TableCell align="right">{t("SendingDate")}</TableCell>
              <TableCell align="right">{t("Message")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingData ? <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            ><TableCell colSpan={7}><Loadingdata /></TableCell></TableRow> :
              data.length > 0 ? data.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="right" component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.email || t("NotAvailable")}</TableCell>
                  <TableCell align="right">{row.phone}</TableCell>
                  <TableCell align="right">{row.subject}</TableCell>
                  <TableCell align="right">{row.isRead === "1" ? <span>{t("Read")}</span> : <span style={{ color: 'var(--main-color-one)' }}>{t("New1")}</span>}</TableCell>
                  <TableCell align="right"> {moment(row.createdAt).format('DD-MMMM-YYYY')}</TableCell>

                  <TableCell align="right">
                    <button className="outlined-btn px-3" onClick={() => {
                      if (row.isRead === "0")
                        markContactUsAsRead({ userId: token, language: i18n.language === 'ar' ? '0' : '1', id: row.id }).then(() => getData());
                      setSelectedMsg(row.text);
                      setOpenModal(true);
                    }}>
                      {t('ViewMessage')}
                    </button>
                  </TableCell>

                </TableRow>
              )) :
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                ><TableCell colSpan={7}><NoData msg={t("ThereAreNoMessages")} /></TableCell></TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={openModal}
        className="custom-dialog model"
        onClose={() => setOpenModal(false)}>
        <DialogTitle>
          <div className="dialog-head">
            <div>
              <h2>{t("Message")}</h2>
            </div>
            <div className="dialog-actions">
              <span onClick={() => setOpenModal(false)} style={{ cursor: 'pointer' }}>
                <Close />
              </span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='row justify-content-start'>
            <div style={{ display: 'flex', height: '400px', width: '100%' }}>
              <div style={{ flexGrow: 1, width: '100%', padding: '0 15px', whiteSpace:'pre-line' }}>
                {selectedMsg}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ClientsMessagesPage;
