import React from "react";
import { useTranslation } from "react-i18next";
import { getLocalizedText } from "../../../helpers";


const T05Services = ({ data, desc }) => {
    const { t, i18n } = useTranslation();

    return (
        <section className="services-section">
            <div className="row mx-0 w-100" style={{ marginBottom: '50px', marginTop: '40px' }}>
                <div className="col-12 d-flex flex-column p-0" >
                    <span className="section-prefix tag" data-aos="fade-left" data-aos-duration="1500">{t("OurServices")}</span>
                    <h2 className="sec-title text-start" data-aos="fade-up" data-aos-duration="1500">
                        <span>{t("ServicesWeProvide")}</span>
                        <span className="wavy-line"></span>
                    </h2>
                </div>
                <div className="col-12 p-0" data-aos="fade-right" data-aos-duration="1500">
                    <p className="sec-desc text-start">{desc}</p>
                </div>
            </div>
            <div className='col-12 news-details-wrap row mx-0 p-0 projects-page justify-content-center'>
                {data.length > 0 &&
                    data.map((item, i) => (
                        <div key={item.id} className='col-12 h-100 w-100 mb-5 project-big service-big p-0' data-aos="fade-up" data-aos-duration="1500">
                            <div className={`row w-100 mx-0 h-100 align-items-center ${i === 1 ? 'flex-row-reverse' : ''}`}>
                                <div className='col-12 h-100 shine-animate-item p-0' style={{ overflow: 'hidden' }}>
                                    <img
                                        src={item.icon}
                                        alt={getLocalizedText(item.title, i18n.language, false)}
                                        className="shine-animate"
                                        style={{ objectFit: 'cover', height: '400px', width: '100%' }}
                                    />
                                    <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100" className={`col-12 d-flex flex-column justify-content-center ${i % 2 === 0 ? 'align-items-end' : ''} px-3 pb-3 project-data`}>
                                        <div className="col-12 col-lg-6 details">
                                            <h4 className='mb-3'>{getLocalizedText(item.title, i18n.language, false)}</h4>
                                            <p>{getLocalizedText(item.description_small, i18n.language, false)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </section>
    );
}

export default T05Services;
