
import React from 'react';
import { Grid, Card, Box, Container, CardHeader, CardContent, Tooltip } from '@mui/material';
import { useAuth } from '../helpers/context';
import { plansAvailabilty } from '../constants';
import { GreenCheck, RedClose } from '../constants/icons';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';

export default function OnBoardingPlansCards({ normalPlans, data, currentPlan, isUserVerified, onClick }) {
    const { token } = useAuth();
    // const [openVerifyModal, setOpenVerifyModal] = useState(false);
    // const [openChangeTypeModal, setOpenChangeTypeModal] = useState(false);
    // const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    return data && data.length > 0 ? (
        <section>
            <Container maxWidth="lg">
                <Box py={3} textAlign="center">
                    <Grid container spacing={3} sx={{
                        border: 'none',
                        padding: '50px 4px 20px',
                        width: '100%',
                        borderRadius: '12px',
                        justifyContent: 'center',
                    }}>
                        {data?.filter(d => plansAvailabilty['2'].includes(d.id))?.map(item =>
                            (!token || (token && (([9, 10].includes(+currentPlan?.planId) && +item.id === 6) ||
                                ([11, 12].includes(+currentPlan?.planId) && +item === 8) ||
                                ([0, 1, 2, 3, 4, 5, 6, 7, 8].includes(+currentPlan?.planId || 0)))))
                            && item.duration === '30' && (
                                <Grid item xs={12} md={4} key={item.id} style={{ padding: '0 6px 15px' }} data-aos="fade-up" data-aos-duration="1500">
                                    <Card className='pricing-card'>
                                        <span className='circle circle-1'></span>
                                        <span className='circle circle-2'></span>
                                        <CardHeader className='pricing-card-header' title={item.name}></CardHeader>
                                        <CardContent sx={{ minHeight: 300 }}>
                                            <Box px={1}>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'><GreenCheck green /></span>
                                                    <span> {t("AbilityToAddADs", { number: normalPlans?.[+item.id === 6 ? 8 : 10]?.allowedPublishedProperties })}</span>
                                                    <span>
                                                        <Tooltip title={<div>{t("YouCanAddUnlimitedNumberOfRealEstateADsButYouCanPublish")} {" " + normalPlans?.[+item.id === 6 ? 8 : 10]?.allowedPublishedProperties + " "} {t("RealEstateAD1") + ' '+ t("AtOnce")}</div>}>
                                                            <InfoIcon sx={{ cursor: 'pointer', position: 'relative', top: '2px', fontSize: '16px', color: 'var(--main-color-one)' }} />
                                                        </Tooltip>
                                                    </span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.allowedAgents !== '0' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>{normalPlans?.[+item.id === 6 ? 8 : 10]?.allowedAgents !== '0' ? t("AbilityToAddAgents1", { number: normalPlans?.[+item.id === 6 ? 8 : 10]?.allowedAgents }) : t("AbilityToAddAgents")} </span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.hasSupportPriority === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>{t("TechnicalSupportPriority")}</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canAddLogo === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>{t("AbilityToAddTheCompanyLogoToTheADImages")}</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canAddNumber === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>{t("AbilityToAddTheUnifiedContactNumber")}</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canAddOnMap === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>{t("AbilityToAddTheLocationToTheMapOnTheCompanyPage")}</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canAddVideo === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>{t("AbilityToAddVideosToTheRealEstateAD")}</span>
                                                </p>
                                                {/* <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canAddExtraAds === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>{t("AbilityToAddAdditionalADsFor20SARPerAD")}</span>
                                                </p> */}
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canExportAdPhoto === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>{t("PrintableAndShareableImageOfRealEstateListingDetails")}</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.permanentUpgradeToNumberOfAds > '0' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>
                                                        {+normalPlans?.[+item.id === 6 ? 8 : 10]?.permanentUpgradeToNumberOfAds > 0 ? (
                                                            `${t("AbilityToFeature")} ${+normalPlans?.[+item.id === 6 ? 8 : 10]?.permanentUpgradeToNumberOfAds > 2 ? `${normalPlans?.[+item.id === 6 ? 8 : 10]?.permanentUpgradeToNumberOfAds} ${t("RealEstateAds")}` : t("OnlyOneRealEstateAD")}`
                                                        ) : t("AbilityToFeatureRealEstateADs")
                                                        }
                                                    </span>
                                                </p>
                                                <hr />
                                                <p>{t("SaaSService")}</p>
                                                {item?.features?.map((f, i) => <p key={i} className="pricing-card-feature">
                                                    <span className='icon'>{f.available === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>{f.name === "CRM2" ? t(f.name, { num: Intl.NumberFormat('en').format(normalPlans?.[+item.id === 6 ? 8 : 10]?.planCustomers) }) : t(f.name)}</span>
                                                </p>)}
                                            </Box>
                                        </CardContent>
                                        {/* <Box px={1}>
                                            {token ? isUserVerified !== '3' ? (
                                                <VerifyPopup
                                                    openVerifyModal={openVerifyModal}
                                                    setOpenVerifyModal={setOpenVerifyModal}
                                                    desc={t("YouMustVerifyYourAccountInOrderToContinue")}
                                                    trigger={
                                                        <LoadingButton
                                                            loading={false}
                                                            classes="mb-4 py-2 primary-btn w-100 d-flex justify-content-center small-raduis"
                                                            handleClick={() => setOpenVerifyModal(true)}
                                                            label={[9, 10, 11, 12].includes(+currentPlan?.planId) ? t("UpgradeThePlan") : t("TryItForFree")}
                                                        />
                                                    }
                                                />
                                            ) : AccountType === '1' ? (
                                                <ChangeTypePopup
                                                    openChangeTypeModal={openChangeTypeModal}
                                                    setOpenChangeTypeModal={setOpenChangeTypeModal}
                                                    desc={t("YouMustChangeYourAccountTypeToBusinessAccountInOrderToContinue")}
                                                    trigger={
                                                        <LoadingButton
                                                            loading={false}
                                                            classes="mb-4 py-2 primary-btn w-100 d-flex justify-content-center"
                                                            handleClick={() => setOpenChangeTypeModal(true)}
                                                            label={[9, 10, 11, 12].includes(+currentPlan?.planId) ? t("UpgradeThePlan") : t("TryItForFree")}
                                                        />
                                                    }
                                                />
                                            ) : (<LoadingButton
                                                loading={false}
                                                classes="mb-4 py-2 primary-btn w-100 d-flex justify-content-center small-raduis"
                                                handleClick={() => isUserVerified !== '3' ? null : onClick()}
                                                label={[9, 10, 11, 12].includes(+currentPlan?.planId) ? t("UpgradeThePlan") : t("TryItForFree")}
                                            />) : (<LoginPopup
                                                setToken={setToken}
                                                setUserId={setUserId}
                                                children={<button className="mb-4 py-2 primary-btn w-100 d-flex justify-content-center small-raduis">{t("TryItForFree")}</button>}
                                                path={(window.location.href.includes('amakkn-pro') ? '/amakkn-pro?loggedIn' : window.location.href.includes('pro') ? '/pro?loggedIn' : `/minasati?loggedIn`) + searchParams.get('dark') === "true" ? '&dark=true' : ""}

                                            />)}
                                        </Box> */}
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>
                </Box>
            </Container>
        </section>
    ) : <></>;
}
