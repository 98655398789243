import i18next from "i18next";
import { DEVSA2_DOMAINS, appVersion } from "../constants";
import { handleResponse, handleError } from "./apiUtils";
import { getLocation } from "./generalAPI";
import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);
const browserObject = browser.getBrowser();

const baseUrl = DEVSA2_DOMAINS.includes(window.location.host) ? 'https://devsa2.amakkn.com' : process.env.REACT_APP_API_URL;

export function getPropertyTypesForCategory(props) {
  const data = { category: props.category, language: props.language };
  return fetch(baseUrl + "/Property/getPropertyTypesForCategoryNew/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function getFeaturedProperty(token) {
  const data = {
    platform: "3",
    language: i18next.language === 'ar' ? '0' : '1',
    latitude: "24.68773078918457",
    longitude: "46.72185134887695",
    platform1: token ? 'Web' : undefined,
    appVersion: token ? appVersion : undefined,
    deviceName: token ? browserObject.name : undefined,
    osVersion: token ? browserObject.version : undefined,
    userId: token
  };
  getLocation().then((_DefLocation) => {
    data.latitude = _DefLocation.latitude;
    data.longitude = _DefLocation.longitude;
  });
  return fetch(baseUrl + "/PropertyFeatured/getFeaturedPropertiesInLocation/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  })
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getPropertyDescription(props) {
  let data;
  if (props.userId)
    data = {
      propertyId: props.propertyId,
      language: props.language,
      userId: props.userId,
    };
  else data = { propertyId: props.propertyId, language: props.language };
  return fetch(baseUrl + "/Property/getPropertyDescription/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  })
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getRelatedProperty(props) {
  let data;
  if (props.userId)
    data = {
      propertyId: props.propertyId,
      language: props.language,
      userId: props.userId,
    };
  else data = { propertyId: props.propertyId, language: props.language };
  return fetch(baseUrl + "/Property/getRelatedProperties/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  })
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getMyProperties(props) {
  return fetch(baseUrl + "/Property/getMyProperties/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function getFavouritesForUser(props) {
  return fetch(baseUrl + "/Property/getFavouritesForUser/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function searchPropertiesWithFilter(props) {
  return fetch(baseUrl + "/Property/searchPropertiesWithFilter/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function searchPropertiesWithFilterWL(props) {
  return fetch(baseUrl + "/Property/searchPropertiesWithFilterWL/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function AddPropertyStep1(props) {
  return fetch(baseUrl + "/Property/addPropertyStep1New/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function addPropertyStep2(props) {
  return fetch(baseUrl + "/Property/addPropertyStep2/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function uploadPropertyImage(props) {
  return fetch(baseUrl + "/Property/uploadPropertyImage/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function getHomeFeaturedForUser(props) {
  return fetch(baseUrl + "/Property/getHomeFeaturedForUser/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function getHomeFeaturedOnly(props) {
  return fetch(baseUrl + "/Property/getHomeFeaturedOnly/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}


export function saveHomeFeatured(props) {
  return fetch(baseUrl + "/Property/saveHomeFeatured/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function savePropertyPhotos(props) {
  return fetch(baseUrl + "/Property/savePropertyPhotos/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function savePropertyFloorPlans(props) {
  return fetch(baseUrl + "/Property/savePropertyFloorPlans/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function savePropertyRooms(props) {
  return fetch(baseUrl + "/Property/savePropertyRooms/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function getAllParamsForAddProperty(props) {
  return fetch(
    baseUrl + "/Property/getAllParamsForAddPropertyForPropertyType/",
    {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(props),
    }
  )
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function getAllParamsForFilterForPropertyType(props) {
  return fetch(baseUrl + "/Property/getAllParamsForFilterForPropertyType/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function publishMyListing(props) {
  return fetch(baseUrl + "/Property/publishMyListing/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function addFavourite(props) {
  return fetch(baseUrl + "/Property/addFavourite/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function removeFavourite(props) {
  return fetch(baseUrl + "/Property/removeFavourite/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function getPropertiesOfOwnerNew(props) {
  return fetch(baseUrl + "/Property/getPropertiesOfOwnerNew/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function getCompanyDetailsNew(props) {
  return fetch(baseUrl + "/Property/getCompanyDetailsNew/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function getVerifiedCompaniesNew(props) {
  return fetch(baseUrl + "/Login/getVerifiedCompaniesNew/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function getREGASubjects(props) {
  return fetch(baseUrl + "/Property/getREGASubjects/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function createREGAIssue(props) {
  return fetch(baseUrl + "/Property/createREGAIssue/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function addOrChangeLikeStatusForProperty(props) {
  return fetch(baseUrl + "/Property/addOrChangeLikeStatusForProperty/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function getPublishedUserProperties(props) {
  return fetch(baseUrl + "/Property/getPublishedUserProperties/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function getUserStatus(props) {
  return fetch(baseUrl + "/Property/getUserStatus/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function checkPromo(props) {
  return fetch(baseUrl + "/Property/checkPromo/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function validateProperty(props) {
  return fetch(baseUrl + "/Property/validateProperty/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function saveLocation(props) {
  return fetch(baseUrl + "/Property/saveLocation/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function getPropertiesWithTickets(props) {
  return fetch(baseUrl + "/Property/getPropertiesWithTickets/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
export function createAd(props) {
  return fetch(baseUrl + "/Property/createAd/", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

//upload images to nhc
// export function uploadNhc(userId, file) {
//   let formData = new FormData();
//   formData.append('file', file);
//   formData.append('userId', userId);
//   return fetch(baseUrl + "/Property/uploadNhc/", {
//     method: "POST",
//     // headers: { "content-type": "application/json" },
//     body: formData
//   })
//     .then(handleResponse)
//     .catch(handleError);
// }

export function getMyPromo(props) {
  return fetch(baseUrl + `/Property/getMyPromo/`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function uploadVideo(props) {
  let formData = new FormData();
  formData.append('video', props.file);
  formData.append('userId', props.userId);
  formData.append('propertyId', props.propertyId);
  formData.append('index', props.index);

  return fetch(baseUrl + "/Property/uploadVideo/", {
    method: "POST",
    body: formData
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function saveFeatured(props) {
  return fetch(baseUrl + `/Property/featured/save/`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

export function featuredRemaining(props) {
  return fetch(baseUrl + `/Property/featured/remaining/`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}
// export function platformCompliance(props) {
//   return fetch(baseUrl + `/Property/platformCompliance/`, {
//     method: "POST",
//     headers: { "content-type": "application/json" },
//     body: JSON.stringify(props),
//   })
//     .then(res => handleResponse(res, props))
//     .catch(handleError);
// }
export function getCompanyDetailsWithCompanyId(props) {
  return fetch(baseUrl + `/Property/getCompanyDetailsWithCompanyId/`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(props),
  })
    .then(res => handleResponse(res, props))
    .catch(handleError);
}

