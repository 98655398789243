import { Avatar, Badge, Box, ClickAwayListener, Divider, Grow, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Popper, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useTranslation } from 'react-i18next';
import NoData from "../../global-components/no-data";
import moment from "moment";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useNavigate } from 'react-router-dom';
import { getMyNotifications, markAsRead } from '../../../api/notifications-api';
import { useAuth } from "../../../helpers/context";

export default function NotificationsPopper() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openPopper, setOpenPopper] = React.useState(false);
    const { t, i18n } = useTranslation();
    const { token, setUnreadNotificationsCount, unreadNotificationsCount } = useAuth();
    const history = useNavigate();
    const [data, setData] = useState([]);
    const [totalNotificationsCount, setTotalNotificationsCount] = useState(0);

    useEffect(() => {
        refreshNotifications();
    },// eslint-disable-next-line
        [unreadNotificationsCount, i18n.language]);
    
    
    
    const refreshNotifications = () => {
        getMyNotifications({
            "userId": token,
            "page": 1,
            "pageSize": 6,
            "language": i18n.language === "ar" ? "0" : "1",
            // "isRead": "",
            // "searchString": ""
        }).then(res => {
           
            if (res.resCode === 0) {
                setData(res.response.array);
                setUnreadNotificationsCount(res.response.unreadCount);
                setTotalNotificationsCount(res.response.totalCount);
            }
        });
    };

    return (
        <>
            <Tooltip title={t("Notifications")}>
                <IconButton
                    edge='start'
                    onClick={(e) => {
                        setOpenPopper(!openPopper);
                        setAnchorEl(anchorEl ? null : e.target);
                    }}
                    sx={{
                        color: '#121212',
                        background: 'none',
                        '&:hover': { color: 'var(--main-color-one)', background: 'none' },
                        marginRight: "0px",
                        borderRadius: '8px',
                        width: '33px',
                        height: '33px',
                        top: 7
                    }}>
                    <Badge badgeContent={unreadNotificationsCount || 0} color="primary"
                        sx={{
                            '& .MuiBadge-badge': {
                                bgcolor: 'var(--main-color-one)',
                                left: -15,
                                right: 'unset'
                            },
                            
                        }}>
                        <NotificationsNoneIcon sx={{ width: 26, height: 26 }} />
                    </Badge>
                </IconButton>
            </Tooltip>
          
            <Popper
                sx={{
                    zIndex: 100,
                    offset: '55px 0 0 5px',
                }}
                open={openPopper}
                role={undefined}
                transition
                placement='bottom-start'
                anchorEl={anchorEl}
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'center top',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => { setAnchorEl(null); setOpenPopper(false); }}>
                                <Box sx={{ minWidth: '290px', width: '290px' }}>
                                    {data?.length > 0 ? <>
                                        <List sx={{ pb: 0, width: '100%', bgcolor: 'background.paper' }}>
                                            {data?.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <ListItem
                                                        key={item.id}
                                                        alignItems="center"
                                                        className={`py-1 ${item.isRead ? '' : 'new'} notification`}
                                                        onClick={() => {

                                                            if (!item.isRead) {
                                                                markAsRead({
                                                                    "userId": token,
                                                                    "id": item.id,
                                                                    "language": i18n.language === 'ar' ? "0" : '1'
                                                                });
                                                                setUnreadNotificationsCount(unreadNotificationsCount - 1);
                                                            }
                                                            if (item.modelType === "Task" && !item.body.includes("delete"))
                                                                history(`/admin/tasks?task=${item.modelId}&${new Date().getTime()}`);
                                                            else if (item.modelType === "Bargain" && !item.body.includes("delete")) {
                                                                history(`/admin/deals?deal=${item.modelId}&${new Date().getTime()}`);
                                                            }
                                                            setAnchorEl(null);
                                                            setOpenPopper(false);
                                                        }}
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar style={{ width: '50px', height: '50px', boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", border: '1px solid #ddd' }} alt={item.body} src={item?.extra?.senderAvatar}>
                                                                <PersonOutlineIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={item.body}
                                                            secondary={moment(item.createdAt).add(3, 'hours').fromNow()}
                                                            sx={{
                                                                "& .MuiTypography-body1": { fontSize: '12px' },
                                                                "& .MuiTypography-body2": { fontSize: '10px' },
                                                            }}
                                                        />
                                                        <div>
                                                            {item.isRead ? <>&nbsp;</> : <span
                                                                style={{
                                                                    width: '9px',
                                                                    height: '9px',
                                                                    borderRadius: '100%',
                                                                    display: 'block',
                                                                    background: 'var(--main-color-one)'
                                                                }}></span>}
                                                        </div>
                                                   
                                                    </ListItem>
                                                    {index >= data?.length - 1 ?
                                                        <></> :
                                                        <Divider variant="fullWidth" component="li" />
                                                    }
    
                                                </React.Fragment>
                                            ))}
                                        </List>
                                        {totalNotificationsCount > 6 && <button
                                            onClick={() => {
                                                setAnchorEl(null); setOpenPopper(false);
                                                history('/admin/notifications');
                                            }}
                                            className='w-100 no-border-radius primary-btn d-flex justify-content-center'
                                            style={{ borderRadius: '0!important' }}>
                                            {t("ShowAllNotifications")}
                                        </button>}
                                    </> : (
                                        <NoData msg={t("NoNotificationsFound")} />
                                    )}
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
