import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPublicProjectDetails } from "../../../../api/userApi";
import PropertyBox from "../../../../components/section-components/property-box";
import { Link } from "react-router-dom";

const isAdmin = window?.location?.href?.includes('admin');

const RelatedProject = (props) => {
  const [project, setProject] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (props.projectId && props.projectId !== "None") {
      getPublicProjectDetails({
        language: i18n.language === "ar" ? '0' : '1',
        projectId: props.projectId
      }).then((res) => {
        if (res?.resCode === 0) {
          setProject(res?.response);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectId, i18n.language]);
  

  return (
    <>
      <div className={`recommended-area`}>
        {project && <h4 className="mb-3">{t("AboutProject")}</h4>}
        <div className='row mx-0' >
          <div className="col-12 p-0">
            <h4 className="mb-1 mt-0">
              <Link
                style={{ color: 'var(--main-color-one)', fontSize: '20px', textDecoration: 'underline' }}
                to={isAdmin ? `/admin/project-details/${project?.id}` : `/project/${project?.id}`}>{project?.name}</Link>
            </h4>
          </div>
          <div className="col-12 p-0"><p>{project?.description}</p></div>
        </div>
      </div>
      <div className={`recommended-area ${props.isMap ? '' : 'pd-bottom-70'}`}>
        {project && <h4>{t("RealEstateADsInSameProject")}</h4>}
        <div className='row mx-0' >
          {project?.relatedProperties?.length > 0 && (
            project?.relatedProperties?.map((item, i) => +item.propertyId !== +props?.id && (
              <div
                key={i}
                className='col-xl-3 col-lg-4 col-md-4 col-sm-6'
                style={{ cursor: "pointer" }}>
                <PropertyBox
                  isAdmin={isAdmin}
                  disableLink={props.isMap}
                  onItemSelected={props.isMap ? props.onItemSelected : null}
                  propertey={item} key={i} isHashed={true} />
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
  
}

export default RelatedProject;
